import axios from 'axios';
import authHeader from '../Auth/auth-header';



let axiosConfig = {
    headers: authHeader()
};
let axiosConfigs = {
  headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
  }
};

let API_URL = process.env.REACT_APP_API_URL + 'api/v1';
//let SERVICE_URL= ' http://localhost:8084/mirth-monitoring';
let SERVICE_URL= process.env.REACT_APP_API_URL +'api/mirth-monitoring';
let SETTINGS_URL= process.env.REACT_APP_API_URL +'api/general-settings';
  var XMLParser = require('react-xml-parser') ;

// export async function getData(endpoint,request) {
//     try {
//       const { data: data_res } = await axios.get(
//         `${endpoint}?request=${request}`
//       )
//       var xml = new XMLParser().parseFromString(data_res) ;                                      
     
//       return xml
//     } catch (e) {  
//       return e
//     }
//   }

  export async function getData(endpoint,request) {
    try {
      const { data: data_res } = await axios.post(
        `${SERVICE_URL}/channel/getDataXML`,
         `${endpoint}?request=${request}`,
        {headers: authHeader() }
      )
      var xml = new XMLParser().parseFromString(data_res) ;                                      
     
      return xml
    } catch (e) {  
      return e
    }
  }
  export async function getDataJSON(endpoint,request) {
    try {
      const { data: data_res } = await axios.post(
        `${SERVICE_URL}/channel/getDataXML`,
         `${endpoint}?request=${request}`,
        {headers: authHeader() }
      )
      
      return data_res
    } catch (e) {  
      return e
    }
  }
  export async function getDataNew(endpoint,request) {
    try {
      const { data: data_res } = await axios.post(
        `${SERVICE_URL}/channel/getDataJSON`,
         `${endpoint}?request=${request}`,
        {headers: authHeader() }
      )
  
      return data_res
    } catch (e) {  
      return e
    }
  }
export async function createServer(data) {
    try {
      const { data: data_res } = await axios.post(
        `${SERVICE_URL}/server/create`,
        data,
        {
          headers: authHeader()
      }
      )
     console.log(data_res);
      return data_res
    } catch (e) {  
      return e
    }
  }
  export async function deleteServer(data) {
    try {
      const { data: data_res } = await axios.post(
        `${SERVICE_URL}/server/delete`,
        data,
        {
          headers: authHeader()
      }
      )
     console.log(data_res);
      return data_res
    } catch (e) {  
      return e
    }
  }
  export async function updateServer(data) {
    try {
      const { data: data_res } = await axios.post(
        `${SERVICE_URL}/server/update`,
        data,
        {
          headers: authHeader()
      }
      )
     console.log(data_res);
      return data_res
    } catch (e) {  
      return e
    }
  }
export async function getServerList() {

    try {
      console.log(axiosConfig)
        const { data: response } = await axios.get(
            `${SERVICE_URL}/server/list`,{
              headers: authHeader()
          }
        )
        return response;
    }
    catch (error) {
        return error;
    }
}
export async function getServerExistByName(name) {
  try {
    const { data: data_res } = await axios.post(
      `${SERVICE_URL}/server/existByName?name=${name}`,
      {
        headers: authHeader()
    }
    )
   console.log(data_res);
    return data_res
  } catch (e) {  
    return e
  }
}
export async function getServerByToken(token) {
  try {
    const { data: data_res } = await axios.post(
      `${SERVICE_URL}/server/getByToken?token=${token}`,
      {
        headers: authHeader()
    }
    )
   console.log(data_res);
    return data_res
  } catch (e) {  
    return e
  }
}
export async function getRoleList() {

  try {
      const { data: response } = await axios.get(
          `${SERVICE_URL}/roles/list`,{
            headers: authHeader()
        }
      )
      return response;
  }
  catch (error) {
      return error;
  }
}

export async function createUser(data) {
  try {
    let op;
    await axios.post(
      `${SERVICE_URL}/create-user`,
      data,
      {
        headers: authHeader()
    }
    ).then(function (done){ op = done.data })
    .catch(function (error) { console.log(error.response.data);op= error.response.data})
   // op = data_res
    return op
  } catch (e) {  
    return e
  }
}

export async function getChannelStats(data) {

  try {
      const { data: response } = await axios.post(
          `${SERVICE_URL}/channel-stat/list`,data,{
            headers: authHeader()
        }
      )
      return response;
  }
  catch (error) {
      return error;
  }
}

export async function getChannelStatsDiff(data) {

  try {
      const { data: response } = await axios.post(
          `${SERVICE_URL}/channel-stat/diff_list`,data,{
            headers: authHeader()
        }
      )
      return response;
  }
  catch (error) {
      return error;
  }
}
export async function getChannelStatsBySub(data) {

  try {
      const { data: response } = await axios.post(
          `${SERVICE_URL}/channel-stat/listBySub`,data,{
            headers: authHeader()
        }
      )
      return response;
  }
  catch (error) {
      return error;
  }
}

export async function getChannelStatsDiffBySub(data) {

  try {
      const { data: response } = await axios.post(
          `${SERVICE_URL}/channel-stat/diff_listBySub`,data,{
            headers: authHeader()
        }
      )
      return response;
  }
  catch (error) {
      return error;
  }
}
export async function getPGToastStats(data) {

  try {
      const { data: response } = await axios.post(
          `${SETTINGS_URL}/pgToast/list`,data,{
            headers: authHeader()
        }
      )
      return response;
  }
  catch (error) {
      return error;
  }
}
export async function getPGToastStatsBySub(data) {

  try {
      const { data: response } = await axios.post(
          `${SETTINGS_URL}/pgToast/listBySub`,data,{
            headers: authHeader()
        }
      )
      return response;
  }
  catch (error) {
      return error;
  }
}
export async function getLatestChannelStats(token) {

  try {
      const { data: response } = await axios.post(
          `${SERVICE_URL}/channel-stat/latest?accessToken=${token}`,{
            headers: authHeader()
        }
      )
      return response;
  }
  catch (error) {
      return error;
  }
}
export async function getMirthServerDetails(serverId) {
  try {
    const { data: data_res } = await axios.post(
      `${SERVICE_URL}/server-details/list?accessToken=${serverId}`,
      {headers: authHeader() }
    )
   console.log(data_res)
    return data_res;
  } catch (e) {  
    return e
  }
}
export async function getMirthServerDetailsByOrgId(id) {
  try {
    const { data: data_res } = await axios.post(
      `${SERVICE_URL}/server-details/listAllByOrg?orgId=${id}`,
      {headers: authHeader() }
    )
   console.log(data_res)
    return data_res;
  } catch (e) {  
    return e
  }
}
export async function getServerByOrgId(id) {
  try {
    const { data: data_res } = await axios.post(
      `${SERVICE_URL}/server/getByOrgId`,id,
      {headers: authHeader() }
    )
   
    return data_res;
  } catch (e) {  
    return e
  }
}
export async function getServerDetailsByOrgId(id) {
  try {
    const { data: data_res } = await axios.post(
      `${SERVICE_URL}/server/getDetailsByOrgId`,id,
      {headers: authHeader() }
    )
   
    return data_res;
  } catch (e) {  
    return e
  }
}
export async function getAllServerByOrgId(id) {
  try {
    const { data: data_res } = await axios.post(
      `${SERVICE_URL}/server/all/getByOrgId`,id,
      {headers: authHeader() }
    )
   
    return data_res;
  } catch (e) {  
    return e
  }
}
export async function getOrgById(id) {
  try {
    console.log(id)
    const { data: data_res } = await axios.post(
      `${SERVICE_URL}/org/getById`,id,
      {headers: authHeader() }
    )
    
    return data_res
  } catch (e) {  
    return e
  }
}
export async function getAllOrg(id) {
  try {
    console.log(id)
    const { data: data_res } = await axios.get(
      `${SERVICE_URL}/org/list`,
      {headers: authHeader() }
    )
    
    return data_res
  } catch (e) {  
    return e
  }
}

export async function getAlertsByToken(token) {
  try {
    const { data: data_res } = await axios.post(
      `${SERVICE_URL}/alerts/getByToken`,token,
      {headers: authHeader() }
    )
    
    return data_res
  } catch (e) {  
    return e
  }
}
export async function pdf(token,data,request) {
  try {
  
    let filter={
      "token":token,
      "page":data,
      "fromDate":request.fromDate,
      "toDate":request.toDate,
      "userName":request.userName

    }
    console.log(typeof(filter))
      const user = JSON.parse(localStorage.getItem('user'));
      console.log(axiosConfig)
          const { data: response } = await axios.post(
            `${SETTINGS_URL}/pdf`,
            filter,
      
      {
        headers: authHeader(),
          responseType:'arraybuffer'
      
      }
      
    )
    return response;
  }

catch (error) {
return error;
}
}



      
export async function getSubscriptionsByOrg(id) {
  try {

    const { data: data_res } = await axios.post(
      `${SETTINGS_URL}/subscription/getByOrgId`,id,
      {headers: authHeader() }
    )
    
    return data_res
  } catch (e) {  
    return e
  }
}
export async function getAllSubscriptions() {
  try {

    const { data: data_res } = await axios.get(
      `${SETTINGS_URL}/subscription/getAllPlans`,
      {headers: authHeader() }
    )
    
    return data_res
  } catch (e) {  
    return e
  }
}
export async function saveSubscription(data) {
  try {

    const { data: data_res } = await axios.post(
      `${SETTINGS_URL}/subscription/save`,data,
      {headers: authHeader() }
    )
    
    return data_res
  } catch (e) {  
    return e
  }
}
export async function updateSubscription(data) {
  try {

    const { data: data_res } = await axios.post(
      `${SETTINGS_URL}/subscription/update`,data,
      {headers: authHeader() }
    )
    
    return data_res
  } catch (e) {  
    return e
  }
}
export async function deleteSubscription(data) {
  try {

    const { data: data_res } = await axios.post(
      `${SETTINGS_URL}/subscription/delete`,data,
      {headers: authHeader() }
    )
    
    return data_res
  } catch (e) {  
    return e
  }
}
export async function checkIfOrgIsValid(data) {
  try {

    const { data: data_res } = await axios.post(
      `${SETTINGS_URL}/subscription/checkIfValid`,data,
      {headers: authHeader() }
    )
    
    return data_res
  } catch (e) {  
    return e
  }
}
export async function saveOrganization(data) {
  try {

    const { data: data_res } = await axios.post(
      `${SERVICE_URL}/org/saveOrg`,data,
      {headers: authHeader() }
    )
    
    return data_res
  } catch (e) {  
    return e
  }
}
export async function updateOrganization(data) {
  try {

    const { data: data_res } = await axios.post(
      `${SERVICE_URL}/org/update`,data,
      {headers: authHeader() }
    )
    
    return data_res
  } catch (e) {  
    return e
  }
}
export async function deleteOrganization(data) {
  try {

    const { data: data_res } = await axios.post(
      `${SERVICE_URL}/org/delete`,data,
      {headers: authHeader() }
    )
    
    return data_res
  } catch (e) {  
    return e
  }
}

export async function getJobDetails(orgId) {

  try {
      const { data: response } = await axios.get(
          `${SETTINGS_URL}/jobDetails/getDeletedJobs?orgId=${orgId}`,{
            headers: authHeader()
        }
      )
      return response;
  }
  catch (error) {
      return error;
  }
}

export async function isJobNameExists(orgId,jobName) {

  try {
      const { data: response } = await axios.post(
          `${SETTINGS_URL}/jobDetails/isJobNameExists?jobName=${jobName}&orgId=${orgId}`,{
            headers: authHeader()
        }
      )
      return response;
  }
  catch (error) {
      return error;
  }
}
export async function updateJobActiveStatus(orgId,jobName) {

  try {
      const { data: response } = await axios.post(
          `${SETTINGS_URL}/jobDetails/updateJobActiveStatus?jobName=${jobName}&orgId=${orgId}`,{
            headers: authHeader()
        }
      )
      return response;
  }
  catch (error) {
      return error;
  }
}

export async function getEmailAlertData(id) {
  try {
    const { data: data_res } = await axios.post(
      `${SETTINGS_URL}/email-alert/getEmailAlert?orgId=${id}`,
      {headers: authHeader() }
    )
   
    return data_res;
  } catch (e) {  
    return e
  }
}

export async function getServerDetails(token) {
  try {
    const { data: data_res } = await axios.post(
      `${SERVICE_URL}/server-details/getByToken?accessToken=${token}`,
      {headers: authHeader() }
    )
   
    return data_res;
  } catch (e) {  
    return e
  }
}

export async function getChannelCount(token) {
  try {
    const { data: data_res } = await axios.post(
      `${SERVICE_URL}/server-details/channelCountByToken?accessToken=${token}`,
      {headers: authHeader() }
    )
   
    return data_res;
  } catch (e) {  
    return e
  }
}

export async function getSubscribedChannels(token) {
  try {
    const { data: data_res } = await axios.post(
      `${SERVICE_URL}/server-details/subscribed/listByToken?accessToken=${token}`,
      {headers: authHeader() }
    )
   
    return data_res;
  } catch (e) {  
    return e
  }
}

export async function getVulnerabilitiesByVersion(id) {
  try {
    const { data: data_res } = await axios.get(
      `${SETTINGS_URL}/vulnerabilities/getByVersion?version=${id}`,
      {headers: authHeader() }
    )
   
    return data_res;
  } catch (e) {  
    return e
  }
}
export async function getAllVulnerabilities() {
  try {
    const { data: data_res } = await axios.get(
      `${SETTINGS_URL}/vulnerabilities/getAll`,
      {headers: authHeader() }
    )
   
    return data_res;
  } catch (e) {  
    return e
  }
}
export async function saveVulnerabilities(data) {
  try {

    const { data: data_res } = await axios.post(
      `${SETTINGS_URL}/vulnerabilities/save`,data,
      {headers: authHeader() }
    )
    
    return data_res
  } catch (e) {  
    return e
  }
}
export async function updateVulnerabilities(data) {
  try {

    const { data: data_res } = await axios.post(
      `${SETTINGS_URL}/vulnerabilities/update`,data,
      {headers: authHeader() }
    )
    
    return data_res
  } catch (e) {  
    return e
  }
}
export async function deleteVulnerabilities(data) {
  try {

    const { data: data_res } = await axios.post(
      `${SETTINGS_URL}/vulnerabilities/delete`,data,
      {headers: authHeader() }
    )
    
    return data_res
  } catch (e) {  
    return e
  }
}