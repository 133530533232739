import React, { Component } from "react";

import {
  Breadcrumb,
  Card,
  Spin,
  Table,
  Steps,
  Divider,
  Row,
  Col,
  Form,
  Button,
  notification,
  Input,
  Typography,
  Tabs,
  Space,
  Tooltip,
  InputNumber,
  Select,
} from "antd";
// import './Settings.css';

import {
  PlusOutlined,
  PlusCircleFilled,
  DownloadOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeFilled,
  EyeTwoTone,
  EyeInvisibleFilled,
} from "@ant-design/icons";
// import 'antd/dist/antd.css';
import { SearchBar } from "../../../components";
import "../../../styles/custom-css/antd-custom.css";
import {
  getServerList,
  createServer,
  updateServer,
  deleteServer,
  getServerByOrgId,
  saveOrganization,
  getAllOrg,
  saveSubscription,
  getSubscriptionsByOrg,
  deleteOrganization,
  deleteSubscription,
  updateOrganization,
  updateSubscription,
  getAllSubscriptions
} from "../../../service/mirth/MirthService";
import moment from "moment-timezone";
import AuthService from "../../../service/Auth/auth-service";

const { Meta } = Card;
const { Option } = Select;
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: { offset: 10, span: 16 },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};
const plans = [
  {
    id: "1",
    name: "Plan 1",
    servers: "2",
    channels: "10",
    duration: "365 days",
    isSelected: false,
  },
  {
    id: "2",
    name: "Plan 2",
    servers: "5",
    channels: "10",
    duration: "365 days",
    isSelected: false,
  },
  {
    id: "3",
    name: "Plan 3",
    servers: "5",
    channels: "15",
    duration: "365 days",
    isSelected: false,
  },
];
const openNotification = (message) => {
  notification.success({
    message: message,
  });
};
const uid = function () {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
};
export default class SubscriptionSettings extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      spin: true,
      orgDetails: [],
      orgWithoutSub: [],
      cloneOrgDetails: [],
      isFormVisible: false,
      isEdit: false,
      currentServer: {},
      initialValues: {},
      orgRequest: {},
      confirmFormLoading: false,
      tabKey: "1",
      access: "",
      activeStep: 0,
      plans: plans,
      durationAddOn : 'days'
    };

    this.loadData = this.loadData.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleActionClick = this.handleActionClick.bind(this);
  }
  componentDidMount() {
    this.loadData();
  }

  loadData() {
    // getAllOrg().then((res) => {
    //   if (res.code == 200) {
    //     let result = res.result;
    //     let orgWithoutSub = [];
    //     this.setState({
    //       orgDetails: result,
    //       cloneOrgDetails: result,
    //       access: AuthService.getCurrentUserAccess(),
    //     });
    //     for (let i = 0; i < result.length; i++) {
    //       getSubscriptionsByOrg(result[i].orgId).then((subRes) => {
    //         if (subRes.code == 200 && subRes.result.length > 0) {
    //           let subResult = subRes.result[i];
    //           result[i].planDuration = subResult.planDuration;
    //           result[i].planExpiryOn = moment(subResult.planExpiryOn)
    //             .tz("UTC")
    //             .format("YYYY-MM-DD HH:mm:ss");
    //           let attrs = JSON.parse(subResult.planAttributes);
    //           result[i].channelCount = attrs.channelCount;
    //           result[i].serverCount = attrs.serverCount;
    //         } else {
    //           orgWithoutSub.push(result[i]);
    //         }
    //         if (i == result.length - 1) {
    //           this.setState({
    //             orgDetails: result,
    //             cloneOrgDetails: result,
    //             spin: false,
    //             access: AuthService.getCurrentUserAccess(),
    //             orgWithoutSub: orgWithoutSub,
    //           });
    //         }
    //       });
    //     }
    //   }
    // });

    getAllSubscriptions().then((res) => {
      let orgWithoutSub = [];
      let orgWithSub = [];
      if (res.code == 200 && res.result.length > 0) {
        let result = res.result;
        for (let i = 0; i < result.length; i++) {
          let subResult = result[i];
            if (subResult.plan_attributes !== null) {
              let finalObj ={};
              finalObj.orgId =   subResult.org_id
              finalObj.orgName =  subResult.org_name
              finalObj.orgDomain = subResult.org_domain
              finalObj.planDuration =  subResult.plan_duration
              finalObj.planId =  subResult.plan_id
              finalObj.planExpiryOn = moment(subResult.plan_expiry_on)
                .tz("UTC")
                .format("YYYY-MM-DD HH:mm:ss");
              let attrs = JSON.parse(subResult.plan_attributes);
              finalObj.channelCount = attrs.channelCount;
              finalObj.serverCount = attrs.serverCount;
              orgWithSub.push(finalObj);
            } else {
              let finalObj ={};
              finalObj.orgId =   subResult.org_id
              finalObj.orgName =  subResult.org_name
              finalObj.orgDomain = subResult.org_domain
              orgWithoutSub.push(finalObj);
            }
            if (i == result.length - 1) {
              this.setState({
                orgDetails: orgWithSub,
                cloneOrgDetails: orgWithSub,
                spin: false,
                access: AuthService.getCurrentUserAccess(),
                orgWithoutSub: orgWithoutSub,
              });
            }
        }
      }
    })
  }

  handleSearch(data) {
    this.setState({ orgDetails: data });
  }
  showForm = () => {
    this.setState({ initialValues: {}, isEdit: false, isFormVisible: true });
  };

  onFinish = (values) => {
    this.setState({ confirmFormLoading: true, spin: true });
  
    let request = this.formRef.current.getFieldsValue();
    this.formRef.current.resetFields();

   
    let attr = {
      serverCount: request.servers.toString(),
      channelCount: request.channels.toString(),
    };
    request.planAttributes = JSON.stringify(attr);
    request.planDuration = request.duration+" "+this.state.durationAddOn;
    var end_date;
    var start_date = moment.tz("UTC");

  
      end_date = moment(start_date)
        .add(request.duration, this.state.durationAddOn)
        .format("YYYY-MM-DD HH:mm:ss");
    

    request.planExpiryOn = new Date(end_date);
    console.log(request)
    saveSubscription(request).then((subRes) => {
      if (subRes.code == 200) {
       
       
        this.loadData();
        this.setState({
          confirmFormLoading: false,
          isFormVisible: false,
          
        });
        openNotification("Subscription Added successfully!");
      }
    });
  };
  onReset = () => {
    this.formRef.current.resetFields();

    this.setState({ isFormVisible: false, isEdit: false });
  };
  onResets = () => {
    this.formRef.current.resetFields();
  };
  addonChange =(val) =>{
    this.setState({ durationAddOn:val});
  }
  handleActionClick = (record, action) => {
    this.setState({ initialValues: record, spin: true });
    if (action == "delete") {
      deleteSubscription(record.planId).then((subres) => {
        if (subres.code == 200) {
          
         
          this.loadData();
          this.setState({ initialValues: {}, isEdit: false});
          openNotification("Subscription removed successfully!");
        }
      });
    } else if (action == "edit") {
      this.formRef.current.setFieldsValue(record);
      this.setState({ isEdit: true, spin: false });
    }
  };

  planUpdate(val) {
    let availablePlans = this.state.plans;

    availablePlans.map((plan) => {
      if (plan.id == val.id) {
        plan.isSelected = true;
      } else {
        plan.isSelected = false;
      }
    });
    this.setState({ plans: availablePlans });
  }
  render() {
    const {
      spin,
      orgDetails,
      plans,
      activeStep,
      orgWithoutSub,
      access,
      cloneOrgDetails,
      isFormVisible,
      initialValues,
      confirmFormLoading,
      tabKey,
      isEdit,
    } = this.state;
    const actionStatus = () => {
      if (access == "read&write") {
        return false;
      } else {
        return true;
      }
    };
    const { getFieldDecorator } = this.formRef;
    const selectAfter = (
        <Select defaultValue="days" className="select-after" onChange={this.addonChange}>
          <Option value="days">days</Option>
          <Option value="months">months</Option>
          <Option value="years">years</Option>
        </Select>
      );
    const orgColumns = [
      { title: "Organization Name", dataIndex: "orgName", key: "orgName" },
      {
        title: "Organization Domain",
        dataIndex: "orgDomain",
        key: "orgDomain",
      },
      {
        title: "Plan Duartion",
        dataIndex: "planDuration",
        key: "planDuration",
      },
      {
        title: "Plan Expires on",
        dataIndex: "planExpiryOn",
        key: "planExpiryOn",
      },
      { title: "Max Channels", dataIndex: "channelCount", key: "channelCount" },
      { title: "Max Servers", dataIndex: "serverCount", key: "serverCount" },
      {
        title: "Actions",
        dataIndex: "",
        key: "",
        render: (text, record) => (
          <>
            <Space>
              {/* <Tooltip title="Edit">
                            <Button  type="text" disabled={actionStatus()}  onClick={() => { this.handleActionClick(record, 'edit') }} icon={<EditOutlined />}/>
                              
                            </Tooltip> */}
              <Tooltip title="Delete">
                <Button
                  type="text"
                  disabled={actionStatus()}
                  onClick={() => {
                    this.handleActionClick(record, "delete");
                  }}
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </Space>
          </>
        ),
      },
    ];

    return (
      <>
        <Breadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Settings</Breadcrumb.Item>
          <Breadcrumb.Item>Subscription</Breadcrumb.Item>
        </Breadcrumb>

        <Spin size="large" spinning={spin}>
          <div class="content_panel">
            <Row>
              <Col span={12}>
                {!isEdit && !isFormVisible && (
                  <div class="content_panel_heading">Subscription</div>
                )}
                {isEdit && (
                  <div class="content_panel_heading">Edit Subscription</div>
                )}
                {isFormVisible && (
                  <div class="content_panel_heading">Add Subscription</div>
                )}
              </Col>
              <Col span={12}>
                {!isEdit && !isFormVisible && (
                  <Button
                    disabled={actionStatus()}
                    style={{
                      float: "right",
                      borderRadius: "7px",
                      backgroundColor: "#efefef",
                      fontFamily: "AktivGroteskMedium",
                      border: "none",
                    }}
                    onClick={this.showForm}
                  >
                    <PlusCircleFilled
                      style={{ color: "#fc9558", fontSize: "16px" }}
                    />{" "}
                    Add Subscription
                  </Button>
                )}
              </Col>
            </Row>
            <div class="divider-1">
              {" "}
              <span></span>
            </div>
            {/* <Divider /> */}

            <Form
              ref={this.formRef}
              labelCol={{ span: 10 }}
              hideRequiredMark
              wrapperCol={{ span: 16 }}
              name="control-ref"
              onFinish={this.onFinish}
            >
              {(isEdit || isFormVisible) && (
                <>
                  <Form.Item
                    name="orgId"
                    label="Organization"
                    rules={[
                      {
                        required: true,
                        message: "Please select Name!",
                      },
                    ]}
                  >
                    <Select  style={{ width: "300px" }}>
                      {orgWithoutSub.map((org) => (
                        <Option key={org.orgId} value={org.orgId}>
                          {org.orgName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name="servers" label="Server Limit"
    
                    rules={[
                        {
                          required: true,
                          message: "Please input limit!",
                        },
                      ]}>
                    <InputNumber  style={{ width: "300px",borderRadius:'7px' }} min={1} max={20} />
                  </Form.Item>
                  <Form.Item name="channels" label="Channel Limit" 
                 
                    rules={[
                        {
                          required: true,
                          message: "Please input limit!",
                        },
                      ]}>
                    <InputNumber  style={{ width: "300px",borderRadius:'7px' }} min={1} max={300} />
                  </Form.Item>
                  <Form.Item
                    name="duration"
                    label="Duration"
                    rules={[
                      {
                        required: true,
                        message: "Please select duration!",
                      },
                      {
                        pattern: new RegExp(/^[0-9]+$/),
                        message: "Please input numbers!",
                      }
                    ]}
                  >
           
                     <Input style={{ width: "300px" }}  addonAfter={selectAfter}  />
                  </Form.Item>
              



                 
                  {/* <Form.Item
                                      
                                        name="subDetails"
                                        label="Plan"
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message: 'Please choose plan!',
                                        //     },
                                        // ]}
                                    >
                        <div   style={{width:'800px'}}>
                            <Row gutter={10}>
                            {plans.map((plan) => (
                            <Col span={8}>
                        
                            <Card
                             hoverable
                             style={{backgroundColor:plan.isSelected &&'#f0f2f5'}}
                            //   cover={

                            //     <img
                            //       alt="example"
                            //       src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
                            //     />
                            //   }
                              actions={[
                                <Button type="primary" onClick={e => { e.stopPropagation(); this.planUpdate(plan)}}>GET</Button>
                                ,
                              ]}
                            >
                              <Meta
                               
                                title={plan.name}
                                description={ 
                                <> 
                                <Row>
                                    <Col span={12}><p style={{ color: 'grey' }}>Server Limit</p></Col>
                                    <Col span={12}>{plan.servers}</Col>
                                </Row>
                                <Row>
                                    <Col span={12}><p style={{ color: 'grey' }}>Channel Limit</p></Col>
                                    <Col span={12}>{plan.channels}</Col>
                                </Row>
                                <Row>
                                    <Col span={12}><p style={{ color: 'grey' }}>Duration</p></Col>
                                    <Col span={12}>{plan.duration}</Col>
                                </Row>
                                </>}
                              />
                            </Card>
                           
                            </Col>))}
                            
                            </Row>
                        </div>
                        </Form.Item> */}

                  <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                    <Button
                      htmlType="button"
                      style={{ backgroundColor: "#efefef", margin: "0 8px" }}
                      onClick={this.onReset}
                    >
                      Cancel
                    </Button>
                    <Button htmlType="button"style={{ backgroundColor: '#efefef',margin: "0 8px" }} onClick={this.onResets}> Reset</Button>
                  </Form.Item>
                </>
              )}
            </Form>

            {!isEdit && !isFormVisible && (
              <div>
                <SearchBar
                  data={cloneOrgDetails}
                  handler={this.handleSearch}
                ></SearchBar>

                <Table
                  style={{ marginTop: "10px" }}
                  columns={orgColumns}
                  dataSource={orgDetails}
                />
              </div>
            )}
          </div>
        </Spin>
      </>
    );
  }
}
