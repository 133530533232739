import React, { Component } from "react";
import { Column } from '@ant-design/charts';
import { Row, Col, Table, Typography, Card, Result, Tag, Space, Select, Tooltip, Modal, Form, Input, Button, DatePicker, Breadcrumb, Spin } from 'antd';
import {
    EyeOutlined
} from '@ant-design/icons';
import {
    getServerList
} from '../../../service/mirth/MirthService'
import { getChannelIdsandNames,getChannelStatistics } from '../../../service/mirth/ClientAPIService'
import './ChannelTrends.css';

const {Option} = Select;
const {Text} = Typography;


export default class ChannelTrend extends Component {

    constructor(props) {
        super(props)

        this.state = {
            spin:false,
            channelStatisticsData:[] ,
            serverOptions:['localhost'],
            barGraphData:[]
            
         }
         this.formatJson = this.formatJson.bind(this);
       
         this.handleServerChange = this.handleServerChange.bind(this);
    }

    componentDidMount() {
        let host = 'localhost:8443';
        getChannelIdsandNames(host,'admin','admin').then(res => {
            let list = res.map.entry;
            let channelarr=[];
            let statarr=[];
            for(let i=0;i<list.length;i++)
            {
                let channeljson ={id: list[i].string[0],name:list[i].string[1]};
                channelarr.push(channeljson);
                getChannelStatistics(host,'admin','admin',list[i].string[0]).then(res=>{

                    let statjson = res.channelStatistics;
                    statjson.name = list[i].string[1];
                
                    this.setState({channelStatisticsData:[...this.state.channelStatisticsData,statjson ]})
                })
            }   

    })
   
    }
    handleServerChange(val){
        let host = val+':8440';
       this.formatJson();
    }
    formatJson()
    {
        let listarr=[];
        let listdata= this.state.channelStatisticsData;

        for(let i=0;i<listdata.length;i++)
        {
            let errorjson={
                name:listdata[i].name,
                type:'error',
                count:listdata[i].error
            };
            let sentjson={
                name:listdata[i].name,
                type:'sent',
                count:listdata[i].sent
            };
            let recievedjson={
                name:listdata[i].name,
                type:'received',
                count:listdata[i].received
            };

            listarr.push(errorjson);
            listarr.push(sentjson);
            listarr.push(recievedjson);
        }
        console.log(listarr)
        this.setState({barGraphData:listarr})
    }

    render() {


        const { spin,serverOptions,barGraphData} = this.state;
       
          var config ={ 
            data : barGraphData ,
            isGroup:true, 
            xField : 'name' , 
            yField : 'count' , 
            colorField: 'type',
            color: ['#C53111', '#2ca02c', '#1194C5'],
            seriesField:'type', 
            label:{ 
              position:'middle', 
              layout:[ 
                {type:'interval-adjust-position'},   
                {type:'interval-hide-overlap'},   
                {type:'adjust-color'},   
              ],
            },
          };
        
        return (
            <>
                <Breadcrumb>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Mirth Monitoring</Breadcrumb.Item>
                    <Breadcrumb.Item><a href="" style={{ color: '#1890ff' }}>Channel Trends</a></Breadcrumb.Item>
                </Breadcrumb>

                <div className="server_log_panel">

                    <Spin size="large" spinning={spin}>
                    <Row>
                            <Col span={5}>
                                <div class="server_log_heading" style={{ fontSize: "24px", transform: "translateX(-20px)", marginLeft: '20px' }}>Channel Trends
                                </div>
                            </Col>
                            <Col span={2} style={{marginLeft:'-80px'}} >
                            <Text type="secondary" style={{ fontSize: "15px", transform: "translateX(-20px)", marginLeft: '20px' }}>Server: </Text>
                            </Col>
                            <Col span={12} style={{marginTop:'-4px',marginLeft:'-30px'}}>
                            <Select placeholder="Select server" onChange={this.handleServerChange} style={{ width: 160 }} bordered={false}>
                                    {serverOptions.map((server) => (
                                        <Option key={server} value={server}>{server}</Option>
                                    ))

                                    }
                                </Select>
                            </Col>
                        </Row>
                    {barGraphData.length!==0 && <Column {...config} />}
                    </Spin>

                </div>
            </>
        );
    }
}

