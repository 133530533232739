import React, { Component } from "react";
import { Row, Col,Tag, Table, Typography,Divider, Card, Descriptions, Result, Space, Select, Tooltip, Modal, Form, Input, Button, DatePicker, Breadcrumb, Spin } from 'antd';
import {
    PlayCircleOutlined,
    StopOutlined,
    PauseCircleOutlined,
    SearchOutlined
} from '@ant-design/icons';
import { Link} from 'react-router-dom';
import {
    getServerList,
    getData,
    getMirthServerDetails,
    getServerDetailsByOrgId,
    getSubscribedChannels
} from '../../../service/mirth/MirthService'
import AuthService from '../../../service/Auth/auth-service'
import {SearchBar} from '../../../components'
// import './ChannelList.css';
import '../../../styles/custom-css/antd-custom.css';
import { properties } from '../../../properties';
import Highlighter from "react-highlight-words";
import { configurationData } from "../../../service/ConfigService/Config";


const {Option} = Select;
const {Text} = Typography;

export default class ChannelList extends Component {

    constructor(props) {
        super(props)

        this.state = {
            spin: true,
            modalTitle: '',
            isModalVisible: false,
            modalMessage: '',
            serverOptions:[],
            channelData:[],
            cloneChannelData: [],
            resultStatus:'',
            resultTitle:'',
            resultSubTitle:'',
            currentServer:'',
            selectedRowKeys: [],
            selectedRows: [],
            isError:false,
            page:'channelList'
        }

        this.handleModalView = this.handleModalView.bind(this);
        this.handleOperationClick = this.handleOperationClick.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleServerChange = this.handleServerChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
       
    }
    state = {
        searchText: '',
        searchedColumn: '',
    };


    componentDidMount() {
      
        let token = this.props.match.params.accessToken;
        let user = AuthService.getCurrentUser();
        if(token == null )
        {
            this.setState({ resultStatus:'warning',resultTitle:'Add a server to check statistics', isError:true,spin: false})
        }
        else
        {
            configurationData(this.state.page).then((res) =>{
                if(res.code==200){
                  let jsonData=JSON.parse(res.result[0].config);
                  console.log(jsonData)
                this.setState({resultStatus:jsonData.status.resultStatus,
                resultTitle:jsonData.status.resultTitle,
                resultSubTitle:jsonData.status.resultSubTitle})
                
                
                
              
            getServerDetailsByOrgId(user.orgId).then(ServerRes=> {
            if (ServerRes.code == 200) {
                let server=[];
                server = ServerRes.result;
             
                if(server.length > 0)
                {
                    this.setState({serverOptions:server,spin: false});
                    this.handleServerChange(token);
                }
                else
                {
                    this.setState({ resultStatus:'warning',resultTitle:'Add a server to check statistics', isError:true,spin: false})
                }
               
            }
        })
    }
})
    }
      
    }
    handleSearch(data)
    {
        this.setState({ channelData: data });
    }
    handleServerChange(val){
     
        this.setState({ spin: true ,currentServer:val,isError:false});
        getMirthServerDetails(val).then(serverRes=>{
            if (serverRes.code == 200 && serverRes.result.length !=0) {
                let details = JSON.parse(serverRes.result[0].details);
                if(details.channelStatus && typeof(details.channelStatus) == 'string')
                {
                let res =JSON.parse( details.channelStatus);
                let values= res.list.dashboardStatus;
                let finalArray =[];
                if (values !== null && !Array.isArray(values)) {
                    finalArray.push(values);
                    this.setState({ spin: false,channelData:finalArray ,cloneChannelData:finalArray})
                  }
                else
                {
                    finalArray =values;
                    this.setState({ spin: false,channelData:finalArray ,cloneChannelData:finalArray })
                }
            }
            else
            {
                getSubscribedChannels(val).then(channelRes =>{
                    if (channelRes.code == 200 && channelRes.result.length != 0) {
                        let result = channelRes.result;
                        var chArr =result.map(function(obj) {
                            return {
                                name: obj.channel_name,
                                channelId: obj.channel_id,
                                state: obj.status
                            }
                        })
                        
                        this.setState({ spin: false,channelData:chArr ,cloneChannelData:chArr })
                    }
                    else
                    {
                        this.setState({isError:true,spin: false})
                    }
                })
             
            }
            }
             else
            {
                this.setState({isError:true,spin: false})
            }
        })
     
    }
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearchs(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearchs(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                    text
                ),
    });

    handleSearchs = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

 
    handleCancel() {
        this.setState({ isModalVisible: false })
    }

    handleModalView(data, title) {

        this.setState({ modalMessage: data, modalTitle: title, isModalVisible: true })

    }
    handleOperationClick(record,op)
    {
        let host = this.state.currentServer+':8443';
        let username,password,endPoint;
        this.state.serverOptions.map((server)=>{
            if(server.serverId == this.state.currentServer)
            {
                host = server.serverIP+':'+server.port;
                username = server.userName;
                password = server.password;
                endPoint =  server.channelEndPoint;
            }
        });
      
        let request =op+"&channelId="+record.channelId;
            getData(endPoint,request).then(opres=>{
            getData(endPoint,"channelStatus").then(res=>{
                console.log(res)
                if(res.children)
                {
                let channelArr =[];
                let channels = res.children;
                console.log(channels);
    
                for(let i=0;i<channels.length;i++){
                    let channel = channels[i].children;
                    let dataObj={};
                    for(let j =0;j<channel.length;j++)
                    {
                        console.log(channel[j]);
                        if(channel[j].name == "channelId" ||channel[j].name == "name"||channel[j].name == "state"){
                            dataObj[channel[j].name] =channel[j].value;
                        }
                    }
                  channelArr.push(dataObj);
                 
                }
                console.log(channelArr)
                this.setState({ spin: false,channelData:channelArr ,cloneChannelData:channelArr })
            }
            else
            {
                this.setState({isError:true,spin: false})
            }
            })
        })
    }

    render() {


        const { selectedRowKeys, selectedRows,spin, modalTitle, modalMessage, resultStatus,resultTitle,resultSubTitle, isModalVisible ,serverOptions,channelData,cloneChannelData,isError} = this.state;
        const rowSelection = {
            selectedRowKeys, selectedRows,
            onChange: this.onSelectChange
        };
        const renderTag = (tag) => {

            if (tag == 'STARTED')
                return <Tag color="success">{tag}</Tag>;
            else if (tag == 'PAUSED')
                return <Tag color="processing">{tag}</Tag>;
                else if (tag == 'STOPPED')
                return <Tag color="error">{tag}</Tag>;
            else
                return <Tag color="default">{tag}</Tag>;

        }
        const channelColumns = [
            { title: 'Channel Id', dataIndex: 'channelId', key: 'channelId',...this.getColumnSearchProps("channelId") },
            { title: 'Channel Name', dataIndex: 'name', key: 'name' },
            { title: 'State', dataIndex: 'state', key: 'state',render: (text) => renderTag(text), },
        //     { title: 'Operations', dataIndex: '', key: '',
        //     render: (text, record) => (
        //         <>
        //             <Space size="middle">
        //             <Tooltip title="Start">
        //                 <a 
        //                     onClick={() => { this.handleOperationClick(record, 'start') }}><PlayCircleOutlined /></a>
        //             </Tooltip>
        //             <Tooltip title="Pause">
        //     <a 
        //                     onClick={() => { this.handleOperationClick(record, 'pause') }}><PauseCircleOutlined /></a>
        //             </Tooltip>
        //             <Tooltip title="Stop">
        //                 <a 
        //                     onClick={() => { this.handleOperationClick(record, 'stop') }}><StopOutlined /></a>
        //             </Tooltip>
        //             </Space>
        //         </>)
        
        // },
        ];
        return (
            <>
                <Breadcrumb>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Mirth Monitoring</Breadcrumb.Item>
                    <Breadcrumb.Item>Channel List</Breadcrumb.Item>
                </Breadcrumb>

                <div className="content_panel">

                    <Spin size="large" spinning={spin}>
                   
                        <Modal title={modalTitle} visible={isModalVisible} footer={null} onCancel={this.handleCancel} width={900} bodyStyle={{ overflowY: 'scroll', height: 380 }}>
                            {modalMessage}
                        </Modal>
                        <Row>

                            <Col span={5}>
                              <div class="content_panel_heading" >Channel List
                                </div>
                            </Col>
                            {serverOptions.length !==0 &&
                            <>
                            <Col span={2}   style={{marginTop:'4px'}}>
                            <Text>Server: </Text>
                            </Col>
                            <Col span={12} style={{marginLeft:'-30px'}}>
                            
                            <Select placeholder="Select server" defaultValue={serverOptions[0].accessToken} onChange={this.handleServerChange} style={{ width: 160 }} bordered={false}>
                            {serverOptions.map((server) => (
                                                    <Option key={server.accessToken} value={server.accessToken}>{server.serverName}</Option>
                                                ))
                                }
                                </Select>
                            </Col>
                            </>}
                           
                        </Row>
                        {/* <Row>
                        <Col xs={2} sm={4} md={6} lg={8} xl={10}>
                        <div class="content_panel_heading" >Channel List
                                </div>
                        </Col>
                        <Col xs={20} sm={16} md={12} lg={8} xl={4}>
                        <div >Channel List
                                </div>
                        </Col>
                        <Col xs={2} sm={4} md={6} lg={8} xl={10}>
                        <div  >Channel List
                                </div>
                        </Col>
                        </Row> */}
                        {/* <Divider /> */}
                        <div class="divider-1"> <span></span></div>
                        {
                        isError ? (
                        <>
                        <Result
                            status={resultStatus}
                            title={resultTitle}
                            subTitle={resultStatus== '500'?resultSubTitle:''}
                            extra={resultStatus== '500'?<></>:  <Link to="/settings/server"><Button type="primary" style={{borderRadius:'7px'}}>Add Server</Button></Link>}
                        />
                        </>):(
                        <>
                                <SearchBar data={cloneChannelData} handler={this.handleSearch}></SearchBar>
                                <Table
                                  style={{marginTop:'10px'}}
                                    columns={channelColumns}
                                    dataSource={channelData}
                                
                                />
                           

                    </>)
                    }
                    </Spin>

                </div>
            </>
        );
    }
}

