import React, { Component } from "react";
import { Redirect, useParams } from 'react-router-dom'
import { Row, Col, Table, Typography, Divider, Checkbox, Tabs, Radio, Pagination, Card, Result, Tag, Space, Select, Tooltip, Modal, Form, Input, Button, DatePicker, Breadcrumb, Spin } from 'antd';
import {
    EyeOutlined,
    UpOutlined,
    DownOutlined,
    RollbackOutlined,
    ReloadOutlined,
    SearchOutlined
} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import {
    getServerList,
    getData,
    getDataNew
} from '../../../service/mirth/MirthService'
import { Link } from 'react-router-dom';
import './MessageView.css';
import '../../../styles/custom-css/antd-custom.css';
import { SearchBar } from '../../../components'
import { properties } from '../../../properties';
import moment from 'moment-timezone';

const { Option } = Select;
const { Text } = Typography;
const { TabPane } = Tabs;


const filterCheckBoxOptions = [
    { label: 'Received', value: 'R' },
    { label: 'Transformed', value: 'T' },
    { label: 'Sent', value: 'S' },
    { label: 'Errored', value: 'E' },
    { label: 'Filtered', value: 'F' },
    { label: 'Queued', value: 'Q' },
];

export default class MessageView extends Component {

    constructor(props) {
        super(props)

        this.state = {
            messagesData: [],
            cloneMessagesData: [],
            AllchannelStatisticsData: [],
            channelStatisticsData: [],
            spin: false,
            modalTitle: '',
            isModalVisible: false,
            modalMessage: '',
            messageContent: '',
            messageCount: '',
            messageOptions: [],
            mappingData: [],
            serverOptions: [],
            channelOptions: [],
            resultStatus: '500',
            resultTitle: '500',
            resultSubTitle: 'Server not reachable',
            currentServer: '',
            isError: false,
            currentServerId: '',
            currentChannelId: '',
            currentEndpoint:''
        }


        this.handleSearch = this.handleSearch.bind(this);
        this.handleModalView = this.handleModalView.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleReprocessClick = this.handleReprocessClick.bind(this);
        this.filterCheckBoxOnChange = this.filterCheckBoxOnChange.bind(this);
        this.paginationOnchange = this.paginationOnchange.bind(this);
    }

    componentDidMount() {
        let channelId = this.props.match.params.channelId;
        let serverId = this.props.match.params.serverId;
        this.setState({ currentChannelId: channelId, currentServerId: serverId });

        getServerList().then(ServerRes => {
            if (ServerRes.code == 200) {
                let server = [];
                server = ServerRes.result;

                if (server.length > 0) {
                    this.setState({ serverOptions: server, spin: false });
                    let endPoint = "";
                    console.log(serverId)
                    server.map((crserver) => {
                        console.log(crserver.serverId )
                        if (crserver.serverId == serverId) {
                            endPoint = crserver.channelEndPoint;
                     
                        getDataNew(endPoint, "getMessageCount&channelId="+channelId).then(countres => {
                            this.setState({ messageCount: countres.long,currentEndpoint: endPoint});
                            getDataNew(endPoint, "getMessages&channelId="+channelId+"&limit=5").then(res => {
                                let finalArr = [];
                                if (res.list.message) {
                                    let data = res.list.message;
                                    for (let i = 0; i < data.length; i++) {
                                        let connectors = data[i].connectorMessages.entry;
                                        let json = {};
                                        let children = [];
                                        for (let j = 0; j < connectors.length; j++) {


                                            let mappings = {};
                                            let messages = {};
                                            let message = connectors[j].connectorMessage;

                                            if (message.connectorName == "Source") {
                                                json.messageId = data[i].messageId;
                                                json.processed = data[i].processed;
                                                json.connectorName = message.connectorName;
                                                json.status = message.status;
                                                json.receivedDate = moment(message.receivedDate.time).tz(message.receivedDate.timezone).format("YYYY-MM-DD HH:mm:ss");
                                                json.responseDate = moment(message.responseDate.time).tz(message.responseDate.timezone).format("YYYY-MM-DD HH:mm:ss");
                                                mappings.responseMapContent = message.responseMapContent;
                                                mappings.sourceMapContent = message.sourceMapContent;
                                                json.mapping = mappings;

                                                messages.raw = message.raw;
                                                messages.encoded = message.encoded;
                                                json.message = messages;
                                            }
                                            else {
                                                let childJson = {};
                                                let childMappings = {};
                                                let childMessages = {};
                                                childJson.messageId = '';
                                                childJson.processed = data[i].processed;
                                                childJson.connectorName = message.connectorName;
                                                childJson.status = message.status;
                                                childJson.receivedDate = moment(message.receivedDate.time).tz(message.receivedDate.timezone).format("YYYY-MM-DD HH:mm:ss");
                                                childJson.responseDate = moment(message.responseDate.time).tz(message.responseDate.timezone).format("YYYY-MM-DD HH:mm:ss");

                                                childMappings.responseMapContent = message.responseMapContent;
                                                childMappings.sourceMapContent = message.sourceMapContent;
                                                childJson.mapping = childMappings;

                                                childMessages.raw = message.raw;
                                                childMessages.encoded = message.encoded;
                                                childMessages.sent = message.sent;
                                                childMessages.response = message.response;
                                                childJson.message = childMessages;
                                                children.push(childJson);
                                            }
                                        }
                                        json.children = children;
                                        console.log(json)
                                        finalArr.push(json);

                                    }
                                }
                                this.setState({ messagesData: finalArr, cloneMessagesData: finalArr });
                                console.log(finalArr)
                                console.log(res)
                            });

                        });
                    }

                });
                }
                else {
                    this.setState({ resultStatus: 'warning', resultTitle: 'Add a server to check statistics', isError: true, spin: false })
                }
            }
        })


    }
    handleSearch(data) {
        this.setState({ messagesData: data });
    }

    handleModalView(data) {
        let message = data.message;
        let messageOptions = [];
        let mappings = [];
        for (var key in message) {
            if (message.hasOwnProperty(key)) {
                var val = message[key];
                messageOptions.push({ key: val.contentType, value: val.content })
            }
        }
        let mapping = data.mapping;
        let mapJson = {};
        console.log(mapping);
        let entry = mapping.sourceMapContent.content.m.entry;
       

        mapJson.variable = mapping.sourceMapContent.content.m.entry.string;
        if(mapJson.variable == "destinationSet")
        {
            mapJson.value = entry["linked-hash-set"].int;
        }
        mapJson.scope = 'Source';
        mappings.push(mapJson);
        mapJson = {};
        mapJson.value = mapping.responseMapContent.content.entry.response.status + ":" + mapping.responseMapContent.content.entry.response.statusMessage;
        mapJson.variable = mapping.responseMapContent.content.entry.string;
        mapJson.scope = 'Response';
        mappings.push(mapJson);
        console.log(mappings);

        this.setState({ mappingData: mappings, messageOptions: messageOptions, isModalVisible: true, messageContent: messageOptions[0].value })

    }
    radioOnChange = (messageOptions) => {
        this.setState({ messageContent: messageOptions.value })
    };

    handleCancel() {
        this.setState({ isModalVisible: false })
    }
    filterCheckBoxOnChange(val) {
        console.log(val);
        let endPoint = this.state.currentEndpoint;
        getDataNew(endPoint, "getMessages&channelId=3c1f8089-c1a7-4e83-8821-a784bbbdf429&filters=T&filters=S").then(res => {
            let finalArr = [];
            console.log(res)
            if (res.list.message) {
                let data = res.list.message;
                for (let i = 0; i < data.length; i++) {
                    let connectors = data[i].connectorMessages.entry;

                    let json = {};
                    let mappings = {};
                    let messages = {};
                    json.messageId = data[i].messageId;
                    json.processed = data[i].processed;
                    let message = connectors.connectorMessage;
                    json.connectorName = message.connectorName;
                    json.status = message.status;
                    mappings.responseMapContent = message.responseMapContent;
                    mappings.sourceMapContent = message.sourceMapContent;
                    json.mapping = mappings;
                    messages.raw = message.raw;
                    messages.encoded = message.encoded;
                    if (message.connectorName != "Source") {
                        messages.sent = message.sent;
                        messages.response = message.response;
                        json.message = messages;
                    }
                    else {
                        json.message = messages;
                    }

                    json.receivedDate = moment(message.receivedDate.time).tz(message.receivedDate.timezone).format("YYYY-MM-DD HH:mm:ss");
                    json.responseDate = moment(message.responseDate.time).tz(message.responseDate.timezone).format("YYYY-MM-DD HH:mm:ss");
                    console.log(json)
                    finalArr.push(json);


                }
            }
            this.setState({ messagesData: finalArr, cloneMessagesData: finalArr });
            console.log(finalArr)
            console.log(res)
        });
    }
    paginationOnchange(page, size) {
        console.log(page, size);

        let endPoint = this.state.currentEndpoint;
        if ((page * size) > this.state.cloneMessagesData.length && this.state.cloneMessagesData.length < this.state.messageCount) {
            this.setState({ spin: true });
            getDataNew(endPoint, "getMessages&channelId="+this.state.currentChannelId+"&limit=" + (page * size)).then(res => {
                let finalArr = [];
                if (res.list.message) {
                    let data = res.list.message;
                    for (let i = 0; i < data.length; i++) {
                        let connectors = data[i].connectorMessages.entry;
                        let json = {};
                        let children = [];
                        for (let j = 0; j < connectors.length; j++) {


                            let mappings = {};
                            let messages = {};
                            let message = connectors[j].connectorMessage;

                            if (message.connectorName == "Source") {
                                json.messageId = data[i].messageId;
                                json.processed = data[i].processed;
                                json.connectorName = message.connectorName;
                                json.status = message.status;
                                json.receivedDate = moment(message.receivedDate.time).tz(message.receivedDate.timezone).format("YYYY-MM-DD HH:mm:ss");
                                json.responseDate = moment(message.responseDate.time).tz(message.responseDate.timezone).format("YYYY-MM-DD HH:mm:ss");
                                mappings.responseMapContent = message.responseMapContent;
                                mappings.sourceMapContent = message.sourceMapContent;
                                json.mapping = mappings;

                                messages.raw = message.raw;
                                messages.encoded = message.encoded;
                                json.message = messages;
                            }
                            else {
                                let childJson = {};
                                let childMappings = {};
                                let childMessages = {};
                                childJson.messageId = '';
                                childJson.processed = data[i].processed;
                                childJson.connectorName = message.connectorName;
                                childJson.status = message.status;
                                childJson.receivedDate = moment(message.receivedDate.time).tz(message.receivedDate.timezone).format("YYYY-MM-DD HH:mm:ss");
                                childJson.responseDate = moment(message.responseDate.time).tz(message.responseDate.timezone).format("YYYY-MM-DD HH:mm:ss");

                                childMappings.responseMapContent = message.responseMapContent;
                                childMappings.sourceMapContent = message.sourceMapContent;
                                childJson.mapping = childMappings;

                                childMessages.raw = message.raw;
                                childMessages.encoded = message.encoded;
                                childMessages.sent = message.sent;
                                childMessages.response = message.response;
                                childJson.message = childMessages;
                                children.push(childJson);
                            }


                        }
                        json.children = children;
                        console.log(json)
                        finalArr.push(json);

                    }
                }
                this.setState({ messagesData: finalArr, spin: false, cloneMessagesData: finalArr });
            });
        }
    }
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => this.handleCustomSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => this.handleCustomReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  this.setState({
                    searchText: selectedKeys[0],
                    searchedColumn: dataIndex,
                  });
                }}
              >
                Filter
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
          }
        },
        render: text =>
          this.state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });
    
      handleCustomSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
      handleCustomReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
      };
      handleReprocessClick(record){
        let endPoint = this.state.currentEndpoint;
        this.setState({ spin:true});
        getDataNew(endPoint, "reprocessMessage&channelId="+this.state.currentChannelId+"&messageId="+record.messageId).then(reprocessRes => {

           if(reprocessRes)
           {
            getDataNew(endPoint, "getMessageCount&channelId="+this.state.currentChannelId).then(countres => {
                this.setState({ messageCount: countres.long});
                getDataNew(endPoint, "getMessages&channelId="+this.state.currentChannelId+"&limit=5").then(res => {
                    let finalArr = [];
                    if (res.list.message) {
                        let data = res.list.message;
                        for (let i = 0; i < data.length; i++) {
                            let connectors = data[i].connectorMessages.entry;
                            let json = {};
                            let children = [];
                            for (let j = 0; j < connectors.length; j++) {


                                let mappings = {};
                                let messages = {};
                                let message = connectors[j].connectorMessage;

                                if (message.connectorName == "Source") {
                                    json.messageId = data[i].messageId;
                                    json.processed = data[i].processed;
                                    json.connectorName = message.connectorName;
                                    json.status = message.status;
                                    json.receivedDate = moment(message.receivedDate.time).tz(message.receivedDate.timezone).format("YYYY-MM-DD HH:mm:ss");
                                    json.responseDate = moment(message.responseDate.time).tz(message.responseDate.timezone).format("YYYY-MM-DD HH:mm:ss");
                                    mappings.responseMapContent = message.responseMapContent;
                                    mappings.sourceMapContent = message.sourceMapContent;
                                    json.mapping = mappings;

                                    messages.raw = message.raw;
                                    messages.encoded = message.encoded;
                                    json.message = messages;
                                }
                                else {
                                    let childJson = {};
                                    let childMappings = {};
                                    let childMessages = {};
                                    childJson.messageId = '';
                                    childJson.processed = data[i].processed;
                                    childJson.connectorName = message.connectorName;
                                    childJson.status = message.status;
                                    childJson.receivedDate = moment(message.receivedDate.time).tz(message.receivedDate.timezone).format("YYYY-MM-DD HH:mm:ss");
                                    childJson.responseDate = moment(message.responseDate.time).tz(message.responseDate.timezone).format("YYYY-MM-DD HH:mm:ss");

                                    childMappings.responseMapContent = message.responseMapContent;
                                    childMappings.sourceMapContent = message.sourceMapContent;
                                    childJson.mapping = childMappings;

                                    childMessages.raw = message.raw;
                                    childMessages.encoded = message.encoded;
                                    childMessages.sent = message.sent;
                                    childMessages.response = message.response;
                                    childJson.message = childMessages;
                                    children.push(childJson);
                                }
                            }
                            json.children = children;
                            console.log(json)
                            finalArr.push(json);

                        }
                    }
                    this.setState({ messagesData: finalArr, cloneMessagesData: finalArr,spin:false });
                    console.log(finalArr)
                    console.log(res)
                });

            });
           }
        });
      } 

    render() {


        const { spin, messageCount, modalTitle, modalMessage, mappingData, messagesData, cloneMessagesData, messageContent, messageOptions, resultStatus, resultTitle, resultSubTitle, isModalVisible, serverOptions, channelOptions, channelStatisticsData, AllchannelStatisticsData, isError } = this.state;
        let sameKey;
        const getDescendantValues = (record) => {
            const values = [];
            console.log(record)
                (function recurse(record) {
                    values.push(record.status);
                    record.children.forEach(recurse);
                })(record);
            return values;
        }
        const renderTag = (tag) => {

            if (tag == 'SENT')
                return <Tag color="success">{tag}</Tag>;
            else if (tag == 'TRANSFORMED')
                return <Tag color="processing">{tag}</Tag>;
            else if (tag == 'ERROR')
                return <Tag color="error">{tag}</Tag>;
            else
                return <Tag color="default">{tag}</Tag>;

        }
        const messagesColumns = [

            {
                title: 'Message Id', dataIndex: 'messageId', key: 'messageId',
                ...this.getColumnSearchProps('messageId'),
                sorter: (a, b) => a.messageId - b.messageId,
                sortDirections: ['descend', 'ascend'],
                // render: (value, row, index) => {
                //     const obj = {
                //         children: value,
                //         props: {}
                //     };
                //     if (!(sameKey !== value)) {
                //         obj.props.rowSpan = 0;
                //         return obj;
                //     }
                //     const count = messagesData.filter(item => item.messageId === value).length;
                //     sameKey = value;
                //     obj.props.rowSpan = count;
                //     return obj;
                // }
            },
            { title: 'Connector Name', dataIndex: 'connectorName', key: 'connectorName' },
            {
                title: 'Status', dataIndex: 'status', key: 'status', render: (text) => renderTag(text),

                filters: [
                    {
                        text: 'TRANSFORMED',
                        value: 'TRANSFORMED',
                    },
                    {
                        text: 'SENT',
                        value: 'SENT',
                    },
                    {
                        text: 'ERROR',
                        value: 'ERROR',
                    },
                    {
                        text: 'RECEIVED',
                        value: 'RECEIVED',
                    },
                    {
                        text: 'QUEUED',
                        value: 'QUEUED',
                    },
                ],
                
                onFilter: (value, record) => {
                    return record.status.startsWith(value) || record.children.filter(child => child.status === value).length > 0;
                },
                //  onFilter: (value, record) => record.children.filter(child => child.status === value).length > 0,
                //   onFilter: (value, record) => record.status.startsWith(value)||record.children.status.startsWith(value),
                filterSearch: true,
            },
            { title: 'Received Date', dataIndex: 'receivedDate', key: 'receivedDate' },
            { title: 'ResponsecDate', dataIndex: 'responseDate', key: 'responseDate' },
            {
                title: 'Operations', dataIndex: '', key: '',
                render: (text, record) => (
                    <>
                        <Space size="middle">
                            <Tooltip title="Reprocess">
                                <a onClick={(e)=>{e.stopPropagation(); this.handleReprocessClick(record)}} ><ReloadOutlined /></a>
                            </Tooltip>
                            
                        </Space>
                    </>)

            }
        ];
        const mappingColumns = [

            { title: 'Scope', dataIndex: 'scope', key: 'scope' },
            { title: 'Variable', dataIndex: 'variable', key: 'variable' },
            { title: 'Value', dataIndex: 'value', key: 'value' }

        ];
        return (
            <>
                <Breadcrumb>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Mirth Monitoring</Breadcrumb.Item>
                    <Breadcrumb.Item>Message</Breadcrumb.Item>
                </Breadcrumb>

                <div className="content_panel">

                    <Spin size="large" spinning={spin}>
                        <Modal visible={isModalVisible} footer={null} onCancel={this.handleCancel} width={900} bodyStyle={{ overflowY: 'scroll', height: 380 }}>
                            <div className="card-container">
                                <Tabs type="card">
                                    <TabPane tab="Message" key="1">
                                        <Radio.Group onChange={(e) => { this.radioOnChange(messageOptions[e.target.value]) }} defaultValue={0}>
                                            {messageOptions.map((msg, index) => (
                                                <Radio value={index}>{msg.key}</Radio>
                                            ))
                                            }
                                        </Radio.Group>
                                        <Card style={{ marginTop: '10px', minHeight: '250px' }} type="inner" >
                                            {messageContent}
                                        </Card>
                                    </TabPane>
                                    <TabPane tab="Mappings" key="2">
                                        <Table
                                            style={{ marginTop: '10px' }}
                                            columns={mappingColumns}
                                            dataSource={mappingData}


                                        />
                                    </TabPane>

                                </Tabs>
                            </div>
                        </Modal>
                        <Row>

                            <Col span={12}>
                                <div class="content_panel_heading">Messages
                                </div>
                            </Col>
                            <Col span={12}>
                            <Link to="/mirth/channel-statistics">
                            <Button  style={{ float: 'right' ,borderRadius:'7px',backgroundColor:'#efefef',fontFamily:'AktivGroteskMedium',border:'none'}} onClick={this.showForm}>
                            <RollbackOutlined style={{color:'#fc9558',fontSize:'16px'}}/> Back
                                </Button>
                             </Link>
                            </Col>
                        </Row>
                        <Divider />


                        {
                            isError ? (
                                <>
                                    <Result
                                        status={resultStatus}
                                        title={resultTitle}
                                        subTitle={resultStatus == '500' ? resultSubTitle : ''}
                                        extra={resultStatus == '500' ? <></> : <Link to="/settings/server"><Button type="primary" style={{ borderRadius: '7px' }}>Add Server</Button></Link>}
                                    />
                                </>) : (
                                    <>
                                        <SearchBar data={cloneMessagesData} handler={this.handleSearch}></SearchBar>

                                        {/* <Checkbox.Group options={filterCheckBoxOptions} onChange={this.filterCheckBoxOnChange} /> */}


                                        <Table
                                            rowClassName={(record, index) => record.messageId % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
                                            style={{ marginTop: '10px' }}
                                            columns={messagesColumns}
                                            pagination={{ total: messageCount, showLessItems: true, showSizeChanger: true, defaultPageSize: 5, pageSizeOptions: ['5','10', '20'], onChange: this.paginationOnchange, showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} messages` }}
                                            dataSource={messagesData.map((d, i) => ({ key: i, ...d }))}
                                            onRow={(record, rowIndex) => {
                                                return {
                                                    onClick: event => { this.handleModalView(record) },
                                                };
                                            }}


                                        />
                                        {/* <Pagination  total={messageCount} /> */}


                                    </>)
                        }
                    </Spin>

                </div>
            </>
        );
    }
}

