import React, { Component } from 'react';

import { Breadcrumb, Spin, Table, Row, Col, Form, Button, notification, Input, Typography, Tabs, Space, Tooltip, InputNumber, Select } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { Link, Redirect } from "react-router-dom";
import {

    getRoleList,
    createUser
} from './../../service/mirth/MirthService'
import AuthService from "../../service/Auth/auth-service";
import './SignUp.css'


const { Option } = Select;

export default class SignUp extends Component {

    signUpformRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {

            spin: true,
            roles: [],
            signedUp: false
        }

        // this.handleTabChange = this.handleTabChange.bind(this);
        this.onFinish = this.onFinish.bind(this);
        // this.handleActionClick = this.handleActionClick.bind(this);
    }
    componentDidMount() {
        let roleList = [
            {
                'name': 'User', 'value': 'ROLE_USER'
            },
            {
                'name': 'Admin', 'value': 'ROLE_ADMIN'
            }
        ];
        this.setState({ spin: false, roles: roleList })
        // getRoleList().then(res => {
        //     if (res.code == 200) {
        //         this.setState({ spin: false, roles: res.result })
        //     }
        // })
    }

    onFinish(val) {
        this.setState({ spin: true })
        //     createUser(val).then(res=>{
        //         console.log(res)
        //         if(res.code ==200)
        //         {
        //             this.setState({signedUp:true})
        //             notification.success({
        //                 message: res.result,

        //             });
        //         }
        //         else
        //         {
        //             notification.error({
        //                 message: res.result,
        //             });
        //         }
        //   })

        AuthService.register(
            val.userName,
            val.email,
            val.password
        ).then(
            response => {
                this.setState({ signedUp: true, spin: false })
                notification.success({
                    message: response.data.message,

                });
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                this.setState({ spin: false })
                notification.error({
                    message: resMessage,

                });
            }
        );

    }

    render() {
        const { spin, roles, signedUp } = this.state;
        return (
            <>
                <Spin size="large" spinning={spin}>
                    <div class="signUp_panel">

                        <div class="signUp_heading" style={{ fontSize: "25px", transform: "translateX(-20px)", textAlign: 'center' }}>Sign up
                                </div>
                        <Form ref={this.signUpformRef} labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }} name="control-ref" onFinish={this.onFinish}>

                            <Form.Item
                                name="userName"
                                label="User Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter username!',
                                    },

                                ]}
                            >

                                <Input style={{ width: 180 }}
                                    placeholder="Enter User Name"
                                />
                            </Form.Item>
                            {/* <Form.Item
                                name="firstName"
                                label="First Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter first name!',
                                    },
                                ]}
                            >

                                <Input style={{ width: 180 }}
                                    placeholder="Enter First Name"
                                />
                            </Form.Item>
                            <Form.Item
                                name="lastName"
                                label="Last Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter last name!',
                                    },
                                ]}
                            >

                                <Input style={{ width: 180 }}
                                    placeholder="Enter Last Name"
                                />
                            </Form.Item> */}
                            <Form.Item
                                name="email"
                                label="Email Id"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter email!',
                                    },
                                    {
                                        message: "Email is not valid",
                                        type: 'email'
                                    }
                                ]}
                            >

                                <Input style={{ width: 180 }}
                                    placeholder="Enter Email"
                                />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                label="Password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter password!',
                                    },
                                    { min: 8, message: 'Password must have a minimum length of 8' },
                                    // {
                                    //     pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,30}$'),
                                    //     message: 'Password must contain at least one lowercase letter, uppercase letter, number, and special character'
                                    // }
                                ]}
                                
                            >
                                <Input.Password
                                    style={{ width: 180 }}
                                    placeholder="Enter password"
                                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                />
                            </Form.Item>
                    
                            <Form.Item
                                name="confirm"
                                label="Confirm Password"
                                dependencies={['password']}
                                
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password   style={{ width: 180 }} 
                                 placeholder="Enter password"
                                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}/>
                            </Form.Item>
                            <Form.Item
                                name="role"
                                label="Role"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input role!',
                                    },
                                ]}


                            >
                                {roles.length !== 0 &&
                                    <Select placeholder="Select role" mode="multiple" style={{ width: 180 }}
                                    >
                                        {roles.map((role) => (
                                            <Option key={role.value} value={role.value}>{role.name}</Option>
                                        ))

                                        }
                                    </Select>}

                            </Form.Item>
                            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>

                                <Button type="primary" style={{ marginLeft: '10px' }} htmlType="submit">
                                    Sign Up
                                </Button>

                            </Form.Item>

                            <div style={{ fontSize: "15px", transform: "translateX(-20px)", textAlign: 'center' }}>Already have an account?  <Link to="/login">Login</Link>
                            </div>
                        </Form>


                    </div>
                </Spin>
                {signedUp && <Redirect to="/login" />}
            </>
        )
    }
}