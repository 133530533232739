import React, { Component ,useState,useRef} from "react";
import { Row, Col, Table, Tag, Typography, Collapse,Result ,message,Radio, Transfer, Divider, Tabs, notification, Space, Select, Tooltip, Modal, Form, Input, Button, DatePicker, Breadcrumb, Spin,InputNumber } from 'antd';
import { getjobs } from "../../../service/EmailScheduler/Scheduler";
import {
    PlayCircleOutlined,
    StopOutlined,
    PauseCircleOutlined,
    MinusCircleOutlined,
    PlusOutlined,
    DeleteOutlined,
    Loading3QuartersOutlined,
    PoweroffOutlined,
    ReloadOutlined,
    ScheduleOutlined,
    LoadingOutlined,
    PauseOutlined,
    CheckOutlined,
    CloseCircleOutlined,
    EyeOutlined,
    HistoryOutlined,
    ExclamationCircleFilled,
    EditOutlined,
    QuestionCircleOutlined
} from '@ant-design/icons';
import {
    getAllJobs,
    scheduleEmail,
    scheduleEmailForEdit,
    startJob,
    pauseJob,
    resumeJob,
    stopJob,
    deleteJob,
    checkJobName
    
} from '../../../service/EmailScheduler/Scheduler'
import {
    getCronExpList,
    getServerList,
    getData,
    getMirthServerDetails,
    getServerByOrgId,
    isJobNameExists,
    updateJobActiveStatus,
    getSubscribedChannels
} from '../../../service/mirth/MirthService'
import ChannelTransfer from './ChannelTransfer';
import Cron from 'react-cron-generator'
import './cronBuilder.css'
import { properties } from '../../../properties';
import AuthService from '../../../service/Auth/auth-service';
import { configurationData } from "../../../service/ConfigService/Config";
import moment from 'moment-timezone';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Link } from 'react-router-dom';

import { element } from "prop-types";



const {Option} = Select;
const {Text} = Typography;
const InputGroup = Input.Group;


const { RangePicker } = DatePicker;

const { TabPane } = Tabs;
const { Panel } = Collapse;
const { confirm } = Modal;

const getFullDate = (date) => {
    if (date !== null) {
      const value = moment(date).format("YYYY-MM-DD HH:mm:ss");
      return value;
    }
  };
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    },
};
const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: { span: 32, offset: 18 },
        sm: { span: 24, offset: 8 },
    },

};
function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }
function disabledDate(current) {
    return moment().add(-1, 'days') >= current
}
function disabledTime() {
    var today=new Date();
    var hours=today.getHours();
    console.log(hours)
    var minutes=today.getMinutes();
    var seconds=today.getSeconds();
    return {
      disabledHours: () => range(0, 24).splice(0, hours),
      disabledMinutes: () => range(0,minutes),
    //   disabledSeconds: () => [55, 56],
    };
  }
  


const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};
const reportOptions = [
    { label: 'Channel Statistics', value: 'channelStatistics' },
    { label: 'Error metric', value: 'errorMetric' },
    { label: 'Usage', value: 'usage' },
];

const alertOptions = [
    { label: 'Error metric', value: 'errorMetric' },
    { label: 'Server Down', value: 'serverDown' },
];
const cronExpressionList = [
    {
        "expression": "0 15 10 * * ? *",
        "key": "Fire at 10:15am every day"
    },
    {
        "expression": "0 30 10-13 ? * WED,FRI",
        "key": "Fires at 10:30, 11:30, 12:30, and 13:30, on every Wednesday and Friday"
    }
];
const contextList = [
    {
        "key": "Error Percent Alert",
        "val": "errorPercentAlert"
    }
    ,
    {
        "key": "Server Down Alert",
        "val": "serverDownAlert"
    },
    {
        "key": "0 Received Alert",
        "val": "zeroReceivedAlert"
    },
    {
        "key": "Raw Delta Alert",
        "val": "rawDeltaAlert"
    },
    {
        "key": "Standard Deviation Alert",
        "val": "stdDevAlert"
    },
    {
        "key": "Standard Deviation observation",
        "val": "stdDevObservation"
    } ,
    // {
    //     "key": "Poller Data Delete Job",
    //     "val": "pollerDataDeleteJob"
    // },
    {
        "key": "Error Report",
        "val": "errorReport"
    },
    // {
    //     "key": "User Audit Report",
    //     "val": "userAuditReport"
    // },
    // {
    //     "key": "Session Monitoring Report",
    //     "val": "sessionMonitoring"
    // },
    {
        "key": "Channel Trends Report",
        "val": "channelTrendsReport"
        
    },
    {
        "key": "Daily Useage Report",
        "val": "useageReport"
        
    }
    
]
const openNotification = (message) => {
    notification.success({
        message: message,

    });
};
// const redirect = (isRedirect) => {
//     if(isRedirect){
//        return  <Link to={`/mirth/email-scheduler`}></Link>
//     }
//     else{
//         return null;
//     }
// };


const dateFormat ='YYYY-MM-DD HH:mm:ss';
export default class EditForm extends Component {
    editFormRef = React.createRef();

    constructor(props) {
        super(props)

        this.state = {
            data:[],
            initialValue:{},
            spin: true,
            scheduledJobs: [],
            cloneScheduledJobs: [],
            serverOptions: [],
            timeZones: ['Asia/Kolkata', 'America/New_York'],
            jobTypeValue: 1,
            jobType: 'oneTimeJob',
            isOneTimeJob: true,
            isServerDownAlert: false,
            isChannelStats: false,
            isZeroRecievedAlert: false,
            isErrorPercentAlert: false,
            isRawDeltaAlert: false,
            isStdDevAlert: false,
            isStdDevObservation: false,
            isPollerDataDeleteJob: false,
            isErrorReport: false,
            isUserAuditReport:false,
            isSessionMonitoring:false,
            isServerChanged: Math.random(),
            channelStatisticsData: [],
            channelListData: [],
            channelThresholdList: [],
            targetKeys: [],
            cronValue: '',
            host: null,
            endPoint: null,
            userName: null,
            password: null,
            access: '',
            accessToken: null,
            modalTitle: '',
            isModalVisible: false,
            modalMessage: '',
            currentServerName:'',
            serverName:[],
            alertType:'',
            currentUser:{},
            activeKey:'2',
            isRefreshData:false,
            refreshValue:'',
            resultStatus:'',
            resultTitle:'',
            isError:false,
            ischannelTrends:false,
            emailId:[],
            isUsageReport:false,
            open:false,
            jobName:'',
            confirmLoading:false,
            request:'',
            contextList:[],
            interval:[],
            status:[],
            page:'emailScheduler',
            validateMessages:[],
            isEdit:false,
            initialValue:{},
            isFormVisible: false,
            channelData:[],
            date:'' ,
            isPgToastAlert:false,
            isErrorCountAlert:false,
            isSpikeReport:false,
            isWeeklyReport:false,
            tableSize:[],
            tableValue:'MB',
            threshold:3,
            isRedirect:false,
            serverOptionsForServerDown:[],
            isChannelTrendsRange:false,
            isUsageReportWithInterval:false
                  // jobDetails:{}
        }
        this.handleContextChange = this.handleContextChange.bind(this);
        this.handleJobTypeChange = this.handleJobTypeChange.bind(this);
        this.handleCronChange = this.handleCronChange.bind(this);
        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleServerChange = this.handleServerChange.bind(this);
        this.handleChannelListChange=this.handleChannelListChange.bind(this);
        this.handleChannelTransfer=this.handleChannelTransfer.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.handleChannelData=this.handleChannelData.bind(this);
         this.readOnly=true;
         this.convertTZ=this.convertTZ.bind(this)
  
        this.onKeyChange=this.onKeyChange.bind(this);
        this.onChange=this.onChange.bind(this);

    }
 
    componentDidMount() {
        let user = AuthService.getCurrentUser();
        let jobName = this.props.match.params.jobName;
        console.log(jobName)
        configurationData(this.state.page).then((res) =>{
            if(res.code==200){
              let jsonData=JSON.parse(res.result[0].config);
              console.log(jsonData)
            this.setState({contextList:jsonData.contextList,interval:jsonData.interval,resultStatus:jsonData.status.resultStatus,
                resultTitle:jsonData.status.resultTitle,validateMessages:jsonData.validateMessages,tableSize:jsonData.tableSize})
        getAllJobs().then(res => {
            if (res.statusCode == 200) {
                let jobs = res.data;
                let orgJobs=[];
                for(let i=0;i<jobs.length;i++)
                {
                    
                    
                    jobs[i].scheduleTime = getFullDate(jobs[i].scheduleTime);
                    jobs[i].lastFiredTime = getFullDate(jobs[i].lastFiredTime);
                    jobs[i].nextFireTime = getFullDate(jobs[i].nextFireTime);
                  
                    if (jobs[i].jobDetails.servers !== null) {
                    let server = jobs[i].jobDetails.servers[0];
                    if(server.orgId == user.orgId)
                    {
                        
                        orgJobs.push(jobs[i])
                    }
                    if(jobs[i].jobName == jobName){
                        this.setState({date:getFullDate(jobs[i].scheduleTime)})
                        console.log(this.state.date)
                    }
                }
                }
                getServerByOrgId(user.orgId).then(ServerRes => {
                    if (ServerRes.code == 200) {
                        let server=[];
                        let newServer=[];
                        console.log(ServerRes.result)
                        server = ServerRes.result;
                        
                        if (server.length>0){
                            this.setState({serverOptionsForServerDown:server})
                            console.log(this.state.serverOptionsForServerDown)
                        for(let i=0;i<server.length;i++){
                            console.log("inside for")
                            let accessToken=server[i].accessToken;
                            getMirthServerDetails(accessToken).then(serverRes=>{
                                if (serverRes.code == 200 && serverRes.result.length !=0) {
                                    let details = JSON.parse(serverRes.result[0].details);
                                    if (details.channelStatus && typeof details.channelStatus == "string"){

                                    console.log(serverRes.result)
                                    console.log("inside if")
                                    newServer.push(server[i])
                                    console.log(newServer)}
                                    else{
                                        getSubscribedChannels(accessToken).then((channelRes) => {
                                            if (channelRes.code == 200 && channelRes.result.length != 0) {
                                                console.log(serverRes.result)
                                    console.log("inside if")
                                    newServer.push(server[i])
                                    console.log(newServer)

                                            }})

                                    }
                                    getjobs(jobName).then(DataRes=> {
                                        console.log(DataRes)
                                        if (DataRes.code == 200) {
                                           
                                            this.setState({data:DataRes.result[0]})
                                            this.handleEditClick(DataRes.result[0])
                                            
                                        }
                                        else{
                            
                                        }
                                    }
                                    )

                        }})}
                        this.setState({currentUser:user, scheduledJobs: orgJobs, serverOptions: newServer, cloneScheduledJobs: orgJobs, access: AuthService.getCurrentUserAccess() });

                        
                    }

                     
                        // if(server.length > 0)
                        // {
                        //     this.setState({currentUser:user, spin: false, scheduledJobs: orgJobs, serverOptions: newServer, cloneScheduledJobs: orgJobs, access: AuthService.getCurrentUserAccess() });
                        // }
                        else
                        {
                            this.setState({currentUser:user,isError:true, spin: false, scheduledJobs: orgJobs, serverOptions: ServerRes.result, cloneScheduledJobs: orgJobs, access: AuthService.getCurrentUserAccess() });
                        }
                        
                    
                
                       
                    
                        
                    }
                    
                })

            }
            console.log(this.state.orgJobs)


        })
    }

       
    })
}
 redirect (){
    this.props.history.push("/mirth/email-scheduler");

   
    
 }
convertTZ(date) {
//  moment.tz.add("Asia/Calcutta|HMT BURT IST IST|-5R.k -6u -5u -6u|01232|-18LFR.k 1unn.k HB0 7zX0");
// moment.tz.link("Asia/Calcutta|Asia/Kolkata");
    var format = 'YYYY/MM/DD HH:mm:ss ZZ';
    let timezone='America/New_York'
    return moment.tz(date,timezone);
}


handleSubmit(val) {
    let user = this.state.currentUser;
    this.setState({ spin: true });
    console.log(val)
  
    let request = {};
    let stdObvRequest = {};
     let details =JSON.parse(this.state.data.details);
    let content = "<p>This message was trigged by Aigilx health on</p><p id='date'></p><h3>SERVER DOWN ERROR</h3><table style='width:100%; border:1px solid black'><tr style=' border:1px solid black'><th style=' border:1px solid black'>Server Id</th><th style=' border:1px solid black'>Server Name</th><th style=' border:1px solid black'>Time</th>    <th style=' border:1px solid black'>Metric</th></tr><tr>  <td style=' border:1px solid black'>jihiui</td> <td style=' border:1px solid black'>Local</td> <td style=' border:1px solid black'>29-09-20</td> <td style=' border:1px solid black'>SERVER DOWN</td></tr></table>";
    request.email = val.email;
    //request.subject = val.subject;
    request.jobName = val.jobName;
    request.timeZone = 'America/New_York';
    request.context = val.context;
    
    if (this.state.jobTypeValue == 1) {
        console.log(moment(val.dateTime).tz("America/New_York").format("YYYY-MM-DDTHH:mm:ss"));
        //   request.dateTime = moment(val.dateTime).format("YYYY-MM-DDTHH:mm:ss");
        request.dateTime = moment(val.dateTime).tz("America/New_York").format("YYYY-MM-DDTHH:mm:ss");
        request.cronExpression = null;
        request.interval = null;
    }
    else {
        request.dateTime = null;
        request.cronExpression = val.cronExpression;
        request.interval = val.cronExpression.substring(4, 6).trim();
    }
   
    if (val.context == "errorPercentAlert") {
        this.state.serverOptions.map((server) => {
            if (server.accessToken == val.server) {
                request.servers = [server];
            }
        });
        if(this.state.channelThresholdList!=0){
        request.channels = this.state.channelThresholdList;
        console.log("inside if")
        }
        else{
            request.channels=details.channels
            console.log("inside else")
        }
       
    }
    else if (val.context == "errorCountAlert") {
        this.state.serverOptions.map((server) => {
            if (server.accessToken == val.server) {
                request.servers = [server];
            }
        });
        if(this.state.channelThresholdList!=0){
            request.channels = this.state.channelThresholdList;
            console.log("inside if")
            }
            else{
                request.channels=details.channels
                console.log("inside else")
            }
           
    }
    else if (val.context == "rawDeltaAlert") {
        this.state.serverOptions.map((server) => {
            if (server.accessToken == val.server) {
                request.servers = [server];
            }
        });
        if(this.state.channelThresholdList!=0){
            request.channels = this.state.channelThresholdList;
            console.log("inside if")
            }
            else{
                request.channels=details.channels
                console.log("inside else")
            }
           
    }
    else if (val.context == "channelStats" || val.context == "pollerDataDeleteJob") {
        request.servers = this.state.serverOptions;
        request.channels = null;
    }
    else if (val.context == "serverDownAlert" ) {
        let servers = val.servers;
        let serversArr=[];
        for(let i=0;i<servers.length;i++)
        {
            this.state.serverOptionsForServerDown.map((server) => {
                if (server.accessToken == servers[i]) {
                    serversArr.push(server);
                   
                }
            });
        }
        request.servers = serversArr;
        request.channels = null;
        request.threshold=this.state.threshold;
    }
    else if (val.context == "zeroReceivedAlert" || val.context == "stdDevAlert" || val.context == "stdDevObservation"|| val.context == "errorReport") {
        let channelArr = [];
        this.state.serverOptions.map((server) => {
            if (server.accessToken == val.server) {
                request.servers = [server];
            }
        });

        for (let j = 0; j < this.state.channelListData.length; j++) {
            for (let k = 0; k < val.channels.length; k++) {
                let channel=val.channels
                if(typeof(channel[k])=="string"){
                    if (val.channels[k] == this.state.channelListData[j].key) {
                        let channeljson = { 'id': val.channels[k], 'name': this.state.channelListData[j].name };
                        console.log(channeljson)
                        channelArr.push(channeljson)
                    }
                }
                else{
                    if (val.channels[k].id == this.state.channelListData[j].key) {
                        let channeljson = { 'id': val.channels[k].id, 'name': this.state.channelListData[j].name };
                        console.log(channeljson)
                        channelArr.push(channeljson)
                    }
                }
    
            }
               
        request.channels = channelArr;
    }}
    else if(val.context == 'userAuditReport' || val.context == 'sessionMonitoring'){
        let serverData=[]
        serverData.push(this.state.serverOptions[0])
        request.timeRange=val.interval;
        request.servers=serverData;
       
    }
    else if(val.context == 'channelTrendsReport'){
        let channelArr = [];
        this.state.serverOptions.map((server) => {
            if (server.accessToken == val.server) {
                request.servers = [server];
            }
        });
       
        // for(let i=0;i<channel.length;i++){
        //     console.log(typeof(channel[i]))
           
        console.log( this.state.channelListData)
        for (let j = 0; j < this.state.channelListData.length; j++) {
            for (let k = 0; k < val.channels.length; k++) {
                let channel=val.channels
                if(typeof(channel[k])=="string"){
                if (val.channels[k] == this.state.channelListData[j].key) {
                    let channeljson = { 'id': val.channels[k], 'name': this.state.channelListData[j].name };
                    console.log(channeljson)
                    channelArr.push(channeljson)
                }
            }
            else{
                if (val.channels[k].id == this.state.channelListData[j].key) {
                    let channeljson = { 'id': val.channels[k].id, 'name': this.state.channelListData[j].name };
                    console.log(channeljson)
                    channelArr.push(channeljson)
                }
            }

        }
    }
        request.channels = channelArr;
        request.timeRange=val.interval;
        
       
    }
    else if(val.context == 'channelTrendsRangeReport'){
        let channelArr = [];
        this.state.serverOptions.map((server) => {
            if (server.accessToken == val.server) {
                request.servers = [server];
            }
        });
       
        // for(let i=0;i<channel.length;i++){
        //     console.log(typeof(channel[i]))
           
        console.log( this.state.channelListData)
        for (let j = 0; j < this.state.channelListData.length; j++) {
            for (let k = 0; k < val.channels.length; k++) {
                let channel=val.channels
                if(typeof(channel[k])=="string"){
                if (val.channels[k] == this.state.channelListData[j].key) {
                    let channeljson = { 'id': val.channels[k], 'name': this.state.channelListData[j].name };
                    console.log(channeljson)
                    channelArr.push(channeljson)
                }
            }
            else{
                if (val.channels[k].id == this.state.channelListData[j].key) {
                    let channeljson = { 'id': val.channels[k].id, 'name': this.state.channelListData[j].name };
                    console.log(channeljson)
                    channelArr.push(channeljson)
                }
            }

        }
    }
        request.channels = channelArr;
        request.toDate =moment(val.toDate).tz("America/New_York").format("YYYY-MM-DD HH:mm:ss");
        request.fromDate = moment(val.fromDate).tz("America/New_York").format("YYYY-MM-DD HH:mm:ss");
        
       
    }
    else if(val.context == 'usageReport'){
        let channelArr = [];
        this.state.serverOptions.map((server) => {
            if (server.accessToken == val.server) {
                request.servers = [server];
            }
        });
       
        // for(let i=0;i<channel.length;i++){
        //     console.log(typeof(channel[i]))
           
        console.log( this.state.channelListData)
        for (let j = 0; j < this.state.channelListData.length; j++) {
            for (let k = 0; k < val.channels.length; k++) {
                let channel=val.channels
                if(typeof(channel[k])=="string"){
                if (val.channels[k] == this.state.channelListData[j].key) {
                    let channeljson = { 'id': val.channels[k], 'name': this.state.channelListData[j].name };
                    console.log(channeljson)
                    channelArr.push(channeljson)
                }
            }
            else{
                if (val.channels[k].id == this.state.channelListData[j].key) {
                    let channeljson = { 'id': val.channels[k].id, 'name': this.state.channelListData[j].name };
                    console.log(channeljson)
                    channelArr.push(channeljson)
                }
            }

        }
    }
    request.channels = channelArr;
        request.toDate= moment(val.toDate).tz("America/New_York").format("YYYY-MM-DD HH:mm:ss");
        request.fromDate = moment(val.fromDate).tz("America/New_York").format("YYYY-MM-DD HH:mm:ss");
        
       
    }
    


    else if(val.context == 'dailyUsageReport'){
        let channelArr = [];
        this.state.serverOptions.map((server) => {
            if (server.accessToken == val.server) {
                request.servers = [server];
            }
        });
        console.log(typeof( val.channels))
        for (let j = 0; j < this.state.channelListData.length; j++) {
            for (let k = 0; k < val.channels.length; k++) {
                let channel=val.channels
                if(typeof(channel[k])=="string"){
                if (val.channels[k] == this.state.channelListData[j].key) {
                    let channeljson = { 'id': val.channels[k], 'name': this.state.channelListData[j].name };
                    console.log(channeljson)
                    channelArr.push(channeljson)
                }
            }
            else{
                if (val.channels[k].id == this.state.channelListData[j].key) {
                    let channeljson = { 'id': val.channels[k].id, 'name': this.state.channelListData[j].name };
                    console.log(channeljson)
                    channelArr.push(channeljson)
                }
            }

        }
        request.channels = channelArr;
    }
        // request.timeRange=val.interval;
        
       
    }
    else if (val.context == "pgToastVacuumAlert") {
        let channelArr = [];
        this.state.serverOptions.map((server) => {
            if (server.accessToken == val.server) {
                request.servers = [server];
            }
        });

        for (let j = 0; j < this.state.channelListData.length; j++) {
            for (let k = 0; k < val.channels.length; k++) {
                let channel=val.channels
                if(typeof(channel[k])=="string"){
                if (val.channels[k] == this.state.channelListData[j].key) {
                    let channeljson = { 'id': val.channels[k], 'name': this.state.channelListData[j].name };
                    console.log(channeljson)
                    channelArr.push(channeljson)
                }
            }
            else{
                if (val.channels[k].id == this.state.channelListData[j].key) {
                    let channeljson = { 'id': val.channels[k].id, 'name': this.state.channelListData[j].name };
                    console.log(channeljson)
                    channelArr.push(channeljson)
                }
            }

        }
    }
        
        request.channels = channelArr;
        let size=val.tableValue;
        console.log(size)
        if(this.state.tableValue == "MB"){
        request.tableSize=size;
    }
    else{
        let tableSize=size*1000;
        request.tableSize=tableSize;

    }
}

    else if(val.context == 'spikeReport'){
        let channelArr = [];
        this.state.serverOptions.map((server) => {
            if (server.accessToken == val.server) {
                request.servers = [server];
            }
        });
        for (let j = 0; j < this.state.channelListData.length; j++) {
            for (let k = 0; k < val.channels.length; k++) {
                let channel=val.channels
                if(typeof(channel[k])=="string"){
                if (val.channels[k] == this.state.channelListData[j].key) {
                    let channeljson = { 'id': val.channels[k], 'name': this.state.channelListData[j].name };
                    console.log(channeljson)
                    channelArr.push(channeljson)
                }
            }
            else{
                if (val.channels[k].id == this.state.channelListData[j].key) {
                    let channeljson = { 'id': val.channels[k].id, 'name': this.state.channelListData[j].name };
                    console.log(channeljson)
                    channelArr.push(channeljson)
                }
            }

        }
    }
    request.channels = channelArr;
        // request.timeRange=val.interval;
        
       
    }
    else if(val.context == 'weeklyUsageReport'){
        let channelArr = [];
        this.state.serverOptions.map((server) => {
            if (server.accessToken == val.server) {
                request.servers = [server];
            }
        });
        for (let j = 0; j < this.state.channelListData.length; j++) {
            for (let k = 0; k < val.channels.length; k++) {
                let channel=val.channels
                if(typeof(channel[k])=="string"){
                if (val.channels[k] == this.state.channelListData[j].key) {
                    let channeljson = { 'id': val.channels[k], 'name': this.state.channelListData[j].name };
                    console.log(channeljson)
                    channelArr.push(channeljson)
                }
            }
            else{
                if (val.channels[k].id == this.state.channelListData[j].key) {
                    let channeljson = { 'id': val.channels[k].id, 'name': this.state.channelListData[j].name };
                    console.log(channeljson)
                    channelArr.push(channeljson)
                }
            }

        }
    }
        request.channels = channelArr;
        // request.timeRange=val.interval;
        
       
    }

    this.setState({JobName:val.jobName,request:request});
    //request.body = val.subject;
    console.log(request);
    this.scheduledJob();
   
            


   

}

scheduledJob()
{
    let user = AuthService.getCurrentUser()
   
    scheduleEmailForEdit(this.state.request,user.orgId).then(response => {
        if (response.statusCode == 200) {
                let jobs = response.data;
                let orgJobs=[];
                for(let i=0;i<jobs.length;i++)
                {
                    if (jobs[i].jobDetails.servers !== null) {
                    let server = jobs[i].jobDetails.servers[0];
                    if(server.orgId == this.state.currentUser.orgId)
                    {
                        orgJobs.push(jobs[i])
                    }
                }
                }
                this.setState({ spin: false, scheduledJobs: orgJobs, isOneTimeJob: true, isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isPollerDataDeleteJob:false,isChannelStats:false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: false,isErrorReport:false, cloneScheduledJobs: orgJobs ,activeKey: '2',jobName:'',request:''})
                this.editFormRef.current.resetFields();
                openNotification('Job scheduled successfully!');
                // redirect();
                console.log(this.state.isRedirect)
                this.redirect()
        }
    })
}

    
    handleEditClick =(data)=>{

        let user = this.state.currentUser;      
        let currentRecord = {};
        let element=''
        let trimString=''
        let details =JSON.parse(data.details);
        console.log(details)
        currentRecord.jobName = data.jobName;
        currentRecord.timeZone = 'America/New_York';
        let urls = (details.email).split(',')
        let arr = urls.map(url => url.replace(/'|\[|\]/g, ''))
        arr = arr.map(function (el) {
            return el.trim();
          });
          console.log(arr);
        console.log(arr)
        currentRecord.email=arr
         if(details.dateTime == "null" ||details.dateTime == undefined){
                    currentRecord.cronExpression=details.cronExpression;
                    currentRecord.interval=details.interval;
                    currentRecord.jobType=2;
                    this.setState({isOneTimeJob:false,jobType:'cronJob',jobTypeValue:2})

                }
                else{
                 
                    
                   
                    currentRecord.dateTime=moment(this.state.date)
                    currentRecord.jobType=1
                    this.setState({isOneTimeJob:true,jobType:'oneTimeJob',jobTypeValue:1})
                }
                if (this.state.data.context == "errorPercentAlert") {
                    let channelArr=[]
                    currentRecord.context=this.state.data.context
                    currentRecord.server=[details.servers[0].accessToken]
                    currentRecord.channels = details.channels;
                    
                        channelArr=details.channels
            // channelArr.push(currentRecord.channels)
            console.log(channelArr)
            this.setState({isErrorPercentAlert:true,accessToken:details.servers[0].accessToken,isServerChanged: Math.random(), channelData:channelArr})
        }

    else if (this.state.data.context == "rawDeltaAlert") {
        let channelArr=[]
        currentRecord.context=this.state.data.context
        currentRecord.server=[details.servers[0].accessToken]
           currentRecord.channels = details.channels;
           channelArr=details.channels
           this.setState({isRawDeltaAlert:true,accessToken:details.servers[0].accessToken,isServerChanged: Math.random(), channelData:channelArr})
        }
        else if (this.state.data.context == "serverDownAlert" ) {
            currentRecord.context=this.state.data.context
            let servers = details.servers;
            let serversArr=[];
            for(let i=0;i<servers.length;i++)
            {
                this.state.serverOptionsForServerDown.map((server) => {
                    if (server.accessToken == servers[i].accessToken) {
                        serversArr.push(server.accessToken);
                       
                    }
                });
            }
            console.log(serversArr)
            currentRecord.servers = serversArr ;
            console.log(details)
            let threshold=details.threshold;
            currentRecord.threshold=Number(threshold)
            this.setState({isServerDownAlert:true ,threshold:details.threshold})
        }
        else if (this.state.data.context == "zeroReceivedAlert" ) {
            let channel=[]
            currentRecord.context=this.state.data.context
            
            currentRecord.server=[details.servers[0].accessToken]
            details.channels.map((value=>{
                channel.push(value.id)

            }))
            console.log(channel)
            currentRecord.channels = details.channels;
            this.handleChannelData(details.servers[0].accessToken,channel)
            this.setState({isZeroRecievedAlert:true,accessToken:details.servers[0].accessToken})
            
        }
        else if (this.state.data.context == "stdDevAlert" ) {
            let channel=[]
            currentRecord.context=this.state.data.context
            currentRecord.server=[details.servers[0].accessToken]
                details.channels.map((value=>{
                    channel.push(value.id)
    
                }))
                console.log(channel)
                currentRecord.channels = details.channels;
                this.handleChannelData(details.servers[0].accessToken,channel)
                currentRecord.channels = details.channels;

                this.setState({isStdDevAlert:true,accessToken:details.servers[0].accessToken})
            }
            else if (this.state.data.context == "stdDevObservation" ) {
                let channel=[]
                currentRecord.context=this.state.data.context
                currentRecord.server=[details.servers[0].accessToken]
                
                      
                             details.channels.map((value=>{
                                channel.push(value.id)
                
                            }))
                            console.log(channel)
                            currentRecord.channels = details.channels;
                            this.handleChannelData(details.servers[0].accessToken,channel)
                             currentRecord.channels = details.channels;
                             this.setState({isStdDevObservation:true,accessToken:details.servers[0].accessToken})
            }
            else if (this.state.data.context == "errorReport" ) {
                let channel=[]
                currentRecord.context=this.state.data.context
                currentRecord.server=[details.servers[0].accessToken]
                details.channels.map((value=>{
                    channel.push(value.id)
    
                }))
                console.log(channel)
                currentRecord.channels = details.channels;
                this.handleChannelData(details.servers[0].accessToken,channel)
                currentRecord.channels = details.channels;
                this.setState({isErrorReport:true,accessToken:details.servers[0].accessToken})
            }
            else if(this.state.data.context == 'channelTrendsReport'){
                let channel=[]
                currentRecord.context=this.state.data.context
                console.log(this.state.serverOptions)
               currentRecord.server=[details.servers[0].accessToken]
                    details.channels.map((value=>{
                        channel.push(value.id)
        
                    }))
                    console.log(channel)
                    currentRecord.channels = details.channels;
                    this.handleChannelData(details.servers[0].accessToken,channel)
                    currentRecord.channels = details.channels;
                   
                    
                    currentRecord.interval=details.timeRange;
                    
                    this.setState({ischannelTrends:true,accessToken:details.servers[0].accessToken})
            }
            else if(this.state.data.context == 'channelTrendsRangeReport'){
                let channel=[]
                
                currentRecord.context=this.state.data.context
                console.log(this.state.serverOptions)
               currentRecord.server=[details.servers[0].accessToken]
                    details.channels.map((value=>{
                        channel.push(value.id)
        
                    }))
                    console.log(channel)
                    currentRecord.channels = details.channels;
                    this.handleChannelData(details.servers[0].accessToken,channel)
                    currentRecord.channels = details.channels;
                    let fromDate=this.convertTZ(details.fromDate)
                    let toDate=this.convertTZ(details.toDate)
                   
                    
                    currentRecord.fromDate=fromDate;
                    currentRecord.toDate=toDate;
                    
                    this.setState({isChannelTrendsRange:true,accessToken:details.servers[0].accessToken})
            }
            else if(this.state.data.context == 'usageReport'){
                let channel=[]
                currentRecord.context=this.state.data.context
                console.log(this.state.serverOptions)
               currentRecord.server=[details.servers[0].accessToken]
                    details.channels.map((value=>{
                        channel.push(value.id)
        
                    }))
                    console.log(channel)
                    currentRecord.channels = details.channels;
                    this.handleChannelData(details.servers[0].accessToken,channel)
                    currentRecord.channels = details.channels;
                   
                    
                    let fromDate=this.convertTZ(details.fromDate)
                    let toDate=this.convertTZ(details.toDate)
                   
                    
                    currentRecord.fromDate=fromDate;
                    currentRecord.toDate=toDate;
                    this.setState({isUsageReportWithInterval:true,accessToken:details.servers[0].accessToken})
            }
            else if(this.state.data.context == 'dailyUsageReport'){
                let channel=[]
                currentRecord.context=this.state.data.context
                currentRecord.server=[details.servers[0].accessToken]
                details.channels.map((value=>{
                    channel.push(value.id)
    
                }))
                console.log(channel)
                currentRecord.channels = details.channels;
                this.handleChannelData(details.servers[0].accessToken,channel)
                currentRecord.channels = details.channels;
               
                
          
                
              
                
                this.setState({isUsageReport:true,accessToken:details.servers[0].accessToken})
            }
            else if(this.state.data.context == 'spikeReport'){
                let channel=[]
                currentRecord.context=this.state.data.context
                currentRecord.server=[details.servers[0].accessToken]
                details.channels.map((value=>{
                    channel.push(value.id)
    
                }))
                console.log(channel)
                currentRecord.channels = details.channels;
                this.handleChannelData(details.servers[0].accessToken,channel)
                currentRecord.channels = details.channels;
               
                
                

                
                
                this.setState({isSpikeReport:true,accessToken:details.servers[0].accessToken})
            }
            else if(this.state.data.context == 'weeklyUsageReport'){
                let channel=[]
                currentRecord.context=this.state.data.context
                currentRecord.server=[details.servers[0].accessToken]
                details.channels.map((value=>{
                    channel.push(value.id)
    
                }))
                console.log(channel)
                currentRecord.channels = details.channels;
                this.handleChannelData(details.servers[0].accessToken,channel)
                currentRecord.channels = details.channels;
               
                
            
                
                this.setState({isWeeklyReport:true,accessToken:details.servers[0].accessToken})
            }
            else if (this.state.data.context == "pgToastVacuumAlert" ) {
                let channel=[]
                currentRecord.context=this.state.data.context
                currentRecord.server=[details.servers[0].accessToken]
                details.channels.map((value=>{
                    channel.push(value.id)
    
                }))
                console.log(channel)
                currentRecord.channels = details.channels;
                this.handleChannelData(details.servers[0].accessToken,channel)
                currentRecord.channels = details.channels;
                let tableValue=details.tableSize;
                currentRecord.tableValue=Number(tableValue)
                
                this.setState({isPgToastAlert:true,accessToken:details.servers[0].accessToken})
            }
            else if (this.state.data.context == "errorCountAlert") {
                let channelArr=[]
                currentRecord.context=this.state.data.context
                currentRecord.server=[details.servers[0].accessToken]
                
                    currentRecord.channels = details.channels;
                    channelArr=details.channels
        // channelArr.push(currentRecord.channels)
        console.log(channelArr)
        this.setState({isErrorCountAlert:true,accessToken:details.servers[0].accessToken,isServerChanged: Math.random(), channelData:channelArr})
    
}
            


            console.log(currentRecord)
            
            this.editFormRef.current.setFieldsValue(currentRecord);
            this.setState({spin:false})
        } 
    handleCronChange(val) {
        console.log(val);
        this.setState({ cronValue: val })
    }
    handleChannelTransfer(val) {
        console.log(val)
        this.setState({ channelThresholdList: val });
    }
     error = () => {
        message.error('Please select the channels');
      };
      handleChannelListChange = (items) => {

        this.setState({ targetKeys: items });
    }
    handleJobTypeChange(e) {

        let val = e.target.value;
        let type = "oneTimeJob";
        let booleanJob = this.state.isOneTimeJob;
        if (val == 1) {
            booleanJob = true;
            type = "oneTimeJob";
        }
        else {
            booleanJob = false;
            type = "cronJob";
        }
        this.setState({ jobTypeValue: e.target.value, jobType: type, isOneTimeJob: booleanJob })
    }
    onKeyChange(val)
    {
        this.setState({ activeKey: val })
       }
       handleChange(val){
        this.setState({tableValue:val})

       }
   
        handleServerChange(val) {
    
            if (this.state.isZeroRecievedAlert || this.state.isStdDevAlert || this.state.isStdDevObservation || this.state.isErrorReport||this.state.isPgToastAlert||this.state.isWeeklyReport||this.state.isSpikeReport||this.state.isUsageReport||this.state.ischannelTrends||this.state.isChannelTrendsRange||this.state.isUsageReportWithInterval) {
                this.setState({ spin: true,accessToken:val });
                getMirthServerDetails(val).then(serverRes=>{
                    let channelArr = [];
                    if (serverRes.code == 200 && serverRes.result.length !=0) {
                        let details = JSON.parse(serverRes.result[0].details);
                        if(details.channelStatus && typeof(details.channelStatus) == 'string')
                        {
                        let res =JSON.parse( details.channelStatus);
                        let values= res.list.dashboardStatus;
                        let finalArr =[];
                        if (values !== null && !Array.isArray(values)) {
                            channelArr.push(values);
                          }
                        else
                        {
                            channelArr =values;
                        }
                        for (let l = 0; l < channelArr.length; l++) {
                            let channeljson = { key: channelArr[l].channelId, name: channelArr[l].name, threshold: 50 };
                            finalArr.push(channeljson);
                        }
                        this.setState({ channelListData: finalArr, targetKeys: [], spin: false })
                        }
                        else
                        {
                            getSubscribedChannels(val).then(channelRes =>{
                                if (channelRes.code == 200 && channelRes.result.length != 0) {
                                    let result = channelRes.result;
                                    var channelArr =result.map(function(obj) {
                                        return {
                                            name: obj.channel_name,
                                            channelId: obj.channel_id,
                                            state: obj.status
                                        }
                                    })
                                    let finalArr =[];
                                    for (let l = 0; l < channelArr.length; l++) {
                                        let channeljson = { key: channelArr[l].channelId, name: channelArr[l].name, threshold: 50 };
                                        finalArr.push(channeljson);
                                    }
                                    this.setState({ channelListData: finalArr, targetKeys: [], spin: false })
                                }
                                
                            })
                        }
                    }
                  
                })

            }
            else if (this.state.isErrorPercentAlert || this.state.isRawDeltaAlert ||this.state.isErrorCountAlert) {
                this.setState({ isServerChanged: Math.random() ,accessToken:val });
            }
        


}
handleChannelData(val,key){
    this.setState({ spin: false,accessToken:val,targetKeys:key });
    getMirthServerDetails(val).then(serverRes=>{
        let channelArr = [];
        if (serverRes.code == 200 && serverRes.result.length !=0) {
            let details = JSON.parse(serverRes.result[0].details);
            if(details.channelStatus && typeof(details.channelStatus) == 'string')
            {
            let res =JSON.parse( details.channelStatus);
            let values= res.list.dashboardStatus;
            let finalArr =[];
            if (values !== null && !Array.isArray(values)) {
                channelArr.push(values);
              }
            else
            {
                channelArr =values;
            }
            for (let l = 0; l < channelArr.length; l++) {
                let channeljson = { key: channelArr[l].channelId, name: channelArr[l].name, threshold: 50 };
                finalArr.push(channeljson);
            }
            this.setState({ channelListData: finalArr,  spin: false })
            }
            else
            {
                getSubscribedChannels(val).then(channelRes =>{
                    if (channelRes.code == 200 && channelRes.result.length != 0) {
                        let result = channelRes.result;
                        var channelArr =result.map(function(obj) {
                            return {
                                name: obj.channel_name,
                                channelId: obj.channel_id,
                                state: obj.status
                            }
                        })
                        let finalArr =[];
                        for (let l = 0; l < channelArr.length; l++) {
                            let channeljson = { key: channelArr[l].channelId, name: channelArr[l].name, threshold: 50 };
                            finalArr.push(channeljson);
                        }
                        this.setState({ channelListData: finalArr, spin: false })
                    }
                    
                })
            }
        }
      
    })

}

       


    
        


    handleContextChange(val) {

        //  let val = e.target.value;
        console.log(val);
        this.editFormRef.current.resetFields(["server","servers"]);
        this.setState({ accessToken: null, isServerChanged: Math.random(), channelListData: [], targetKeys: [] });


        if (val == "errorPercentAlert") {
            this.setState({ isErrorPercentAlert: true, isRawDeltaAlert: false, isServerDownAlert: false, isChannelStats: false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: false,isPollerDataDeleteJob:false,isErrorReport:false,isUserAuditReport:false,isSessionMonitoring:false,ischannelTrends:false,isUsageReport:false,isSpikeReport:false,isWeeklyReport:false,isErrorCountAlert:false,isPgToastAlert:false,isChannelTrendsRange:false,isUsageReportWithInterval:false})
        
        }
        else if (val == "serverDownAlert") {
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: true,isChannelStats: false, isZeroRecievedAlert: false, isUserAuditReport:false,isStdDevAlert: false, isStdDevObservation: false,isPollerDataDeleteJob:false ,isErrorReport:false,isSessionMonitoring:false,ischannelTrends:false,isUsageReport:false,isSpikeReport:false,isWeeklyReport:false,isErrorCountAlert:false,isPgToastAlert:false,isChannelTrendsRange:false,isUsageReportWithInterval:false})
        }
        else if (val == "zeroReceivedAlert") {
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isChannelStats: false, isZeroRecievedAlert: true, isStdDevAlert: false, isStdDevObservation: false,isPollerDataDeleteJob:false ,isErrorReport:false,isUserAuditReport:false,isSessionMonitoring:false,ischannelTrends:false,isUsageReport:false,isSpikeReport:false,isWeeklyReport:false,isErrorCountAlert:false,isPgToastAlert:false,isChannelTrendsRange:false,isUsageReportWithInterval:false})
        }
        else if (val == "rawDeltaAlert") {
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: true, isServerDownAlert: false,isChannelStats: false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: false,isPollerDataDeleteJob:false,isErrorReport:false,isUserAuditReport:false,isSessionMonitoring:false,ischannelTrends:false,isUsageReport:false,isSpikeReport:false,isWeeklyReport:false,isErrorCountAlert:false,isPgToastAlert:false,isChannelTrendsRange:false,isUsageReportWithInterval:false})
        }
        else if (val == "stdDevAlert") {
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isChannelStats: false, isZeroRecievedAlert: false, isStdDevAlert: true, isStdDevObservation: false,isPollerDataDeleteJob:false ,isErrorReport:false,isUserAuditReport:false,isSessionMonitoring:false ,ischannelTrends:false,isUsageReport:false,isSpikeReport:false,isWeeklyReport:false,isErrorCountAlert:false,isPgToastAlert:false,isChannelTrendsRange:false,isUsageReportWithInterval:false})
        }
        else if (val == "stdDevObservation") {
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isChannelStats: false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: true,isPollerDataDeleteJob:false ,isErrorReport:false,isUserAuditReport:false,isSessionMonitoring:false,ischannelTrends:false,isUsageReport:false ,isSpikeReport:false,isWeeklyReport:false,isErrorCountAlert:false,isPgToastAlert:false,isChannelTrendsRange:false,isUsageReportWithInterval:false})
        }
        else if (val == "channelStats") {
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isChannelStats: true, isZeroRecievedAlert: false ,isUserAuditReport:false,isStdDevAlert: false, isStdDevObservation: false,isPollerDataDeleteJob:false ,isErrorReport:false,isSessionMonitoring:false,ischannelTrends:false,isUsageReport:false,isSpikeReport:false,isWeeklyReport:false,isErrorCountAlert:false,isPgToastAlert:false,isChannelTrendsRange:false,isUsageReportWithInterval:false})
        }
         else if (val == "pollerDataDeleteJob") {
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isChannelStats: false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: false,isPollerDataDeleteJob:true,isErrorReport:false,isUserAuditReport:false,isSessionMonitoring:false ,ischannelTrends:false,isUsageReport:false,isSpikeReport:false,isWeeklyReport:false,isErrorCountAlert:false,isPgToastAlert:false,isChannelTrendsRange:false,isUsageReportWithInterval:false})
        }
        else if (val == "errorReport") {
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isChannelStats: false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: false,isPollerDataDeleteJob:false,isErrorReport:true,isUserAuditReport:false,isSessionMonitoring:false,ischannelTrends:false ,isUsageReport:false,isSpikeReport:false,isWeeklyReport:false,isErrorCountAlert:false,isPgToastAlert:false,isChannelTrendsRange:false,isUsageReportWithInterval:false})
        }
        else if(val=="userAuditReport"){
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isChannelStats: false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: false,isPollerDataDeleteJob:false,isErrorReport:false,isUserAuditReport:true,isSessionMonitoring:false, ischannelTrends:false ,isUsageReport:false,isSpikeReport:false,isWeeklyReport:false,isErrorCountAlert:false,isPgToastAlert:false,isChannelTrendsRange:false,isUsageReportWithInterval:false})

        }
        else if(val=="sessionMonitoring"){
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isChannelStats: false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: false,isPollerDataDeleteJob:false,isErrorReport:false,isUserAuditReport:false,isSessionMonitoring:true,ischannelTrends:false,isUsageReport:false,isSpikeReport:false,isWeeklyReport:false,isErrorCountAlert:false,isPgToastAlert:false,isChannelTrendsRange:false,isUsageReportWithInterval:false})

        }
        else if(val=="channelTrendsReport"){
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isChannelStats: false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: false,isPollerDataDeleteJob:false,isErrorReport:false,isUserAuditReport:false,isSessionMonitoring:false,ischannelTrends:true ,isUsageReport:false,isSpikeReport:false,isWeeklyReport:false,isErrorCountAlert:false,isPgToastAlert:false,isChannelTrendsRange:false,isUsageReportWithInterval:false})

        }
        else if(val=="dailyUsageReport"){
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isChannelStats: false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: false,isPollerDataDeleteJob:false,isErrorReport:false,isUserAuditReport:false,isSessionMonitoring:false,ischannelTrends:false,isUsageReport:true,isSpikeReport:false,isWeeklyReport:false,isErrorCountAlert:false,isPgToastAlert:false,isChannelTrendsRange:false,isUsageReportWithInterval:false })

        }
        else if(val=="spikeReport"){
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isChannelStats: false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: false,isPollerDataDeleteJob:false,isErrorReport:false,isUserAuditReport:false,isSessionMonitoring:false,ischannelTrends:false,isUsageReport:false,isSpikeReport:true,isWeeklyReport:false,isErrorCountAlert:false,isPgToastAlert:false,isChannelTrendsRange:false,isUsageReportWithInterval:false})

        }
        else if(val=="weeklyUsageReport"){
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isChannelStats: false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: false,isPollerDataDeleteJob:false,isErrorReport:false,isUserAuditReport:false,isSessionMonitoring:false,ischannelTrends:false,isUsageReport:false,isSpikeReport:false,isWeeklyReport:true,isErrorCountAlert:false,isPgToastAlert:false,isChannelTrendsRange:false,isUsageReportWithInterval:false })

        } 
        else if(val=="errorCountAlert"){
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isChannelStats: false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: false,isPollerDataDeleteJob:false,isErrorReport:false,isUserAuditReport:false,isSessionMonitoring:false,ischannelTrends:false,isUsageReport:false,isSpikeReport:false,isWeeklyReport:false,isErrorCountAlert:true,isPgToastAlert:false,isChannelTrendsRange:false,isUsageReportWithInterval:false })

        }
        else if(val=="pgToastVacuumAlert"){
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isChannelStats: false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: false,isPollerDataDeleteJob:false,isErrorReport:false,isUserAuditReport:false,isSessionMonitoring:false,ischannelTrends:false,isUsageReport:false,isSpikeReport:false,isWeeklyReport:false,isErrorCountAlert:false,isPgToastAlert:true,isChannelTrendsRange:false,isUsageReportWithInterval:false })

        }
        else if(val=="channelTrendsRangeReport"){
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isChannelStats: false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: false,isPollerDataDeleteJob:false,isErrorReport:false,isUserAuditReport:false,isSessionMonitoring:false,ischannelTrends:false,isUsageReport:false,isSpikeReport:false,isWeeklyReport:false,isErrorCountAlert:false,isPgToastAlert:true,isChannelTrendsRange:true,isUsageReportWithInterval:false })

        }
        else if(val=="usageReport"){
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isChannelStats: false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: false,isPollerDataDeleteJob:false,isErrorReport:false,isUserAuditReport:false,isSessionMonitoring:false,ischannelTrends:false,isUsageReport:false,isSpikeReport:false,isWeeklyReport:false,isErrorCountAlert:false,isPgToastAlert:true,isChannelTrendsRange:false,isUsageReportWithInterval:true })

        }

    }
    onChange(val){
        this.setState({threshold:val})
       }
       

        
        
    
    render() {


        const {serverOptionsForServerDown,isSpikeReport,isWeeklyReport,tableSize,isPgToastAlert,isErrorCountAlert,targetKeys,channelData,isEdit,isFormVisible,contextList,currentUser,validateMessages,interval,isUsageReport,ischannelTrends,isUserAuditReport,isSessionMonitoring,emailId,serverName,spin, access, serverOptions,isError,accessToken,resultStatus,resultTitle,alertType, activeKey,modalMessage, scheduledJobs, cloneScheduledJobs,  isOneTimeJob, isErrorPercentAlert, isRawDeltaAlert, isServerDownAlert, isZeroRecievedAlert,isErrorReport, isStdDevAlert, isStdDevObservation,isServerChanged, isModalVisible,threshold,isChannelTrendsRange,isUsageReportWithInterval } = this.state;

        return( 
            <>
            <Breadcrumb>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Mirth Monitoring</Breadcrumb.Item>
                    <Breadcrumb.Item>Email Scheduler</Breadcrumb.Item>
                    <Breadcrumb.Item>Edit Form</Breadcrumb.Item>
                </Breadcrumb>

                <div className="content_panel">
                <Spin size="large" spinning={spin}>
             <Row>

<Col span={12}>

    <div class="content_panel_heading">Edit Form
    </div>
</Col></Row>
<div class="divider-1"> <span></span></div>
        <div className="email_scheduler_form_panel">
                            
        <Form name="scheduler_form" ref={this.editFormRef} hideRequiredMark
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={this.handleSubmit}
            validateMessages={validateMessages}
        >
           

            <Form.Item 
           
                name="jobName"
                label="Job Name"
                validateTrigger="onBlur"
                
                rules={[
                    {
                        required: true,
                        //   message: 'Please input Job name!',
                    },
                    
                        
                        
                    // }) => ({
                       
                    //     validator(rule, value) {
                    //         return new Promise((resolve, reject) => {
                    //             checkJobName(value).then(res => {
                                    
                    //                 if (res.data == true) {
                    //                     reject("Job Name already exists!");
                    //                 }
                    //                 else {
                    //                     isJobNameExists( currentUser.orgId,value).then(res => {
                    //                         if (res.code == 200) {
                    //                             let result = res.result[0];
                                            
                    //                             if(result.exists === true)
                    //                             {
                    //                                 if(result.existsbyorg === true)
                    //                                 {
                    //                                     resolve();
                    //                                 }
                    //                                 else
                    //                                 {
                    //                                     reject("Job Name already exists!");
                    //                                 }  
                    //                             }
                    //                             else
                    //                             {
                    //                                 resolve();
                    //                             }
                    //                         }
                    //                     })
                                      
                    //                 }

                            
                            
                        

                
                ]}>
                <Input  disabled="true"
                    placeholder="Enter Job name" 
                />
            </Form.Item>

            <Form.Item
                name="jobType"
                label="Job Type"
            >
                <Radio.Group onChange={this.handleJobTypeChange} defaultValue={1}>
                    <Radio value={1}>One time job</Radio>
                    <Radio value={2}>Cron job</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                name="dateTime"
                label="Date Time"
                style={{ display: isOneTimeJob ? '' : 'none' }}
                rules={[
                    {
                        required: isOneTimeJob,
                        //  message: 'Please input date time!',
                    },
                    // ({ getFieldValue }) => ({
                    //     validator(_, value) {
                    //         var today=new Date();
                    //         if (getFieldValue("dateTime")<today) {
                    //             return Promise.reject(new Error('The seleceted date is passed'));
                               
                    //         }
                    //         return Promise.resolve();
                            
                    //     },
                    // }),
                

                    
                
                            
            
                    // {
                    //     minDate:()=>{var today=new Date()},
                    //     message:'passed date and time'
                    // }
                    // {
                    //     validator:disabledDate(),
                    //     message:'Passesd Date'
                    // },
                    // {
                    //     validator:disabledTime(),
                    //     message:'Passed Time'
                    // }
                ]}

            >
                <DatePicker readOnly={this.readOnly} style={{ width: '300px', height: '35px', borderRadius: '7px' }} showTime disabledDate={disabledDate}  disabledTime={disabledTime}/>

            </Form.Item>

            <Form.Item
                name="cronExpression"
                label="Cron Expression"
                style={{ display: isOneTimeJob ? 'none' : '' }}
            >
                <Cron
                     onChange={(e)=> {this.handleCronChange(e)}}
                    showResultText={true}
                    showResultCron={true}
                />
            </Form.Item>
         
            <Form.List
                name="email"
                

            >
                {(fields, { add, remove }, { errors }) => (
                    <>
                        {fields.map((field, index) => (
                            <Form.Item
                                {...(index === 0 ? '' : formItemLayoutWithOutLabel)}
                                label={index === 0 ? 'To Email' : ''}
                                required={false}
                                style={{ display: "flex", marginBottom: 8 }}
                                key={field.key}
                            >
                                <Form.Item
                                    {...field}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: "Please input email or delete this field.",
                                            //type: 'email'
                                        },
                                        {
                                            message: "Email is not valid",
                                            type: 'email'
                                        }
                                    ]}
                                    noStyle
                                >
                                    <Input placeholder="Enter email" />
                                </Form.Item>
                                {fields.length > 1 ? (
                                    <MinusCircleOutlined
                                        className="dynamic-delete-button"
                                        onClick={() => remove(field.name)}
                                    />
                                ) : null}
                            </Form.Item>
                        ))}
                        <Form.Item  {...tailFormItemLayout}>
                            <Button
                                type="dashed"
                                onClick={() => add()}

                                icon={<PlusOutlined />}
                            >
                                Add Email
                        </Button>

                            <Form.ErrorList errors={errors} />
                        </Form.Item>
                    </>
                )}
            </Form.List>
           


            <Form.Item
                name="context"
                label="Error Type"
                rules={[
                    {
                        required: true,
                        // message: 'Please input context!',
                    },
                ]}

            >
                {contextList.length !== 0 &&
                    <Select placeholder="Select type" style={{ width: '300px' }} onChange={this.handleContextChange}>
                        {contextList.map((context) => (
                            <Option key={context.val} value={context.val}>{context.key}</Option>
                        ))

                        }
                    </Select>}

            </Form.Item>
            <Form.Item name="interval" label="Select Interval:"
             style={{ display: isSessionMonitoring || isUserAuditReport || ischannelTrends? '' : 'none' }}>
           <Select style={{ width: '300px' }} placeholder="Select the interval">
           {interval.map((int) => (
                            <Option key={int.key} value={int.key}>{int.key}</Option>
                        ))

                        }
    </Select>
    </Form.Item>
    <Form.Item label="Threshold in Minutes:" name ="threshold" style={{ display:isServerDownAlert? '' : 'none' }}>
                                <InputNumber min={3} max={60} onChange={this.onChange} />
                                
                                </Form.Item>
    <Form.Item   label="Table Size:" style={{ display:isPgToastAlert? '' : 'none' }}>
                                
                                <InputGroup compact >
                                <Form.Item  name="tableValue">
                               
                                <InputNumber  /></Form.Item>
                                
                                <Form.Item >         
                <Select defaultValue="MB" onChange={this.handleChange}   style={{height:'40px'}}>
                               {tableSize.map((int) => (
                                                <Option key={int.key} value={int.val}>{int.key}</Option>
                                            ))
            
                                            }
                        </Select></Form.Item>
              
           

                
                        
                        
                        
        
                        </InputGroup>
                        
                        </Form.Item>
                        <Form.Item
                                            name="fromDate"
                                            label="From Date"
                                            style={{ display: isChannelTrendsRange ||isUsageReportWithInterval? '' : 'none' }}
                                            // rules={[
                                            //     {
                                            //         required: isOneTimeJob,
                                            //         //  message: 'Please input date time!',
                                            //     },
                                            //     ({ getFieldValue }) => ({
                                            //         validator(_, value) {
                                            //             var today=new Date();
                                            //             if (getFieldValue("dateTime")<today) {
                                            //                 return Promise.reject(new Error('The seleceted date is passed'));
                                                           
                                            //             }
                                            //             return Promise.resolve();
                                                        
                                            //         },
                                            //     }),
                                            

                                                
                                            
                                                        
                                        
                                            //     // {
                                            //     //     minDate:()=>{var today=new Date()},
                                            //     //     message:'passed date and time'
                                            //     // }
                                            //     // {
                                            //     //     validator:disabledDate(),
                                            //     //     message:'Passesd Date'
                                            //     // },
                                            //     // {
                                            //     //     validator:disabledTime(),
                                            //     //     message:'Passed Time'
                                            //     // }
                                            // ]}

                                        >
                                            <DatePicker readOnly={this.readOnly} style={{ width: '300px', height: '35px', borderRadius: '7px' }} showTime disabled/>

                                        </Form.Item>
                                        <Form.Item
                                            name="toDate"
                                            label="To Date"
                                            style={{ display: isChannelTrendsRange ||isUsageReportWithInterval ? '' : 'none' }}
                                            // rules={[
                                            //     {
                                            //         required: isOneTimeJob,
                                            //         //  message: 'Please input date time!',
                                            //     },
                                            //     ({ getFieldValue }) => ({
                                            //         validator(_, value) {
                                            //             var today=new Date();
                                            //             if (getFieldValue("dateTime")<today) {
                                            //                 return Promise.reject(new Error('The seleceted date is passed'));
                                                           
                                            //             }
                                            //             return Promise.resolve();
                                                        
                                            //         },
                                            //     }),
                                            

                                                
                                            
                                                        
                                        
                                            //     // {
                                            //     //     minDate:()=>{var today=new Date()},
                                            //     //     message:'passed date and time'
                                            //     // }
                                            //     // {
                                            //     //     validator:disabledDate(),
                                            //     //     message:'Passesd Date'
                                            //     // },
                                            //     // {
                                            //     //     validator:disabledTime(),
                                            //     //     message:'Passed Time'
                                            //     // }
                                            // ]}

                                        >
                                            <DatePicker readOnly={this.readOnly} style={{ width: '300px', height: '35px', borderRadius: '7px' }} showTime disabled/>

                                        </Form.Item>
                              
                                      
           
            <Form.Item
                name="server"
                label="Server"
                style={{ display: (isErrorPercentAlert || isZeroRecievedAlert || isRawDeltaAlert || isStdDevAlert || isStdDevObservation|| isErrorReport||ischannelTrends || isUsageReport||isPgToastAlert||isErrorCountAlert||isSpikeReport||isWeeklyReport||isChannelTrendsRange||isUsageReportWithInterval) ? '' : 'none' }}
                rules={[
                    {
                        required: (isErrorPercentAlert || isZeroRecievedAlert || isRawDeltaAlert || isStdDevAlert || isStdDevObservation|| isErrorReport||ischannelTrends||isUsageReport||isPgToastAlert||isErrorCountAlert||isSpikeReport||isWeeklyReport||isChannelTrendsRange||isUsageReportWithInterval)
                        //  message: 'Please input server!',
                    },
                ]}


            >
                {serverOptions.length !== 0 &&
                    <Select placeholder="Select server"
                        style={{ width: '300px' }}
                        onChange={this.handleServerChange}>
                        {serverOptions.map((server) => (
                            <Option key={server.accessToken} value={server.accessToken}>{server.serverName}</Option>
                        ))

                        }
                    </Select>}

            </Form.Item>
            <Form.Item
                name="servers"
                label="Servers"
                style={{ display: (isServerDownAlert) ? '' : 'none' }}
                rules={[
                    {
                        required: (isServerDownAlert),
                        //  message: 'Please input server!',
                    },
                ]}


            >
                {serverOptionsForServerDown.length !== 0 &&
                    <Select placeholder="Select server"
                        style={{ width: '300px' }}
                        mode="multiple"
                        allowClear
                        maxTagCount='responsive'>
                        {serverOptionsForServerDown.map((server) => (
                            <Option key={server.accessToken} value={server.accessToken}>{server.serverName}</Option>
                        ))

                        }
                    </Select>}

            </Form.Item>
            {this.state.channelListData.length !== 0 ? (<Form.Item
                name="channels"
                label="Channel"
                style={{ display: (isZeroRecievedAlert || isStdDevAlert || isStdDevObservation || isErrorReport ||isPgToastAlert||isWeeklyReport||isUsageReport||isSpikeReport||ischannelTrends||isChannelTrendsRange||isUsageReportWithInterval) ? '' : 'none' }}
            >

                <Transfer
                    dataSource={this.state.channelListData}
                    listStyle={{
                        width: 250,
                        height: 300,
                    }}
                    showSearch
                    filterOption={(inputValue, item) =>
                        item.name.indexOf(inputValue) !== -1
                    }
                    targetKeys={targetKeys}
                    onChange={this.handleChannelListChange}
                    render={item => item.name}
                />
            </Form.Item>) : (<></>)}


            {accessToken !== null ?
                (<Form.Item
                    name="channels"
                    label="Channel"
                    style={{ display: (isErrorPercentAlert || isRawDeltaAlert||isErrorCountAlert) ? '' : 'none' }}
                >

                    <ChannelTransfer key={isServerChanged} accessToken={accessToken}  onSubmitMessage={this.handleChannelTransfer} dataSource={channelData} direction={"right"}  errorType={this.state.isErrorCountAlert}/>

                </Form.Item>) : (<></>)
            }

           
            <Form.Item {...tailFormItemLayout}>
                <Button htmlType="submit" type="primary" style={{ borderRadius: '7px' }}  >SUBMIT </Button>
                <Link to={`/mirth/email-scheduler`}><Button htmlType="button" style={{ borderRadius: '7px', backgroundColor: '#efefef', marginLeft: '10px' }} > Cancel</Button></Link>
                
            </Form.Item>
            
        </Form>
       

    </div>
    </Spin>
    </div>
    

</>
);
        }
}
        
    
    
                