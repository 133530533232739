import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Line } from '@ant-design/plots';
import moment from 'moment-timezone';

function Graph (props)  {
    const {graphData} = props
  const [data, setData] = useState(graphData);

  useEffect(() => {
      console.log(graphData)
  setData(graphData);
  }, [graphData]);

  const config = {
    data,
 //   tooltip:'false',
    tooltip: {
       shared:'false',
        formatter: (datum) => {
            let toast = datum.toastSizeBytes ;
            var i = toast == 0 ? 0 : Math.floor( Math.log(toast) / Math.log(1024) );
          return { name: datum.channelName, value:(  toast / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i] };
        },
      },
//     tooltip: {
//         showCrosshairs: false,
//         showMarkers: false,
//         enterable: false,
//         enabled:false,
//        formatter: (function(value){
//         console.log(value)
//         var i = Math.floor( Math.log(value.toastSizeBytes) / Math.log(1024) );
// return ( value.toastSizeBytes / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
//     })
//       },
    xField: 'observationTime',
    yField: 'toastSizeBytes',
    seriesField: 'channelName',
    animation: {
        appear: {
          animation: 'path-in',
          duration: 2000,
  }, 
   },
   
  //  slider: {
  //   start: 0.1,
  //   end: 0.5,
  // },
     // smooth: true,
      
        
    xAxis: {

            // label: {
            //     formatter: (function(value){
            //         console.log(value)
            //         return moment(value).format('HH:mm');
            //     })
            // },
        //   type: 'time',
    },
    yAxis: {
       label: {
                formatter: (function(value){
                    var i = value == 0 ? 0 : Math.floor( Math.log(value) / Math.log(1024) );
    return ( value / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
                })
            },
    },
    interactions: [{ type: 'element-active' }],
  };

  return <Line {...config} />;
};


export default Graph;

