import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Line } from '@ant-design/plots';
import moment from 'moment-timezone';

function LineGraph (props)  {
    const {graphData,typeFilter} = props
  const [data, setData] = useState(graphData);
  const [radioFilter, setRadioFilter] = useState(typeFilter);

  useEffect(() => {
      console.log(graphData)
  setData(graphData);
  }, [graphData]);

  useEffect(() => {
    setRadioFilter(typeFilter);
    }, [typeFilter]);

  const config = {
    data,
    tooltip:'false',
    xField: 'observationTime',
    yField: radioFilter,
    seriesField: 'channelName',
    animation: {
        appear: {
          animation: 'path-in',
          duration: 2000,
  }, 
   },
  //  slider: {
  //   start: 0.1,
  //   end: 0.5,
  // },
     // smooth: true,
      
        
    xAxis: {

            // label: {
            //     formatter: (function(value){
            //         console.log(value)
            //         return moment(value).format('HH:mm');
            //     })
            // },
        //   type: 'time',
    },
    // yAxis: {
    //   label: {
    //     // 数值格式化为千分位
    //     //formatter: (v) => `${v}mirth)/g, (s) => `${s},`),
    //   },
    // },
    interactions: [{ type: 'element-active' }],
  };

  return <Line {...config} />;
};


export default LineGraph;

