import React, { Component } from 'react';

import { Popconfirm,Switch,Breadcrumb, Spin, Table,Modal, Row, Col,Tag, Form, Button, notification, Input, Typography, Tabs, Space, Tooltip, InputNumber, Select, message } from 'antd';
// import './Settings.css';

import { PlusOutlined,TransactionOutlined, QuestionCircleOutlined,PlusCircleFilled,UserOutlined,EditOutlined,EyeFilled,EyeInvisibleFilled, DeleteOutlined, EyeTwoTone, EyeInvisibleOutlined,RetweetOutlined } from '@ant-design/icons';
import {SearchBar} from '../../components'
import {

    getServerList,
    createServer,
    updateServer,
    deleteServer
} from '../../service/mirth/MirthService'
import '../../styles/custom-css/antd-custom.css';
import AuthService from '../../service/Auth/auth-service';



const { Option } = Select;

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: { offset: 10, span: 16 },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};
const openNotification = (message) => {
    notification.success({
        message: message,

    });
};
export default class UserSettings extends Component {
    formRef = React.createRef();
    pwdFormRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {

            spin: true,
            userDetails: [],
            cloneUserDetails: [],
            roles:[],
            serverDetails: [],
            cronExpDetails: [],
            isFormVisible: false,
            isEdit: false,
            currentServer: {},
            initialValues: {},
            confirmFormLoading: false,
            tabKey: "1",
            access:'',
            isAdmin:false,
            isModalVisible:false,
            currentUser:{},
            isMFA:true,
            isMobile:false
        }
        this.handleSearch = this.handleSearch.bind(this);
        this.onToggleChange=this.onToggleChange.bind(this);
        this.onMobileToggleChange=this.onMobileToggleChange.bind(this);
        

    }
    componentDidMount() {
        let user = AuthService.getCurrentUser();
        let roles = user.roles;
        let isAdmin =false;
        console.log(user)
        if(roles.includes("ROLE_PROD_ADMIN"))
        {
            isAdmin = true;
        }
        let roleList = [
            {
                'name': 'Org User', 'value': 'org_user'
            },
            {
                'name': 'Org Admin', 'value': 'org_admin'
            }
        ];
    //     if(isAdmin)
    //     {
    //         AuthService.getAllUsers().then(res=>{
           
    //             this.setState({ userDetails:res, cloneUserDetails:res,spin:false, roles: roleList,access:AuthService.getCurrentUserAccess(),isAdmin:isAdmin});
              
    //    })
    //     }
    //     else
    //     {
           
       AuthService.getUsersByOrgId(user.orgId).then(res=>{
           
        this.setState({ userDetails:res, cloneUserDetails:res,spin:false, roles: roleList,access:AuthService.getCurrentUserAccess(),isAdmin:isAdmin});
      
        })
       // }
       
    }
    handleSearch(data)
    {
        this.setState({ userDetails: data });
    }
    showForm=()=>{
        this.setState({initialValues:{},isEdit:false})
            this.setState({isFormVisible: true });  
        
    }
    getUsers(response)
    {
        let user = AuthService.getCurrentUser();
           
       AuthService.getUsersByOrgId(user.orgId).then(res=>{
           
        this.setState({ userDetails:res, cloneUserDetails:res,confirmFormLoading: false, isFormVisible: false,spin:false});
        notification.success({
            message: response.data.message,

        });
        })
        
       
    }
    onModalFinish =(values)=>{
        this.setState({ confirmFormLoading:true})
        let initialValues=this.state.initialValues;
        let roles=[];
        let password = this.pwdFormRef.current.getFieldsValue().password;
       
        AuthService.resetPassword(
            initialValues.id,
            initialValues.username,
            initialValues.email,
            password,
           roles
        ).then(
            response => {
                this.setState({initialValues:{},isModalVisible:false,spin:true})
                this.getUsers(response);
                this.pwdFormRef.current.resetFields();
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

               
                notification.error({
                    message: resMessage,

                });
            }
        );
    }
    onFinish = (values) => {
        console.log(values);
        let user = AuthService.getCurrentUser();
        this.setState({ confirmFormLoading: true, spin:true });
        let userId=this.state.initialValues.id;
        let request = this.formRef.current.getFieldsValue();
        this.formRef.current.resetFields();
        if(this.state.isEdit){
            request.serverId = this.state.initialValues.serverId;
          
            AuthService.updateUser(
                userId,
                values.userName,
                values.email,
               'newpassword',
               [values.role],
               values.mfa,
              this.state.isMobile
            ).then(
                response => {
                    this.setState({initialValues:{},isEdit:false})
                    this.getUsers(response);
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
    
                    this.setState({ spin: false })
                    notification.error({
                        message: resMessage,
    
                    });
                }
            );


        }
        else
        {
          

            AuthService.register(
                values.userName,
                values.email,
                values.password,
                [values.role],
                user.orgId,
                values.mfa,
                values.mobile
            ).then(
                response => {
                    this.setState({initialValues:{},isEdit:false})
                    this.getUsers(response);
                    // AuthService.getAllUsers().then(res=>{
                            
                    //         this.setState({ userDetails:res,cloneUserDetails:res,confirmFormLoading: false, isFormVisible: false,spin:false});
                    //         notification.success({
                    //             message: response.data.message,
            
                    //         });
                    // })
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
    
                    this.setState({ spin: false })
                    notification.error({
                        message: resMessage,
    
                    });
                }
            );
        }
    
 
       
    };

    onReset = () => {
        this.formRef.current.resetFields();
        
        this.setState({ isFormVisible: false,isEdit:false });
    };
    onModalReset = () => {
        this.pwdFormRef.current.resetFields();
    
    };
    onModalCancel= () => {
        this.pwdFormRef.current.resetFields();
        
        this.setState({ isModalVisible: false });
    };
    onResets = () => {
        this.formRef.current.resetFields();
      };
      onToggleChange(e)

    {
        console.log(e)
        // this.formRef.current.resetFields();
        if(e)
        {
          this.setState({ isMFA:true });
        }
        else
        {
            this.setState({ isMFA:false ,isMobile:false});
        }
    }
     
    onMobileToggleChange(e)

    {
        console.log(e)
        // this.formRef.current.resetFields();
        if(e)
        {
          this.setState({ isMobile:true });
        }
        else
        {
            this.setState({isMobile:false});
        }
    }
     
    handleActionClick=(record,action)=>
    {
        this.setState({initialValues:record,spin:true});
       if(action == "delete")
        {
            AuthService.deleteUser(
                record.id
            ).then(
                response => {
                    this.setState({initialValues:{},isEdit:false})
                    this.getUsers(response);
                    // AuthService.getAllUsers().then(res=>{
                            
                    //         this.setState({ userDetails:res,cloneUserDetails:res,spin:false});
                    //         notification.success({
                    //             message: response.data.message,
            
                    //         });
                    // })
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
    
                    this.setState({ spin: false })
                    notification.error({
                        message: resMessage,
    
                    });
                }
            );

        }
        else if(action == "edit")
        {
            console.log(record);
            let currentRecord = record;
            currentRecord.userName = record.username;
            currentRecord.password = 'Password@2';
            currentRecord.confirm = 'Password@2';
            let roles=[];
            for(let i=0;i<record.roles.length;i++)
            {
                if(record.roles[i].name == "ROLE_PROD_ADMIN")
                {
                    roles.push('prod_admin');
                }
                if(record.roles[i].name == "ROLE_ORG_ADMIN")
                {
                    roles.push('org_admin');
                }
                if(record.roles[i].name == "ROLE_ORG_USER")
                {
                    roles.push('org_user');
                }
            }
            currentRecord.role = roles[0];
            currentRecord.mfa=record.enable_mfa;
            currentRecord.mobile=record.enable_mobile_access;
            console.log(currentRecord)
            this.formRef.current.setFieldsValue(currentRecord);
            this.setState({isEdit:true,spin:false,currentUser:currentRecord ,isMFA:record.enable_mfa,isMobile:record.enable_mobile_access});
         
            
         

        }
        else if(action == "resetPwd")
        {

            this.setState({isModalVisible:true,spin:false});
        }
        else if(action == "resetOTP")
        {
            AuthService.resetOTP(
                record.id
            ).then(
                response => {
                    this.setState({spin:false});
                    notification.success({
                    message: response.data.message,

                });
                    
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
    
                    this.setState({ spin: false })
                    notification.error({
                        message: resMessage,
    
                    });
                }
            );
          
        }
    }

    render() {
        const { isMobile,isMFA,currentUser,spin,isModalVisible, serverDetails, isFormVisible,access,userDetails,cloneUserDetails,initialValues,roles, confirmFormLoading, tabKey ,cronExpDetails,isEdit} = this.state;
        const renderTag = (tag) => {
           
            const steps = [];
            for(let i=0;i<tag.length;i++)
            {
                steps.push(<Tag color="warning"  icon={<UserOutlined />}>{tag[i].name}</Tag>);
                
            }
           return steps;
      

        };
         const actionStatus = () => {
            if (access == "read&write") {
               return false
            }
            else
            {
                return true
            }
           
        };
        const UserColumns = [
            { title: 'ID', dataIndex: 'id', key: 'id' },
            { title: 'User Name', dataIndex: 'username', key: 'username' },
            { title: 'Email', dataIndex: 'email', key: 'email' },
            {
                title: 'Roles', dataIndex: 'roles', key: 'roles',
                render: (text,record) => renderTag(record.roles),
                // filters: [
                //     {
                //       text: 'ERROR',
                //       value: 'ERROR',
                //     },
                //     {
                //       text: 'INFO',
                //       value: 'INFO',
                //     },
                //   ],
                //  onFilter: (value, record) => record.level.startsWith(value),
                 // filterSearch: true,
            },
            {
                title: 'Actions', dataIndex: '', key: '',
                render: (text, record) => (
                    <>
                        <Space >
                        <Tooltip title="Edit">
                            <Button  type="text" disabled={actionStatus()}  onClick={() => { this.handleActionClick(record, 'edit') }} icon={<EditOutlined />}/>
                              
                            </Tooltip>
                            <Tooltip title="Delete">
                            <Popconfirm
    title="Are you sure  want to delete this user?"
    
    onConfirm={ ()=>{this.handleActionClick(record, 'delete')} }
  
   
    
    okText="Yes"
    cancelText="No"
  >
    <Button type="text" disabled={actionStatus()}  icon={<DeleteOutlined />} />

  </Popconfirm>
                             
                            </Tooltip>
                            <Tooltip title="Reset Password">
                            <Button  type="text" disabled={actionStatus()}  onClick={() => { this.handleActionClick(record, 'resetPwd') }} icon={<TransactionOutlined />}/>
                              
                            </Tooltip>
                            {record.enable_mfa?(<Tooltip title="Reset OTP">
                            <Button  type="text" disabled={actionStatus()}  onClick={() => { this.handleActionClick(record, 'resetOTP') }} icon={<RetweetOutlined />}/>
                              
                            </Tooltip>) :''}
                        </Space>
                    </>)
            }
        
        ];
      
        return (
            <>
                <Breadcrumb>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Settings</Breadcrumb.Item>
                    <Breadcrumb.Item>User</Breadcrumb.Item>
                </Breadcrumb>


                <Spin size="large" spinning={spin}>
                    <div class="content_panel">
                    <Modal title="Reset Password" closable={false}
         visible={isModalVisible} onCancel={this.onModalReset} 
        width={400} 
        footer={[
          
            <Button key="submit"  form="myForm" type="primary" htmlType="submit" loading={confirmFormLoading} >
              Submit
            </Button>,
             <Button onClick={this.onModalReset}>
             Reset
           </Button>,
              <Button key="back" onClick={this.onModalCancel}>
              Cancel
            </Button>,
          ]}>
                    <Form style={{padding:'0 0 0 0'}} id="myForm" ref={this.pwdFormRef}  name="pwd-control-ref" onFinish={this.onModalFinish}>
                         
                            <Form.Item
                                name="password"
                                label="Password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter password!',
                                    },
                                    { min: 8, message: 'Password must have a minimum length of 8' },
                                    { max: 30, message: 'Password can have a maximum length of 30' },
                                   
                                ]}
                                
                            >
                                <Space><Input.Password
                                    
                                    placeholder="Enter password"
                                    iconRender={visible => (visible ? <EyeFilled style={{color:'#fc9558'}}/>: <EyeInvisibleFilled />)}
                                />
                                <Tooltip title="Password must be of length min 8 - 30"><Typography.Link href="#"><QuestionCircleOutlined /></Typography.Link></Tooltip>
                                </Space></Form.Item>
                    
                            <Form.Item
                                name="confirm"
                                label="Confirm Password"
                                dependencies={['password']}
                                
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password    
                                 placeholder="Enter password"
                                 iconRender={visible => (visible ? <EyeFilled style={{color:'#fc9558'}}/>: <EyeInvisibleFilled />)}/>
                            </Form.Item>
                            {/* <Form.Item style={{width:'300px'}}>
                            
                                <Button type="primary"  style={{borderRadius:'7px'}} htmlType="submit">
                                    Submit
                                </Button>
                               
                                <Button htmlType="button" style={{borderRadius:'7px',backgroundColor:'#efefef',marginLeft:'10px'}} onClick={this.onModalReset}>
                                    Cancel
                                </Button>
                             
                            </Form.Item> */}
                        </Form>
                       
                    </Modal>
                        <Row>

                            <Col span={12}>
                            {(!isEdit && !isFormVisible) &&   <div class="content_panel_heading" >User
                                </div>}
                                {isEdit &&  <div class="content_panel_heading" >Edit User
                                </div>}
                        {isFormVisible &&  <div class="content_panel_heading" >Add User
                                </div>}
                            </Col>
                            <Col span={12}>
                            {(!isEdit && !isFormVisible) &&   <Button disabled={actionStatus()} style={{ float: 'right' ,borderRadius:'7px',backgroundColor:'#efefef',fontFamily:'AktivGroteskMedium',border:'none'}} onClick={this.showForm} >
                                            <PlusCircleFilled style={{color:'#fc9558',fontSize:'16px'}} /> Add User
                                </Button>}
                            </Col>
                           

                        </Row>
                        {/* <Divider /> */}
                        <div class="divider-1"> <span></span></div>
                        {/* {isEdit &&  <div class="settings_heading" style={{ fontSize: "20px", transform: "translateX(-20px)",textAlign:'center' }}>Edit User
                                </div>}
                                {isFormVisible &&  <div class="settings_heading" style={{ fontSize: "20px", transform: "translateX(-20px)",textAlign:'center'}}>Add User
                                </div>} */}

                          {/* {isEdit &&   <Divider orientation="left"><a >Edit User</a></Divider>}
                        {isFormVisible &&  <Divider  orientation="left"><a >Add User</a></Divider>} */}

                        <Form  ref={this.formRef} labelCol={{ span: 10 }} 
                                        wrapperCol={{ span: 16 }} name="control-ref" onFinish={this.onFinish}>
                        {(isEdit || isFormVisible) && 
                        <>
                              <Form.Item
                                name="userName"
                                label="User Name"
                                validateTrigger="onBlur"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter username!'
                                    },
                                    { min: 3, message: 'Username must have a minimum length of 3' },
                                    { max: 20, message: 'Username can have a maximum length of 20' },
                                    {
                                        pattern:/^[^A-Z]+$/g,
                                        message:"Username must be in lower case only "

                                    },
                                    ({
                                        
                                        getFieldValue
                                    }) => ({
                                       
                                        validator(rule, value) {
                                          
                                                return new Promise((resolve, reject) => {
                                                    if(isEdit)
                                                    {
                                                        resolve();
                                                    // AuthService.findByUsername(value).then((res)=>{
                                                     
                                                    //     let result = res;
                                                    //     if(result.id ==  currentUser.id)
                                                    //     {
                                                    //         resolve();
                                                    //     }
                                                    //     else
                                                    //     {
                                                    //         reject("User name already taken!");
                                                    //     }
                                                   
                                                    // })
                                                }
                                                else
                                                {
                                                    AuthService.existsByUsername(value).then((res)=>{
                                                        if(res)
                                                        {
                                                            reject("User name already taken!");
                                                        }
                                                        else
                                                        {
                                                            resolve();
                                                        }
                                                       })
                                                }
                                                 });
                                       
                                        }

                                    })
                                ]}
                               
                            >
                               
                                <Input disabled={isEdit?true:false}
                                    placeholder="Enter User Name"
                                /> 
                                
                            </Form.Item>
                          
                            <Form.Item
                                name="email"
                                label="Email Id"
                                validateTrigger="onBlur"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter email!',
                                    },
                                    {
                                        message: "Email is not valid",
                                        type: 'email'
                                    },
                                    ({
                                        
                                        getFieldValue
                                    }) => ({
                                       
                                        validator(rule, value) {
                                            
                                                return new Promise((resolve, reject) => {
                                                    if(isEdit)
                                                    {
                                                    AuthService.findByEmail(value).then((res)=>{
                                                    
                                                        let result = res;
                                                        if(result)
                                                        {
                                                            if(result.id ==  currentUser.id)
                                                            {
                                                                resolve();
                                                            }
                                                            else
                                                            {
                                                                reject("Email Id already exists!");
                                                            }
                                                        }
                                                        else
                                                        {
                                                            resolve();
                                                        }
                                                      
                                            
                                                   
                                                    })
                                                    }
                                                    else
                                                    {
                                                        AuthService.existsByEmail(value).then((res)=>{
                                                            if(res)
                                                            {
                                                                reject("Email Id already exists!");
                                                            }
                                                            else
                                                            {
                                                                resolve();
                                                            }
                                                           })
                                                    }
                                                 });
                                            
                                        
                                           
                                        }

                                    })
                                ]}
                            >

                                <Input 
                                    placeholder="Enter Email"
                                />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                label="Password"
                                style={{ display: isEdit ? 'none' : '' }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter password!',
                                    },
                                    { min: 8, message: 'Password must have a minimum length of 8' },
                                    { max: 30, message: 'Password can have a maximum length of 30' },
                        
                                    ]}
                            >
                                <Space><Input.Password
                                    
                                    placeholder="Enter password"
                                    iconRender={visible => (visible ? <EyeFilled style={{color:'#fc9558'}}/>: <EyeInvisibleFilled />)}
                                />
                                <Tooltip title="Password must be of length  8 - 30"><Typography.Link href="#"><QuestionCircleOutlined /></Typography.Link></Tooltip>
                                </Space></Form.Item>
                    
                            <Form.Item
                                name="confirm"
                                label="Confirm Password"
                                style={{ display: isEdit ? 'none' : '' }}
                                dependencies={['password']}
                                
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password    
                                 placeholder="Enter password"
                                 iconRender={visible => (visible ? <EyeFilled style={{color:'#fc9558'}}/>: <EyeInvisibleFilled />)}/>
                            </Form.Item>
                            <Form.Item
                                name="role"
                                label="Role"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input role!',
                                    },
                                ]}


                            >
                                {roles.length !== 0 &&
                                    <Select placeholder="Select role" disabled={actionStatus()}
                                     style={{width:'300px'}}
                                    >
                                        {roles.map((role) => (
                                            <Option key={role.value} value={role.value}>{role.name}</Option>
                                        ))

                                        }
                                    </Select>}

                            </Form.Item>
                            <Form.Item
                                name="mfa"
                                label="Enable MFA"
                                valuePropName="checked"
                                initialValue={isMFA}><Switch  onChange={this.onToggleChange}  /></Form.Item>
                                <Form.Item
                                name="mobile"
                                label="Enable Mobile Access"
                                valuePropName="checked"
                                style={{ display: isMFA ? '' : 'none' }}
                                
                                initialValue={isMobile}
                                ><Switch  onChange={this.onMobileToggleChange} /></Form.Item>
                            <Form.Item {...tailLayout}>
                            
                                <Button type="primary"  style={{borderRadius:'7px'}} htmlType="submit">
                                    Submit
                                </Button>
                               
                                <Button htmlType="button" style={{borderRadius:'7px',backgroundColor:'#efefef',marginLeft:'10px'}} onClick={this.onReset}>
                                    Cancel
                                </Button>
                                {!isEdit &&
                                <Button htmlType="button" style={{ backgroundColor: '#efefef',margin: "0 8px" }} onClick={this.onResets}>
                                    Reset</Button>}
                            </Form.Item>
                            </>
                        }
                        </Form>
                       
                        {(!isEdit && !isFormVisible) && 
                        <div>
                           
                         <SearchBar data={cloneUserDetails} handler={this.handleSearch}></SearchBar>

                                <Table
                                 style={{marginTop:'10px'}}
                                    columns={UserColumns}
                                    dataSource={userDetails}
                                />
                                </div>
                        }
                    </div>
                </Spin>
            </>
        );
    }
}