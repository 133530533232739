import React from 'react';
import { Switch, Route} from 'react-router-dom';

// Components

import ServerLogs from '../pages/mirth/ServerLog/ServerLog';
import ChannelStatistics from '../pages/mirth/ChannelStatistics/ChannelStatistics';
import MessageView from '../pages/mirth/MessageView/MessageView';
import EmailScheduler from '../pages/mirth/EmailScheduler/EmailScheduler';
import JobDetailsList from '../pages/mirth/EmailScheduler/JobDetailsList';
import PGToastTable from '../pages/mirth/PGToastData/PGToastTable';
import ServerSettings from '../pages/Settings/ServerSettings';
import UserSettings from '../pages/Settings/UserSettings';
import UserProfile from '../pages/Settings/UserProfile';
import OrganizationSettings from '../pages/Admin/Organization/OrganizationSettings';
import SubscriptionSettings from '../pages/Admin/Subscription/SubscriptionSettings';
import ChannelCreate from '../pages/mirth/ChannelCreate/ChannelCreate';
import ChannelList from '../pages/mirth/ChannelList/ChannelList';
import ChannelGraph from '../pages/mirth/ChannelGraph/ChannelGraph';
import ChannelDiffGraph from '../pages/mirth/ChannelGraph/ChannelDiffGraph';
import ChannelViewGraph from '../pages/mirth/ChannelViewGraph/ChannelViewGraph';
import ChannelTrends from '../pages/mirth/ChannelTrends/ChannelTrends';
import ServerDashboard from '../pages/mirth/Dashboard/ServerDashboard';
import SingleServerDashboard from '../pages/mirth/Dashboard/SingleServerDashboard';
import Login from '../pages/login/login';
import SSO from '../pages/login/sso';
import SignUp from '../pages/login/SignUp';
import AlertList from '../pages/Alerts/AlertList';
import MirthServers from '../pages/Admin/MirthServers/MirthServers';
import UserAudit from '../pages/Admin/UserAudit/UserAudit';
import Visitors from '../pages/Admin/Visitors/Visitors';
import AdminEmailScheduler from '../pages/Admin/EmailScheduler/AdminEmailScheduler';
import PGToastTrends from '../pages/mirth/PGToastTrends/PGToastTrends';
import EmailAlertList from '../pages/Alerts/EmailAlert';
import EditForm from '../pages/mirth/EmailScheduler/EditForm';
import ReportGeneration from '../pages/mirth/ReportGenerator/ReportGenerator'
import MCVulnerabilities from '../pages/Admin/MCVulnerabilities/MCVulnerabilities';

const Routes = () => {
    const routes = [

        {
            path: "/mirth",
            children: [
                {
                    path: "/server-log/:accessToken",
                    component: ServerLogs
                },
                {
                    path: "/channel-statistics/:accessToken",
                    component: ChannelStatistics
                },
                {
                    path: "/channel-create",
                    component: ChannelCreate
                },
                {
                    path: "/server-dashboard",
                    component: ServerDashboard
                },
                {
                    path: "/channel-list/:accessToken",
                    component: ChannelList
                },
                {
                    path: "/channel-trends/:accessToken",
                    component: ChannelTrends
                },
                {
                    path: "/email-scheduler",
                    component: EmailScheduler
                },
                {
                    path: "/edit-form/:jobName",
                    component: EditForm
                },
                {
                    path: "/report-generator",
                    component: ReportGeneration
                },
                
                {
                    path: "/message/:serverId/:channelId",
                    component: MessageView
                },
                {
                    path: "/login",
                    component: Login
                },
                {
                    path: "/signUp",
                    component: SignUp
                } ,
                {
                    path: "/channel-graph/:accessToken",
                    component: ChannelGraph
                }
                ,
                {
                    path: "/channel-diff-graph/:accessToken",
                    component: ChannelDiffGraph
                }
                ,
                {
                    path: "/channel-view-graph",
                    component: ChannelViewGraph
                },
                {
                    path: "/server-dashboard/:accessToken",
                    component: SingleServerDashboard
                } ,
                {
                    path: "/pg-toast-data/:accessToken",
                    component: PGToastTable
                }
                ,
                {
                    path: "/pg-toast-trends/:accessToken",
                    component: PGToastTrends
                },
                {
                    path: "/deleted-jobs",
                    component: JobDetailsList
                },
                
            ]
    
        },
        {
            path: "/admin",
            children: [
               
                
                {
                    path: "/mirth-servers",
                    component: MirthServers
                },
                {
                    path: "/admin-email-scheduler",
                    component: AdminEmailScheduler
                },
                {
                    path: "/session-monitoring",
                    component: UserAudit
                },
                {
                    path:"/user-audit",
                    component:Visitors
                },
                {
                    path: "/organization",
                    component: OrganizationSettings
                },
                {
                    path: "/subscription",
                    component: SubscriptionSettings
                },
                {
                    path: "/mcVulnerabilities",
                    component: MCVulnerabilities
                },
                


            ]
            
    
        },
        // {
        //     path: "/alerts",
        //     children: [
               
        //         {
        //             path: "/list",
        //             component: AlertList
        //         }

        //     ]
    
        // },
        {
            path: "/email-alerts",
            children: [
               
                {
                    path: "/list",
                    component: EmailAlertList
                }
                

            ]
    
        },
        {
            path: "/settings",
            children: [
               
                {
                    path: "/server",
                    component: ServerSettings
                },
                {
                    path: "/user",
                    component: UserSettings
                },
                {
                    path: "/user-profile",
                    component: UserProfile
                }

            ]
    
        },
        {
            path: "/sso/:token",
            component: SSO
        },
    ]
    return (
        
        <Switch>
 
            {routes.map((route, index) => (
                route.children ? (route.children.map((child) => (
                    <Route
                        exact
                        path={route.path+child.path}
                        component={child.component}
                    />
                ))) : (
                <Route
                exact
                    path={route.path}
                    component={route.component}
                />)

            ))}

        </Switch>
       
    )
}

export default Routes