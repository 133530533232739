import axios from 'axios';
var XMLParser = require('react-xml-parser') ;
let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
};

console.log("env",process.env.REACT_APP_API_URL);
let API_URL = process.env.REACT_APP_API_URL + 'api/v1';

export async function getServerLogs(host,username,password) {

    try {
        const { data: response } = await axios.get(
            `https://${host}/api/extensions/serverlog?fetchSize=100`,
           { headers:{
                "Authorization": 'Basic ' + btoa(username+':'+password),
                // "Access-Control-Allow-Origin": "*",
                // "Access-Control-Allow-Credentials":"true",
                // "Access-Control-Allow-Methods":"GET,HEAD,OPTIONS,POST,PUT",
                // "Access-Control-Allow-Headers": "*"
            }}
           
        )
    
        return response;
    }
    catch (error) {
        return 'error';
    }
}

export async function getChannelIdsandNames(host,username,password) {

    try {
        const { data: response } = await axios.get(
            `https://${host}/api/channels/idsAndNames`,
           { headers:{
                'Authorization': 'Basic ' + btoa(username+':'+password)
            }}
        )
        return response;
    }
    catch (error) {
        return error;
    }
}
export async function getChannelStatistics(host,username,password,channelId) {

    try {
        const { data: response } = await axios.get(
            `https://${host}/api/channels/${channelId}/statistics`,
           { headers:{
                'Authorization': 'Basic ' + btoa(username+':'+password)
            }}
        )
        return response;
    }
    catch (error) {
        return error;
    }
}
export async function getChannelStatus(host,username,password,channelId) {

    try {
        const { data: response } = await axios.get(
            `https://${host}/api/channels/${channelId}/status`,
           { headers:{
                'Authorization': 'Basic ' + btoa(username+':'+password)
            }}
        )
        return response;
    }
    catch (error) {
        return error;
    }
}
export async function getAllChannelStatistics(host,username,password) {

    try {
        const { data: response } = await axios.get(
            `https://${host}/api/channels/statistics`,
           { headers:{
                'Authorization': 'Basic ' + btoa(username+':'+password)
            }}
        )
        return response;
    }
    catch (error) {
        return error;
    }
}
export async function getMessagesByChannel(host,username,password,channelId) {

    try {
        const { data: response } = await axios.get(
            `https://${host}/api/channels/${channelId}/messages`,
           { headers:{
                'Authorization': 'Basic ' + btoa(username+':'+password)
            }}
        )
        return response;
    }
    catch (error) {
        return error;
    }
}

export async function getSystemStat(host,username,password) {

    try {
        const { data: response } = await axios.get(
            `https://${host}/api/system/stats`,
           { headers:{
                'Authorization': 'Basic ' + btoa(username+':'+password)
            }}
        )
        var xml = new XMLParser().parseFromString(response) ; 
        return xml;
    }
    catch (error) {
        return error;
    }
}
export async function getSystemInfo(host,username,password) {

    try {
        const { data: response } = await axios.get(
            `https://${host}/api/system/info`,
           { headers:{
                'Authorization': 'Basic ' + btoa(username+':'+password)
            }}
        )
        var xml = new XMLParser().parseFromString(response) ; 
        return xml;
    }
    catch (error) {
        return error;
    }
}
export async function getServerConfigurations(host,username,password) {

    try {
        const { data: response } = await axios.get(
            `https://${host}/api/server/about`,
           { headers:{
                'Authorization': 'Basic ' + btoa(username+':'+password)
            }}
        )
        return response;
    }
    catch (error) {
        return error;
    }
}
export async function getServerId(host,username,password) {

    try {
        const { data: response } = await axios.get(
            `https://${host}/api/server/id`,
           { headers:{
                'Authorization': 'Basic ' + btoa(username+':'+password)
            }}
        )
        return response;
    }
    catch (error) {
        return error;
    }
}
export async function getServerJvm(host,username,password) {

    try {
        const { data: response } = await axios.get(
            `https://${host}/api/server/jvm`,
           { headers:{
                'Authorization': 'Basic ' + btoa(username+':'+password)
            }}
        )
       
        return response;
    }
    catch (error) {
        return error;
    }
}
export async function createChannel(host,username,password,channelSchema) {

    try {
        const { data: response } = await axios.post(
            `https://${host}/api/channels`,
            `${channelSchema}`,
           { headers:{
                'Authorization': 'Basic ' + btoa(username+':'+password),
                'Content-Type': 'application/xml'
               
            }}
        )
        return response;
    }
    catch (error) {
        return error;
    }
}
export async function channelOperation(host,username,password,channelId,operation) {

    try {
        const { data: response } = await axios.post(
            `https://${host}/api/channels/${channelId}/${operation}`,
           '',
           { headers:{
                'Authorization': 'Basic ' + btoa(username+':'+password),
                'Content-Type': 'application/xml'
               
            }}
        )
        return response;
    }
    catch (error) {
        return error;
    }
}

export async function sendEmail(request) {

    try {
        const { data: response } = await axios.post(
            `http://localhost:8084/api/mirth/send-email`,
            request,
          axiosConfig
        )
        return response;
    }
    catch (error) {
        return error;
    }
}