
import { Switch,Route,Redirect,BrowserRouter as Router } from 'react-router-dom';
import {SideBar} from './components';
import Login from './pages/login/login';
import SSO from './pages/login/sso';
import PrivateRoute from './router/PrivateRoute';
import SignUp from './pages/login/SignUp';
import AuthVerify from './common/auth-verify';
import AuthService from './service/Auth/auth-service';
import './App.css';
import './styles/antd.css';
 import 'antd/dist/antd.css';

//  var apm = initApm({

//   // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
//   serviceName: 'control-center-ui-dev',

//   // Set custom APM Server URL (default: http://localhost:8200)
//   serverUrl: 'http://172.16.10.114:8200',

//   // Set service version (required for sourcemap feature)
//   serviceVersion: ''
// })

//  var apm = initApm({

//   // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
//   serviceName: 'control-center-ui-dev',

//   // Set custom APM Server URL (default: http://localhost:8200)
//   serverUrl: 'http://172.16.10.114:8200',

//   // Set service version (required for sourcemap feature)
//   serviceVersion: ''
// })

 
function App() {

 const logOut=()=> {
    AuthService.logout();
  }

  return (
   <div>
    
      <Switch>
      <Route path="/logout" component={() => { window.location = process.env.REACT_APP_AUTH_SERVER_URL+'auth-server/logout'; return null;} }/>
      <Route path="/signup" component={SignUp} />
      <Route path="/sso/:token" component={SSO} />
      <Route path='/external' component={() => { window.location = process.env.REACT_APP_AUTH_SERVER_URL+'auth-server/users'; return null;} }/>
      {/* <Route path="/" component={SideBar} /> */}
      <PrivateRoute path="/" component={SideBar} />
      </Switch>
      <AuthVerify logOut={logOut}/>
    
  
    </div>
     
  );
}

export default App;
