import React, { Component } from 'react';

import { Layout,Spin, Form, Button,Result, notification, Input } from 'antd';
import { EyeTwoTone, EyeInvisibleOutlined,LockOutlined,UserOutlined } from '@ant-design/icons';
import AuthService from "../../service/Auth/auth-service";
import './login.css'


const {  Content, Footer } = Layout;
const openNotification = (message) => {
    notification.error({
        message: message,

    });
};
export default class SSO extends Component {

    loginformRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {

            spin: false,
            resultStatus:'Something went wrong',
            resultTitle:'Error',
            resultSubTitle:'Server not reachable',
            isError:false
        }
    }
    componentDidMount() {
        let token = this.props.match.params.token;
        AuthService.ssoLogin(token).then(
            () => {
              this.props.history.push("/mirth/server-dashboard");
            //  window.location.reload();
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
               // openNotification(resMessage)
               console.log(resMessage)
                this.setState({resultSubTitle:resMessage,isError:true,spin:false});
            }
          );
    }

  
   
    render() {
        const { isError,resultSubTitle,resultTitle,resultStatus,spin} = this.state;
        return (
            <Layout style={{ minHeight: '100vh' }}>
                 <Spin size="large" spinning={spin}>
                <>
               {isError && <Result
    status="500"
    title="Sorry, something went wrong."
    subTitle={resultSubTitle}
    extra={<a href={process.env.REACT_APP_AUTH_SERVER_URL+'auth-server/logout'}><Button type="primary" >Back Home</Button></a>}
  />}
            
                </>
                </Spin>
             </Layout >
        )
    }
}