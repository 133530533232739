import React, { Component } from 'react';

import { Breadcrumb, Spin,Skeleton, Statistic,Modal,Table,Divider,List, Row,Card, Col,Tag, Form, Button, notification, Input, Typography, Tabs, Space, Tooltip, InputNumber, Select } from 'antd';
// import './Settings.css';

import { PlusOutlined, AlertOutlined,UserOutlined,QuestionCircleOutlined,EyeFilled,EyeInvisibleFilled, DeleteOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import {SearchBar} from '../../components'
import {

    getOrgById,
    getSubscriptionsByOrg,
    getServerByOrgId,
    getMirthServerDetails
} from '../../service/mirth/MirthService'
import '../../styles/custom-css/antd-custom.css';
import moment from 'moment-timezone';
import { RingProgress,Progress } from '@ant-design/plots';
import AuthService from '../../service/Auth/auth-service';



const { Option } = Select;
const { Text ,Link} = Typography;
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: { offset: 10, span: 16 },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};
const openNotification = (message) => {
    notification.success({
        message: message,

    });
};
export default class UserProfile extends Component {

    pwdFormRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {

            spin: true,
            userDetails: [],
            cloneUserDetails: [],
            orgDetails: [],
            subDetails: [],
            serverData:[],
            roles:[],
            serverDetails: [],
            cronExpDetails: [],
            isFormVisible: false,
            isEdit: false,
            currentServer: {},
            initialValues: {},
            confirmFormLoading: false,
            isModalVisible:false,
            tabKey: "1",
            access:'',
            isAdmin:false,
            usersCount:0,
            serverUsage:0,
            serverCount:0
        }
      //  this.handleSearch = this.handleSearch.bind(this);

    }
    componentDidMount() {
        let user = AuthService.getCurrentUser();
        getOrgById(user.orgId).then((res)=>{
            if(res.code == 200)
            {
                AuthService.getUsersByOrgId(user.orgId).then(usersRes=>{
           
                    getSubscriptionsByOrg(user.orgId).then((subRes)=>{
                        let subResult ;
                        if (subRes.code == 200 && subRes.result.length >0) {
                             subResult = subRes.result[0];
                            subResult.planExpiryOn =moment( subResult.planExpiryOn).tz("UTC").format("YYYY-MM-DD HH:mm:ss");
                            let attrs =JSON.parse( subResult.planAttributes);
                            subResult.channelCount = attrs.channelCount;
                            subResult.serverCount = attrs.serverCount;
                        }
                        getServerByOrgId(user.orgId).then(ServerResult => {
                            let serverCount =0,serverUsage=0;
                            let newServers = [];
                            if (ServerResult.code == 200 && ServerResult.result.length >0) {
                                let server =ServerResult.result;
                                 serverCount =server.length;
                                  serverUsage =  (subResult.serverCount - (subResult.serverCount-serverCount)) / subResult.serverCount;
                                 
                                 
                                //   for (let i = 0; i < server.length; i++) {
                        
                                //     let updatedServer ={}, channelUsage=0;
                                //         updatedServer =server[i];
                                      
                                //         getMirthServerDetails(updatedServer.accessToken).then(serverRes => {
                                //             if (serverRes.code == 200  && serverRes.result.length != 0) {
                                              
                                //                 let details = JSON.parse(serverRes.result[0].details);
                                //                 let res = JSON.parse(details.mirthInfo);
                                //                 updatedServer.channelCount= res.channelCount;
                                          
                                //             let statusres =JSON.parse( details.channelStatus);
                                //             let statusvalues= statusres.list.dashboardStatus;
                                //             let channelsDataArr =[];
                                          
                                //             if (statusvalues !== null && !Array.isArray(statusvalues)) {
                                //                 channelsDataArr.push(statusvalues);
                                //               }
                                //             else
                                //             {
                                //                 channelsDataArr =statusvalues;
                                //             }
                                //             updatedServer.dashboardChannelCount= channelsDataArr.length;
                                //             let channelCount = channelsDataArr.length;
                                //             channelUsage =  (subResult.channelCount - (subResult.channelCount-channelCount)) / subResult.channelCount;

                                //             updatedServer.channelUsage=channelUsage;
                                //                 newServers.push(updatedServer);
                                //                 this.setState({serverData:newServers,spin: false});

                                //             console.log(newServers)
                                //             }
            
                                //         })
                                // }
                            }
                            this.setState({serverCount:serverCount,serverUsage:serverUsage,  subDetails :subResult, usersCount:usersRes.length,initialValues:user, userDetails:user,orgDetails:res.result[0],spin:false, access:AuthService.getCurrentUserAccess()});
                  
                        })
                     

                    })
                 
                })
            }
        });
    
      
       
    }
   
    onModalFinish =(values)=>{
        this.setState({ confirmFormLoading:true})
        let initialValues=AuthService.getCurrentUser();
        let roles=[];
        let password = this.pwdFormRef.current.getFieldsValue().password;
        let oldPassword = this.pwdFormRef.current.getFieldsValue().oldPassword;
        AuthService.resetPwdWithPwd(
            initialValues.id,
            initialValues.username,
            initialValues.email,
            password,
            oldPassword,
           roles
        ).then(
            response => {
                this.setState({isModalVisible:false,confirmFormLoading:false})
                this.pwdFormRef.current.resetFields();
                notification.success({
                    message: "Password changed successfully!",

                });
            
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

               
        this.setState({ confirmFormLoading:false});
                notification.error({
                    message: resMessage,
                    description:"The Password you entered is incorrect. Please try again!"
                });
            }
        );
    }
    onModalReset = () => {
        this.pwdFormRef.current.resetFields();
        this.setState({ confirmFormLoading:false});
    };
    onModalCancel= () => {
        this.pwdFormRef.current.resetFields();
        
        this.setState({ isModalVisible: false ,confirmFormLoading:false});
    };
    onChangeClick=()=>{
        this.setState({isModalVisible:true});
    }
    

    render() {
        const {confirmFormLoading,isModalVisible, spin, orgDetails,usersCount,subDetails,serverCount,serverUsage,serverData, isFormVisible,access,userDetails,cloneUserDetails,initialValues,roles, tabKey ,cronExpDetails,isEdit} = this.state;
        const renderTag = (tag) => {
           
            console.log(tag)
            const steps = [];
          
            if(tag.roles){
                let roles = tag.roles;
            for(let i=0;i<roles.length;i++)
            {
                steps.push(<Tag color="warning"  icon={<UserOutlined />}>{roles[i]}</Tag>);
                
            }
        }
           return steps;
      

        };
        const renderChannelUsageTag = (tag) => {
          
            if(tag.channelUsage > 1){
 
              return <Tag color="error"  icon={<AlertOutlined />}>CHANNEL USAGE LIMIT EXCEEDED</Tag>;
           
        }
        else
        {
            return <Tag color="success"  icon={<AlertOutlined />}>CHANNEL USAGE UNDER LIMIT</Tag>;
        }
           

        };
        const ringConfig = {
            height: 120,
            width: 120,
            percent:serverUsage,
            color: ['#F4664A', '#E8EDF3'],
            innerRadius: 0.75,
            radius: 0.98,
           
          };
         const actionStatus = () => {
            if (access == "read&write") {
               return false
            }
            else
            {
                return true
            }
           
        };
  
        const buildProgress=(record)=>{

            const progressConfig = {
                height: 100,
                width: 300,
                autoFit: false,
                percent: record.channelUsage,
                barWidthRatio: 0.2,
                color: ['#F4664A', '#E8EDF3'],
              };
          return <Progress {...progressConfig} />

        }
      
        return (
            <>
                <Breadcrumb>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Settings</Breadcrumb.Item>
                    <Breadcrumb.Item>User Profile</Breadcrumb.Item>
                    <Breadcrumb.Item>{userDetails.username}</Breadcrumb.Item>
                </Breadcrumb>


                <Spin size="large" spinning={spin}>
                    <div class="content_panel">
                    <Modal title="Change Password" 
                    closable={false}
         visible={isModalVisible} onCancel={this.onModalReset} 
        width={400} 
        footer={[
          
            <Button key="submit" form="myForm" type="primary" htmlType="submit" loading={confirmFormLoading} >
              Submit
            </Button>,
             <Button onClick={this.onModalReset}>
             Reset
           </Button>,
              <Button key="back" onClick={this.onModalCancel}>
              Cancel
            </Button>,
          ]}>
                    <Form style={{padding:'0 0 0 0'}} id="myForm" ref={this.pwdFormRef} hideRequiredMark name="pwd-control-ref" onFinish={this.onModalFinish}>
                         
                            <Form.Item
                                name="oldPassword"
                                label="Old password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter old password!',
                                    }
                                   
                                ]}
                                
                            >
                                <Space><Input.Password
                                    
                                    placeholder="Enter old password"
                                    iconRender={visible => (visible ? <EyeFilled style={{color:'#fc9558'}}/>: <EyeInvisibleFilled />)}
                                />
                              </Space></Form.Item>
                            <Form.Item
                                name="password"
                                label="New Password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter password!',
                                    },
                                    { min: 8, message: 'Password must have a minimum length of 8' },
                                    { max: 30, message: 'Password can have a maximum length of 30' },
                                   
                                ]}
                                
                            >
                                <Space><Input.Password
                                    
                                    placeholder="Enter password"
                                    iconRender={visible => (visible ? <EyeFilled style={{color:'#fc9558'}}/>: <EyeInvisibleFilled />)}
                                />
                                <Tooltip title="Password must be of length  8 - 30"><Typography.Link href="#"><QuestionCircleOutlined /></Typography.Link></Tooltip>
                                </Space></Form.Item>
                    
                            <Form.Item
                                name="confirm"
                                label="Confirm new password"
                                dependencies={['password']}
                                
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password    
                                 placeholder="Enter password"
                                 iconRender={visible => (visible ? <EyeFilled style={{color:'#fc9558'}}/>: <EyeInvisibleFilled />)}/>
                            </Form.Item>
                          
                        </Form>
                       
                    </Modal>
                    <Row>

                    <Col span={7}>
                        <div class="content_panel_heading" style={{ color: 'black', fontSize: "24px", transform: "translateX(-20px)", marginLeft: '20px' }}>User Profile
                        </div>
                    </Col>
                    
                    </Row>
                    <div class="divider-1"> <span></span></div>

                      <Row>
                      <Col span={12}>
                                <Card className="server_configuration_card" style={{ marginLeft: '20px', marginTop: '30px' }}>
                                    <Row>
                                    <Col span={12}>   <div class="server_configuration_heading" style={{ color: 'black', fontSize: "20px", transform: "translateX(-20px)", marginLeft: '20px',marginBottom: '20px' }}>User Details
                                </div></Col>
                                <Col span={12}>
                                <Link onClick={this.onChangeClick} style={{float:'right'}}>
                                    Change password
                                    </Link>
                                </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}><p style={{ color: 'grey' }}>User Name</p></Col>
                                        <Col span={12}>{userDetails.username}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}><p style={{ color: 'grey' }}>Email</p></Col>
                                        <Col span={12}>{userDetails.email}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}><p style={{ color: 'grey' }}>Roles</p></Col>
                                        <Col span={12}>{renderTag(userDetails)}</Col>
                                    </Row>

                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card className="server_configuration_card" style={{ marginLeft: '20px', marginTop: '30px' }}>
                                    <Row>
                                        <div class="server_configuration_heading" style={{ color: 'black', fontSize: "20px", transform: "translateX(-20px)", marginLeft: '20px',marginBottom: '20px' }}>Organization Details
                                </div>
                                    </Row>
                                    <Row>
                                        <Col span={12}><p style={{ color: 'grey' }}>Organization Name</p></Col>
                                        <Col span={12}>{orgDetails.orgName}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}><p style={{ color: 'grey' }}>Domain</p></Col>
                                        <Col span={12}>{orgDetails.orgDomain}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}><p style={{ color: 'grey' }}>Users Count</p></Col>
                                        <Col span={12}><Tag color="success"  icon={<UserOutlined />}>{usersCount}</Tag></Col>
                                    </Row>

                                </Card>
                            </Col>
                            </Row>
                            <Row>
                            <Col span={12}>
                                <Card className="server_configuration_card" style={{ marginLeft: '20px', marginTop: '30px' }}>
                                    <Row>
                                        <div class="server_configuration_heading" style={{ color: 'black', fontSize: "20px", transform: "translateX(-20px)", marginLeft: '20px',marginBottom: '20px' }}>Subscription Details
                                </div>
                                    </Row>
                                    <Row>
                                        <Col span={12}><p style={{ color: 'grey' }}>Max Server Count</p></Col>
                                        <Col span={12}>{subDetails.serverCount}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}><p style={{ color: 'grey' }}>Max Channels per Server</p></Col>
                                        <Col span={12}>{subDetails.channelCount}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}><p style={{ color: 'grey' }}>Plan Duration</p></Col>
                                        <Col span={12}>{subDetails.planDuration}</Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}><p style={{ color: 'grey' }}>Plan expires by</p></Col>
                                        <Col span={12}><Tag color="error">{subDetails.planExpiryOn}</Tag></Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col span={12}>
                            <Card className="server_configuration_card" style={{ marginLeft: '20px', marginTop: '30px' }}>
                            <Row>
                                        <div class="server_configuration_heading" style={{ color: 'black', fontSize: "20px", transform: "translateX(-20px)", marginLeft: '20px',marginBottom: '20px' }}>Server Usage
                                </div>
                                    </Row>
                            <Row>
                           
                                        <Col span={8}> <Statistic  valueStyle={{ fontSize:"40px" }} title="Servers" value={serverCount} /></Col>
                                        <Col span={12}>  <RingProgress {...ringConfig} /></Col>
                            </Row>
                          
                            </Card>
                            </Col>
                            </Row>
                        
                {/* <Card className="server_configuration_card" style={{ marginLeft: '20px', marginTop: '30px' }}>
                <div class="server_configuration_heading" style={{ color: 'black', fontSize: "20px", transform: "translateX(-20px)", marginLeft: '20px',marginBottom: '20px' }}>Channel Usage
                                                </div>
                            <List
                                    itemLayout="horizontal"
                                    dataSource={serverData}
                                    renderItem={item => (
                                   
                                    <List.Item>
                                      
                                        <List.Item.Meta
                                        title={<><Text style={{ color:'black', fontSize: "18px", transform: "translateX(-20px)" }}>{item.serverName}</Text><Divider type="vertical"/>Channel count: {item.dashboardChannelCount}<Divider type="vertical"/>{renderChannelUsageTag(item)}</>}
                                        description={<p>{item.serverVersion}</p>}
                                        />
                                         <div>{buildProgress(item)}</div>
                                  
                                    </List.Item>
                                   
                                    )}
                                />
                                 </Card> */}
                                
                    </div>
                </Spin>
            </>
        );
    }
}