import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { CirclePacking } from '@ant-design/plots';


function CirclePackingGraph (props)  {
    const {graphData} = props
    const [data, setData] = useState(graphData);
  
    useEffect(() => {
        console.log(graphData)
    setData(graphData);
    }, [graphData]);
 
  const config = {
    autoFit: true,
    padding: 0,
    data,
    height:200,
    sizeField: 'r',
    color: 'rgb(252, 253, 191)-rgb(231, 82, 99)-rgb(183, 55, 121)',
    // 自定义 label 样式
    label: {
      formatter: ({ name }) => {
        return name !== 'root' ? name : '';
      },
      // 偏移
      offsetY: 8,
      style: {
        fontSize: 12,
        textAlign: 'center',
        fill: 'rgba(0,0,0,0.65)',
      },
    },
    legend: false,
  };

  return <CirclePacking {...config} />;
};

export default CirclePackingGraph;