import axios from "axios";
import authHeader from './auth-header';
//const API_URL = "http://172.19.40.75:8084/api/auth/";
const API_URL = process.env.REACT_APP_API_URL + "api/auth/";

class AuthService {
  login(username, password) {
    return axios
      .post(API_URL + "signin", {
        username,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
          let access;
          // let roles = response.data.roles;
          // if(roles.includes("ROLE_ADMIN"))
          // {
          //   access="read&write"
          // }
          // else
          // {
          //   access ="read"
          // }
          // localStorage.setItem("access", access);
        }

        return response.data;
      });
  }
  ssoLogin(token) {
    return axios
      .post(API_URL + "sso/"+ token)
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

       return response.data;
      
      });
  }
  getAllUsers() {
    return axios
      .get(API_URL + "user/list", {
        headers: authHeader()
      })
      .then(response => {
        return response.data;
      });
  }
  existsByUsername(name) {
    return axios
      .post(API_URL + "user/existsByUsername?name="+name, {
        headers: authHeader()
      })
      .then(response => {
        return response.data;
      });
  }
  findByUsername(name) {
    return axios
      .post(API_URL + "user/findByUsername?name="+name, {
        headers: authHeader()
      })
      .then(response => {
        return response.data;
      });
  }
  existsByEmail(email) {
    return axios
      .post(API_URL + "user/existsByEmail?email="+email, {
        headers: authHeader()
      })
      .then(response => {
        return response.data;
      });
  }
  findByEmail(email) {
    return axios
      .post(API_URL + "user/findByEmail?email="+email, {
        headers: authHeader()
      })
      .then(response => {
        return response.data;
      });
  }
  getUsersByOrgId(id) {
    return axios
      .post(API_URL + "user/listByOrgId/"+id, {
        headers: authHeader()
      })
      .then(response => {
        return response.data;
      });
  }
  
  logout() {
    localStorage.removeItem("user");
   // localStorage.removeItem("access");
  }

  register(username, email, password, role,orgId,enable_mfa,enable_mobile_access) {
    console.log(role)
    return axios.post(API_URL + "signup", {
      username,
      email,
      password,
      role,
      orgId,
      enable_mfa,
      enable_mobile_access
    });
  }
  updateUser(id, username, email, password, role,enable_mfa,enable_mobile_access) {
    return axios.put(API_URL + "user/update/" + id, {
      username,
      email,
      password,
      role,
      enable_mfa,
      enable_mobile_access

    });
  }
  resetPassword(id, username, email, password, role) {
    return axios.post(API_URL + "user/resetpwd/" + id, {
      username,
      email,
      password,
      role
    });
  }
  resetPwdWithPwd(id, username, email, password,oldPassword, role) {
    return axios.post(API_URL + "user/resetPwdWithPwd/" + id, {
      username,
      email,
      password,
      oldPassword,
      role
    });
  }
  resetOTP(id) {
    return axios.post(API_URL + "user/resetOTP/" + id);
  }
  deleteUser(id) {
    return axios.delete(API_URL + "user/delete/" + id);
  }
  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
  setCurrentUser(data) {
    localStorage.setItem("user", JSON.stringify(data))
  }
  getCurrentUserAccess() {
    let user= JSON.parse(localStorage.getItem('user'));
    let roles = user.roles;
     let access ='';
          if(roles.includes("ROLE_PROD_ADMIN") || roles.includes("ROLE_ORG_ADMIN"))
          {
            access="read&write"
          }
          else
          {
            access ="read"
          }
         return access;
  }
}

export default new AuthService();