import React, { Component } from "react";
import { Redirect, useParams } from 'react-router-dom'
import { Row, Col, Table, Typography, Divider, Card, Result, Tag, Space, Select, Tooltip, Modal, Form, Input, Button, DatePicker, Breadcrumb, Spin } from 'antd';
import {
    EyeOutlined
} from '@ant-design/icons';
import {
    getServerList,
    getData,
    getLatestChannelStats,
    getMirthServerDetails,
    getServerDetailsByOrgId,
    getSubscribedChannels
} from '../../../service/mirth/MirthService'

import AuthService from '../../../service/Auth/auth-service'
import { Link } from 'react-router-dom';
import CirclePackingGraph from '../../../components/Graph/CirclePackingGraph';
import '../../../styles/custom-css/antd-custom.css';
import { SearchBar } from '../../../components'
import { properties } from '../../../properties';
import { configurationData } from "../../../service/ConfigService/Config";
const { Option } = Select;
const { Text } = Typography;



export default class ChannelStatistics extends Component {

    constructor(props) {
        super(props)

        this.state = {
            AllchannelStatisticsData: [],
            channelStatisticsData: [],
            spin: true,
            modalTitle: '',
            isModalVisible: false,
            successRedirect: false,
            currentServerId: '',
            currentChannelId: '',
            modalMessage: '',
            serverOptions: [],
            channelOptions: [],
            receivedData: [],
            sentData: [],
            errorData:[],
            resultStatus: '',
            resultTitle: '',
            resultSubTitle: '',
            currentServer: '',
            serverChange:[],
            isError: false,
            page:'channelStatistics'
        }

        this.handleModalView = this.handleModalView.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleServerChange = this.handleServerChange.bind(this);
        this.handleChannelChange = this.handleChannelChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.rowClick = this.rowClick.bind(this);


    }

    componentDidMount() {
        configurationData(this.state.page).then((res) =>{
            if(res.code==200){
              let jsonData=JSON.parse(res.result[0].config);
              console.log(jsonData)
            this.setState({resultStatus:jsonData.status.resultStatus,
            resultTitle:jsonData.status.resultTitle,
            resultSubTitle:jsonData.status.resultSubTitle})
            
            
        let token = this.props.match.params.accessToken;
       
        let user = AuthService.getCurrentUser();
        getServerDetailsByOrgId(user.orgId).then(ServerRes=> {
            if (ServerRes.code == 200) {
                let server=[];
                server = ServerRes.result;

                 if(token == null )
                 {
                    let change=server[0].accessToken;
                    this .setState({serverChange: change})
                    this.setState({ resultStatus:'warning',resultTitle:'Add a server to check statistics', isError:true,spin: false})
                }
                else
                {
                   
                      server.map((servers) =>{
                        let  atoken=servers.accessToken
                        if(atoken==token){
                        this.setState({serverChange:servers.serverName})
                        }})

                if (server.length > 0) {
                    this.setState({ serverOptions: server, spin: false });
                    this.handleServerChange(token);
                }
                else {
                    this.setState({ resultStatus: 'warning', resultTitle: 'Add a server to check statistics', isError: true, spin: false })
                }
            }
        }
    })
}
        })
    }
    handleSearch(data) {
        this.setState({ channelStatisticsData: data });
    }
    handleServerChange(val) {
       
        this.setState({ spin: true, currentServer: val, isError: false });
        let request={};
        request.accessToken = val;

      
        getMirthServerDetails(val).then(serverRes=>{
            if (serverRes.code == 200 && serverRes.result.length !=0) {
                let details = JSON.parse(serverRes.result[0].details);
                if(typeof(details.channels) == 'string')
                {
                let channelsRes =JSON.parse( details.channels);
                let statisticsRes =JSON.parse( details.statistics);
                let channelValues = channelsRes.list.channel;
                let channelsDataArr =[];
                let statValues = statisticsRes.list.channelStatistics;
                let statArr =[];
                if (statValues !== null && !Array.isArray(statValues)) {
                    statArr.push(statValues);
                    console.log("yes")
                  }
                else
                {
                    statArr =statValues;
                }
                if (channelValues !== null && !Array.isArray(channelValues)) {
                    channelsDataArr.push(channelValues);
                    console.log("yes")
                  }
                else
                {
                    channelsDataArr =channelValues;
                }
                let channelArr = [];
                for (let i = 0; i < statArr.length; i++) {
                    let dataObj = {};
                    let channelData={};

                    dataObj = statArr[i];
                    
                    for(let j=0; j < channelsDataArr.length; j++)
                    {
                       
                         channelData = channelsDataArr[j];
                        if (channelData.id == dataObj.channelId) {
                            dataObj.channelName = channelData.name;
                            channelArr.push(dataObj);
                        }

                    }
                        
                }
                console.log(channelArr);
                    let recChildren = [];
                    let sentChildren = [];
                    let errorChildren = [];
                    for (let n = 0; n < channelArr.length; n++) {
                        let recJson = {};
                        let sentJson = {};
                        let errorJson = {};
                        recJson.name = channelArr[n].channelName;
                        recJson.value = channelArr[n].received;

                        sentJson.name = channelArr[n].channelName;
                        sentJson.value = channelArr[n].sent;

                        errorJson.name = channelArr[n].channelName;
                        errorJson.value = channelArr[n].error;

                        recChildren.push(recJson);
                        sentChildren.push(sentJson);
                        errorChildren.push(errorJson);

                    }
                    let recData = {
                        name: 'root',
                        children: recChildren
                    }
                    let sentData = {
                        name: 'root',
                        children: sentChildren
                    }
                    let errorData = {
                        name: 'root',
                        children: errorChildren
                    }
                    
                    this.setState({ spin: false, sentData: sentData, errorData: errorData, receivedData: recData, channelStatisticsData: channelArr, AllchannelStatisticsData: channelArr, channelOptions: channelArr })
                }
                else
                {
                    getSubscribedChannels(val).then(channelRes =>{
                        if (channelRes.code == 200 && channelRes.result.length != 0) {
                            let result = channelRes.result;
                            let statArr = details.statistics;
                            let channelsDataArr = channelRes.result
                            let channelArr =[];
                    for (let i = 0; i < statArr.length; i++) {
                        let dataObj = {};
                        let channelData={};
                        dataObj = statArr[i];
                        
                        for(let j=0; j < channelsDataArr.length; j++)
                        {
                           
                             channelData = channelsDataArr[j];
                            if (channelData.channel_id == dataObj.channelId) {
                                dataObj.channelName = channelData.channel_name;
                                channelArr.push(dataObj);
                            }
    
                        }
                            
                    }
                            let recChildren = [];
                            let sentChildren = [];
                            let errorChildren = [];
                            for (let n = 0; n < channelArr.length; n++) {
                                let recJson = {};
                                let sentJson = {};
                                let errorJson = {};
                                recJson.name = channelArr[n].channelName;
                                recJson.value = channelArr[n].received;
        
                                sentJson.name = channelArr[n].channelName;
                                sentJson.value = channelArr[n].sent;
        
                                errorJson.name = channelArr[n].channelName;
                                errorJson.value = channelArr[n].error;
        
                                recChildren.push(recJson);
                                sentChildren.push(sentJson);
                                errorChildren.push(errorJson);
        
                            }
                            let recData = {
                                name: 'root',
                                children: recChildren
                            }
                            let sentData = {
                                name: 'root',
                                children: sentChildren
                            }
                            let errorData = {
                                name: 'root',
                                children: errorChildren
                            }
                            
                            this.setState({ spin: false, sentData: sentData, errorData: errorData, receivedData: recData, channelStatisticsData: channelArr, AllchannelStatisticsData: channelArr, channelOptions: channelArr })
                  
                            /*commented as a fix*/
                            // getLatestChannelStats(val).then(statRes =>{

                            //     if(statRes.code == 200 && statRes.result.length !=0)   
                            //        {
                            //         let statArr = statRes.result;
                            //         let channelsDataArr = channelRes.result
                            //         let channelArr =[];
                            // for (let i = 0; i < statArr.length; i++) {
                            //     let dataObj = {};
                            //     let channelData={};
                            //     dataObj = statArr[i];
                                
                            //     for(let j=0; j < channelsDataArr.length; j++)
                            //     {
                                   
                            //          channelData = channelsDataArr[j];
                            //         if (channelData.channel_id == dataObj.channelId) {
                            //             channelArr.push(dataObj);
                            //         }
            
                            //     }
                                    
                            // }
                            //         let recChildren = [];
                            //         let sentChildren = [];
                            //         let errorChildren = [];
                            //         for (let n = 0; n < channelArr.length; n++) {
                            //             let recJson = {};
                            //             let sentJson = {};
                            //             let errorJson = {};
                            //             recJson.name = channelArr[n].channelName;
                            //             recJson.value = channelArr[n].received;
                
                            //             sentJson.name = channelArr[n].channelName;
                            //             sentJson.value = channelArr[n].sent;
                
                            //             errorJson.name = channelArr[n].channelName;
                            //             errorJson.value = channelArr[n].error;
                
                            //             recChildren.push(recJson);
                            //             sentChildren.push(sentJson);
                            //             errorChildren.push(errorJson);
                
                            //         }
                            //         let recData = {
                            //             name: 'root',
                            //             children: recChildren
                            //         }
                            //         let sentData = {
                            //             name: 'root',
                            //             children: sentChildren
                            //         }
                            //         let errorData = {
                            //             name: 'root',
                            //             children: errorChildren
                            //         }
                                    
                            //         this.setState({ spin: false, sentData: sentData, errorData: errorData, receivedData: recData, channelStatisticsData: channelArr, AllchannelStatisticsData: channelArr, channelOptions: channelArr })
                          
                            //        }
                            //     })
                        }
                        else
                        {
                            this.setState({isError:true,spin: false})
                        }
                    })
                    
                }
              }

                   else {
                this.setState({ isError: true, spin: false })
            }

        })
        // getData(endPoint, "channels").then(res => {
        //     console.log(res)
        //     if (res.children) {
        //         let channelArr = [];
        //         let channels = res.children;
        //         for (let i = 0; i < channels.length; i++) {
        //             let channel = channels[i].children;
        //             let dataObj = {};
        //             for (let j = 0; j < channel.length; j++) {
        //                 if (channel[j].name == "id" || channel[j].name == "name") {
        //                     dataObj[channel[j].name] = channel[j].value;
        //                 }
        //             }
        //             channelArr.push(dataObj);

        //         }
        //         getData(endPoint, "channelStat").then(statres => {
        //             let stats = statres.children;
        //             let channelStatArr = [];
        //             for (let k = 0; k < stats.length; k++) {
        //                 let channelStat = stats[k].children;
        //                 let dataObj = {};
        //                 for (let l = 0; l < channelStat.length; l++) {

        //                     dataObj[channelStat[l].name] = channelStat[l].value;

        //                 }
        //                 for (let m = 0; m < channelArr.length; m++) {
        //                     if (channelArr[m].id == dataObj.channelId) {
        //                         dataObj['channelName'] = channelArr[m].name;
        //                     }
        //                 }
        //                 channelStatArr.push(dataObj);
        //             }
        //             let recChildren = [];
        //             let sentChildren = [];
        //             for (let n = 0; n < channelStatArr.length; n++) {
        //                 let recJson = {};
        //                 let sentJson = {};
        //                 recJson.name = channelStatArr[n].channelName;
        //                 recJson.value = channelStatArr[n].received;

        //                 sentJson.name = channelStatArr[n].channelName;
        //                 sentJson.value = channelStatArr[n].sent;

        //                 recChildren.push(recJson);
        //                 sentChildren.push(sentJson);

        //             }
        //             let recData = {
        //                 name: 'root',
        //                 children: recChildren
        //             }
        //             let sentData = {
        //                 name: 'root',
        //                 children: sentChildren
        //             }
        //             console.log(recData)
        //             this.setState({ spin: false, sentData: sentData, receivedData: recData, channelStatisticsData: channelStatArr, AllchannelStatisticsData: channelStatArr, channelOptions: channelArr })
        //         });
        //     }
        //     else {
        //         this.setState({ isError: true, spin: false })
        //     }

        // })
        //     getChannelIdsandNames(host,username,password).then(res => {
        //         let list = res.map.entry;
        //         let channelarr=[];
        //         let statarr=[];
        //         for(let i=0;i<list.length;i++)
        //         {
        //             let channeljson ={id: list[i].string[0],name:list[i].string[1]};
        //             channelarr.push(channeljson);
        //             getChannelStatistics(host,username,password,list[i].string[0]).then(res=>{
        //                 let statjson = res.channelStatistics;
        //                 statjson.name = list[i].string[1];
        //                 // getChannelStatus(host,'admin','admin',list[i].string[0]).then(res=>{
        //                 //     console.log(res)
        //                 // })
        //                 this.setState({channelStatisticsData:[...this.state.channelStatisticsData,statjson ]})
        //                 // getMessagesByChannel(host,username,password,list[i].string[0]).then(res=>{
        //                 //     console.log(res);
        //                 // })
        //             })
        //         }

        //        this.setState({ spin: false,channelOptions:channelarr })


        // })
    }
    handleChannelChange(val) {
        this.setState({ spin: true })
        this.state.AllchannelStatisticsData.map((chn => {
            if (chn.channelId == val) {
                this.setState({ spin: false, channelStatisticsData: [chn] })
            }
        }))
    }
    handleCancel() {
        this.setState({ isModalVisible: false })
    }

    handleModalView(data, title) {

        this.setState({ modalMessage: data, modalTitle: title, isModalVisible: true })

    }
    rowClick(record) {
        console.log(record);
        this.setState({ successRedirect: true, currentServerId: record.serverId, currentChannelId: record.channelId })
    }

    render() {


        const { serverChange,spin, modalTitle, successRedirect, sentData,errorData, receivedData, currentServerId, currentServer, currentChannelId, modalMessage, resultStatus, resultTitle, resultSubTitle, isModalVisible, serverOptions, channelOptions, channelStatisticsData, AllchannelStatisticsData, isError } = this.state;
        const renderTag = (tag) => {

            if (tag == 'INFO')
                return <Tag color="success">{tag}</Tag>;
            else if (tag == 'ERROR')
                return <Tag color="error">{tag}</Tag>;
            else
                return <Tag color="default">{tag}</Tag>;

        }
        const channelStatisticsColumns = [
            { title: 'Channel Id', dataIndex: 'channelId', key: 'channelId' },
            { title: 'Channel Name', dataIndex: 'channelName', key: 'name' },
            {
                title: 'Received', dataIndex: 'received', key: 'received', defaultSortOrder: 'descend',
                sorter: (a, b) => a.received - b.received
            },
            {
                title: 'Sent', dataIndex: 'sent', key: 'sent',
                render: (text, record) => (
                    <>
                        <Tag color="success">{text}</Tag>
                    </>),
                sorter: (a, b) => a.sent - b.sent
            },
            {
                title: 'Error', dataIndex: 'error', key: 'error',
                render: (text, record) => (
                    <>
                        <Tag color="error">{text}</Tag>
                    </>),
                sorter: (a, b) => a.error - b.error
            },
            { title: 'Filtered', dataIndex: 'filtered', key: 'filtered', sorter: (a, b) => a.filtered - b.filtered },
            { title: 'Queued', dataIndex: 'queued', key: 'queued', sorter: (a, b) => a.queued - b.queued },


        ];
        const recConfig = {
            autoFit: true,
            padding: 0,
            receivedData,
            sizeField: 'r',
            color: 'rgb(252, 253, 191)-rgb(231, 82, 99)-rgb(183, 55, 121)',
            // 自定义 label 样式
            label: {
                formatter: ({ name }) => {
                    return name !== 'root' ? name : '';
                },
                // 偏移
                offsetY: 8,
                style: {
                    fontSize: 12,
                    textAlign: 'center',
                    fill: 'rgba(0,0,0,0.65)',
                },
            },
            legend: false,
        };
        return successRedirect ? (<Redirect to={`/mirth/message/${currentServer}/${currentChannelId}`} />) : (
            <>
                <Breadcrumb>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Mirth Monitoring</Breadcrumb.Item>
                    <Breadcrumb.Item> Channel Statistics</Breadcrumb.Item>
                </Breadcrumb>

                <div className="content_panel">

                    <Spin size="large" spinning={spin}>

                        <Modal title={modalTitle} visible={isModalVisible} footer={null} onCancel={this.handleCancel} width={900} bodyStyle={{ overflowY: 'scroll', height: 380 }}>
                            {modalMessage}
                        </Modal>
                        <Row>

                            <Col span={7}>
                                <div class="content_panel_heading">Channel Statistics
                                </div>
                            </Col>
                            {serverOptions.length !== 0 &&
                                <>
                                    <Col span={2}>
                                        <Text style={{ marginTop: '2px' }}>Server: </Text>
                                    </Col>
                                    <Col span={12} style={{ marginLeft: '-30px', marginTop: '-4px' }}>

                                        <Select placeholder="Select server" defaultValue={serverChange} onChange={this.handleServerChange} style={{ width: 160 }} bordered={false}>
                                            {serverOptions.map((server) => (
                                                <Option key={server.accessToken} value={server.accessToken}>{server.serverName}</Option>
                                            ))

                                            }
                                        </Select>
                                    </Col>
                                </>}
                            {/* <Col span={2}  >
                            <Text type="secondary">Channel: </Text>
                            </Col>
                            <Col span={5}style={{marginTop:'-4px',marginLeft:'-20px'}}>
                            <Select placeholder="Select channel" onChange={this.handleChannelChange} style={{ width: 160 }} bordered={false}>
                                    {channelOptions.map((channel) => (
                                        <Option key={channel.id} value={channel.id}>{channel.name}</Option>
                                    ))

                                    }
                                </Select>
                            </Col> */}
                        </Row>
                        <div class="divider-1"> <span></span></div>
                        {/* <Divider /> */}
                        {
                            isError ? (
                                <>
                                    <Result
                                        status={resultStatus}
                                        title={resultTitle}
                                        subTitle={resultStatus == '500' ? resultSubTitle : ''}
                                        extra={resultStatus == '500' ? <></> : <Link to="/settings/server"><Button type="primary" style={{ borderRadius: '7px' }}>Add Server</Button></Link>}
                                    />
                                </>) : (
                                    <>
                                        <Row style={{marginBottom:'10px'}}>

                                            <Col span={8}>
                                            <h1 style={{ marginTop: '2px',textAlign:'center',fontWeight:'bold'}}>Received</h1>
                                            {receivedData.length != 0 && <CirclePackingGraph graphData={receivedData} />}
                                            </Col>
                                            <Col span={8}>
                                            <h1 style={{ marginTop: '2px',textAlign:'center',fontWeight:'bold' }}>Sent</h1>
                                            {sentData.length != 0 && <CirclePackingGraph graphData={sentData} />}
                                            </Col>
                                            <Col span={8}>
                                            <h1 style={{ marginTop: '2px',textAlign:'center',fontWeight:'bold' }}>Error</h1>
                                            {errorData.length != 0 && <CirclePackingGraph graphData={errorData} />}
                                            </Col>
                                        </Row>
                                           
                                           
                                            <SearchBar data={AllchannelStatisticsData} handler={this.handleSearch}></SearchBar>
                                            <Table
                                                style={{ marginTop: '10px' }}
                                                columns={channelStatisticsColumns}
                                                dataSource={channelStatisticsData}
                                                // onRow={(record, rowIndex) => {
                                                //     return {
                                                //         onClick: event => { this.rowClick(record) },
                                                //     };
                                                // }}
                                            />
                           

                    </>)
                                        }
                    </Spin>

                </div>
            </>
                    );
                }
            }
            
