import React, { Component } from 'react';

import { Layout,Breadcrumb,DatePicker, Spin, Table, Tag,  Popover,Divider, Row, Col, Form, Button, notification, Input, Typography, Tabs, Space, Tooltip, InputNumber, Select } from 'antd';
// import './Settings.css';
import {
    UserOutlined
  } from '@ant-design/icons';
import { CaretDownOutlined,CaretRightOutlined,PlayCircleOutlined,PlusOutlined, PlusCircleFilled, DownloadOutlined,EditOutlined, DeleteOutlined, EyeFilled, EyeTwoTone, EyeInvisibleFilled } from '@ant-design/icons';
import 'antd/dist/antd.css';
import { SearchBar } from '../../../components';
import moment from 'moment-timezone';
// import 'public/src/styles/custom-css/antd-custom.css';
import { Link } from 'react-router-dom';
import authService from '../../../service/Auth/auth-service';
import { getVisitor } from '../../../service/Visitorsservice';
import { pdf } from '../../../service/mirth/MirthService';
const { Header, Content, Sider } = Layout;

const { RangePicker } = DatePicker
function disabledDate(current) {
    return current > moment().endOf('day');
}
const getFullDate = (date) => {
    if (date !== null) {
      const value = moment(date).format("YYYY-MM-DD HH:mm:ss");
      return value;
    }
  };
export default class Visitors extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            spin:false,
            cloneVisitorsData:[],
            obj:[],
            isSearched:false,
            startTime: "",
            endTime: "",
            userName:"",
            request:{}
            // visitorsData:[]
   
        }
        this.handleSearch = this.handleSearch.bind(this);
        this.go = this.go.bind(this);
        this.setTimeFilter = this.setTimeFilter.bind(this);
        this.handleReportDonwload=this.handleReportDonwload.bind(this);
        }

        componentDidMount() {
            let user = authService.getCurrentUser();
           
        }
        go(val) {
            this.setState({ spin: true ,isSearched:true})
            let request = {}
            if (val.userName != undefined & val.userName!="") {
                request = {
                  fromDate: this.state.startTime,
                  toDate: moment(this.state.endTime).add(1, "days").format("YYYY-MM-DD"),
                  userName: val.userName,
                };
              } else {
                request = {
                  fromDate: this.state.startTime,
                  toDate: moment(this.state.endTime).add(1, "days").format("YYYY-MM-DD"),
                };
              }
              this.setState({ request: request });
           
            console.log(request)
            this.setState({cloneUserData:[],userData:[]})
            getVisitor(request).then(vres=>{
                console.log(vres)
                let visitor=[];
                visitor=vres.result;
                let data = [];
                for (let i = 0; i < visitor.length; i++) {
                    let rowData = visitor[i];
                    rowData.loggedTime = getFullDate(rowData.loggedTime);
                    data.push(rowData);
      }
            
                
                
                
                    
                    this.setState({spin:false,visitorsData:visitor,cloneVisitorsData:visitor})
                    
                   
                
               
            });
               
    
        }
        onReset = () => {
            this.setState({
                visible: false,
                isSearched:false,
                countData:[]
            });
            this.formRef.current.resetFields();
        };
        setTimeFilter(value) {
            if (value !== null)
                this.setState({
                    startTime: value[0].format('YYYY-MM-DD'),
                    endTime:value[1].format('YYYY-MM-DD')
                });
        }
        handleReportDonwload(val)
        {
            this.setState({ isSearched:true,spin:true})
            let request = {}
            if (val.userName != undefined) {
                request = {
                  fromDate: this.state.startTime,
                  toDate: moment(this.state.endTime).add(1, "days").format("YYYY-MM-DD"),
                  userName: val.userName,
                };
              } else {
                request = {
                  fromDate: this.state.startTime,
                  toDate: moment(this.state.endTime).add(1, "days").format("YYYY-MM-DD"),
                };
              }
            this.setState({ request: request });
            pdf("token","visitor",this.state.request).then(response =>{
                console.log(response)
                const url=window.URL.createObjectURL(new Blob([response]));
                const link=document.createElement('a');
                link.href=url;
                link.setAttribute('download','userAudit.pdf');
                document.body.appendChild(link);
                link.click();
                this.setState({ spin:false });
            });
        }
   
        handleSearch(data)
    {
        console.log("In handle search",data)
        this.setState({ visitorsData: data });

    }
    render(){
        const getFullDate = (date) => {
            if (date !== null) {
                const value = moment(date).tz("America/New_York").format("YYYY-MM-DD HH:mm:ss")
                return value;
            }
            
        };
        const renderTag = (tag) => {

            if (tag == 'POST')
                return <Tag color="geekblue">{tag}</Tag>;
                else if (tag == 'GET')
                return <Tag color="purple">{tag}</Tag>;
            else
                return <Tag color="blue">{tag}</Tag>;

        };
        const user=(text)=>{
            

            
            const valuesArray = JSON.parse(text);
            console.log(valuesArray);
            
            // if({valuesArray.id}!=null){
            //     const data=[
            //         <ul>
            //         <li>({valuesArray.id})</li>,
            //         <li>({valuesArray.username})</li>,
            //         <li>({valuesArray.email})</li>,
            //         <li>({valuesArray.authorities})</li>
            //         </ul>
    
            //     ];
            let authoritystring="";
            if (typeof valuesArray != "undefined" && valuesArray != null ){
                const numbers = valuesArray.authorities;
                const listItems = numbers.map((number) =>{
                    authoritystring=authoritystring+number.authority+" "
                    console.log(number);
                }
                 );
                 console.log(numbers);
                 console.log(listItems);
                 console.log(authoritystring);


            
            const data=[
                <ul>
                <li>Username:{valuesArray.username}</li>
                <li>ID:{valuesArray.id}</li>
                <li>Email:{valuesArray.email}</li>
                <li>Role:{authoritystring}</li>
                {/* <li>Authorities:{valuesArray.authorities.map((info)=>{
                return (<>info.authorities</>
                );
                }
                ) */}
             
            
            
                
                {/* </li> */}
                </ul>

            ];
            // const data=[
            //     <ul>
            //     <li>ID:({valuesArray.id})</li>
            //     </ul> 

            // ];

        
        
          
                return(
                <Popover content={data} title="User Details">
                <Space><UserOutlined style={{ fontSize: '150%'}}/></Space>
              </Popover>)
            }
            

              
            };
            const username=(text)=>{
                const valuesArray = JSON.parse(text);
                if (typeof valuesArray != "undefined" && valuesArray != null ){
                    const obj=valuesArray.username;
                    return obj;

            }
        };
        
        
        
            const { request,isSearched,spin,visitorsData,cloneVisitorsData,userName} = this.state;
            var myColTitleStyle = {
                textOverflow: "ellipsis",
                // overflow: "hidden",
                whiteSpace: "wrap",
              };
            const VisitorsColumns = [
                // { title: 'Id', dataIndex: 'id', key: 'id', ellipsis: 'true'},
                {title:'User Details',dataIndex:'userDetails',key:'userDetails',
                render: (text)=>(<>{user(text)}</>)},
                {title:'User Name',dataIndex:'userDetails',key:'userDetails',
                render:(text)=>(<>{username(text)}</>)},
            //     <Popover content={text} title="User Details">
            //     <Space><IdcardFilled /></Space>
            //   </Popover>
            
                
                { title: 'Event', dataIndex: 'page', key: 'page' },
                // { title: 'Metric Delta', dataIndex: 'metricDelta', key: 'metricDelta'},
                 
                //  {title:'Referer Page',dataIndex:'refererpage',key:'refererpage'},
                 {title:'method',dataIndex:'method',key:'method',render: (text) => renderTag(text)},
                //  {title:'Url',dataIndex:'url',key:'url'},
                //  {title:'Unique Visit',dataIndex:'uniqueVisit',key:'uniqueVisit'},
                {
                    title: <div style={myColTitleStyle}>LoggedTime</div>,
                    dataIndex: "loggedTime",
                    key: "loggedTime"
                  }
                 ,
                { title: 'Ip', dataIndex: 'ip', key: 'ip' },
                { title: 'User agent', dataIndex: 'userAgent', key: 'userAgent' ,
            render:(text)=><Tooltip placement="topLeft" title={text}>
            <Button>UserAgent </Button></Tooltip>},
                // { title: 'State', dataIndex: 'state', key: 'state',render: (text) => renderTag(text), }
            ];
            const onChange = (pagination, filters, sorter, extra) => {
                console.log('params', pagination, filters, sorter, extra);
              };
            return (
            <>
            <Breadcrumb>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Admin</Breadcrumb.Item>
                <Breadcrumb.Item>User Audit</Breadcrumb.Item>
            </Breadcrumb>

            <div className="content_panel">

                <Spin size="large" spinning={spin}>
               
                    {/* <Modal title={modalTitle} visible={isModalVisible} footer={null} onCancel={this.handleCancel} width={900} bodyStyle={{ overflowY: 'scroll', height: 380 }}>
                        {modalMessage}
                    </Modal> */}
                    <Row>
                    
                              <div class="content_panel_heading" >User Audit
                                 
                                </div>
                            
                            </Row>
                            <div class="divider-1"> <span></span></div>
                            <div class="content_form_panel">
                            <Form name="horizontal_login"    style={{paddingRight:'0px'}}  labelCol={{ span: 6 }}  wrapperCol={{ span: 15 }} ref={this.formRef} onFinish={this.go}  >
                                <Row>
                               
                                    <Col span={14} >
                                        <Form.Item 
                                            name="range"
                                            label="Range"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input Range!',
                                                },
                                            ]}   
                                                                                    
                                        >
                                            <RangePicker  placement={'bottom'} style={{width:'100%'}} 
                                                
                                               
                                                format="YYYY-MM-DD"
                                                disabledDate={disabledDate} onChange={(val) => { this.setTimeFilter(val) }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={9} >
                                        <Form.Item label="UserName" name="userName">
                                            <Input placeholder="User Name"
                                                />
                                            </Form.Item>
                                    </Col>
                                    </Row>
                                    <Row>
                                        <Col span={18}></Col>
                                    <Col span={4}>
                                        <Space>
                                        <Form.Item>
                                            
                                            <Button htmlType="submit" type="primary" style={{borderRadius:'7px'}}>SEARCH <PlayCircleOutlined /></Button>
                                            </Form.Item>
                                            
                                           
                                           
                                            <Form.Item>
                                            <Button htmlType="button" style={{borderRadius:'7px',backgroundColor: "#e3e3e3"}}  onClick={this.onReset}>
                                                Reset
                                            </Button>
                                            </Form.Item>
                                            </Space>
                                            </Col>
                                        
                                        </Row>

                                           
                                        
                                    
                
                                    </Form>
                                    </div>
                
                        {/* <Divider /> */}
                        {
                            <>
                        {isSearched?<Button htmlType="button" style={{ backgroundColor: '#efefef',margin: "0 8px" }}  onClick={this.handleReportDonwload}> Download Report</Button>:<></>}
                        {isSearched?<SearchBar data={cloneVisitorsData} handler={this.handleSearch}></SearchBar>:<></>}

                        {isSearched?<Table
                           style={{marginTop:'10px'}}
                             columns={VisitorsColumns}
                             dataSource={visitorsData}
                             onChange={onChange}
                           
                        />:<></>}
                        </>
    }
                            
                         
                          </Spin>
      
                      </div>
                  </>
              );
                      }
                  }
      
                           