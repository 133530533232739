import React, { Component } from "react";
import { Row, Col, Table,Badge, Typography,Statistic,message,notification, Card, Result,PageHeader, Divider,Collapse, Tag, Space, Select, Tooltip, Modal, Form, Input, Button, DatePicker, Breadcrumb, Spin } from 'antd';
import {
    EyeOutlined,
    ArrowUpOutlined ,
    ArrowDownOutlined ,
    AlertOutlined,
    ReloadOutlined,
    BarChartOutlined,
    AuditOutlined,
    DatabaseOutlined,
    SmileOutlined,
    BugFilled,
    CaretRightOutlined
} from '@ant-design/icons';
import { Gauge, Liquid } from '@ant-design/charts';
import {
    getServerList,
    getData,
    getDataJSON,
    getMirthServerDetails,
    getServerByOrgId,
    getOrgById,
    getSubscriptionsByOrg,
    getMirthServerDetailsByOrgId,
    getServerDetailsByOrgId,
    getServerDetails,
    getChannelCount,
    getSubscribedChannels,
    getLatestChannelStats,
    checkIfOrgIsValid,
    getVulnerabilitiesByVersion
} from '../../../service/mirth/MirthService';
import {
    getAllJobs
} from '../../../service/EmailScheduler/Scheduler'
import AuthService from '../../../service/Auth/auth-service'

import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

import logLogo from '../../../assets/images/log.svg';
import channelStatisticsLogo from '../../../assets/images/channel-statistics.svg';
import viewLogo from '../../../assets/images/view.svg';
import mirthServersLogo from '../../../assets/images/mirth-servers.svg';
import notificationLogo from '../../../assets/images/notification-icon.svg';
import '../../../styles/custom-css/antd-custom.css';
import { properties } from '../../../properties';
import { configurationData } from "../../../service/ConfigService/Config";
import 'animate.css';
import './Dashboard.css';

const { Meta } = Card;
const { Panel } = Collapse;
const { Option } = Select;
const { Text } = Typography;
const renderTag = (tag) => {

    if (tag == 'ACTIVE')
        return <Tag color="success">{tag}</Tag>;
    else
        return <Tag color="error">{tag}</Tag>;

}
let interval;
export default class ServerConfiguration extends Component {

    constructor(props) {
        super(props)

        this.state = {

            spin: true,
            serverOptions: [],
            currentServer: '',
            diskspaceusage: 0.00,
            memoryspaceusage: 0.00,
            cpuUsage: 0.00,
            systemInfo: {},
            serverConfiguration: {},
            serverId: '',
            serverJvm: '',
            isError: false,
            isPostgres:false,
            PGToastData:[],
            resultStatus: '',
            resultTitle: '',
            resultSubTitle: '',
            dashboard:'',
            activeCount:0,
            inActiveCount:0,
            activeChannelCount:0,
            inActiveChannelCount:0,
            erroCount:0,
            serverData:[],
            runningJobsCount:0,
            scheduledJobsCount:0,
            refreshTime:60000,
            page:'serverDashboard',
            isValid : false,
            modalMessage:'',
            modalTitle:'',
            isModalVisible:false,
            modalFooter:null
        }

     this.loadData = this.loadData.bind(this);
     this.loadDataNew = this.loadDataNew.bind(this);
     this.handleTimeChange = this.handleTimeChange.bind(this);
     this.listVulnerability = this.listVulnerability.bind(this);
     this.handleCancel = this.handleCancel.bind(this);
    }
     sortByProperty(property){  
        return function(a,b){  
           if(a[property] > b[property])  
              return -1;  
           else if(a[property] < b[property])  
              return 1;  
       
           return 0;  
        }  
     }

     runInterval(time) {
        if (interval) {
          clearInterval(interval)
          interval = null
        }
   
        interval = setInterval(  this.loadDataNew, time)
      }
     componentDidMount() {
    

        try {
            let user = AuthService.getCurrentUser();
            if(user !== null && user.hasOwnProperty('orgId'))
            {
               
                configurationData(this.state.page).then((res) =>{
                    if(res.code==200){
                      let jsonData=JSON.parse(res.result[0].config);
                      console.log(jsonData)
                    this.setState({resultStatus:jsonData.status.resultStatus,
                    resultTitle:jsonData.status.resultTitle,
                    resultSubTitle:jsonData.status.resultSubTitle});
                  
                    this.loadDataNew()
                this.runInterval(this.state.refreshTime);
                    
                    }
                    
                  })
               
            }
           
          } catch(e) {
            console.log(e);
          }
         
      

    }

    loadDataNew()
    {
        let user = AuthService.getCurrentUser();
        let url = window.location.href;
        if(user !== null && user.hasOwnProperty('orgId') && url.includes("server-dashboard"))
        {
            this.setState({ spin: true});
            let server = [];
            let newServers = [];
            let active = 0;
            let inactive = 0;
            let noData = 0;
            let runningJobsCount=0, scheduledJobsCount=0;
            let errorCount=0, activeChannelCount =0,inactiveChannelCount=0;
            let subResult ;
            getServerByOrgId(user.orgId).then(ServerRes => {
                let server = ServerRes.result;
                if (ServerRes.code == 200 && server.length !=0) {
                    checkIfOrgIsValid(user.orgId).then((response)=>{
                        if(response.code == 200)
                            this.setState({isValid:response.result})
                    })
                    for (let i = 0; i < server.length; i++) {
                        let updatedServer ={};
                        updatedServer =server[i];
                        getMirthServerDetails(updatedServer.accessToken).then(serverRes => {
                            if (serverRes.code == 200  && serverRes.result.length != 0) {
                                console.log(moment.utc(moment(serverRes.result[0].observationTime).utc()).format())
                                let startTime = moment(serverRes.result[0].observationTime).tz("UTC");


                                updatedServer.lastActiveTime = startTime.fromNow();
                               // updatedServer.isValid = serverRes.result[0].valid;
                             //   updatedServer.channelLimit =subResult.channelCount;
                                var endTime = moment.tz("UTC");
                                let timeDiff = moment.duration(endTime.diff(startTime));

                                if (Math.floor(timeDiff.asHours()) == 0 && Math.floor(timeDiff.asDays()) == 0 && Math.floor(timeDiff.asMonths()) == 0 && Math.floor(timeDiff.asYears()) == 0) {
                                    if (Math.floor(timeDiff.asMinutes()) <= 2) {
                                        updatedServer.state = "ACTIVE";
                                        active++;
                                    }
                                    else {
                                        updatedServer.state = "IN ACTIVE";
                                        inactive++;
                                    }
                                }
                                else {
                                    updatedServer.state = "IN ACTIVE";
                                    inactive++;
                                }
                                let details = JSON.parse(serverRes.result[0].details);
                                if(typeof(details.channels) == 'string')
                                {
                                let name=updatedServer.serverName;
                              
                                updatedServer.PGToastData = [];
                                if(details.pgToastInfo.length >0)
                                {
                                    updatedServer.dbName = "PostgreSQL"
                                    //updatedServer.PGToastData = details.pgToastInfo;
                                    //this.setState({ PGToastData: details.pgToastInfo})
                                }
                            
                                let res = JSON.parse(details.mirthInfo);
                                updatedServer.channelCount= res.channelCount;
                                let statisticsRes =JSON.parse( details.statistics);
                          
                            let statusres =JSON.parse( details.channelStatus);
                            let statusvalues= statusres.list.dashboardStatus;
                            let channelsDataArr =[];
                            let statValues = statisticsRes.list.channelStatistics;
                            let statArr =[];
                            if (statValues !== null && !Array.isArray(statValues)) {
                                statArr.push(statValues);
                                
                              }
                            else
                            {
                                statArr =statValues;
                            }
                            if (statusvalues !== null && !Array.isArray(statusvalues)) {
                                channelsDataArr.push(statusvalues);
                              }
                            else
                            {
                                channelsDataArr =statusvalues;
                            }
                            let channelArr = [];
                           
                            for (let i = 0; i < statArr.length; i++) {
                                let dataObj = {};
                                let channelData={};
                                dataObj = statArr[i];
                                errorCount=errorCount+dataObj.error;
                                
                                for(let j=0; j < channelsDataArr.length; j++)
                                {
                                   
                                     channelData = channelsDataArr[j];
                                    if (channelData.channelId == dataObj.channelId) {
                                        dataObj.channelName = channelData.name;
                                        dataObj.state = channelData.state;
                                        // console.log(channelData.channelId)
                                        // console.log(channelData.name)
                                        // console.log(channelData.state)
                                      
                                        if(channelData.state == 'STARTED') 
                                        {
                                            activeChannelCount++;
                                        }
                                        else
                                        {
                                            inactiveChannelCount++;
                                        }
                                        channelArr.push(dataObj);
                                    }
            
                                }
                                    
                            }
                       
                            updatedServer.channels= channelArr;
                                newServers.push(updatedServer);
                              
                                if(server.length == active+inactive+noData)
                                {
                                    this.setState({spin: false,serverData:newServers,activeCount:active,inActiveCount:inactive,activeChannelCount:activeChannelCount,inActiveChannelCount:inactiveChannelCount,errorCount:errorCount});
                               
                                }
                            }
                            else
                            {
                                getServerDetails(updatedServer.accessToken).then(sdRes =>{
                                        
                                            if (sdRes.code == 200 && sdRes.result.length > 0) {
                                       
                                                updatedServer.dbName = sdRes.result[0].dbName;
                                                
                                                    getChannelCount(updatedServer.accessToken).then(countRes =>{
                                                        if (countRes.code == 200 ) {
                                                            updatedServer.channelCount = countRes.result;
                                                            getSubscribedChannels(updatedServer.accessToken).then(channelRes =>{
                                                                if (channelRes.code == 200 && channelRes.result.length != 0) {
                                                                    updatedServer.channels = channelRes.result;
                                                                    // let startTime = moment(channelRes.result[0].updated_time).tz("UTC");
                                                                    

                                                                    // updatedServer.lastActiveTime = startTime.fromNow();
                                                                    // updatedServer.isValid = ServerRes.result[0].valid;
                                                                    // var endTime = moment.tz("UTC");
                                                                    // let timeDiff = moment.duration(endTime.diff(startTime));
                                
                                                                    // if (Math.floor(timeDiff.asHours()) == 0 && Math.floor(timeDiff.asDays()) == 0 && Math.floor(timeDiff.asMonths()) == 0 && Math.floor(timeDiff.asYears()) == 0) {
                                                                    //     if (Math.floor(timeDiff.asMinutes()) <= 2) {
                                                                    //         updatedServer.state = "ACTIVE";
                                                                    //         active++;
                                                                    //     }
                                                                    //     else {
                                                                    //         updatedServer.state = "IN ACTIVE";
                                                                    //         inactive++;
                                                                    //     }
                                                                    // }
                                                                    // else {
                                                                    //     updatedServer.state = "IN ACTIVE";
                                                                    //     inactive++;
                                                                    // }

                                                                    let statArr = details.statistics;
                                                                    let channelsDataArr = updatedServer.channels
                                                                    let channelArr =[];
                                                            for (let i = 0; i < statArr.length; i++) {
                                                                let dataObj = {};
                                                                let channelData={};
                                                                dataObj = statArr[i];
                                                                errorCount=errorCount+dataObj.error;
                                                                
                                                                for(let j=0; j < channelsDataArr.length; j++)
                                                                {
                                                                
                                                                    channelData = channelsDataArr[j];
                                                                    if (channelData.channel_id == dataObj.channelId) {
                                                                       dataObj.channelName = channelData.channel_name;
                                                                        dataObj.state = channelData.status;
                                                                    
                                                                        if(channelData.status == 'STARTED') 
                                                                        {
                                                                            activeChannelCount++;
                                                                        }
                                                                        else
                                                                        {
                                                                            inactiveChannelCount++;
                                                                        }
                                                                        channelArr.push(dataObj);
                                                                    }
                                            
                                                                }
                                                                    
                                                            }
                                                            updatedServer.channels= channelArr;
                                                            console.log(updatedServer)
                                                            newServers.push(updatedServer);
                                                  
                                                                  if(server.length == active+inactive+noData)
                                                                    {
                                                                        // if(statRes.result.length > activeChannelCount +inactiveChannelCount)
                                                                        // {
                                                                        //     this.setState({isValid : false})
                                                                        // }
                                                                        this.setState({spin: false ,serverData:newServers,activeCount:active,inActiveCount:inactive,activeChannelCount:activeChannelCount,inActiveChannelCount:inactiveChannelCount,errorCount:errorCount});
                                                          
                                                                    }
                                                                    /*commented as a fix*/
                                                                //     getLatestChannelStats(updatedServer.accessToken).then(statRes =>{

                                                                //     if(statRes.code == 200 && statRes.result.length !=0)   
                                                                //         {
                                                                //             let statArr = statRes.result;
                                                                //             let channelsDataArr = updatedServer.channels
                                                                //             let channelArr =[];
                                                                //     for (let i = 0; i < statArr.length; i++) {
                                                                //         let dataObj = {};
                                                                //         let channelData={};
                                                                //         dataObj = statArr[i];
                                                                //         errorCount=errorCount+dataObj.error;
                                                                        
                                                                //         for(let j=0; j < channelsDataArr.length; j++)
                                                                //         {
                                                                        
                                                                //             channelData = channelsDataArr[j];
                                                                //             if (channelData.channel_id == dataObj.channelId) {
                                                                //             // dataObj.channelName = channelData.channelName;
                                                                //                 dataObj.state = channelData.status;
                                                                            
                                                                //                 if(channelData.status == 'STARTED') 
                                                                //                 {
                                                                //                     activeChannelCount++;
                                                                //                     console.log("activeChannel" ,channelData.channelName )
                                                                //                 }
                                                                //                 else
                                                                //                 {
                                                                //                     inactiveChannelCount++;
                                                                //                     console.log("inactiveChannel", channelData.channelName )
                                                                //                 }
                                                                //                 channelArr.push(dataObj);
                                                                //             }
                                                    
                                                                //         }
                                                                            
                                                                //     }
                                                                //     updatedServer.channels= channelArr;
                                                                //     console.log(updatedServer)
                                                                //     newServers.push(updatedServer);
                                                          
                                                                //           if(server.length == active+inactive+noData)
                                                                //             {
                                                                //                 // if(statRes.result.length > activeChannelCount +inactiveChannelCount)
                                                                //                 // {
                                                                //                 //     this.setState({isValid : false})
                                                                //                 // }
                                                                //                 this.setState({spin: false ,serverData:newServers,activeCount:active,inActiveCount:inactive,activeChannelCount:activeChannelCount,inActiveChannelCount:inactiveChannelCount,errorCount:errorCount});
                                                                  
                                                                //             }
                                                                // }
                                                                //     })
                                                                    
                                                                }
                                                                else
                                                                {
                                                                    if(server.length == active+inactive+noData)
                                                                    {
                                                                    this.setState({ spin: false })
                                                                     }
                                                                     
                                                                }
                                                            })
                                                        }
                                                    })

                                                
                                            }
                                            else {
                                                //this.setState({ resultStatus: 'warning', resultTitle: 'Add a server to check statistics', isError: true, spin: false })
                                            }
                                        
                                    
                                    })
                            }
                            //   this.setState({serverData:newServers,activeCount:active,inActiveCount:inactive,activeChannelCount:activeChannelCount,inActiveChannelCount:inactiveChannelCount,errorCount:errorCount});
                            //     if(server.length == active+inactive+noData)
                            //     {
                            //         this.setState({ spin: false })
                            //     }
                            }
                            else
                            {
                                noData++;
                                if(server.length == active+inactive+noData)
                                {
                                this.setState({ spin: false })
                                 }
                            }

                        })
      
    }
    getAllJobs().then(jobsres => {
        if (jobsres.statusCode == 200) {
            let jobs = jobsres.data;
            for(let i=0;i<jobs.length;i++)
            {
                if (jobs[i].jobDetails.servers !== null) {
                let server = jobs[i].jobDetails.servers[0];
                if(server.orgId == user.orgId)
                {
                    if(jobs[i].jobStatus =='RUNNING')
                        runningJobsCount++;
                    else if(jobs[i].jobStatus =='SCHEDULED')
                        scheduledJobsCount++;
                }
            }
            }

        }
        this.setState({runningJobsCount:runningJobsCount,scheduledJobsCount:scheduledJobsCount  });
    })
    }
    else
    {
        this.setState({ resultStatus: 'warning', resultTitle: 'Add a server to check statistics', isError: true, spin: false })
    }

    })
        }
    }



    loadData()
    {
       
        let user = AuthService.getCurrentUser();
        let url = window.location.href;
        if(user !== null && user.hasOwnProperty('orgId') && url.includes("server-dashboard"))
        {
            this.setState({ spin: true})
        getServerByOrgId(user.orgId).then(ServerRes => {
            if (ServerRes.code == 200) {
                let server = [];
                server = ServerRes.result;
                var completedRequests = 0;
                let newServers = [];
                let active = 0;
                let inactive = 0;
                let noData = 0;
                let runningJobsCount=0, scheduledJobsCount=0;
                let errorCount=0, activeChannelCount =0,inactiveChannelCount=0;
                let subResult ;
                if (server.length > 0) {
                    getSubscriptionsByOrg(server[0].orgId).then((subRes)=>{
                      
                        if (subRes.code == 200 && subRes.result.length >0) {
                             subResult = subRes.result[0];
                            let attrs =JSON.parse( subResult.planAttributes);
                            subResult.channelCount = attrs.channelCount;
                            subResult.serverCount = attrs.serverCount;
                      
                    for (let i = 0; i < server.length; i++) {
                        
                        let updatedServer ={};
                        updatedServer =server[i];
                                    getMirthServerDetails(updatedServer.accessToken).then(serverRes => {
                                        if (serverRes.code == 200  && serverRes.result.length != 0) {
                                            console.log(moment.utc(moment(serverRes.result[0].observationTime).utc()).format())
                                            let startTime = moment(serverRes.result[0].observationTime).tz("UTC");


                                            updatedServer.lastActiveTime = startTime.fromNow();
                                            updatedServer.isValid = serverRes.result[0].valid;
                                            updatedServer.channelLimit =subResult.channelCount;
                                            var endTime = moment.tz("UTC");
                                            let timeDiff = moment.duration(endTime.diff(startTime));
        
                                            if (Math.floor(timeDiff.asHours()) == 0 && Math.floor(timeDiff.asDays()) == 0 && Math.floor(timeDiff.asMonths()) == 0 && Math.floor(timeDiff.asYears()) == 0) {
                                                if (Math.floor(timeDiff.asMinutes()) <= 2) {
                                                    updatedServer.state = "ACTIVE";
                                                    active++;
                                                }
                                                else {
                                                    updatedServer.state = "IN ACTIVE";
                                                    inactive++;
                                                }
                                            }
                                            else {
                                                updatedServer.state = "IN ACTIVE";
                                                inactive++;
                                            }
                                            let name=updatedServer.serverName;
                                            let details = JSON.parse(serverRes.result[0].details);
                                            updatedServer.PGToastData = [];
                                            if(details.pgToastInfo)
                                            {
                                                updatedServer.PGToastData = details.pgToastInfo;
                                                //this.setState({ PGToastData: details.pgToastInfo})
                                            }
                                        
                                            let res = JSON.parse(details.mirthInfo);
                                            updatedServer.channelCount= res.channelCount;
                                            let statisticsRes =JSON.parse( details.statistics);
                                      
                                        let statusres =JSON.parse( details.channelStatus);
                                        let statusvalues= statusres.list.dashboardStatus;
                                        let channelsDataArr =[];
                                        let statValues = statisticsRes.list.channelStatistics;
                                        let statArr =[];
                                        if (statValues !== null && !Array.isArray(statValues)) {
                                            statArr.push(statValues);
                                            
                                          }
                                        else
                                        {
                                            statArr =statValues;
                                        }
                                        if (statusvalues !== null && !Array.isArray(statusvalues)) {
                                            channelsDataArr.push(statusvalues);
                                          }
                                        else
                                        {
                                            channelsDataArr =statusvalues;
                                        }
                                        let channelArr = [];
                                       
                                        for (let i = 0; i < statArr.length; i++) {
                                            let dataObj = {};
                                            let channelData={};
                                            dataObj = statArr[i];
                                            errorCount=errorCount+dataObj.error;
                                            
                                            for(let j=0; j < channelsDataArr.length; j++)
                                            {
                                               
                                                 channelData = channelsDataArr[j];
                                                if (channelData.channelId == dataObj.channelId) {
                                                    dataObj.channelName = channelData.name;
                                                    dataObj.state = channelData.state;
                                                    // console.log(channelData.channelId)
                                                    // console.log(channelData.name)
                                                    // console.log(channelData.state)
                                                  
                                                    if(channelData.state == 'STARTED') 
                                                    {
                                                        activeChannelCount++;
                                                    }
                                                    else
                                                    {
                                                        inactiveChannelCount++;
                                                    }
                                                    channelArr.push(dataObj);
                                                }
                        
                                            }
                                                
                                        }
                                        console.log("limit",updatedServer.channelLimit)
                                        console.log("valid",updatedServer.isValid)
                                        if(!updatedServer.isValid)
                                        {
                                            const slicedArray = channelArr.slice(0, updatedServer.channelLimit);
                                            updatedServer.channels= slicedArray;
                                            console.log("in slice")
                                            console.log(updatedServer.channels)
                                        }
                                        else
                                        {
                                            updatedServer.channels= channelArr;
                                            console.log(updatedServer.channels)
                                        }
                                        
        
                                            newServers.push(updatedServer);
                                          
                                            this.setState({serverData:newServers,activeCount:active,inActiveCount:inactive,activeChannelCount:activeChannelCount,inActiveChannelCount:inactiveChannelCount,errorCount:errorCount});
                                            if(server.length == active+inactive+noData)
                                            {
                                                this.setState({ spin: false })
                                            }
                                        }
                                        else
                                        {
                                            noData++;
                                            if(server.length == active+inactive+noData)
                                            {
                                            this.setState({ spin: false })
                                             }
                                        }
        
                                    })
                                
                                   
                                         
                    }
                }
                
            })
                    getAllJobs().then(jobsres => {
                        if (jobsres.statusCode == 200) {
                            let jobs = jobsres.data;
                            for(let i=0;i<jobs.length;i++)
                            {
                                if (jobs[i].jobDetails.servers !== null) {
                                let server = jobs[i].jobDetails.servers[0];
                                if(server.orgId == user.orgId)
                                {
                                    if(jobs[i].jobStatus =='RUNNING')
                                        runningJobsCount++;
                                    else if(jobs[i].jobStatus =='SCHEDULED')
                                        scheduledJobsCount++;
                                }
                            }
                            }
            
                        }
                        this.setState({runningJobsCount:runningJobsCount,scheduledJobsCount:scheduledJobsCount  });
                    })
                }
                else {
                    this.setState({ resultStatus: 'warning', resultTitle: 'Add a server to check statistics', isError: true, spin: false })
                }
            }
        })
        }
    }
    handleTimeChange(val){
        this.runInterval(parseInt(val));
        this.setState({refreshTime:parseInt(val)})
    }
    listVulnerability(version){

        getVulnerabilitiesByVersion(version).then(res=>{
            if(res.code == 200 & res.result.length >0 )
            {
                let result = res.result;
                let Vulnerability =JSON.parse(JSON.parse(result[0].vulnerability_details));
                let comp = '';
                let footer = null;
                if(Vulnerability.length >0)
                {
                   comp = (
                    <>
                    <h2 style={{fontWeight:'1000'}}>{'Vulnerabilities in Mirth Connect version - '+version}</h2>
                        <Collapse
                          bordered={false}
                          accordion
                          //defaultActiveKey={['1']}
                          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                          className="site-collapse-custom-collapse"
                        >
                       {Vulnerability.map((vul, index) => (
                                <Panel header={vul.name} key={index} className="site-collapse-custom-panel">
                                <p>{vul.description}</p>
                              </Panel>
                              ))}
                         
                          
                        </Collapse>
                      
                        </>
                      )
                      footer=(  <Text style={{marginTop:'20px'}}>This version is affected by critical vulnerability, please {<a href={"https://control-center.freshdesk.com/support/home"}> Contact Support </a>} to upgrade Mirth Connect to version 4.4.1 or later!</Text>)
                    
                }
                this.setState({modalMessage:comp,modalFooter:footer,isModalVisible:true})
           
            }
            else
            {
                notification.error({
                    message: "No data for this version",
                  })
            }
        })
    
    }
    handleCancel() {
        this.setState({ isModalVisible: false });
      }
    checkIfVulnerable(version) {
       if(version == '4.4.1' ||version == '4.4.2' || version == '4.5.0')
       {
        return false;
       }
       else
       {
        return true;
       }
    }
    render() {


        const {isValid, spin, PGToastData,serverOptions,scheduledJobsCount,runningJobsCount,activeCount,inActiveCount,errorCount,activeChannelCount,inActiveChannelCount, diskspaceusage,dashboard,serverData, memoryspaceusage, resultStatus, resultTitle, resultSubTitle, cpuUsage, systemInfo, serverConfiguration, serverId, serverJvm, isError ,modalMessage,modalFooter,isModalVisible} = this.state;
       
       const formPannelHeader=(server)=>
        {
            const header =    <Row>

            <Col span={18}>
                <div class="collapse_panel_heading" style={{ color: 'black', fontSize: "24px", transform: "translateX(-20px)", marginLeft: '20px' }}>
                <Badge color={server.state=="ACTIVE"?"#87d068":"#8c8c8c"} text=""/><Text style={{ color:'black', fontSize: "18px", transform: "translateX(-20px)" }}> {server.serverName}</Text>
               
               <> <Divider type="vertical" />
             
                            <Text style={{ color: '#8c8c8c', fontSize: "14px", transform: "translateX(-20px)" }}>
                                {server.serverVersion}
                                {this.checkIfVulnerable(server.serverVersion) && <Tooltip title="Vulnerable version">
                                    <BugFilled onClick={() => { this.listVulnerability(server.serverVersion) }} style={{ color: 'red', fontSize: '18px', marginLeft: '10px' }} className="animate__animated animate__heartBeat" />                               
                                </Tooltip>}
                            </Text>
              
                <Divider type="vertical" />
                <Text style={{ color:'#8c8c8c', fontSize: "14px", transform: "translateX(-20px)" }}>{server.lastActiveTime}</Text>
                <Divider type="vertical" />
                <Text style={{ color:'black', fontSize: "14px", transform: "translateX(-20px)" }}>No of Channels :
            <Badge style={{ backgroundColor: '#f5f5f5',color: 'black' }}  count={server.channelCount}/></Text></>
        </div>
                
            </Col>
            <Col span={6}>
            <div class="content_panel_heading" >
            <Space>
           <><Tooltip title="Server Logs">
            <Link to={`/mirth/server-log/${server.accessToken}`}> <img src={logLogo} style={{marginRight: -21,width:'20px',height:'20px' }}></img></Link>
            </Tooltip>
            <Tooltip title="Channel Statistics">
            <Link to={`/mirth/channel-statistics/${server.accessToken}`}>  <img src={channelStatisticsLogo} style={{marginLeft: 22,marginRight: -21,width:'20px',height:'20px' }}></img></Link>
            </Tooltip>
            <Tooltip title="More Details">
            <Link to={`/mirth/server-dashboard/${server.accessToken}`}> <img src={viewLogo} style={{marginLeft: 22,marginRight: -21,width:'20px',height:'20px' }}></img></Link>
            </Tooltip>
            {server.dbName == "PostgreSQL" &&<><Tooltip title="PG Toast Data">
            <Link to={`/mirth/pg-toast-data/${server.accessToken}`}> <img src={mirthServersLogo} style={{marginLeft: 22,marginRight: -21,width:'20px',height:'20px' }}></img></Link>
            </Tooltip>
            <Tooltip title="PG Toast Trends">
            <Link to={`/mirth/pg-toast-trends/${server.accessToken}`}><img src={channelStatisticsLogo} style={{marginLeft: 22,marginRight: -21,width:'20px',height:'20px' }}></img></Link>
            </Tooltip></>}
            </>
            {/* {!server.isValid &&
             <div onClick={e => e.stopPropagation()}>
            <Tooltip title="Notifications">
            <Badge  count={1}>
            <img src={notificationLogo} style={{marginLeft: 22,marginRight: -21,width:'20px',height:'20px' }} onClick={()=>{ 
                                const args = {
                                        type:'error',
                                        duration:10,
                                        style: {
                                            backgroundColor: '#fff1f0',
                                            borderRadius: '2px',
                                            border: 'solid 1px #ffa39e'
                                               
                                          },
                                        message: 'Channel limit exceeded',
                                       description:<span>Sorry, you are not able to monitor all the channels, as the channel limit of the plan is being exceeded.Please Contact our  <a href="https://control-center.freshdesk.com/"> support team  </a> to upgrade your plan.</span> ,
                                    };
                                    notification.open(args);}}></img>
     
            </Badge>
            </Tooltip></div>} */}
            </Space>
            </div>
            </Col>
          
        </Row>;
            return header;
        }
        const renderTag = (tag) => {

            if (tag == 'STARTED')
                return <Tag color="success">{tag}</Tag>;
            else if (tag == 'PAUSED')
                return <Tag color="processing">{tag}</Tag>;
                else if (tag == 'STOPPED')
                return <Tag color="error">{tag}</Tag>;
            else
                return <Tag color="default">{tag}</Tag>;

        }
        const channelStatisticsColumns = [
           
            { title: 'Channel Name', dataIndex: 'channelName', key: 'name' ,
            render: (text, record) => (
                <>
                   <Badge color={record.state=="STARTED"?"#87d068":"#8c8c8c"} text=""/><Text style={{ color:'black', fontSize: "14px", transform: "translateX(-20px)" }}> {text}</Text>
               
                </>),
        },
            { title: 'State', dataIndex: 'state', key: 'state',render: (text) => renderTag(text), },
            {
                title: 'Received', dataIndex: 'received', key: 'received', defaultSortOrder: 'descend',
                sorter: (a, b) => a.received - b.received
            },
            {
                title: 'Sent', dataIndex: 'sent', key: 'sent',
                render: (text, record) => (
                    <>
                        <Tag color="success">{text}</Tag>
                    </>),
                sorter: (a, b) => a.sent - b.sent
            },
            {
                title: 'Error', dataIndex: 'error', key: 'error',
                render: (text, record) => (
                    <>
                        <Tag color="error">{text}</Tag>
                    </>),
                sorter: (a, b) => a.error - b.error
            },
            { title: 'Filtered', dataIndex: 'filtered', key: 'filtered', sorter: (a, b) => a.filtered - b.filtered },
            { title: 'Queued', dataIndex: 'queued', key: 'queued', sorter: (a, b) => a.queued - b.queued },


        ];
      
        return (
            <>
                <Breadcrumb>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Mirth Monitoring</Breadcrumb.Item>
                    <Breadcrumb.Item>Server DashBoard</Breadcrumb.Item>
                </Breadcrumb>

                <div className="content_panel" style={{backgroundColor:'#f0f2f5'}}>

                    <Spin size="large" spinning={spin}>
                        <Modal
                    
                            visible={isModalVisible}
                            footer={modalFooter}
                            onCancel={this.handleCancel}
                            width={900}
                            bodyStyle={{ overflowY: "scroll", height: 380 }}
                        >
                          
                        {modalMessage}
                        </Modal>
                        <Row>

                            <Col span={18}>
                                <div class="content_panel_heading" style={{ color: 'black', fontSize: "24px", transform: "translateX(-20px)", marginLeft: '20px' }}>Server DashBoard
                                </div>
                            </Col>
                            <Col span={4}>
                            <span>
                            <Input.Group compact >
                            <Button type="primary" shape="rectangle" icon={<ReloadOutlined /> } onClick={this.loadDataNew} style={{width:'50px'}} />
                            {/* <Input addonBefore={<Button type="primary" shape="rectangle" icon={<ReloadOutlined /> } onClick={this.loadDataNew} style={{width:'50px'}} />} value="" type="hidden" style={{ width: '30%',height:'80%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto' }} /> */}
                            <Select className="refresh_filter" style={{width:'100px',borderBottomLeftRadius:'0px',borderTopLeftRadius:'0px'}} defaultValue="60000" onChange={this.handleTimeChange}>
                                    <Option  value="5000">5s</Option>
                                    <Option  value="15000">15s</Option>
                                    <Option  value="30000">30s</Option>
                                    <Option  value="60000">60s</Option>
                                    <Option  value="120000">2m</Option>
                                    <Option  value="300000">5m</Option>
                                </Select>
                            </Input.Group>
                            </span> 
                            </Col>
                            {isValid &&<Divider type="vertical" style={{marginTop:'5px'}}/>}
                            <Col  span={1}>
                           
                            {isValid &&
                                <div onClick={e => e.stopPropagation()} style={{marginTop:'7px'}}>
                                <Tooltip title="Notifications">
                                <Badge  count={1}>
                                <img src={notificationLogo} style={{marginLeft: 22,marginRight: -21,width:'20px',height:'20px', cursor:'pointer' }} onClick={()=>{ 
                                                    const args = {
                                                            type:'error',
                                                            duration:10,
                                                            style: {
                                                                backgroundColor: '#fff1f0',
                                                                borderRadius: '2px',
                                                                border: 'solid 1px #ffa39e',
                                                               
                                                            },
                                                            message: 'Channel limit exceeded',
                                                        description:<span>Sorry, you are not able to monitor all the channels, as the channel limit of the plan is being exceeded.Please Contact our  <a href="https://control-center.freshdesk.com/"> support team  </a> to upgrade your plan.</span> ,
                                                        };
                                                        notification.open(args);}}></img>
                        
                                </Badge>
                                </Tooltip></div>}
                                </Col>
                        </Row>
                        <div class="divider-1"> <span></span></div>
                        {/* <Divider /> */}
                        {
                            isError ? (
                                <>
                                    <Result
                                        status={resultStatus}
                                        title={resultTitle}
                                        subTitle={resultStatus == '500' ? resultSubTitle : ''}
                                        extra={resultStatus == '500' ? <></> : <Link to="/settings/server"><Button type="primary" style={{ borderRadius: '7px' }}>Add Server</Button></Link>}
                                    />
                                </>) : (
                                    <>
                                     <Row style={{paddingRight:'20px'}}>

                                    <Col span={6}>
                                    <Card
                                            actions={[
                                                <Statistic title="Active" value={activeCount} valueStyle={{ color: '#3f8600' }} prefix={<ArrowUpOutlined  />} />,
                                                <Statistic title="In Active" value={inActiveCount} valueStyle={{ color: '#cf1322' }} prefix={<ArrowDownOutlined />}/>
                                            ]}
                                        >
                                            <Meta
                                            title={'Servers ('+(activeCount+inActiveCount)+')'}
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={6}>
                                    <Card
                                            actions={[
                                                <Statistic title="Active" value={activeChannelCount} valueStyle={{ color: '#3f8600' }} prefix={<ArrowUpOutlined  />} />,
                                                <Statistic title="In Active" value={inActiveChannelCount} valueStyle={{ color: '#cf1322' }} prefix={<ArrowDownOutlined />}/>
                                            ]}
                                        >
                                            <Meta
                                            title={'Channels ('+(activeChannelCount+inActiveChannelCount)+')'}
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={6}>
                                    <Card
                                            actions={[
                                              
                                                <Statistic title="count" value={errorCount} valueStyle={{ color: '#cf1322' }} prefix={<AlertOutlined />}/>
                                            ]}
                                        >
                                            <Meta
                                            title="Errors"
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={6}>
                                    <Card
                                            actions={[
                                                <Statistic title="Running" value={runningJobsCount} valueStyle={{ color: '#3f8600' }}  />,
                                                <Statistic title="Scheduled" value={scheduledJobsCount} valueStyle={{ color: '#096dd9' }} />
                                            ]}
                                        >
                                            <Meta
                                            title="Email Scheduled"
                                            />
                                        </Card>
                                    </Col>
                                    </Row>
                                    <Row style={{padding: '20px 30px 10px 10px'}}>
                                    <Collapse
                                        defaultActiveKey={[1]}
                                      
                                        expandIconPosition='right'
                                        >
                                         {serverData.map((server,index) => (
                                               <Panel header={formPannelHeader(server)} key={(index+1).toString()} >
                                               <div>
                                               {server.isValid ?  <Table
                                                
                                                columns={channelStatisticsColumns}
                                                dataSource={server.channels}
                                             
                                            />:<Table
                                                
                                            columns={channelStatisticsColumns}
                                            dataSource={server.channels}
                                         
                                        />}
                                               </div>
                                                </Panel>
                                            ))

                                            }
                                        </Collapse>
                                          </Row>
                                    </>)}
                    </Spin>

                </div>
            </>
        );
                                        }
                                    }
                                    
