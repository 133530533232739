import React, { useState, useEffect } from 'react';
import  {  DatePicker  } from 'antd';
import moment from 'moment';
const { RangePicker } = DatePicker;
export const CustomRangePicker = (props) => {
    
  const {handler} = props
  const [dates, setDates] = useState(null);
  const [value, setValue] = useState(null);
  useEffect(() => {
    if(value !== null)
    {
      handler(value);
    }
  
}, [value]);

  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 2;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 2;
    return !!tooEarly || !!tooLate || current > moment().endOf('day');
  };
  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };
  const subTime=(h, m) =>{
    let date = new Date();
    date.setHours(date.getHours() - h);
    date.setMinutes(date.getMinutes() - m);
    date = moment(date);
    return date;
};
  return (
    <RangePicker
      value={dates || value}
      disabledDate={disabledDate}
      onCalendarChange={(val) => setDates(val)}
      onChange={(val) => setValue(val)}
      onOpenChange={onOpenChange}
  //     ranges={{
  //       '5min': [moment().startOf('minute'), subTime(0, 5)],
  //      '15min': [moment().startOf('minute'), subTime(0, 10)],
  //      '45min': [moment().startOf('minute'), subTime(0, 45)],
  //      '1hour': [moment().startOf('minute'), subTime(1, 0)],
  //      '12hour': [moment().startOf('minute'), subTime(12, 0)],
  //      '1day': [moment().startOf('minute'), subTime(24, 0)],

  // //  'Today': [moment().startOf('day'), moment().endOf('day')],
  // //   'This Week':  [moment().startOf('week'), moment().endOf('week')],
  // //     'This Month': [moment().startOf('month'), moment().endOf('month')],
  //   }}
    />
  );
};