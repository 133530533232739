import React, { Component } from "react";
import axios from 'axios';
// import 'antd/dist/antd.variable.min.css'
import { Row, Col, Table, Typography,Divider, Card, Result, Tag, Space, Select, Tooltip, Modal, Form, Input, Button, DatePicker, Breadcrumb, Spin } from 'antd';
import {
    EyeOutlined,
    ReloadOutlined
} from '@ant-design/icons';
import { Link} from 'react-router-dom';
import {
    getServerList,
    getData,
    getMirthServerDetails,
    getServerDetailsByOrgId
} from '../../../service/mirth/MirthService'

import AuthService from '../../../service/Auth/auth-service'
import '../../../styles/custom-css/antd-custom.css';
import {SearchBar} from '../../../components'
import { properties } from '../../../properties';
import moment from 'moment-timezone';
import { configurationData } from "../../../service/ConfigService/Config";

const {Option} = Select;
const {Text} = Typography;

function formatDate(current) {
    var date = new Date(current);
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
}
export default class ServerLog extends Component {

    constructor(props) {
        super(props)

        this.state = {
            serverLogsData: [],
            cloneServerLogsData: [],
            spin: true,
            modalTitle: '',
            isModalVisible: false,
            modalMessage: '',
            currentAccessToken:'',
            resultStatus:'',
            resultTitle:'',
            resultSubTitle:'',
            serverOptions:[],
            isError:false,
            page:'serverLog',
            

        }

        this.handleModalView = this.handleModalView.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleServerChange = this.handleServerChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.refreshData = this.refreshData.bind(this);

    }

    componentDidMount() {

        let token = this.props.match.params.accessToken;
        let user = AuthService.getCurrentUser();
        configurationData(this.state.page).then((res) =>{
            if(res.code==200){
              let jsonData=JSON.parse(res.result[0].config);
              console.log(jsonData)
            this.setState({resultStatus:jsonData.status.resultStatus,
            resultTitle:jsonData.status.resultTitle,
            resultSubTitle:jsonData.status.resultSubTitle})
            
            
            
          
            getServerDetailsByOrgId(user.orgId).then(ServerRes=> {
            if (ServerRes.code == 200) {
                let server=[];
                server = ServerRes.result;

                 if(token == null )
                 {
                    let change=server[0].accessToken;
                    this .setState({serverChange: change})
                    this.setState({ resultStatus:'warning',resultTitle:'Add a server to check statistics', isError:true,spin: false})
                }
                else
                {
                   
                    server.map((servers) =>{
                        let  atoken=servers.accessToken
                        if(atoken==token){
                        
                        this.setState({serverChange:servers.serverName})
                        }})
                     
                    
                    if(server.length > 0)
                    {
                        this.setState({serverOptions:server,spin: false});
                        this.handleServerChange(token);
                    }
                    else
                    {
                        this.setState({ resultStatus:'warning',resultTitle:'Add a server to check statistics', isError:true,spin: false})
                    }
                }
            }
        })
    }
})

    
    
       
      
    }

    refreshData() {
        this.setState({ spin: true });
        getMirthServerDetails(this.state.currentAccessToken).then(serverRes=>{
            if (serverRes.code == 200 && serverRes.result.length !=0) {
                let details = JSON.parse(serverRes.result[0].details);
                if(typeof(details.serverLog) == 'string')
                {
                 let res =JSON.parse( details.serverLog);
                 let keys= Object.keys(res.list);
                 let logsData = res.list[keys[0]];
                 let finalData = [];
                 for(let i=0;i<logsData.length;i++)
                 {
                     let rowData = logsData[i];
                     rowData.date = formatDate(rowData.date);
                     finalData.push(rowData)
 
                 }
                 this.setState({ spin: false, serverLogsData:finalData,cloneServerLogsData :finalData})
                }
                else
                {
                 this.setState({ spin: false, serverLogsData:details.serverLog,cloneServerLogsData :details.serverLog})
                }
            }
             else
            {
                this.setState({isError:true,spin: false})
            }
            document. getElementById("search-box"). value = "";
        })
   
    }
    handleSearch(data)
    {
        this.setState({ serverLogsData: data });
    }
    handleServerChange(val){
      
        this.setState({ spin: true ,currentAccessToken:val,isError:false})
        getMirthServerDetails(val).then(serverRes=>{
            if (serverRes.code == 200 && serverRes.result.length !=0) {
                let details = JSON.parse(serverRes.result[0].details);
               if(typeof(details.serverLog) == 'string')
               {
                let res =JSON.parse( details.serverLog);
                let keys= Object.keys(res.list);
                let logsData = res.list[keys[0]];
                let finalData = [];
                for(let i=0;i<logsData.length;i++)
                {
                    let rowData = logsData[i];
                    rowData.date = formatDate(rowData.date);
                    finalData.push(rowData)

                }
                this.setState({ spin: false, serverLogsData:finalData,cloneServerLogsData :finalData})
               }
               else
               {
                this.setState({ spin: false, serverLogsData:details.serverLog,cloneServerLogsData :details.serverLog})
               }
             
              
            }
             else
            {
                this.setState({isError:true,spin: false})
            }
        })
     
    }

    handleCancel() {
        this.setState({ isModalVisible: false })
    }

    handleModalView(data, title) {

        this.setState({ modalMessage: data, modalTitle: title, isModalVisible: true })

    }


    render() {


        const { serverChange,cloneServerLogsData, serverLogsData, spin, resultStatus,resultTitle,resultSubTitle, modalTitle, modalMessage, isModalVisible ,serverOptions,isError} = this.state;
        const renderTag = (tag) => {

            if (tag == 'INFO')
                return <Tag color="success">{tag}</Tag>;
            else if (tag == 'ERROR')
                return <Tag color="error">{tag}</Tag>;
            else
                return <Tag color="default">{tag}</Tag>;

        }
        const serverLogColumns = [
            { title: 'ID', dataIndex: 'id', key: 'id' },

            {
                title: 'LEVEL', dataIndex: 'level', key: 'level',
                render: (text) => renderTag(text),
                filters: [
                    {
                      text: 'ERROR',
                      value: 'ERROR',
                    },
                    {
                      text: 'INFO',
                      value: 'INFO',
                    },
                  ],
                  onFilter: (value, record) => record.level.startsWith(value),
                  filterSearch: true,
            },
            {
                title: 'Thread Name', dataIndex: 'threadName', key: 'threadName',
                width: '50%',
                onCell: () => {
                    return {
                        style: {
                            whiteSpace: 'nowrap',
                            maxWidth: 100,
                        }
                    }
                },
                render: (text, record) => (
                    <Tooltip title={text}>
                        <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{text}</div>
                    </Tooltip>
                )
            },

            {
                title: 'Date', dataIndex: 'date', key: 'date',
                width: '30%',
                onCell: () => {
                    return {
                        style: {
                            whiteSpace: 'nowrap',
                            maxWidth: 100,
                        }
                    }
                },
                render: (text, record) => (
                    <Tooltip title={text}>
                        <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{text}</div>
                    </Tooltip>
                )
            },
            {
                title: '',
                dataIndex: '',
                key: 'x',

                render: (text, record) => (
                    <>
                        <Tooltip title="View Message">
                            <a
                                onClick={() => { this.handleModalView(record.category + '[' + record.lineNumber + ']:' + record.message, "Message Details") }}><EyeOutlined /></a>
                        </Tooltip>
                    </>)
            },
        ];
        return (
            <>
                <Breadcrumb>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Mirth Monitoring</Breadcrumb.Item>
                    <Breadcrumb.Item> Server Log</Breadcrumb.Item>
                </Breadcrumb>

                <div className="content_panel">

                    <Spin size="large" spinning={spin}>
                   
                        <Modal title={modalTitle} visible={isModalVisible} footer={null} onCancel={this.handleCancel} width={900} bodyStyle={{ overflowY: 'scroll', height: 380 }}>
                            {modalMessage}
                        </Modal>
                        <Row>
                            <Col span={5}>
                                <div class="content_panel_heading" >Server Log
                                </div>
                            </Col>
                            {serverOptions.length !==0 &&
                            <>
                            <Col span={2}  style={{marginTop:'4px'}}>
                            <Text >Server: </Text>
                            </Col>
                            <Col span={12} style={{marginLeft:'-30px'}}>
                          
                            <Select placeholder="Select server" defaultValue={serverChange} onChange={this.handleServerChange} style={{ width: 160 }} bordered={false}>
                            {serverOptions.map((server) => (
                                                    <Option key={server.accessToken} value={server.accessToken}>{server.serverName}</Option>
                                                ))

                                    }
                                </Select>
                            
                            </Col>
                            </>}
                        </Row>
                        <div class="divider-1"> <span></span></div>
                       {/* <Divider/> */}
                        {
                        isError ? (
                        <>
                         <Result
                            status={resultStatus}
                            title={resultTitle}
                            subTitle={resultStatus== '500'?resultSubTitle:''}
                            extra={resultStatus== '500'?<></>:  <Link to="/settings/server"><Button type="primary" style={{borderRadius:'7px'}}>Add Server</Button></Link>}
                        />
                        </>):(
                        <>
                              <Tooltip title="Refresh">
                                <Button type="primary" shape="circle" icon={<ReloadOutlined />} onClick={this.refreshData} />
                                 </Tooltip>
                                 <Divider type="vertical"/>
                                 <SearchBar data={cloneServerLogsData} handler={this.handleSearch}></SearchBar>
                                <Table
                                 style={{marginTop:'10px'}}
                                    className="server_log"
                                    columns={serverLogColumns}
                                    dataSource={serverLogsData}
                                   
                                />
                            
                        
                        </>)
                    }
                        


                    </Spin>

                </div>
            </>
        );
    }
}

