import React, { Component ,useState,useRef} from "react";
import { Row, Col,Tag, Table, Typography,Divider, Card, Descriptions, Result, Space, Select, Tooltip, Modal, Form, Input, Button, DatePicker, Breadcrumb, Spin } from 'antd';
import {
    PlayCircleOutlined,
    StopOutlined,
    PauseCircleOutlined,
    SearchOutlined
} from '@ant-design/icons';
import { Link} from 'react-router-dom';
import {
    getServerList,
    getData,
    getMirthServerDetails,
    getServerByOrgId,
    getAlertsByToken,pdf
} from '../../service/mirth/MirthService'
import moment from 'moment-timezone';
import AuthService from '../../service/Auth/auth-service'
import {SearchBar} from '../../components'
import '../../styles/custom-css/antd-custom.css';
import Highlighter from "react-highlight-words";


const {Option} = Select;
const {Text} = Typography;
const getFullDate = (date) => {
    if (date !== null) {
        const value = moment(date).format("YYYY-MM-DD HH:mm:ss")
        return value;
    }

};
export default class AlertList extends Component {

    constructor(props) {
        super(props)

        this.state = {
            spin: true,
            modalTitle: '',
            isModalVisible: false,
            modalMessage: '',
            serverOptions:[],
            alertData:[],
            cloneAlertData: [],
            resultStatus:'500',
            resultTitle:'500',
            resultSubTitle:'Server not reachable',
            currentServer:'',
            isError:false,
            currentServerToken:'',
            request:{}
        }

        this.handleModalView = this.handleModalView.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleServerChange = this.handleServerChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleReportDonwload=this.handleReportDonwload.bind(this);
       
    }
    state = {
        searchText: '',
        searchedColumn: '',
    };



    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        this.setState({ selectedRowKeys: selectedRowKeys })
        this.setState({ selectedRows: selectedRows })
    }

    componentDidMount() {
        let user = AuthService.getCurrentUser();
        getServerByOrgId(user.orgId).then(ServerRes=> {
            if (ServerRes.code == 200) {
                let server=[];
                server = ServerRes.result;
             
                if(server.length > 0)
                {
                    this.setState({serverOptions:server,spin: false});
                    this.handleServerChange(server[0].accessToken);
                }
                else
                {
                    this.setState({ resultStatus:'warning',resultTitle:'Add a server to check statistics', isError:true,spin: false})
                }
               
            }
        })
      
    }
    handleSearch(data)
    {
        this.setState({ alertData: data });
    }
    handleReportDonwload()
    {
        this.setState({ isSearched: true,spin:true });
        console.log(this.state.resultTitle);
        let request={
            "fromDate":'fromDate',
            "toDate":'toDate',
            "UserName":'userName'
        
        }
        this.setState({request:request});
        pdf(this.state.currentServerToken,"alert",this.state.request).then(response =>{
            console.log(response)

            const url=window.URL.createObjectURL(new Blob([response]));
            const link=document.createElement('a');
            link.href=url;
            link.setAttribute('download','alert.pdf');
            document.body.appendChild(link);
            link.click();
            this.setState({ spin:false });
        });
    }
    
    handleServerChange(val){
     
        this.setState({ spin: true ,currentServer:val,isError:false});
        getAlertsByToken(val).then(alertRes=>{
            console.log(alertRes)
            if (alertRes.code == 200) {
                let data = [];
                for(let i=0;i<alertRes.result.length;i++)
                {
                    let rowData = alertRes.result[i];
                    rowData.reportTime = getFullDate(rowData.reportTime);
                    data.push(rowData)
                }
                this.setState({ spin: false,alertData:data,cloneAlertData:data,currentServerToken:val  })
            }
            else
            {
                this.setState({isError:true,spin: false})
            }
        })
        // getMirthServerDetails(val).then(serverRes=>{
        //     if (serverRes.code == 200 && serverRes.result.length !=0) {
        //         let details = JSON.parse(serverRes.result[0].details);
        //         let res =JSON.parse( details.channelStatus);
        //         let values= res.list.dashboardStatus;
        //         let finalArray =[];
        //         if (values !== null && !Array.isArray(values)) {
        //             finalArray.push(values);
        //             this.setState({ spin: false,channelData:finalArray ,cloneChannelData:finalArray})
        //           }
        //         else
        //         {
        //             finalArray =values;
        //             this.setState({ spin: false,channelData:finalArray ,cloneChannelData:finalArray })
        //         }
        //     }
        //      else
        //     {
        //         this.setState({isError:true,spin: false})
        //     }
        // })
       
    }
 
    handleCancel() {
        this.setState({ isModalVisible: false })
    }

    handleModalView(data, title) {

        this.setState({ modalMessage: data, modalTitle: title, isModalVisible: true })

    }
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearchs(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearchs(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    {/* <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}
                    >
                        Filter
                    </Button> */}
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                    text
                ),
    });

    handleSearchs = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    // setSearchText(data){
    //     this.setState({searchText:data})


    // }
    // setSearchedColumn(dataIndex){
    //     this.setSearchedColumn({searchedColumn:dataIndex})


    // }


    render() {


        const {selectedRowKeys, selectedRows,spin, modalTitle, modalMessage, resultStatus,resultTitle,resultSubTitle, isModalVisible ,serverOptions,alertData,cloneAlertData,isError} = this.state;
        const rowSelection = {
            selectedRowKeys, selectedRows,
            onChange: this.onSelectChange
        };

        const renderTag = (tag) => {

            if (tag == 'STARTED')
                return <Tag color="success">{tag}</Tag>;
            else if (tag == '0 RECEIVED ALERT')
                return <Tag color="processing">{tag}</Tag>;
                else if (tag == 'SERVER DOWN ALERT')
                return <Tag color="error">{tag}</Tag>;
            else
                return <Tag color="default">{tag}</Tag>;

        }
        const getFullDate = (date) => {
            if (date !== null) {
                const value = moment(date).tz("America/New_York").format("YYYY-MM-DD HH:mm:ss")
                return value;
            }

        };
        

  
        const alertColumns = [
            { title: 'Alert Id', dataIndex: 'alertId', key: 'alertId',...this.getColumnSearchProps("alertId") },
    //     filterDropdown:({setSelectedKeys, selectedKeys,confirm,clearFilters})=>{
    //         return (
    //             <>
    //             <Input
    //             autoFocus
    //             placeholder="Type Id here"
    //             value={selectedKeys[0]}
    //             onChange={(e) =>{
    //                 setSelectedKeys(e.target.value? [e.target.value]:[]);
    //                 confirm({closeDropdown:false});
    //             }}
    //             onPressEnter={() =>{
    //                 confirm();

    //             }}
    //             onBlur={() =>{
    //                 confirm();
    //             }}
    //             ></Input>
    //             <Button onClick={() =>{
    //                 confirm();
    //             }}>Search</Button>
    //             <Button onClick={() =>{
    //                 clearFilters();
    //             }}>Reset</Button>
    //             </>
    //         );
    //     },
    //     filterIcon:() =>{
    //         return<SearchOutlined/>;
    //     },
    //     OnFilter:(value,record)=>{
    //         return record.alertId == value;
    //     },
    // },

            { title: 'Server Name', dataIndex: 'serverName', key: 'serverName' },
            { title: 'Channel Name', dataIndex: 'channelName', key: 'channelName' },
            // { title: 'Metric Delta', dataIndex: 'metricDelta', key: 'metricDelta'},
             { title: 'Alert', dataIndex: 'metric', key: 'metric',render: (text) => renderTag(text) },
             {
                title: 'Report Time', dataIndex: 'reportTime', key: 'reportTime',...this.getColumnSearchProps("reportTime"),
                render: (text, record) => (<>{text}</>)
            },
            // { title: 'State', dataIndex: 'state', key: 'state',render: (text) => renderTag(text), }
        ];
        return (
            <>
                <Breadcrumb>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Alerts</Breadcrumb.Item>
                    <Breadcrumb.Item>Alert List</Breadcrumb.Item>
                </Breadcrumb>

                <div className="content_panel">

                    <Spin size="large" spinning={spin}>
                   
                        <Modal title={modalTitle} visible={isModalVisible} footer={null} onCancel={this.handleCancel} width={900} bodyStyle={{ overflowY: 'scroll', height: 380 }}>
                            {modalMessage}
                        </Modal>
                        <Row>

                            <Col span={5}>
                              <div class="content_panel_heading" >Alert List
                                </div>
                            </Col>
                            {serverOptions.length !==0 &&
                            <>
                            <Col span={2}   style={{marginTop:'4px'}}>
                            <Text>Server: </Text>
                            </Col>
                            <Col span={12} style={{marginLeft:'-30px'}}>
                            
                            <Select placeholder="Select server" defaultValue={serverOptions[0].accessToken} onChange={this.handleServerChange} style={{ width: 160 }} bordered={false}>
                            {serverOptions.map((server) => (
                                                    <Option key={server.accessToken} value={server.accessToken}>{server.serverName}</Option>
                                                ))
                                }
                                </Select>
                            </Col>
                            </>}
                           
                        </Row>
                        <div class="divider-1"> <span></span></div>
                        {/* <Divider /> */}
                        {
                        isError ? (
                        <>
                        <Result
                            status={resultStatus}
                            title={resultTitle}
                            subTitle={resultStatus== '500'?resultSubTitle:''}
                            extra={resultStatus== '500'?<></>:  <Link to="/settings/server"><Button type="primary" style={{borderRadius:'7px'}}>Add Server</Button></Link>}
                        />
                        </>):(
                        <>    <Button htmlType="button" style={{ backgroundColor: '#efefef',margin: "0 8px" }}  onClick={this.handleReportDonwload}> Download Report</Button>

                                <SearchBar data={cloneAlertData} handler={this.handleSearch}></SearchBar>

                                <Table
                                  style={{marginTop:'10px'}}
                                
                                    columns={alertColumns}
                                    dataSource={alertData}
                                    rowKey={(record) => record.id}
                                
                                />
                           

                    </>)
                    }
                    </Spin>

                </div>
            </>
        );
    }
}

