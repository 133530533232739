
import axios from 'axios'

import authHeader from '../Auth/auth-header';

let axiosConfig = {
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    "Access-Control-Allow-Origin": "*",
  }
};
//let SCHEDULER= ' http://localhost:8084/';
let SCHEDULER=process.env.REACT_APP_API_URL+'api/scheduler/';

export async function getAllJobs() {
  try {
    const { data: res } = await axios.get(
      `${SCHEDULER}jobs`, {
        headers: authHeader()
    }
    )
  return res
  } catch (e) {
    return e
  }
};
export async function checkJobName(data) {
  try {
    const { data: res } = await axios.post(
      `${SCHEDULER}checkJobName?jobName=${data}`,
      null,
      {
        headers: authHeader()
    }
    )
  return res
  } catch (e) {
    return e
  }
};
export async function scheduleEmail(data) {
  try {
    const { data: data_res } = await axios.post(
      `${SCHEDULER}scheduleEmail`,
      data,
      {
        headers: authHeader()
    }
    )
   console.log(data_res);
    return data_res
  } catch (e) {  
    return e
  }
}
export async function scheduleEmailForEdit(data,orgId) {
  try {
    const { data: data_res } = await axios.post(
      `${SCHEDULER}edit-jobs?orgId=${orgId}`,
      data,
      {
        headers: authHeader()
    }
    )
   console.log(data_res);
    return data_res
  } catch (e) {  
    return e
  }
}
export async function rescheduleEmail(data) {
  try {
    const { data: data_res } = await axios.post(
      `${SCHEDULER}rescheduleEmail`,
      data,
      {
        headers: authHeader()
    }
    )
   console.log(data_res);
    return data_res
  } catch (e) {  
    return e
  }
}
export async function startJob(data) {
  try {
    const { data: data_res } = await axios.post(
      `${SCHEDULER}startJob?jobName=${data}`,
      null,
      {
        headers: authHeader()
    }
    )
   console.log(data_res);
    return data_res
  } catch (e) {  
    return e
  }
}

export async function resumeJob(data) {
  try {
    const { data: data_res } = await axios.post(
      `${SCHEDULER}resumeJob?jobName=${data}`,
      null,
      {
        headers: authHeader()
    }
    )
   console.log(data_res);
    return data_res
  } catch (e) {  
    return e
  }
}

export async function pauseJob(data) {
  try {
    const { data: data_res } = await axios.post(
      `${SCHEDULER}pauseJob?jobName=${data}`,
      null,
      {
        headers: authHeader()
    }
    )
   console.log(data_res);
    return data_res
  } catch (e) {  
    return e
  }
}

export async function stopJob(data) {
  try {
    const { data: data_res } = await axios.post(
      `${SCHEDULER}stopJob?jobName=${data}`,
      null,
      {
        headers: authHeader()
    }
    )
   console.log(data_res);
    return data_res
  } catch (e) {  
    return e
  }
}
export async function deleteJob(data,orgId) {
  try {
    const { data: data_res } = await axios.post(
      `${SCHEDULER}deleteJob?jobName=${data}&orgId=${orgId}`,null,
      {
        headers: authHeader()
    }
    )
   console.log(data_res);
    return data_res
  } catch (e) {  
    return e
  }
}
export async function getjobs(data) {
  try {
    const { data: res } = await axios.post(
      `${SCHEDULER}email-scheduler/getjobs?jobName=${data}`,
      null,
      {
        headers: authHeader()
    }
    )
  return res
  } catch (e) {
    return e
  }
};