import React, { Component } from "react";
import { Row, Col,Tag, Table, Typography,Divider, Card, Descriptions, Result, Space, Select, Tooltip, Modal, Form, Input, Button, DatePicker, Breadcrumb, Spin, notification } from 'antd';
import { Link} from 'react-router-dom';
import {
    SyncOutlined ,
    RollbackOutlined
} from '@ant-design/icons';
import {
    getJobDetails
} from '../../../service/mirth/MirthService'
import {
    rescheduleEmail
} from '../../../service/EmailScheduler/Scheduler'
import AuthService from '../../../service/Auth/auth-service'
import {SearchBar} from '../../../components'
// import './ChannelList.css';
import '../../../styles/custom-css/antd-custom.css';

export default class JobDetailsList extends Component {

    constructor(props) {
        super(props)

        this.state = {
            spin: true,
            modalTitle: '',
            isModalVisible: false,
            modalMessage: '',
            jobDetailsData:[],
            cloneJobDetailsData: []
        }

        this.handleModalView = this.handleModalView.bind(this);
        this.handleOperationClick = this.handleOperationClick.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
       
    }

    componentDidMount() {
        let user = AuthService.getCurrentUser();
        getJobDetails(user.orgId).then(jobDetails =>{
            if (jobDetails.code == 200) {
                this.setState({jobDetailsData:jobDetails.result,cloneJobDetailsData:jobDetails.result,spin: false});
            }
        })
   
    }
    handleSearch(data)
    {
        this.setState({ jobDetailsData: data });
    }

    handleCancel() {
        this.setState({ isModalVisible: false })
    }

    handleModalView(data, title) {

        this.setState({ modalMessage: data, modalTitle: title, isModalVisible: true })

    }
    handleOperationClick(record,op)
    {
        let user = AuthService.getCurrentUser();
        if(op=='restore')
        {
            this.setState({spin:true})
            var details = JSON.parse( record.details);
            var a = details.email;
            details.email =a.replace(/\[|\]/g,'').split(',');
            rescheduleEmail(details).then(response => {
                if (response.statusCode == 200) {
                    getJobDetails(user.orgId).then(jobDetails =>{
                        if (jobDetails.code == 200) {
                            notification.success({
                                message: 'Job rescheduled successfully!',
                        
                            });
                            this.setState({jobDetailsData:jobDetails.result,cloneJobDetailsData:jobDetails.result,spin: false});
                        }
                    })

                }
            })
        }
    }

    render() {


        const { spin, modalTitle, modalMessage, isModalVisible ,jobDetailsData,cloneJobDetailsData} = this.state;
       
        const jobDetailsColumns = [
            { title: 'Job Name', dataIndex: 'jobName', key: 'jobName' },
            { title: 'Error Type', dataIndex: 'context', key: 'context' },
            
            { title: 'Operations', dataIndex: '', key: '',
            render: (text, record) => (
                <>
                    <Space size="middle">
                    <Tooltip title="Restore">
                        <a 
                            onClick={() => { this.handleOperationClick(record, 'restore') }}><SyncOutlined /></a>
                    </Tooltip>
                    </Space>
                </>)
        
        },
        ];
        return (
            <>
                <Breadcrumb>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Mirth Monitoring</Breadcrumb.Item>
                    <Breadcrumb.Item>Deleted Jobs</Breadcrumb.Item>
                </Breadcrumb>

                <div className="content_panel">

                    <Spin size="large" spinning={spin}>
                   
                        <Modal title={modalTitle} visible={isModalVisible} footer={null} onCancel={this.handleCancel} width={900} bodyStyle={{ overflowY: 'scroll', height: 380 }}>
                            {modalMessage}
                        </Modal>
                        <Row>

                            <Col span={12}>
                              <div class="content_panel_heading" >Deleted Jobs
                                </div>
                            </Col>
                            <Col span={12}>
                            <Link to="/mirth/email-scheduler"><Button style={{ float: 'right' ,borderRadius:'7px',backgroundColor:'#efefef',fontFamily:'AktivGroteskMedium',border:'none'}} >
                                            <RollbackOutlined style={{color:'#fc9558',fontSize:'16px'}} /> Back
                                </Button></Link>
                            </Col>

                      
                           
                        </Row>
                    
                        <div class="divider-1"> <span></span></div>
                    
                                <SearchBar data={cloneJobDetailsData} handler={this.handleSearch}></SearchBar>
                                <Table
                                  style={{marginTop:'10px'}}
                                    columns={jobDetailsColumns}
                                    dataSource={jobDetailsData}
                                
                                />
                    </Spin>

                </div>
            </>
        );
    }
}

