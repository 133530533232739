import React, { useEffect } from 'react';
import { Layout, Menu, Breadcrumb,Select, Typography,Dropdown, Modal,Space,Button ,Tooltip} from 'antd';
import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
  BankOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import './sideBar.css';
import '../../styles/custom-css/antd-custom.css';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import Routes from '../../router/Route';
import adminLogo from '../../assets/images/admin.svg';
import alertsLogo from '../../assets/images/alerts.svg';
import alertListLogo from '../../assets/images/alert-list.svg';
import settingsLogo from '../../assets/images/settings.svg';
import subscritionLogo from '../../assets/images/subscriptions.svg';
import userLogo from '../../assets/images/user.svg';
import viewLogo from '../../assets/images/view.svg';
import orgLogo from '../../assets/images/organization.svg';
import notificationIcon from '../../assets/images/notification-icon.svg';
import mirthServersLogo from '../../assets/images/mirth-servers.svg';
import mcMonitoringLogo from '../../assets/images/MC-monitoring.svg';
import logLogo from '../../assets/images/log.svg';
import ccIcon from '../../assets/images/CC-icon.svg';
import channelListLogo from '../../assets/images/channel-list.svg';
import channelStatisticsLogo from '../../assets/images/channel-statistics.svg';
import channelTrendsLogo from '../../assets/images/channel-trends.svg';
import controlCenterLogo from '../../assets/images/control-center-logo.svg';
import dashboardLogo from '../../assets/images/dashboard.svg';
import emailSchedulerLogo from '../../assets/images/email-scheduler.svg';
//import Control_Center_logo from '../../assets/images/Control_Center_logo.png';
import Control_Center_logo from '../../assets/images/CC_logo.png';
import Control_Center_symbol from '../../assets/images/Control_Center_symbol.png';
import { DatabaseOutlined } from "@ant-design/icons";
import AuthService from "../../service/Auth/auth-service";
import {getOrgById,getAllOrg,getServerByOrgId} from "../../service/mirth/MirthService";
import monitoringLogo from '../../assets/images/icon-monitoring.svg';
import dipipelineLogo from '../../assets/images/Data_ingestion.svg';
import kafkaMonitoring from '../../assets/images/kafka_24.svg';
import { configurationData } from '../../service/ConfigService/Config';
import vulnerabilityIcon from '../../assets/images/vulnerabilityIcon.png';
import reportIcon from '../../assets/images/report.png';


const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const { Title ,Text} = Typography;
const { Option } = Select;


export default class SiderDemo extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      openKeys:[(this.props.location.pathname.toString().split("/"))[1]],
      currentUser:'',
      currentOrg:'',
      currentServer:'',
      access:'',
      isModalVisible: false,
      orgList:[],
      page:'sideBar',
      url:''
    }

    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOrgChange = this.handleOrgChange.bind(this);
}
 

  onCollapse = collapsed => {

    if (document.getElementsByClassName('ant-layout-sider-trigger')) {
      var element = document.getElementsByClassName('ant-layout-sider-trigger');
      if (collapsed) {
        element[0].classList.remove("menuClose");
        element[0].classList.add("menuOpen");
      }
      else {
        element[0].classList.remove("menuOpen");
        element[0].classList.add("menuClose");
      }
    }
    this.setState({ collapsed });
  };

  componentDidMount() {
    configurationData(this.state.page).then((res) =>{
      if(res.code==200){
        let jsonData=JSON.parse(res.result[0].config);
        this.setState({url:jsonData["sideBar-url"]})
       
        console.log(jsonData)
        
      
      }
      
    })

    console.log((this.props.location.pathname.toString().split("/"))[2]);
    // Set Default collapse Unfolded
    const user = AuthService.getCurrentUser();
    const roles = user.roles;
    let access ='';
    
    if (user) {
      if(roles.includes("ROLE_PROD_ADMIN"))
      {
        access="admin";
        getAllOrg().then((res)=>{
          let orgs = res.result;
          user.orgId = res.result[0].orgId;
          AuthService.setCurrentUser(user);
          let updatedUser = AuthService.getCurrentUser();
          getServerByOrgId(updatedUser.orgId).then(ServerRes => {
            if (ServerRes.code == 200) {
                let server = [];
                server = ServerRes.result;

                if (server.length > 0) {
                  this.setState({
                    currentUser: updatedUser,currentOrg:orgs[0].orgName,access:access,orgList:orgs, currentServer:server[0].accessToken
                  });
                }
                else {
                  this.setState({
                    currentUser: updatedUser,currentOrg:orgs[0].orgName,access:access,orgList:orgs, currentServer:null
                  });
                }
            }
        })
         
        })
      }
      else
      {
        getOrgById(user.orgId).then((res)=>{
          if(res.code ==  200)
          {
            let result = res.result[0];
            // this.setState({
            //   currentUser: user,currentOrg:result.orgName,access:access
            // });
            getServerByOrgId(user.orgId).then(ServerRes => {
              if (ServerRes.code == 200) {
                  let server = [];
                  server = ServerRes.result;
  
                  if (server.length > 0) {
                    this.setState({
                      currentUser: user,currentOrg:result.orgName,access:access, currentServer:server[0].accessToken
                    });
                  }
                  else {
                    this.setState({
                      currentUser: user,currentOrg:result.orgName,access:access, currentServer:null
                    });
                  }
              }
          })
          }
        })
      }
    
     
    }
  
    this.setCollapseUnfold()
  }

  setCollapseUnfold() {

    if (document.getElementsByClassName('ant-layout-sider-trigger')) {

      var element = document.getElementsByClassName('ant-layout-sider-trigger');

      element[0].classList.add("menuClose");
    }

  }

  logout()
  {
    AuthService.logout();

  }
  handleMenuClick(){
    this.setState({ isModalVisible: true })
  }
  
  handleCancel() {
    this.setState({ isModalVisible: false })
  }
  handleOrgChange(value) {

    let currentUser = this.state.currentUser;
    currentUser.orgId = value;
    AuthService.setCurrentUser(currentUser);
    let updatedUser = AuthService.getCurrentUser();

    this.state.orgList.map((org)=>{
      if(org.orgId == value)
      {
        console.log("in org list")
      //   this.setState({ isModalVisible: false,currentUser:updatedUser ,currentOrg:org.orgName});
      //  // return <Redirect to='/mirth/server-configuration'  />
      //   this.props.history.push("/mirth/server-dashboard");
        getServerByOrgId(updatedUser.orgId).then(ServerRes => {
          if (ServerRes.code == 200) {
              let server = [];
              server = ServerRes.result;

              if (server.length > 0) {
                this.setState({
                  currentUser: updatedUser,currentOrg:org.orgName,currentServer:server[0].accessToken,isModalVisible: false
                });
                this.props.history.push("/mirth/server-logs");
                this.props.history.push("/mirth/server-dashboard");
              }
              else {
                this.setState({
                  currentUser: updatedUser,currentOrg:org.orgName,isModalVisible: false, currentServer:null
                });
                this.props.history.push("/mirth/server-logs");
                this.props.history.push("/mirth/server-dashboard");
              }
          }
      })
      }
    })
   
  }
  render() {
    const { collapsed,openKeys,currentUser ,currentOrg,access,isModalVisible,orgList,currentServer,url} = this.state;
    const rootSubmenuKeys = ['mirth','settings','admin','alerts'];
    const onOpenChange = keys => {

     
          getServerByOrgId(currentUser.orgId).then(ServerRes => {
            if (ServerRes.code == 200) {
                let server = [];
                server = ServerRes.result;
  
                if (server.length > 0) {
                  this.setState({
                    currentServer:server[0].accessToken
                  });
                }
                else {
                  this.setState({
                    currentServer:null
                  });
                }
            }
        })
      
      const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
      if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.setState({openKeys:keys})
      
      } else {
        this.setState({openKeys:latestOpenKey ? [latestOpenKey] : []})
     
      }
    };
    const menu = (
      <Menu onClick={this.handleMenuClick} >
        <Menu.Item>
         Switch Org
        </Menu.Item>
      </Menu>
    );
    const profileMenu = (
      <Menu onClick={() => this.props.history.push("/settings/user-profile")}>
        <Menu.Item>
         View Profile
        </Menu.Item>
      </Menu>
    );
    return (
      <Layout style={{ minHeight: '100vh' }}>
       <Modal title="Switch Organization" visible={isModalVisible} footer={null} onCancel={this.handleCancel} width={400} >
       <Select
        style={{width:'300px'}}
        showSearch
        placeholder="Select Organization"
        optionFilterProp="children"
        onChange={this.handleOrgChange}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {orgList.map((org) => (
          <Option key={org.orgId} value={org.orgId}>{org.orgName}</Option>
                              ))}
      </Select>
        </Modal>
        <Sider theme="light" collapsible collapsed={collapsed} onCollapse={this.onCollapse} 
          style={{
          
            maxHeight: '100vh',
            overflowY:'scroll',
            position: 'fixed',
            left: 0
          }}
          className="main-sidebar">
          <div className="logo" >
            {collapsed ? (<div><img src={Control_Center_symbol} alt="Control Connect logo" class="mdapLogo2" /></div>) : (
              <div >
                <img class="mdapLogo1" src={Control_Center_logo} alt="Control Connect logo" />
                {/* <span style={{ marginLeft: -10 }}>MIGRATION TOOL</span> */}
              </div>
            )}
          </div>

          <Menu mode="inline" openKeys={openKeys} onOpenChange={onOpenChange} style={{ marginTop: 25 }} selectedKeys={[(this.props.location.pathname.toString().split("/"))[2]]} defaultOpenKeys={[(this.props.location.pathname.toString().split("/"))[1]]} >

            <SubMenu key="mirth" icon={<img src={mcMonitoringLogo} style={{ marginLeft: 9, marginRight: -21,width:'23px',height:'23px' }}></img>} title="Mirth Monitoring">
            <Menu.Item key="server-dashboard" icon={<img src={dashboardLogo} style={{ marginLeft: 9, marginRight: -21,width:'18px',height:'17px' }}></img>} class="font-face-gm"> <Link to="/mirth/server-dashboard" />Server DashBoard</Menu.Item>
              <Menu.Item key="server-log" icon={<img src={logLogo} style={{ marginLeft: 9, marginRight: -21,width:'18px',height:'17px' }}></img>} class="font-face-gm"> <Link to={`/mirth/server-log/${currentServer}`} /> Server Log</Menu.Item>
              <Menu.Item key="channel-list" icon={<img src={channelListLogo} style={{ marginLeft: 9, marginRight: -21,width:'18px',height:'17px'}}></img>} class="font-face-gm"> <Link  to={`/mirth/channel-list/${currentServer}`} /> Channel List</Menu.Item>
              <Menu.Item key="channel-statistics" icon={<img src={channelStatisticsLogo} style={{ marginLeft: 9, marginRight: -21,width:'18px',height:'17px' }}></img>} class="font-face-gm"> <Link to={`/mirth/channel-statistics/${currentServer}`} /> Channel Statistics</Menu.Item>
              <Menu.Item key="channel-graph" icon={<img src={channelTrendsLogo} style={{ marginLeft: 9, marginRight: -21,width:'18px',height:'17px' }}></img>} class="font-face-gm"> <Link to={`/mirth/channel-graph/${currentServer}`} /> Channel Overview</Menu.Item>
              <Menu.Item key="channel-diff-graph" icon={<img src={channelTrendsLogo} style={{ marginLeft: 9, marginRight: -21,width:'18px',height:'17px' }}></img>} class="font-face-gm"> <Link to={`/mirth/channel-diff-graph/${currentServer}`} /> Channel Trends</Menu.Item>
              <Menu.Item key="email-scheduler" icon={<img src={emailSchedulerLogo} style={{ marginLeft: 9, marginRight: -21,width:'18px',height:'17px'}}></img>} class="font-face-gm"> <Link to="/mirth/email-scheduler" /> Email Scheduler</Menu.Item>
              <Menu.Item key="report-generator" icon={<img src={reportIcon} style={{ marginLeft: 9, marginRight: -21,width:'22px',height:'20px'}}></img>} class="font-face-gm"> <Link to="/mirth/report-generator" /> Report Generation</Menu.Item>

              {/* <Menu.Item key="email-scheduler" icon={<img src={emailSchedulerLogo} style={{ marginLeft: 9, marginRight: -21,width:'18px',height:'17px'}}></img>} class="font-face-gm"> <Link to="/mirth/edit-form" /> Edit Form</Menu.Item> */}
              {/* <Menu.Item key="/mirth/message" class="font-face-gm"> <Link to="/mirth/message" /> MessageView</Menu.Item> */}
            </SubMenu>
            <SubMenu key="alerts" icon={<img src={alertsLogo} style={{ marginLeft: 9, marginRight: -21,width:'23px',height:'23px' }}></img>} title="Alerts">
              <Menu.Item key="list" icon={<img src={alertListLogo} style={{ marginLeft: 9, marginRight: -21,width:'18px',height:'17px'}}></img>} class="font-face-gm"> <Link to="/email-alerts/list" /> Email Alerts</Menu.Item>
            </SubMenu>
            { access =='admin' && <SubMenu key="admin" icon={<img src={adminLogo} style={{ marginLeft: 9, marginRight: -21 ,width:'23px',height:'23px'}}></img>} title="Admin">
              <Menu.Item key="mirth-servers" icon={<img src={mirthServersLogo} style={{ marginLeft: 9, marginRight: -21,width:'18px',height:'17px'}}></img>} class="font-face-gm"> <Link to="/admin/mirth-servers" /> Mirth Servers</Menu.Item>
              <Menu.Item key="organization" icon={<img src={orgLogo} style={{ marginLeft: 9, marginRight: -21,width:'18px',height:'17px'}}></img>} class="font-face-gm"> <Link to="/admin/organization" /> Organization</Menu.Item>
              <Menu.Item key="subscription" icon={<img src={subscritionLogo} style={{ marginLeft: 9, marginRight: -21,width:'18px',height:'17px'}}></img>} class="font-face-gm"> <Link to="/admin/subscription" /> Subscription</Menu.Item>
              <Menu.Item key="session-monitoring" icon={<img src={userLogo} style={{ marginLeft: 9, marginRight: -21,width:'18px',height:'17px'}}></img>} class="font-face-gm"> <Link to="/admin/session-monitoring" /> Session Monitoring</Menu.Item>
              <Menu.Item key="user-audit" icon={<img src={alertListLogo} style={{ marginLeft: 9, marginRight: -21,width:'18px',height:'17px'}}></img>} class="font-face-gm"> <Link to="/admin/user-audit" /> User Audit</Menu.Item>
              <Menu.Item key="admin-email-scheduler" icon={<img src={emailSchedulerLogo} style={{ marginLeft: 9, marginRight: -21,width:'18px',height:'17px'}}></img>} class="font-face-gm"> <Link to="/admin/admin-email-scheduler" /> Email Scheduler</Menu.Item>
              <Menu.Item key="mcVulnerabilities" icon={<img src={vulnerabilityIcon} style={{ marginLeft: 9, marginRight: -21,width:'23px',height:'20px'}}></img>} class="font-face-gm"> <Link to="/admin/mcVulnerabilities" /> MC Vulnerabilities</Menu.Item>
            </SubMenu>}
            <SubMenu key="settings" icon={<img src={settingsLogo} style={{ marginLeft: 9, marginRight: -21,width:'23px',height:'23px' }}></img>} title="Settings">
              <Menu.Item key="server" icon={<img src={mirthServersLogo} style={{ marginLeft: 9, marginRight: -21,width:'18px',height:'17px'}}></img>} class="font-face-gm"> <Link to="/settings/server" /> Server</Menu.Item>
              <Menu.Item key="user" icon={<img src={userLogo} style={{ marginLeft: 9, marginRight: -21,width:'18px',height:'17px'}}></img>} class="font-face-gm"> <Link to="/settings/user" /> User</Menu.Item>
            </SubMenu>
     
          </Menu>
        </Sider>

        <Layout className="site-layout" style={{ marginLeft: collapsed ? 80 : 200}}>

          <Header>

 
           
            <Title class ="header-title" style={{width:'350px'}}>
            <Space size="large" >
            <a href= {url}  target="_blank" download style={{color:'#ff7a38'}}><DownloadOutlined/> User Guide</a>
           {access == 'admin'?( <Dropdown overlay={menu} placement="bottomLeft">
            <Text class ="user-name" style={{cursor:'pointer'}}><img src={orgLogo} style={{ marginLeft: 9, marginRight: -21,width:'14px',height:'12px'}}></img> {currentOrg}</Text>
            </Dropdown>):(
            <Text class ="user-name" style={{cursor:'pointer'}}><img src={orgLogo} style={{ marginLeft: 9, marginRight: -21,width:'14px',height:'12px'}}></img>  {currentOrg}</Text>)}
           {access == 'admin'?(
            <Text class ="user-name" style={{cursor:'pointer'}}><img src={userLogo} style={{ marginLeft: 9, marginRight: -21,width:'14px',height:'13px'}}></img>  {currentUser.username}</Text>):( <Dropdown overlay={profileMenu} placement="bottomLeft">
            <Text class ="user-name" style={{cursor:'pointer'}}><img src={userLogo} style={{ marginLeft: 9, marginRight: -21,width:'14px',height:'13px'}}></img> {currentUser.username}</Text>
            </Dropdown>)}
            {/* <Text class ="user-name"><UserOutlined />  {currentUser.username}</Text> */}
            <a href={process.env.REACT_APP_AUTH_SERVER_URL+'auth-server/logout'}>
            {/* <Link to="/login"> */}
            <Tooltip title="Logout">
            <Text class ="user-name" onClick={this.logout}  style={{color:'#828282'}}> 
            <LogoutOutlined /></Text> 
            </Tooltip>
            {/* </Link> */}
            </a>
           </Space>
            </Title>
          </Header>
       <Content style={{ margin: '10px 55px 0px 40px', overflow: 'initial',  minHeight: 280 }}>

            <Routes />

          </Content>
          <Footer style={{ textAlign:'right',color:'#505050'}}>2024 © Aigilx Health. All Rights Reserved.</Footer>
        </Layout>
      </Layout>
    );
  }
}
