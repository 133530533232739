import React, { Component } from "react";
import { Row, Col, Table, Typography, Card, Descriptions, Tag, Space, Select, Tooltip, Modal, Form, Input, Button, DatePicker, Breadcrumb, Spin } from 'antd';
import {
    EyeOutlined
} from '@ant-design/icons';
import { createChannel} from '../../../service/mirth/ClientAPIService'
import './ChannelCreate.css';

const { Option } = Select;
const { Text } = Typography;
const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

function uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
        return v.toString(16);
    });
}
function OBJtoXML(obj) {
    var xml = '';
    for (var prop in obj) {
      xml += obj[prop] instanceof Array ? '' : "<" + prop + ">";
      if (obj[prop] instanceof Array) {
        for (var array in obj[prop]) {
          xml += "<" + prop + ">";
          xml += OBJtoXML(new Object(obj[prop][array]));
          xml += "</" + prop + ">";
        }
      } else if (typeof obj[prop] == "object") {
        xml += OBJtoXML(new Object(obj[prop]));
      } else {
        xml += obj[prop];
      }
      xml += obj[prop] instanceof Array ? '' : "</" + prop + ">";
    }
    var xml = xml.replace(/<\/?[0-9]{1,}>/g, '');
    return xml
  }

export default class ChannelCreate extends Component {

    constructor(props) {
        super(props)

        this.state = {

            spin: false,
            serverOptions: ['localhost'],
            currentServer: '',
            channelSchema:{
                "channel": {
                   "id": "1f337a7c-fd30-40df-94b3-02091062a9h6",
                   "nextMetaDataId": "2",
                   "name": "Channel 11",
                   "description": "Example description.",
                   "revision": "0",
                   "sourceConnector": {
                      "metaDataId": "0",
                      "name": "sourceConnector",
                      "properties": {
                         "sourceConnectorProperties": {
                            "responseVariable": "None",
                            "respondAfterProcessing": "true",
                            "processBatch": "false",
                            "firstResponse": "false",
                            "processingThreads": "1",
                            "resourceIds": {
                               "entry": {
                                  "string": [
                                     "Default Resource",
                                     "[Default Resource]"
                                  ]
                               },
                               "_class": "linked-hash-map"
                            },
                            "queueBufferSize": "0",
                            "_version": "3.11.0"
                         },
                         "_class": "com.mirth.connect.connectors.vm.VmReceiverProperties",
                         "_version": "3.11.0"
                      },
                      "transformer": {
                         "elements": "",
                         "inboundTemplate": {
                            "_encoding": "base64"
                         },
                         "outboundTemplate": {
                            "_encoding": "base64"
                         },
                         "inboundDataType": "RAW",
                         "outboundDataType": "RAW",
                         "inboundProperties": {
                            "batchProperties": {
                               "splitType": "JavaScript",
                               "batchScript": "",
                               "_class": "com.mirth.connect.plugins.datatypes.raw.RawBatchProperties",
                               "_version": "3.11.0"
                            },
                            "_class": "com.mirth.connect.plugins.datatypes.raw.RawDataTypeProperties",
                            "_version": "3.11.0"
                         },
                         "outboundProperties": {
                            "batchProperties": {
                               "splitType": "JavaScript",
                               "batchScript": "",
                               "_class": "com.mirth.connect.plugins.datatypes.raw.RawBatchProperties",
                               "_version": "3.11.0"
                            },
                            "_class": "com.mirth.connect.plugins.datatypes.raw.RawDataTypeProperties",
                            "_version": "3.11.0"
                         },
                         "_version": "3.11.0"
                      },
                      "filter": {
                         "elements": "",
                         "_version": "3.11.0"
                      },
                      "transportName": "Channel Reader",
                      "mode": "SOURCE",
                      "enabled": "true",
                      "waitForPrevious": "true",
                      "_version": "3.11.0"
                   },
                   "destinationConnectors": {
                      "connector": {
                         "metaDataId": "1",
                         "name": "Destination 1",
                         "properties": {
                            "destinationConnectorProperties": {
                               "queueEnabled": "false",
                               "sendFirst": "false",
                               "retryIntervalMillis": "10000",
                               "regenerateTemplate": "false",
                               "retryCount": "0",
                               "rotate": "false",
                               "includeFilterTransformer": "false",
                               "threadCount": "1",
                               "threadAssignmentVariable": "",
                               "validateResponse": "false",
                               "resourceIds": {
                                  "entry": {
                                     "string": [
                                        "Default Resource",
                                        "[Default Resource]"
                                     ]
                                  },
                                  "_class": "linked-hash-map"
                               },
                               "queueBufferSize": "0",
                               "reattachAttachments": "true",
                               "_version": "3.11.0"
                            },
                            "channelId": "none",
                            "channelTemplate": "${message.encodedData}",
                            "mapVariables": "",
                            "_class": "com.mirth.connect.connectors.vm.VmDispatcherProperties",
                            "_version": "3.11.0"
                         },
                         "transformer": {
                            "elements": "",
                            "inboundTemplate": {
                               "_encoding": "base64"
                            },
                            "outboundTemplate": {
                               "_encoding": "base64"
                            },
                            "inboundDataType": "RAW",
                            "outboundDataType": "RAW",
                            "inboundProperties": {
                               "batchProperties": {
                                  "splitType": "JavaScript",
                                  "batchScript": "",
                                  "_class": "com.mirth.connect.plugins.datatypes.raw.RawBatchProperties",
                                  "_version": "3.11.0"
                               },
                               "_class": "com.mirth.connect.plugins.datatypes.raw.RawDataTypeProperties",
                               "_version": "3.11.0"
                            },
                            "outboundProperties": {
                               "batchProperties": {
                                  "splitType": "JavaScript",
                                  "batchScript": "",
                                  "_class": "com.mirth.connect.plugins.datatypes.raw.RawBatchProperties",
                                  "_version": "3.11.0"
                               },
                               "_class": "com.mirth.connect.plugins.datatypes.raw.RawDataTypeProperties",
                               "_version": "3.11.0"
                            },
                            "_version": "3.11.0"
                         },
                         "responseTransformer": {
                            "elements": "",
                            "inboundTemplate": {
                               "_encoding": "base64"
                            },
                            "outboundTemplate": {
                               "_encoding": "base64"
                            },
                            "inboundDataType": "RAW",
                            "outboundDataType": "RAW",
                            "inboundProperties": {
                               "batchProperties": {
                                  "splitType": "JavaScript",
                                  "batchScript": "",
                                  "_class": "com.mirth.connect.plugins.datatypes.raw.RawBatchProperties",
                                  "_version": "3.11.0"
                               },
                               "_class": "com.mirth.connect.plugins.datatypes.raw.RawDataTypeProperties",
                               "_version": "3.11.0"
                            },
                            "outboundProperties": {
                               "batchProperties": {
                                  "splitType": "JavaScript",
                                  "batchScript": "",
                                  "_class": "com.mirth.connect.plugins.datatypes.raw.RawBatchProperties",
                                  "_version": "3.11.0"
                               },
                               "_class": "com.mirth.connect.plugins.datatypes.raw.RawDataTypeProperties",
                               "_version": "3.11.0"
                            },
                            "_version": "3.11.0"
                         },
                         "filter": {
                            "elements": "",
                            "_version": "3.11.0"
                         },
                         "transportName": "Channel Writer",
                         "mode": "DESTINATION",
                         "enabled": "true",
                         "waitForPrevious": "true",
                         "_version": "3.11.0"
                      }
                   },
                   "preprocessingScript": "",
                   "postprocessingScript": "",
                   "deployScript": "",
                   "undeployScript": "",
                   "properties": {
                      "clearGlobalChannelMap": "true",
                      "messageStorageMode": "DEVELOPMENT",
                      "encryptData": "false",
                      "removeContentOnCompletion": "false",
                      "removeOnlyFilteredOnCompletion": "false",
                      "removeAttachmentsOnCompletion": "false",
                      "initialState": "STARTED",
                      "storeAttachments": "true",
                      "metaDataColumns": "",
                      "attachmentProperties": {
                         "type": "None",
                         "_version": "3.11.0"
                      },
                      "resourceIds": {
                         "entry": {
                            "string": [
                               "Default Resource",
                               "[Default Resource]"
                            ]
                         },
                         "_class": "linked-hash-map"
                      },
                      "_version": "3.11.0"
                   },
                   "_version": "3.11.0"
                }
             }
        }

       
        this.onFinish = this.onFinish.bind(this);
        this.onReset = this.onReset.bind(this);
        this.handleServerChange = this.handleServerChange.bind(this);
      
    }
    formRef = React.createRef();
    componentDidMount() {
        console.log(uuid());
        let host = 'localhost:8443';
        let xmlchannel = OBJtoXML(this.state.channelSchema);
    //    console.log(OBJtoXML(this.state.channelSchema))
        createChannel(host,'admin','admin',xmlchannel).then(res=>{
            console.log(res)
        })
    }
    handleServerChange(val) {
        let host = val + ':8443';
       
    }
 
    onFinish(val) {
       console.log(val)
    }

    onReset() {
        this.formRef.current.resetFields();
    }


    render() {


        const { spin, serverOptions } = this.state;


        return (
            <>
                <Breadcrumb>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Mirth Monitoring</Breadcrumb.Item>
                    <Breadcrumb.Item><a href="" style={{ color: '#1890ff' }}> Create Channel</a></Breadcrumb.Item>
                </Breadcrumb>

                <div className="channel_statistics_panel">

                    <Spin size="large" spinning={spin}>
                       
                        <Row>

                            <Col span={6}>
                                <div class="channel_statistics_heading" style={{ fontSize: "24px", transform: "translateX(-20px)", marginLeft: '20px' }}>Create Channel
                                </div>
                            </Col>

                        </Row>
                      
                            <Form {...layout} layout ="horizontal" ref={this.formRef} name="create-channel" onFinish={this.onFinish}>
                                
                                <Form.Item
                                    name="server"
                                    label="Server"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                   <Select placeholder="Select server" onChange={this.handleServerChange} style={{ width: 160 }} bordered={false}>
                                    {serverOptions.map((server) => (
                                        <Option key={server} value={server}>{server}</Option>
                                    ))

                                    }
                                </Select>
                                </Form.Item>
                                <Form.Item
                                    name="name"
                                    label="Name"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name="description"
                                    label="Channel Description"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item {...tailLayout}>
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                    <Button htmlType="button" onClick={this.onReset}>
                                        Reset
                                     </Button>
                                   
                                </Form.Item>
                            </Form>
                        



                    </Spin>

                </div>
            </>
        );
    }
}

