import React, { Component } from "react";
import { Row, Col, Table, Typography,Popover,Badge,Descriptions, Card, Result,PageHeader, Divider,Collapse, Tag, Space, Select, Tooltip, Modal, Form, Input, Button, DatePicker, Breadcrumb, Spin } from 'antd';
import {
    EyeOutlined,
    ArrowUpOutlined ,
    ArrowDownOutlined ,
    BellFilled
} from '@ant-design/icons';
import { Gauge, Liquid } from '@ant-design/charts';
import { Pie } from '@ant-design/plots';
import {
    getServerList,
    getData,
    getDataJSON,
    getMirthServerDetails,
    getServerByOrgId,
    getOrgById,
    getSubscribedChannels
} from '../../../service/mirth/MirthService';
import AuthService from '../../../service/Auth/auth-service'
import './Dashboard.css';
import { SearchBar } from '../../../components'
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { getSystemStat, getSystemInfo, getServerConfigurations, getServerId, getServerJvm } from '../../../service/mirth/ClientAPIService'
import '../../../styles/custom-css/antd-custom.css';
import { properties } from '../../../properties';

const { Panel } = Collapse;
const { Option } = Select;
const { Text } = Typography;

export default class SingleServerDashboard extends Component {

    constructor(props) {
        super(props)

        this.state = {

            spin: false,
            serverOptions: [],
            currentServer: '',
            diskspaceusage: 0.00,
            memoryspaceusage: 0.00,
            cpuUsage: 0.00,
            systemInfo: {},
            serverConfiguration: {},
            serverId: '',
            serverJvm: '',
            isError: false,
            resultStatus: '500',
            resultTitle: '500',
            resultSubTitle: 'Server not reachable',
            dashboard:'',
            activeCount:0,
            inActiveCount:0,
            serverData:[],
            channelData:[],
            cloneChannelData:[],
            pieData:[],
            errorCount:0, 
            sentCount :0,
            receivedCount:0,
             filteredCount:0,
             queuedCount:0,
             serverConfiguration:{},
             systemInfo:{}
        }

        this.handleSearch = this.handleSearch.bind(this);
        this.loadData = this.loadData.bind(this);
    }

     componentDidMount() {
        try {
            let token = this.props.match.params.accessToken;
            this.loadData(token)
           // setInterval(this.loadData, 30000);
          } catch(e) {
            console.log(e);
          }
         
      

    }
    handleSearch(data)
    {
        this.setState({ channelData: data });
    }
    loadData(token)
    {
        let user = AuthService.getCurrentUser();
        getServerByOrgId(user.orgId).then(ServerRes => {
            if (ServerRes.code == 200) {
                let updatedServer ={};
                let servers = [];
                servers = ServerRes.result;

                servers.map((server)=>{
                  
                    if(server.accessToken == token)
                    {
                        console.log("yes")
                        updatedServer = server;
                        getMirthServerDetails(token).then(serverRes => {
                            if (serverRes.code == 200  && serverRes.result.length != 0) {
                                let startTime = moment(serverRes.result[0].observationTime).tz("UTC");
                                updatedServer.lastActiveTime = startTime.fromNow();
                                var endTime = moment.tz("UTC");
                                let timeDiff = moment.duration(endTime.diff(startTime));
                
                                if (Math.floor(timeDiff.asHours()) == 0 && Math.floor(timeDiff.asDays()) == 0 && Math.floor(timeDiff.asMonths()) == 0 && Math.floor(timeDiff.asYears()) == 0) {
                                    if (Math.floor(timeDiff.asMinutes()) <= 2) {
                                        updatedServer.state = "ACTIVE";
                                       
                                    }
                                    else {
                                        updatedServer.state = "IN ACTIVE";
                                       
                                    }
                                }
                                else {
                                    updatedServer.state = "IN ACTIVE";
                                   
                                }
                                let name=updatedServer.serverName;
                                let details = JSON.parse(serverRes.result[0].details);
                                let res ;
                                let statArr =[];
                                let channelsDataArr =[];
                                if(typeof(details.statistics) == "string")
                                {
                                     res = JSON.parse(details.mirthInfo);
                                     let statisticsRes =JSON.parse( details.statistics);
                              
                                     let statusres =JSON.parse( details.channelStatus);
                                     let statusvalues= statusres.list.dashboardStatus;
                             
                                     let statValues = statisticsRes.list.channelStatistics;
                                  
                                     if (statValues !== null && !Array.isArray(statValues)) {
                                         statArr.push(statValues);
                                         
                                       }
                                     else
                                     {
                                         statArr =statValues;
                                     }
                                     if (statusvalues !== null && !Array.isArray(statusvalues)) {
                                         channelsDataArr.push(statusvalues);
                                       }
                                     else
                                     {
                                         channelsDataArr =statusvalues;
                                     }
                                     let channelArr = [];
                                     let errorCount=0, sentCount =0,receivedCount=0, filteredCount=0,queuedCount=0;
                                     for (let i = 0; i < statArr.length; i++) {
                                         let dataObj = {};
                                         let channelData={};
                                         dataObj = statArr[i];
                                         
                                         
                                         for(let j=0; j < channelsDataArr.length; j++)
                                         {
                                            
                                              channelData = channelsDataArr[j];
                                             if (channelData.channelId == dataObj.channelId) {
                                                
                                                 dataObj.channelName = channelData.name;
                                                 dataObj.state = channelData.state;
                                                 channelArr.push(dataObj);
     
                                                 errorCount=errorCount+dataObj.error;
                                                 sentCount=sentCount+dataObj.sent;
                                                 receivedCount=receivedCount+dataObj.received;
                                                 filteredCount=filteredCount+dataObj.filtered;
                                                 queuedCount=queuedCount+dataObj.queued;
                                             }
                     
                                         }
                                             
                                     }
                                           
                                let val = res.systemStat;
                                let useddisk = 0.00;
                                useddisk = (val.diskTotalBytes - val.diskFreeBytes) / val.diskTotalBytes;
                                let usedmemory = (val.maxMemoryBytes - val.freeMemoryBytes) / val.maxMemoryBytes;
                                let cpuusage = Number(val.cpuUsagePct);
                                if (res.serverName == "" || res.serverName == null) {
                                    res.serverName = name;
                                }
                                updatedServer.diskspaceusage= useddisk;
                                updatedServer.memoryspaceusage= usedmemory;
                                updatedServer.cpuUsage=cpuusage
                                updatedServer.serverConfiguration= res;
                                updatedServer.channelCount= res.channelCount;
                                updatedServer.systemInfo= res.systemInfo;
                               
                    
                                this.setState({errorCount:errorCount,sentCount:sentCount,receivedCount:receivedCount,filteredCount:filteredCount,queuedCount:queuedCount, channelData:channelArr ,cloneChannelData:channelArr,serverData:updatedServer,serverConfiguration: updatedServer.serverConfiguration,systemInfo: updatedServer.systemInfo,spin: false})
                              
            
     
                                }
                                else
                                {
                                    res =details.mirthInfo;
                                    statArr = details.statistics;
                                    getSubscribedChannels(token).then(channelRes =>{
                                        if (channelRes.code == 200 && channelRes.result.length != 0) {
                                            let result = channelRes.result;
                                            console.log(result)
                                            var chArr =result.map(function(obj) {
                                                return {
                                                    name: obj.channel_name,
                                                    channelId: obj.channel_id,
                                                    state: obj.status
                                                }
                                            })
                                            console.log(chArr)
                                            channelsDataArr= chArr;
                                            let channelArr = [];
                                            let errorCount=0, sentCount =0,receivedCount=0, filteredCount=0,queuedCount=0;
                                            for (let i = 0; i < statArr.length; i++) {
                                                let dataObj = {};
                                                let channelData={};
                                                dataObj = statArr[i];
                                                
                                                
                                                for(let j=0; j < channelsDataArr.length; j++)
                                                {
                                                   
                                                     channelData = channelsDataArr[j];
                                                    if (channelData.channelId == dataObj.channelId) {
                                                       
                                                        dataObj.channelName = channelData.name;
                                                        dataObj.state = channelData.state;
                                                        channelArr.push(dataObj);
            
                                                        errorCount=errorCount+dataObj.error;
                                                        sentCount=sentCount+dataObj.sent;
                                                        receivedCount=receivedCount+dataObj.received;
                                                        filteredCount=filteredCount+dataObj.filtered;
                                                        queuedCount=queuedCount+dataObj.queued;
                                                    }
                            
                                                }
                                                    
                                            }
                                                  
                                let val = res.systemStat;
                                let useddisk = 0.00;
                                useddisk = (val.diskTotalBytes - val.diskFreeBytes) / val.diskTotalBytes;
                                let usedmemory = (val.maxMemoryBytes - val.freeMemoryBytes) / val.maxMemoryBytes;
                                let cpuusage = Number(val.cpuUsagePct);
                                if (res.serverName == "" || res.serverName == null) {
                                    res.serverName = name;
                                }
                                updatedServer.diskspaceusage= useddisk;
                                updatedServer.memoryspaceusage= usedmemory;
                                updatedServer.cpuUsage=cpuusage
                                updatedServer.serverConfiguration= res;
                                updatedServer.channelCount= res.channelCount;
                                updatedServer.systemInfo= res.systemInfo;
                               
                    
                                this.setState({errorCount:errorCount,sentCount:sentCount,receivedCount:receivedCount,filteredCount:filteredCount,queuedCount:queuedCount, channelData:channelArr ,cloneChannelData:channelArr,serverData:updatedServer,serverConfiguration: updatedServer.serverConfiguration,systemInfo: updatedServer.systemInfo,spin: false})
                              
            
            
                                        }
                                        else
                                        {
                                            channelsDataArr= details.channels;
                                        }
                                    })
                                    
                                  //  channelsDataArr= details.channels;

                                }
                                         }
                            else
                            {
                                this.setState({ resultStatus: 'warning', resultTitle: 'Add a server to check statistics', isError: true, spin: false })
                            }
                
                        })
                    }
                })
            }
            else
            {
                this.setState({ resultStatus: 'warning', resultTitle: 'Add a server to check statistics', isError: true, spin: false })
           }
        });
     
        // getMirthServerDetails(token).then(serverRes => {
        //     if (serverRes.code == 200  && serverRes.result.length != 0) {
        //         let startTime = moment(serverRes.result[0].observationTime).tz("UTC");
        //         updatedServer.lastActiveTime = startTime.fromNow();
        //         var endTime = moment.tz("UTC");
        //         let timeDiff = moment.duration(endTime.diff(startTime));

        //         if (Math.floor(timeDiff.asHours()) == 0 && Math.floor(timeDiff.asDays()) == 0 && Math.floor(timeDiff.asMonths()) == 0 && Math.floor(timeDiff.asYears()) == 0) {
        //             if (Math.floor(timeDiff.asMinutes()) <= 2) {
        //                 updatedServer.state = "ACTIVE";
                       
        //             }
        //             else {
        //                 updatedServer.state = "IN ACTIVE";
                       
        //             }
        //         }
        //         else {
        //             updatedServer.state = "IN ACTIVE";
                   
        //         }
        //         let name=updatedServer.serverName;
        //         let details = JSON.parse(serverRes.result[0].details);
        //         let res = JSON.parse(details.mirthInfo);
        //         let val = res.systemStat;
        //         let useddisk = 0.00;
        //         useddisk = (val.diskTotalBytes - val.diskFreeBytes) / val.diskTotalBytes;
        //         let usedmemory = (val.maxMemoryBytes - val.freeMemoryBytes) / val.maxMemoryBytes;
        //         let cpuusage = Number(val.cpuUsagePct);
        //         if (res.serverName == "" || res.serverName == null) {
        //             res.serverName = name;
        //         }
        //         updatedServer.diskspaceusage= useddisk;
        //         updatedServer.memoryspaceusage= usedmemory;
        //         updatedServer.cpuUsage=cpuusage
        //         updatedServer.serverConfiguration= res;
        //         updatedServer.systemInfo= res.systemInfo;
               
        //         console.log(updatedServer)
        //         console.log(details)
        //         let channelres =JSON.parse( details.channelStatus);
        //         let chvalues= channelres.list.dashboardStatus;
        //         let finalArray =[];
        //         if (chvalues !== null && !Array.isArray(chvalues)) {
        //             finalArray.push(chvalues);
        //             this.setState({ spin: false,channelData:finalArray ,cloneChannelData:finalArray,serverData:updatedServer})
        //           }
        //         else
        //         {
                  
        //             finalArray =chvalues;
        //             this.setState({ spin: false,channelData:finalArray ,cloneChannelData:finalArray ,serverData:updatedServer})
        //         }
        //      //   this.setState({serverData:updatedServer,spin: false});
        //     }
        //     else
        //     {
        //         this.setState({ resultStatus: 'warning', resultTitle: 'Add a server to check statistics', isError: true, spin: false })
        //     }

        // })
        
    }
  
 
    
    render() {


        const { spin,channelData,cloneChannelData,errorCount,sentCount,receivedCount,queuedCount,filteredCount, serverOptions,activeCount,inActiveCount, diskspaceusage,dashboard,serverData, memoryspaceusage, resultStatus, resultTitle, resultSubTitle, cpuUsage, systemInfo, serverConfiguration, serverId, serverJvm, isError } = this.state;
       
        // const renderTag = (tag) => {

        //     if (tag == 'ACTIVE')
        //         return <Tag color="success">{tag}</Tag>;
        //     else
        //         return <Tag color="error">{tag}</Tag>;

        // }

        const renderDasboard=(server, header,diskGaugeConfig,memoryGaugeConfig,cpuGaugeConfig) =>{

       
            return (<Card title={header}>
           
         
            <Collapse defaultActiveKey={['1']} style={{ marginLeft: '20px', marginTop: '30px', width: '900px' }}>
                <Panel header="Usage" key="1">
               
                    <Row style={{marginBottom:'20px'}}>

                        <Col span={8}> <Card> <Gauge style={{ height: '200px' }} {...diskGaugeConfig} /></Card></Col>
                        <Col span={8}> <Gauge style={{ height: '200px' }} {...memoryGaugeConfig} /></Col>
                        <Col span={8}> <Gauge style={{ height: '200px' }} {...cpuGaugeConfig} /></Col>

                    </Row>
               
                </Panel>
                <Panel header="More Details" key="2">
                <Row>
                        <Col span={12}>
                            <Card className="server_configuration_card" style={{ marginLeft: '20px'}}>
                                <Row>
                                    <div class="server_configuration_heading" style={{ color: 'black', fontSize: "20px", transform: "translateX(-20px)", marginLeft: '20px',marginBottom: '20px' }}>System Info
                            </div>
                                </Row>
                                <Row>
                                    <Col span={12}><p style={{ color: 'grey' }}>JVM Version</p></Col>
                                    <Col span={12}>{server.systemInfo.jvm}</Col>
                                </Row>
                                <Row>
                                    <Col span={12}><p style={{ color: 'grey' }}>OS Name </p></Col>
                                    <Col span={12}>{server.systemInfo.osName}</Col>
                                </Row>
                                <Row>
                                    <Col span={12}><p style={{ color: 'grey' }}>OS Version</p></Col>
                                    <Col span={12}>{server.systemInfo.osVersion}</Col>
                                </Row>
                                <Row>
                                    <Col span={12}><p style={{ color: 'grey' }}>OS Architecture</p></Col>
                                    <Col span={12}>{server.systemInfo.osArch}</Col>
                                </Row>
                                <Row>
                                    <Col span={12}><p style={{ color: 'grey' }}>DB Name</p></Col>
                                    <Col span={12}>{server.systemInfo.dbName}</Col>
                                </Row>
                                <Row>
                                    <Col span={12}><p style={{ color: 'grey' }}>DB Version</p></Col>
                                    <Col span={12}>{server.systemInfo.dbVersion}</Col>
                                </Row>

                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card className="server_configuration_card" style={{ marginLeft: '20px' }}>
                                <Row>
                                    <div class="server_configuration_heading" style={{ color: 'black', fontSize: "20px", transform: "translateX(-20px)", marginLeft: '20px',marginBottom: '20px' }}>Server Configuration
                            </div>
                                </Row>
                             
                                        <Row>
                                            <Col span={12}><p style={{ color: 'grey' }}> Name </p></Col>
                                            <Col span={12}>{server.serverConfiguration.serverName}</Col>
                                        </Row>
                                        <Row>
                                            <Col span={12}><p style={{ color: 'grey' }}> Version</p></Col>
                                            <Col span={12}>{server.serverConfiguration.serverVersion}</Col>
                                        </Row>
                                        <Row>
                                            <Col span={12}><p style={{ color: 'grey' }}>Channel Count</p></Col>
                                            <Col span={12}>{server.serverConfiguration.channelCount}</Col>
                                        </Row>
        
                                        <Row>
                                            <Col span={12}><p style={{ color: 'grey' }}>ID</p></Col>
                                            <Col span={12}>{server.serverConfiguration.serverId}</Col>
                                        </Row>
                                        <Row>
                                            <Col span={12}><p style={{ color: 'grey' }}>JVM</p></Col>
                                            <Col span={12}>{server.systemInfo.jvmName}</Col>
                                        </Row>
                                   
                            </Card>
                        </Col>
                    </Row>
                </Panel>
               
            </Collapse>
        
    </Card>)
        }
       const buildDashboard=(serverOptions) =>{
        console.log(serverOptions)
            let finalDashboard =[];
          
            let server = serverOptions;
           console.log(server)
            const diskGaugeConfig = {
                percent: server.diskspaceusage,
    
                radius: 0.90,
                range: {
                    color: 'l(0) 0:#30BF78 1:#F4664A ',
                    width: 20,
                },
                indicator: {
                    pointer: {
                        style: {
                            stroke: '#D0D0D0',
                        },
                    },
                    pin: {
                        style: {
                            stroke: '#D0D0D0',
                        },
                    },
                },
                statistic: {
                    title: {
                        style: {
                            fontSize: '20px',
                            lineHeight: '44px',
                            color: '#4B535E',
                            marginTop: '20px'
                        },
                        formatter: function formatter() {
                            return 'Disk Usage';
                        },
                    },
                    content: {
                        formatter: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
                    },
                },
                gaugeStyle: {
                    lineCap: 'round',
                },
            };
            const memoryGaugeConfig = {
                percent: server.memoryspaceusage,
                radius: 0.90,
                range: {
                    color: 'l(0) 0:#30BF78 1:#F4664A ',
                    width: 20,
                },
                indicator: {
                    pointer: {
                        style: {
                            stroke: '#D0D0D0',
                        },
                    },
                    pin: {
                        style: {
                            stroke: '#D0D0D0',
                        },
                    },
                },
                statistic: {
                    title: {
                        style: {
                            fontSize: '20px',
                            lineHeight: '44px',
                            color: '#4B535E',
                            marginTop: '20px'
                        },
                        formatter: function formatter() {
                            return 'Memory Usage';
                        },
                    },
                    content: {
                        formatter: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
                    },
                },
                gaugeStyle: {
                    lineCap: 'round',
                },
            };
            const cpuGaugeConfig = {
                percent: server.cpuUsage,
                radius: 0.90,
                range: {
                    color: 'l(0) 0:#30BF78 1:#F4664A ',
                    width: 20,
                },
                indicator: {
                    pointer: {
                        style: {
                            stroke: '#D0D0D0',
                        },
                    },
                    pin: {
                        style: {
                            stroke: '#D0D0D0',
                        },
                    },
                },
                statistic: {
                    title: {
                        style: {
                            fontSize: '20px',
                            lineHeight: '44px',
                            color: '#4B535E',
                            marginTop: '20px'
                        },
                        formatter: function formatter() {
                            return 'CPU Usage';
                        },
                    },
                    content: {
                        formatter: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
                    },
                },
                gaugeStyle: {
                    lineCap: 'round',
                },
            };
    
            const header =  <PageHeader
            title={server.serverName}
            tags={renderTag(server.state)}
            subTitle={server.serverVersion}
            >
            <Row style={{marginTop:'-20px'}}>
            <Text type="secondary" >{server.lastActiveTime}</Text>
            </Row>
            </PageHeader>
           finalDashboard.push(renderDasboard(server, header,diskGaugeConfig,memoryGaugeConfig,cpuGaugeConfig))
     
           
         return finalDashboard;
        }
        const diskGaugeConfig = {
            percent: serverData.diskspaceusage,
            range: {
            color: 'l(0) 0:#389e0d 0.25:#52c41a 0.75:#ffc53d 1:#cf1322',
            },
            startAngle: Math.PI,
            endAngle: 2 * Math.PI,
            indicator: null,
            statistic: {
            title: {
                offsetY: -20,
                style: {
                fontSize: '26px',
                color: '#4B535E',
                },
              
                    formatter: ({ percent }) => `${(percent * 100).toFixed(0)}%`,

            },
            // content: {
            //     style: {
            //     fontSize: '24px',
            //     lineHeight: '44px',
            //     color: '#4B535E',
            //     },
            //     formatter: () => 'Disk Usage',
            // },
            },
            innerRadius: 0.75,
            radius: 0.70,
        };
        const memoryGaugeConfig = {
            percent: serverData.memoryspaceusage,
            range: {
                color: 'l(0) 0:#389e0d 0.25:#52c41a 0.75:#ffc53d 1:#cf1322',
                },
                startAngle: Math.PI,
                endAngle: 2 * Math.PI,
                indicator: null,
                statistic: {
                    
                title: {
                    offsetY: -20,
                    style: {
                    fontSize: '26px',
                    color: '#4B535E'
                    },
                    formatter: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
                },
                // content: {
                //     style: {
                //     fontSize: '24px',
                //     lineHeight: '44px',
                //     color: '#4B535E',
                //     },
                //     formatter: () => 'Memory Usage',
                // },
                },
                innerRadius: 0.75,
                radius: 0.70,
        };
        const cpuGaugeConfig = {
            percent: serverData.cpuUsage,
            range: {
                color: 'l(0) 0:#389e0d 0.25:#52c41a 0.75:#ffc53d 1:#cf1322',
                },
                startAngle: Math.PI,
                endAngle: 2 * Math.PI,
                indicator: null,
                statistic: {
                title: {
                    offsetY: -20,
                    style: {
                    fontSize: '26px',
                    color: '#4B535E',
                    },
                    formatter: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
                },
                // content: {
                //     style: {
                //     fontSize: '24px',
                //     lineHeight: '44px',
                //     color: '#4B535E',
                //     },
                //     formatter: () => 'CPU Usage',
                // },
                },
                innerRadius: 0.75,
                radius: 0.70,
        };
        const data = [
            {
              type: 'Error',
              value: errorCount,
            },
            {
              type: 'Sent',
              value: sentCount,
            },
            {
              type: 'Received',
              value: receivedCount,
            },
            {
              type: 'Filtered',
              value: filteredCount,
            },
            {
              type: 'Queued',
              value: queuedCount,
            }
          ];
          const pieConfig = {
            appendPadding: 10,
            data,
            angleField: 'value',
            colorField: 'type',
            radius: 1,
            innerRadius: 0.7,
            colorField: 'type',
            color: ({ type }) => {
              if(type === 'Error'){
                return '#ff4d4f';
              }
              else if(type === 'Received'){
                return '#389e0d';
              }  
              else if(type === 'Sent'){
                return '#1890ff';
              }
              return 'blue';
            },
            label: {
              type: 'inner',
              offset: '-50%',
              content: '{value}',
              style: {
                textAlign: 'center',
                fontSize: 14,
              },
             
            },
            interactions: [
              {
                type: 'element-selected',
              },
              {
                type: 'element-active',
              },
            ],
            
            statistic: {
              title: false,
              content: {
                style: {
                  whiteSpace: 'pre-wrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
              // content: 'Stats',
              },
            },
          };
          const renderTag = (tag) => {

            if (tag == 'STARTED')
                return <Tag color="success">{tag}</Tag>;
            else if (tag == 'PAUSED')
                return <Tag color="processing">{tag}</Tag>;
                else if (tag == 'STOPPED')
                return <Tag color="error">{tag}</Tag>;
            else
                return <Tag color="default">{tag}</Tag>;

        }
        const channelStatisticsColumns = [
           
            { title: 'Channel Name', dataIndex: 'channelName', key: 'name' },
            { title: 'State', dataIndex: 'state', key: 'state',render: (text) => renderTag(text), },
            {
                title: 'Received', dataIndex: 'received', key: 'received', defaultSortOrder: 'descend',
                sorter: (a, b) => a.received - b.received
            },
            {
                title: 'Sent', dataIndex: 'sent', key: 'sent',
                render: (text, record) => (
                    <>
                        <Tag color="success">{text}</Tag>
                    </>),
                sorter: (a, b) => a.sent - b.sent
            },
            {
                title: 'Error', dataIndex: 'error', key: 'error',
                render: (text, record) => (
                    <>
                        <Tag color="error">{text}</Tag>
                    </>),
                sorter: (a, b) => a.error - b.error
            },
            { title: 'Filtered', dataIndex: 'filtered', key: 'filtered', sorter: (a, b) => a.filtered - b.filtered },
            { title: 'Queued', dataIndex: 'queued', key: 'queued', sorter: (a, b) => a.queued - b.queued },


        ];
        const content = (
            <div>
              <Row>
                                    <Col span={12}><p style={{ color: 'grey' }}>JVM Version</p></Col>
                                    <Col span={12}>{systemInfo.jvm}</Col>
                                </Row>
                                <Row>
                                    <Col span={12}><p style={{ color: 'grey' }}>OS Name </p></Col>
                                    <Col span={12}>{systemInfo.osName}</Col>
                                </Row>
                                <Row>
                                    <Col span={12}><p style={{ color: 'grey' }}>OS Version</p></Col>
                                    <Col span={12}>{systemInfo.osVersion}</Col>
                                </Row>
                                <Row>
                                    <Col span={12}><p style={{ color: 'grey' }}>OS Architecture</p></Col>
                                    <Col span={12}>{systemInfo.osArch}</Col>
                                </Row>
                                <Row>
                                    <Col span={12}><p style={{ color: 'grey' }}>DB Name</p></Col>
                                    <Col span={12}>{systemInfo.dbName}</Col>
                                </Row>
                                <Row>
                                    <Col span={12}><p style={{ color: 'grey' }}>DB Version</p></Col>
                                    <Col span={12}>{systemInfo.dbVersion}</Col>
                                </Row>
            </div>
          );
        return (
            <>
                <Breadcrumb>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Mirth Monitoring</Breadcrumb.Item>
                    <Breadcrumb.Item>Server DashBoard</Breadcrumb.Item>
                    <Breadcrumb.Item>{serverData.serverName}</Breadcrumb.Item>
                </Breadcrumb>

                <div className="content_panel">

                    <Spin size="large" spinning={spin}>

                        <Row>

                            <Col span={19}>
                                <div class="content_panel_heading" style={{ color: 'black', fontSize: "24px", transform: "translateX(-20px)", marginLeft: '20px',width:'450px' }}>
                                <Badge color={serverData.state=="ACTIVE"?"#87d068":"#8c8c8c"} text=""/> {serverData.serverName}
                                <Divider type="vertical" />
                                <Text style={{ color:'#8c8c8c', fontSize: "14px", transform: "translateX(-20px)" }}>{serverData.serverVersion}</Text>
                                
                                <Divider type="vertical" />
                                <Text style={{ color:'#8c8c8c', fontSize: "14px", transform: "translateX(-20px)" }}>{serverData.lastActiveTime}</Text>
                              
                                </div>
                                
                            </Col>
                            <Col span={4}>
                            <div class="content_panel_heading" >
                            <Text style={{ color:'black', fontSize: "14px", transform: "translateX(-20px)" }}>No of Channels :
                            <Badge style={{ backgroundColor: '#f5f5f5',color: 'black' }}  count={channelData.length}/></Text>
                            </div>
                            </Col>
                          
                        </Row>
                        <div class="divider-1"> <span></span></div>
                        {/* <Divider /> */}
                        {
                            isError ? (
                                <>
                                    <Result
                                        status={resultStatus}
                                        title={resultTitle}
                                        subTitle={resultStatus == '500' ? resultSubTitle : ''}
                                        extra={resultStatus == '500' ? <></> : <Link to="/settings/server"><Button type="primary" style={{ borderRadius: '7px' }}>Add Server</Button></Link>}
                                    />
                                </>) : (
                                  
                                    <> 
                                    <Row >

                                    <Col span={8} class="gauge_card"> 
                                    <div class="gauge_card">
                                    <div class="gauge_heading">Disk Usage</div>
                                    <Gauge style={{ height: '200px' , marginTop:'-70px'}} {...diskGaugeConfig} />
                                    </div>
                                    </Col>
                                    <Col span={8}> 
                                    <div class="gauge_card">
                                    <div class="gauge_heading">Memory Usage</div>
                                    <Gauge style={{ height: '200px' , marginTop:'-70px'}} {...memoryGaugeConfig} />
                                    </div>
                                    </Col>
                                    <Col span={8}> 
                                    <div class="gauge_card">
                                    <div class="gauge_heading">CPU Usage</div>
                                    <Gauge style={{ height: '200px' , marginTop:'-70px'}} {...cpuGaugeConfig} />
                                    </div>
                                    </Col>
            
                                </Row>
                                <Row>
                                <Col span={20}>
                                <Descriptions  layout="vertical"  size='small' >
                                <Descriptions.Item label="ID" style={{fontSize:'10px'}}>{serverConfiguration.serverId}</Descriptions.Item>
                                <Descriptions.Item label="JVM">{systemInfo.jvmName}</Descriptions.Item>
                                </Descriptions>
                                </Col>
                                <Col span={4}>
                                <Popover placement="bottomRight" content={content} trigger="click" style={{width:'300px'}}>
                                    <Button style={{ float: 'right' ,borderRadius:'7px',backgroundColor:'#efefef',fontFamily:'AktivGroteskMedium',border:'none'}}><EyeOutlined />System Info</Button>
                                </Popover>
                                </Col>
                                </Row>
                                <Divider />
                                <Row >
                                <Col span={8} ><Pie {...pieConfig} /> </Col>
                                <Col span={14}>
                                <SearchBar data={cloneChannelData} handler={this.handleSearch}></SearchBar>
                                            <Table
                                                style={{ marginTop: '10px' }}
                                                columns={channelStatisticsColumns}
                                                dataSource={channelData}
                                             
                                            />
                                </Col>
                                </Row>
                                </>
                                    )}
                    </Spin>

                </div>
            </>
        );
    }
}

