import axios from 'axios';
import authHeader from './Auth/auth-header';



// let axiosConfig = {
//     headers: authHeader()
// };
let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
};

let API_URL = process.env.REACT_APP_API_URL + 'api/v1';
//let SERVICE_URL= ' http://localhost:8084/mirth-monitoring';
let SERVICE_URL= process.env.REACT_APP_API_URL +'api/general-settings';
export async function getVisitor(data) {
    try {
        const { data: res } = await axios.post(
            `${SERVICE_URL}/user-audit/getalldata`,
            data, {
                headers: authHeader()
            },
            axiosConfig
        )
        return res
    } catch (e) {
        return e
    }
};
