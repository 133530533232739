import React from 'react'
import AuthService from '../service/Auth/auth-service'
import { Redirect, Route } from 'react-router-dom'

const PrivateRoute = ({ component: Component, ...rest }) => {

  // Add your own authentication on the below line.
  const user = AuthService.getCurrentUser()

  return (
    <Route
      {...rest}
      render={props =>
        user ? (
          <Component {...props} />
          // <Redirect to= '/mirth/server-configuration' {...props} />
        ) : (
          // <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
          <Redirect to={{ pathname: '/external', state: { from: props.location } }} />
        )
      }
    />
  )
}

export default PrivateRoute