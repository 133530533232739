import React, { Component } from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import { Transfer, Input, Spin, Table, Tag, InputNumber } from "antd";
import difference from "lodash/difference";
import {
  getCronExpList,
  getServerList,
  getData,
  getMirthServerDetails,
  getSubscribedChannels,
} from "../../../service/mirth/MirthService";
import { right } from "@popperjs/core";

const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
  <Transfer {...restProps}>
    {({
      direction,
      filteredItems,
      onItemSelectAll,
      onItemSelect,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled,
    }) => {
      const columns = direction === "left" ? leftColumns : rightColumns;

      const rowSelection = {
        getCheckboxProps: (item) => ({
          disabled: listDisabled || item.disabled,
        }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter((item) => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, listSelectedKeys)
            : difference(listSelectedKeys, treeSelectedKeys);
          onItemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          onItemSelect(key, selected);
        },
        selectedRowKeys: listSelectedKeys,
      };

      return (
        <Table
          rowSelection={rowSelection}
          pagination={{ pageSize: 6 }}
          columns={columns}
          dataSource={filteredItems}
          size="small"
          style={{ pointerEvents: listDisabled ? "none" : null }}
          onRow={({ key, disabled: itemDisabled }) => ({
            onClick: () => {
              if (itemDisabled || listDisabled) return;
              onItemSelect(key, !listSelectedKeys.includes(key));
            },
          })}
        />
      );
    }}
  </Transfer>
);

export default class ChannelTransfer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      targetKeys: [],
      tableData: [],
      accessToken: props.accessToken,
      dataSource: props.dataSource,
      errorType: props.errorType,
      spin: true,
    };
  }

  componentDidMount() {
    if (this.state.accessToken !== null) {
      getMirthServerDetails(this.state.accessToken).then((serverRes) => {
        let channelArr = [];
        if (serverRes.code == 200 && serverRes.result.length != 0) {
          let details = JSON.parse(serverRes.result[0].details);
          if (
            details.channelStatus &&
            typeof details.channelStatus == "string"
          ) {
            let res = JSON.parse(details.channelStatus);
            let values = res.list.dashboardStatus;
            let finalArray = [];
            if (values !== null && !Array.isArray(values)) {
              finalArray.push(values);
            } else {
              finalArray = values;
            }
            channelArr = finalArray;
            let finalArr = [];
            if (this.props.direction == right) {
              let channel = [];
              let threshold = this.state.dataSource;
                 
              this.state.dataSource.map((value) => {
                channel.push(value.id);
              });
        
              finalArr = channelArr.map(function (obj) {
                let found = threshold.filter(item => item.id === obj.channelId).map(item => item.threshold)
                return {
                  name: obj.name,
                  key: obj.channelId,
                  threshold: found[0]?found[0]:50
                };
              });
        
              this.setState({ tableData: finalArr, targetKeys: channel ,spin: false });
            } 
            else {
              for (let l = 0; l < channelArr.length; l++) {
                let channeljson = {
                  key: channelArr[l].channelId,
                  name: channelArr[l].name,
                  threshold: 50,
                };
                finalArr.push(channeljson);
                console.log(finalArr);
              }
              this.setState({ tableData: finalArr, spin: false });
            }
            
          }
            else {
            getSubscribedChannels(this.state.accessToken).then((channelRes) => {
              if (channelRes.code == 200 && channelRes.result.length != 0) {
                let result = channelRes.result;
                channelArr = result.map(function (obj) {
                  return {
                    name: obj.channel_name,
                    channelId: obj.channel_id,
                    state: obj.status,
                  };
                });
                console.log(channelArr);
                let finalArr = [];
                if (this.props.direction == right) {
                  let channel = [];
                  let threshold = this.state.dataSource;
                     
                  this.state.dataSource.map((value) => {
                    channel.push(value.id);
                  });
            
                  finalArr = channelArr.map(function (obj) {
                    let found = threshold.filter(item => item.id === obj.channelId).map(item => item.threshold)
                    return {
                      name: obj.name,
                      key: obj.channelId,
                      threshold: found[0]?found[0]:50
                    };
                  });
            
                  this.setState({ tableData: finalArr, targetKeys: channel ,spin: false });
                } 
                else {
                  for (let l = 0; l < channelArr.length; l++) {
                    let channeljson = {
                      key: channelArr[l].channelId,
                      name: channelArr[l].name,
                      threshold: 50,
                    };
                    finalArr.push(channeljson);
                    console.log(finalArr);
                  }
                  this.setState({ tableData: finalArr, spin: false });
                }
                
              }
            });
          }
        }

        //     if(this.props.direction==right){
        //         for(let l = 0;l<channelArr.length;l++){
        //         for(let i=0;i<this.state.dataSource.length;i++){
        //             if(channelArr[l].channelId==this.state.dataSource[i].id){
        //                 let channeljson = { key: channelArr[l].channelId, name: channelArr[l].name, threshold:this.state.dataSource[i].threshold };
        //                 finalArr.push(channeljson);

        //             }
        //             else{
        //                 let channeljson = { key: channelArr[l].channelId, name: channelArr[l].name, threshold: 50 };
        //         finalArr.push(channeljson);
        //             }

        //     }}
        //     this.setState({ tableData: finalArr, spin: false})

        //     }
        //     else{
        //     for(let l = 0;l<channelArr.length;l++)
        //     {
        //         let channeljson = { key: channelArr[l].channelId, name: channelArr[l].name, threshold: 50 };
        //         finalArr.push(channeljson);
        //     }
        // }
        // console.log(finalArr);
        // this.setState({ tableData: finalArr, spin: false})
        // if(this.props.direction==right){
        //     console.log(this.state.dataSource)
        //     const newData = [...this.state.tableData];
        //     let channel=[]
        //     let threshold=this.state.dataSource
        //     this.state.dataSource.map((value=>{
        //         channel.push(value.id)
        //         // threshold.push(value.threshold)

        //     }))
        //     console.log(channel)

        //     this.setState({ targetKeys:channel});
        //     console.log(this.state.targetKeys)
        //     this.props.onSubmitMessage(this.getChannelsFromKeys(channel));

        //     // this.props.onSubmitMessage(this.getChannelsFromKeys(channel));
        //     // this.onInputChange(this.state.targetKeys,threshold)
        // }
      });
    }
  }

  onInputChange = (key, index) => (e) => {
    console.log("value--", e);
    console.log("index--", index);

    const newData = [...this.state.tableData];
    console.log(newData);

    newData.map((data) => {
      if (data.key == index) {
        data.threshold = e;
      }
    });
    console.log(newData);
    // newData[index][key] = Number(e.target.value);
    this.setState({ tableData: newData });
  };
  onChange = (nextTargetKeys) => {
    this.props.onSubmitMessage(this.getChannelsFromKeys(nextTargetKeys));
    this.setState({ targetKeys: nextTargetKeys });
  };

  getChannelsFromKeys(keys) {
    console.log(keys);

    let channelsArr = [];
    for (let i = 0; i < keys.length; i++) {
      this.state.tableData.map((data) => {
        if (data.key == keys[i]) {
          let channeljson = {
            id: data.key,
            threshold: data.threshold,
            name: data.name,
          };
          channelsArr.push(channeljson);
        }
      });
    }
    return channelsArr;
  }
  renderTags = (text, record, index) => {
    if (this.state.errorType) {
      return (
        <InputNumber
          value={text}
          style={{ width: 50 }}
          onChange={this.onInputChange("Count", record.key)}
        />
      );
    } else {
      return (
        <InputNumber
          value={text}
          style={{ width: 50 }}
          onChange={this.onInputChange("threshold", record.key)}
        />
      );
    }
  };

  render() {
    const { targetKeys, tableData, spin, errorType } = this.state;
    const leftTableColumns = [
      {
        dataIndex: "name",
        title: "Name",
      },
      {
        dataIndex: "threshold",
        title: "Threshold(%)",
        className: this.state.errorType["threshold"] ? "hide" : "show",
        render: (text, record, index) => (
          <InputNumber
            value={text}
            style={{ width: 50 }}
            onChange={this.onInputChange("threshold", record.key)}
          />
        ),
        hidden: this.state.errorType,
      },
      {
        title: "Count",
        dataIndex: "threshold",
        className: this.state.errorType["count"] ? "show" : "hide",
        render: (text, record, index) => (
          <InputNumber
            value={text}
            style={{ width: 50 }}
            onChange={this.onInputChange("threshold", record.key)}
          />
        ),
        hidden: !this.state.errorType,
      },
    ].filter((item) => !item.hidden);
    const rightTableColumns = [
      {
        dataIndex: "name",
        title: "Name",
      },
      {
        dataIndex: "threshold",
        title: "Threshold(%)",
        render: (text, record, index) => (
          <InputNumber
            value={text}
            style={{ width: 50 }}
            onChange={this.onInputChange("threshold", record.key)}
            disabled="true"
          />
        ),
        hidden: this.state.errorType,
      },
      {
        title: "Count",
        dataIndex: "threshold",
        render: (text, record, index) => (
          <InputNumber
            value={text}
            style={{ width: 50 }}
            onChange={this.onInputChange("threshold", record.key)}
            disabled="true"
          />
        ),
        hidden: !this.state.errorType,
      },
    ].filter((item) => !item.hidden);

    return (
      <>
        <Spin size="large" spinning={spin}>
          <TableTransfer
            dataSource={tableData}
            targetKeys={targetKeys}
            disabled={false}
            // listStyle={{
            //     width: 300,
            //    // height: 300,
            //     }}
            showSearch={true}
            onChange={this.onChange}
            filterOption={(inputValue, item) =>
              item.name.indexOf(inputValue) !== -1
            }
            leftColumns={leftTableColumns}
            rightColumns={rightTableColumns}
          />
        </Spin>
      </>
    );
  }
}