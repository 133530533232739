import React, { Component } from "react";

import {
  Layout,
  Breadcrumb,
  DatePicker,
  Descriptions,
  Spin,
  Table,
  Tag,
  Modal,
  Result,
  Divider,
  Row,
  Col,
  Form,
  Button,
  notification,
  Input,
  Typography,
  Tabs,
  Space,
  Tooltip,
  InputNumber,
  Select,
  Pagination
} from "antd";
// import './Settings.css';

import {
  CaretDownOutlined,
  CaretRightOutlined,
  PlayCircleOutlined,
  PlusOutlined,
  PlusCircleFilled,
  DownloadOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeFilled,
  EyeTwoTone,
  EyeInvisibleFilled,
} from "@ant-design/icons";
import "antd/dist/antd.css";
import { SearchBar } from "../../../components";
// import moment from 'moment-timezone';
import moment from "moment";
// import 'public/src/styles/custom-css/antd-custom.css';

import { Link } from "react-router-dom";
import { getAllData } from "../../../service/UserAuditservice";
import { pdf } from "../../../service/mirth/MirthService";

import authService from "../../../service/Auth/auth-service";

const { RangePicker } = DatePicker;

const { Header, Content, Sider } = Layout;
function disabledDate(current) {
  return current > moment().endOf("day");
}
const getFullDate = (date) => {
  if (date !== null) {
    const value = moment(date).format("YYYY-MM-DD HH:mm:ss");
    return value;
  }
};
const onShowSizeChange = (current, pageSize) => {
  console.log(current, pageSize);
};
export default class UserAudit extends Component {
  formRef = React.createRef();
  searchformRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      spin: false,
      cloneuserData: [],
      userData: [],
      isDownloadPage: true,
      isSearched: false,
      startTime: "",
      endTime: "",
      userName: "",
      request: {},
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.go = this.go.bind(this);
    this.setTimeFilter = this.setTimeFilter.bind(this);
    this.handleReportDonwload = this.handleReportDonwload.bind(this);
    this.setval = this.setval.bind(this);
  }

  componentDidMount() {
    let user = authService.getCurrentUser();
  }
  go(val) {
    this.setState({ spin: true, isSearched: true });
    let request = {};
    if (val.userName != undefined && val.userName !="") {
      request = {
        fromDate: this.state.startTime,
        toDate: moment(this.state.endTime).add(1, "days").format("YYYY-MM-DD"),
        userName: val.userName,
      };
    } else {
      request = {
        fromDate: this.state.startTime,
        toDate: moment(this.state.endTime).add(1, "days").format("YYYY-MM-DD"),
      };
    }
    this.setState({ request: request });

    console.log(request);
    this.setState({ cloneUserData: [], userData: [] });
    getAllData(request).then((res) => {
      console.log(res);
      let user = [];
      user = res.result;
      let data = [];
      for (let i = 0; i < user.length; i++) {
        let rowData = user[i];
        rowData.loggedTime = getFullDate(rowData.loggedTime);
        data.push(rowData);
      }
      this.setState({ spin: false, userData: data, cloneUserData: data });
    });
  }
  onReset = () => {
    this.setState({
      visible: false,
      isSearched: false,
      countData: [],
    });
    this.formRef.current.resetFields();
  };
  setval(value) {
    this.setState({ range: value });
    this.searchformRef.current.setFieldsValue({ 'userName': '' });
}
  setTimeFilter(value) {
    if (value !== null)
      this.setState({
        startTime: value[0].format("YYYY-MM-DD"),
        endTime: value[1].format("YYYY-MM-DD"),
      });
  }
  handleReportDonwload(val) {
    this.setState({ isSearched: true,spin:true });
    let request = {};
    if (val.userName != undefined) {
      request = {
        fromDate: this.state.startTime,
        toDate: moment(this.state.endTime).add(1, "days").format("YYYY-MM-DD"),
        userName: val.userName,
      };
    } else {
      request = {
        fromDate: this.state.startTime,
        toDate: moment(this.state.endTime).add(1, "days").format("YYYY-MM-DD"),
      };
    }
    this.setState({ request: request });

    pdf("token", "useraudit", this.state.request).then((response) => {
      console.log(response);
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "sessionMonitoring.pdf");
      document.body.appendChild(link);
      link.click();
      this.setState({ spin:false });
    });
  }

  handleSearch(data) {
    console.log("In handle search", data);
    this.setState({ userData: data });
  }

  render() {
   
    const renderTag = (tag) => {
      if (tag == "AUTHENTICATION_SUCCESS")
        return <Tag color="success">{tag}</Tag>;
      else if (tag == "AUTHENTICATION_FAILURE")
        return <Tag color="error">{tag}</Tag>;
      else return <Tag color="default">{tag}</Tag>;
    };
    const renderTags = (tag) => {
      if (tag == "POST") return <Tag color="geekblue">{tag}</Tag>;
      else if (tag == "GET") return <Tag color="purple">{tag}</Tag>;
      else return <Tag color="blue">{tag}</Tag>;
    };

    const {
      request,
      userName,
      isSearched,
      isDownloadPage,
      spin,
      userData,
      cloneUserData,
    } = this.state;
    const COLUMN_STYLE = { width: 100 };
    var myColTitleStyle = {
      textOverflow: "ellipsis",
      // overflow: "hidden",
      whiteSpace: "wrap",
    };

    const UserauditColumns = [
      {
        title: "UserName",
        dataIndex: "userName",
        key: "userName",
        ellipsis: "true",
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        render: (text) => renderTag(text),
      },
     
      // { title: "Url", dataIndex: "url", key: "url" },
      {
        title: <div style={myColTitleStyle}>LoggedTime</div>,
        dataIndex: "loggedTime",
        key: "loggedTime"
      },
      { title: "IP", dataIndex: "ip", key: "ip" },
      {
        title: "UserAgent",
        dataIndex: "userAgent",
        key: "userAgent",
        render: (text) => (
          <Tooltip placement="topLeft" title={text}>
            <Button>UserAgent </Button>
          </Tooltip>
        ),
      },
    ];
    // var heading = ["UserName", "Type", "Method", "Url", "IP", "UserAgent"];
    // var body=[userData.map((data) =>
    //     [data.UserName,data.type,data.method,data.ip,data.userAgent],
    //     [data.UserName,data.type,data.method,data.ip,data.userAgent],
    //     [data.UserName,data.type,data.method,data.ip,data.userAgent],
    //     [data.UserName,data.type,data.method,data.ip,data.userAgent]
    //     )];

    const onChange = (pagination, filters, sorter, extra) => {
      console.log("params", pagination, filters, sorter, extra);
    };

    return (
      <>
        <Breadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Admin</Breadcrumb.Item>
          <Breadcrumb.Item>SessionMonitoring</Breadcrumb.Item>
        </Breadcrumb>

        <div className="content_panel">
          <Spin size="large" spinning={spin}>
            {/* <Modal title={modalTitle} visible={isModalVisible} footer={null} onCancel={this.handleCancel} width={900} bodyStyle={{ overflowY: 'scroll', height: 380 }}>
                            {modalMessage}
                        </Modal> */}
            <Row>
              <div class="content_panel_heading">Session Monitoring</div>
            </Row>
            <div class="divider-1">
              {" "}
              <span></span>
            </div>

            <div class="content_form_panel">
              <Form
                name="horizontal_login"
                className="ant-advanced-search-form"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 15 }}
                ref={this.formRef}
                onFinish={this.go}
                style={{paddingRight:'0px'}}
              >
                <Row>
                  <Col span={12}>
                    <Form.Item
                      name="range"
                      label="Range"
                      rules={[
                        {
                          required: true,
                          message: "Please input Range!",
                        },
                      ]}
                    >
                      <RangePicker
                        placement={"bottom"}
                        format="YYYY-MM-DD"
                        style={{width:'100%'}} 
                        disabledDate={disabledDate}
                        onChange={(val) => {
                          this.setTimeFilter(val);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="User Name" name="userName">
                      <Input placeholder="User Name" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={18}></Col>
                  <Col span={4}>
                    <Space>
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        type="primary"
                        style={{ borderRadius: "7px" }}
                      >
                        SEARCH <PlayCircleOutlined />
                      </Button>
                    </Form.Item>

                    <Form.Item>
                      <Button
                        htmlType="button"
                        style={{
                          borderRadius: "7px",
                          backgroundColor: "#e3e3e3",
                         
                        }}
                        onClick={this.onReset}
                      >
                        Reset
                      </Button>
                    </Form.Item>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </div>

            {/* <Divider /> */}
            {
              <>
                {isSearched ? (
                  <Button
                    htmlType="button"
                    style={{ backgroundColor: "#efefef", margin: "0 8px" }}
                    onClick={this.handleReportDonwload}
                  >
                    {" "}
                    Download Report
                  </Button>
                ) : (
                  <></>
                )}{" "}
                {isSearched ? (
                  <SearchBar
                    data={cloneUserData}
                    handler={this.handleSearch}
                  ></SearchBar>
                ) : (
                  <></>
                )}
                {isSearched ? (
                  <Table
                    style={{ marginTop: "10px" }}
                    columns={UserauditColumns}
                    dataSource={userData}
                    onChange={onChange}
                    
                    //  pagination={
                    //     50
                    //   }
                    scroll={{
                      x: true,
                    }}
                  />
                  
                ) : (
                  <></>
                )}
              </>
            }
          </Spin>
        </div>
      </>
    );
  }
}
