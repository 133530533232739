import React, { Component } from "react";
import { Row, Col, Tag, Switch,Radio , Typography, Divider, Card, Descriptions, Result, Space, Select, Tooltip, Modal, Form, Input, Button, DatePicker, Breadcrumb, Spin } from 'antd';
import {
    PlayCircleOutlined,
    StopOutlined,
    PauseCircleOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import {
    getServerList,
    getData,
    getDataNew,
    getChannelStats,
    getChannelStatsBySub,
    getServerByOrgId
} from '../../../service/mirth/MirthService'
import AuthService from '../../../service/Auth/auth-service'
import { Line,Area } from '@ant-design/plots';
import moment from 'moment-timezone';
import LineGraph from '../../../components/Graph/LineGraph'
import '../../../styles/custom-css/antd-custom.css';
import {CustomRangePicker} from '../../../components/CustomRangePicker';
import { properties } from '../../../properties';
import { configurationData } from "../../../service/ConfigService/Config";


const { Option } = Select;
const { Text } = Typography;


// const radioFilterValues=[
//     {
//         key:1,
//         value:'received',
//         data:'Received'
//     },
//     {
//         key:2,
//         value:'sent',
//         data:'Sent'
//     },
//     {
//         key:3,
//         value:'error',
//         data:'Error'
//     },
//     {
//         key:4,
//         value:'queued',
//         data:'Queued'
//     },
//     {
//         key:5,
//         value:'filtered',
//         data:'Filtered'
//     }
// ]
export default class ChannelGraph extends Component {

    constructor(props) {
        super(props)

        this.state = {
            spin: true,
            graphData:[],
            serverOptions:[],
            resultStatus:'',
            resultTitle:'',
            resultSubTitle:'',
            isError:false,
            currentServer:'',
            currentAccessToken:'',
            radioFilter:'received',
            startTime: "",
            endTime: "",
            lastVal: "5 MINUTES",
            isDateVisible:false,
            selected:'5 MINUTES',
            radioFilterValues:[],
            interval:[],
            page:'channelGraph'

        }

        this.handleServerChange = this.handleServerChange.bind(this);
        this.onRadioChange = this.onRadioChange.bind(this);
        this.handleTimeChange = this.handleTimeChange.bind(this);
        this.onToggleChange = this.onToggleChange.bind(this);
        this.getData = this.getData.bind(this);
        this.setTimeFilter = this.setTimeFilter.bind(this);
    }
    formRef = React.createRef();
    componentDidMount() {
       
        let token = this.props.match.params.accessToken;
        let user = AuthService.getCurrentUser();
        if(token == null )
        {
            this.setState({ resultStatus:'warning',resultTitle:'Add a server to check statistics', isError:true,spin: false})
        }
        else
        {
            configurationData(this.state.page).then((res) =>{
                if(res.code==200){
                  let jsonData=JSON.parse(res.result[0].config);
                  console.log(jsonData)
                this.setState({radioFilterValues:jsonData.radioFilterValues,interval:jsonData.interval,resultStatus:jsonData.status.resultStatus,
                    resultTitle:jsonData.status.resultTitle,
                    resultSubTitle:jsonData.status.resultSubTitle})

                getServerByOrgId(user.orgId).then(ServerRes=> {
                    if (ServerRes.code == 200) {
                        let server=[];
                        server = ServerRes.result;
                    
                        if(server.length > 0)
                        {
                            this.setState({serverOptions:server,spin: false});
                            this.handleServerChange(token);
                        }
                        else
                        {
                            this.setState({ resultStatus:'warning',resultTitle:'Add a server to check statistics', isError:true,spin: false})
                        }
                        
                    }
                })
            }
          })
          }

    }
    getData(request){
      getChannelStatsBySub(request).then(serverRes =>{
        if(serverRes.code ==200)
        {
          let result = serverRes.result;
          if(result.length > 0)
          {
            for(let i=0;i<result.length;i++)
            {
                result[i].observationTime = moment(result[i].observationTime).tz("America/New_York").format('YYYY-MM-DD HH:mm');
            }
            this.setState({graphData:result,spin:false})
          }
          else
          {
            getChannelStats(request).then(res=>{
              if(res.code ==200)
              {
                  let result = res.result;
                  for(let i=0;i<result.length;i++)
                  {
                      result[i].observationTime = moment(result[i].observationTime).tz("America/New_York").format('YYYY-MM-DD HH:mm');
                  }
                  this.setState({graphData:result,spin:false})
              }
              else
              {
                  this.setState({isError:true,spin: false})
              }
            
          })
          }
        }
        else
        {
          this.setState({isError:true,spin: false})
        }
      })
    }
    handleServerChange(val){
   
        this.setState({ spin: true ,currentServer:val,isError:false,currentAccessToken:val})
      
           let request={};
           request.accessToken = val;
            if(this.state.isDateVisible)
            {
              request.startTime = this.state.startTime;
              request.endTime = this.state.endTime;
            }
            else
            {
              request.time = this.state.selected;
            }
            this.getData(request);
    }

      setTimeFilter(value) {
        if (value !== null) {
          this.setState({ spin: true });
          let token = this.state.currentAccessToken;
          let request = {};
          request.accessToken = token;
          request.time = null;
          request.startTime = value[0].format("YYYY-MM-DD");
          request.endTime = moment(value[1]).add(1, "days").format("YYYY-MM-DD");
          //this.formRef.current.resetFields(["last"]);
          this.setState({
            lastVal: "",
            startTime: value[0].format("YYYY-MM-DD"),
            endTime: value[1].format("YYYY-MM-DD"),
          });
          this.getData(request);
        }
    }    
    onRadioChange(e){
    this.state.radioFilterValues.map((filter)=>{
      
        if(e.target.value == filter.key )
        {
            this.setState({radioFilter:filter.value,graphData:this.state.graphData})
        }
    })

    }
    onToggleChange(checked)
    {
        this.formRef.current.resetFields();
        if(checked)
        {
            this.setState({ isDateVisible:false });
        }
        else
        {
            this.setState({ isDateVisible:true });
        }
    }
    handleTimeChange(val) {
        this.setState({ spin: true ,selected:val});
        let token = this.state.currentAccessToken;
       // this.formRef.current.resetFields(["dateRange"]);
        let request = {};
        request.accessToken = token;
        request.time = val;
        request.startTime = null;
        request.endTime = null;
        this.getData(request);
      }
    render() {


        const { isDateVisible,interval,radioFilterValues,spin,graphData,serverOptions,isError, resultStatus,resultTitle,resultSubTitle,radioFilter,lastVal} = this.state;
      
        
        return (
            <>
                <Breadcrumb>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Mirth Monitoring</Breadcrumb.Item>
                    <Breadcrumb.Item>Channel Overview</Breadcrumb.Item>
                </Breadcrumb>

                <div className="content_panel">

                    <Spin size="large" spinning={spin}>

                        <Row>

                            <Col span={7}>
                                <div class="content_panel_heading" >Channel Overview
                                </div>
                            </Col>
                            {serverOptions.length !==0 && 
                            <>
                            <Col span={2}>
                            <Text style={{marginTop:'2px'}}>Server: </Text>
                            </Col>
                            <Col span={12} style={{marginLeft:'-30px',marginTop:'-4px'}}>
                            
                            <Select placeholder="Select server" defaultValue={serverOptions[0].accessToken} onChange={this.handleServerChange} style={{ width: 160 }} bordered={false}>
                            {serverOptions.map((server) => (
                                                    <Option key={server.accessToken} value={server.accessToken}>{server.serverName}</Option>
                                                ))

                                    }
                                </Select>
                            </Col>
                            </>}
                        </Row>
                        <div class="divider-1"> <span></span></div>
                        {/* <Divider /> */}
                        {
                        isError ? (
                        <>
                         <Result
                            status={resultStatus}
                            title={resultTitle}
                            subTitle={resultStatus== '500'?resultSubTitle:''}
                            extra={resultStatus== '500'?<></>:  <Link to="/settings/server"><Button type="primary" style={{borderRadius:'7px'}}>Add Server</Button></Link>}
                        />
                        </>):(
                        <>
                          <Row>
                          <Col span={10}></Col>
                          <Col span={4}>
                    <Space style={{ marginTop:'5px',float:'right',marginRight:'10px'}}>
                   <Text >Filter By </Text>
                  <Switch defaultChecked onChange={this.onToggleChange}/>
                  </Space>
                 </Col>
                    <Col span={9}>
                    <Form
                  name="horizontal_login"
                  className="ant-advanced-search-form"
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 15 }}
                  ref={this.formRef}
                  style={{ paddingRight: "0px" }}
                >
                      <Form.Item name="dateRange" style={{ display: isDateVisible ? '' : 'none' }} label="Date Range">
                        <CustomRangePicker  handler={this.setTimeFilter}/>
                      </Form.Item>
                  
                      <Form.Item label="Show Last" style={{ display: isDateVisible ? 'none' : ' ' }} name="last">
                        <Select
                          style={{ width: "300px" }}
                          defaultValue={lastVal}
                          onChange={this.handleTimeChange}
                        >
                          {interval.map((int) => (
                                                        <Option key={int.key} value={int.key}>{int.key}</Option>
                                                    ))

                                                    }
                        </Select>
                      </Form.Item>
                      </Form>
                    </Col>
                          </Row>
                          {graphData.length !=0 ?(<> <Row>

                        <Col span={12}>
                            <Radio.Group onChange={this.onRadioChange} style={{marginBottom:'20px'}} defaultValue={1}>
                            {radioFilterValues.map((filter) => (
                                                    <Radio value={filter.key}>{filter.data}</Radio>
                                                ))

                                    }
                            </Radio.Group>
                            </Col>
                           
                            </Row>
                            <LineGraph graphData={graphData} typeFilter={radioFilter}/></>):
                (<Result
                  title="No Data Available"
                />)}

                    </>)
                    }
                       
                    </Spin>

                </div>
            </>
        );
    }
}

