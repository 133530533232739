import React, { Component } from 'react';

import { Popconfirm,Badge,Tag,Breadcrumb, Spin, Table, Modal,Result,Divider, Row, Col, Form, Button, Collapse,notification, Input, Typography, Tabs, Space, Tooltip, InputNumber, Select } from 'antd';
// import './Settings.css';

import { PlusOutlined,BugFilled,CaretRightOutlined, PlusCircleFilled, DownloadOutlined,EditOutlined, DeleteOutlined, EyeFilled, EyeTwoTone, EyeInvisibleFilled } from '@ant-design/icons';
// import 'antd/dist/antd.css';
import { SearchBar } from '../../components'
import '../../styles/custom-css/antd-custom.css';
import { Link } from 'react-router-dom';
import {

    getServerList,
    createServer,
    updateServer,
    deleteServer,
    getServerByOrgId,
    getAllServerByOrgId,
    getSubscriptionsByOrg,
    getServerExistByName,
    getServerByToken,
    getVulnerabilitiesByVersion
} from '../../service/mirth/MirthService'
import AuthService from '../../service/Auth/auth-service';
import { configurationData } from '../../service/ConfigService/Config';
import { version } from 'react/cjs/react.production.min';
import 'animate.css';

const { Panel } = Collapse;

const { Option } = Select;
const { Text } = Typography;
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: { offset: 10, span: 16 },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};
const openNotification = (message) => {
    notification.success({
        message: message,

    });
};
const uid = function(){
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
}
export default class ServerSettings extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {

            spin: true,
            serverDetails: [],
            cloneServerDetails: [],
            cronExpDetails: [],
            isFormVisible: false,
            isEdit: false,
            currentServer: {},
            initialValues: {},
            isModalVisible: false,
            confirmFormLoading: false,
            tabKey: "1",
            access: '',
            serverLimit:0,
            page:'serverSettings',
            interval:[],
            version:[],
            url:'',
            activeServers:0,
            modalMessage:'',
            modalTitle:'',
            modalFooter:null,
            selectedVersion:''
        }

        // this.handleTabChange = this.handleTabChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }
    componentDidMount() {
        configurationData(this.state.page).then((res) =>{
            if(res.code==200){
              let jsonData=JSON.parse(res.result[0].config);
              console.log(jsonData)
            this.setState({version:jsonData.version,interval:jsonData.interval,url:jsonData.url})
            
            
          

        // this.formRef.current.setFieldsValue({
        //     serverName: 'hello!',
        // });
        let user = AuthService.getCurrentUser();
        console.log(user)
        getAllServerByOrgId(user.orgId).then(res => {
            if (res.code == 200) {
                let serverLimit=0;
                let servers = res.result;
                let activeServers=0;
                servers.map((server)=>{
                    if(server.active)
                    {
                        activeServers++;
                    }
                })
                        getSubscriptionsByOrg(user.orgId).then((subRes)=>{
                        if (subRes.code == 200 && subRes.result.length >0) {
                            let subResult = subRes.result[0];
                           
                            let attrs =JSON.parse( subResult.planAttributes);
                            serverLimit = attrs.serverCount;
                        }
                       
                        this.setState({ serverDetails: res.result, cloneServerDetails: res.result, spin: false, access: AuthService.getCurrentUserAccess() ,serverLimit:serverLimit,activeServers:activeServers});
                        console.log(res.result)
                    })
              
            }
        })
    }
})
    }
    handleSearch(data) {
        this.setState({ serverDetails: data });
    }
    showForm = () => {
  
        if(this.state.activeServers >= this.state.serverLimit)
        {
            let url = this.state.url
            let res = ( <Result
                title="Sorry, you are not allowed to add more servers, as the server limit of the plan is being exceeded"
                subTitle="Please Contact our support  team to upgrade your plan."
                extra={<a href={url}><Button type="primary">Contact Support</Button></a>}
            />)
            this.setState({ isModalVisible:true ,modalMessage:res,modalTitle:''})
        }
        else
        {
             this.setState({ initialValues: {},isFormVisible: true, isEdit: false })
        
        }
      

    }
     handleSelectChange = value => {
      this.setState({selectedVersion:value})
      };
    
    onFinish = (values) => {
        console.log(values);
        this.setState({ confirmFormLoading: true, spin: true });

        let request = this.formRef.current.getFieldsValue();
        this.formRef.current.resetFields();
        if (this.state.isEdit) {
            let user = AuthService.getCurrentUser();
            request.serverid = this.state.initialValues.serverid;
            request.orgId = user.orgId;
            request.accessToken = uid();
           // console.log(this.state.initialValues)
            updateServer(request).then(res => {
                if (res.code == 200) {
                    this.setState({ initialValues: {}, isEdit: false })
                    getAllServerByOrgId(user.orgId).then(res => {
                        if (res.code == 200) {
                            let servers = res.result;
                            let activeServers=0;
                            servers.map((server)=>{
                                if(server.active)
                                {
                                    activeServers++;
                                }
                            })
                            this.setState({ serverDetails: res.result, cloneServerDetails: res.result, confirmFormLoading: false, isFormVisible: false, spin: false ,activeServers:activeServers});
                            openNotification("Server edited successfully!");
                        }
                    })

                }

            })
        }
        else {

           let user = AuthService.getCurrentUser();

           request.orgId = user.orgId;
           request.accessToken = uid();
           console.log(request)
            createServer(request).then(res => {
                if (res.code == 200) {
                    this.setState({ initialValues: {}, isEdit: false })
                    getAllServerByOrgId(user.orgId).then(res => {
                        if (res.code == 200) {
                            let servers = res.result;
                            let activeServers=0;
                            servers.map((server)=>{
                                if(server.active)
                                {
                                    activeServers++;
                                }
                            })
                            this.setState({ serverDetails: res.result, cloneServerDetails: res.result, confirmFormLoading: false, isFormVisible: false, spin: false ,activeServers:activeServers});
                            openNotification("Server created successfully!");
                        }
                    })

                }

            })
        }



    };
    onReset = () => {
        this.formRef.current.resetFields();

        this.setState({ isFormVisible: false, isEdit: false });
    };
    onResets = () => {
        this.formRef.current.resetFields();
      };
    downloadXMLFile = (record) => {
        const element = document.createElement("a");
        const file = new Blob([record.dataCollectorChannel], {type: 'application/xml'});
        element.href = URL.createObjectURL(file);
        element.download = record.serverName+"_monitoring.xml";
        document.body.appendChild(element); 
        element.click();
      }
    handleActionClick = (record, action) => {
        this.setState({ initialValues: record, spin: true });
        if (action == "delete") {
            let user = AuthService.getCurrentUser();

            deleteServer(record).then(res => {
                if (res.code == 200) {
                    this.setState({ initialValues: {}, isEdit: false });
                    getAllServerByOrgId(user.orgId).then(res => {
                        if (res.code == 200) {
                            let servers = res.result;
                            let activeServers=0;
                            servers.map((server)=>{
                                if(server.active)
                                {
                                    activeServers++;
                                }
                            })
                            this.setState({ serverDetails: res.result, cloneServerDetails: res.result, spin: false,activeServers:activeServers });
                            openNotification("Server deleted successfully!");
                        }
                    })
                }
            })
        }
        else if (action == "edit") {
            console.log(record);

            if(record.interval == 15000)
            {
                record.interval  = '15s';
            }
            else if(record.interval == 30000)
            {
                record.interval  = '30s';
            } else if(record.interval == 60000)
            {
                record.interval  = '1m';
            }
            console.log(record);
            this.formRef.current.setFieldsValue(record);
            this.setState({ isEdit: true, spin: false ,currentServer:record});


        }
        else if (action == "download") {
            console.log(record);
            this.downloadXMLFile(record);
            this.setState({ isEdit: false, spin: false });
        }
    }
    handleCancel() {
        this.setState({ isModalVisible: false })
    }
    checkIfVulnerable(version) {
        if(version == '4.4.1' ||version == '4.4.2' || version == '4.5.0' ||version == '' )
        {
         return false;
        }
        else
        {
         return true;
        }
     }
     listVulnerability(version){

        getVulnerabilitiesByVersion(version).then(res=>{
            if(res.code == 200 && res.result.length > 0)
            {
                let url = this.state.url
                let result = res.result;
                let Vulnerability =JSON.parse(JSON.parse(result[0].vulnerability_details));
               
                let comp = '';
                let footer = null;
                if(Vulnerability.length >0)
                {
                   comp = (
                    <>
                    <h2 style={{fontWeight:'1000'}}>{'Vulnerabilities in Mirth Connect version - '+version}</h2>
                    <Divider />
                        <Collapse
                          bordered={false}
                          accordion
                          //defaultActiveKey={['1']}
                          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                          className="site-collapse-custom-collapse"
                        >
                       {Vulnerability.map((vul, index) => (
                                <Panel header={vul.name} key={index} className="site-collapse-custom-panel">
                                <p>{vul.description}</p>
                              </Panel>
                              ))}
                         
                          
                        </Collapse>
                      
                       
                        </>
                      )
                      footer=(  <Text style={{marginTop:'20px'}}>This version is affected by critical vulnerability, please {<a href={"https://control-center.freshdesk.com/support/home"}> Contact Support </a>} to upgrade Mirth Connect to version 4.4.1 or later!</Text>)
                    
                }
                this.setState({modalMessage:comp,modalFooter:footer,isModalVisible:true})
                    
                console.log(Vulnerability)
            }
            else
            {
                notification.error({
                    message: "No data for this version",
                  })
            }
        })
    
    }
    
    render() {
        const { currentServer,url,interval,version,spin,selectedVersion, serverDetails,modalMessage,modalTitle, access,isModalVisible, cloneServerDetails, isFormVisible, initialValues, confirmFormLoading, tabKey, cronExpDetails, isEdit ,modalFooter} = this.state;
        const actionStatus = () => {
            if (access == "read&write") {
                return false
            }
            else {
                return true
            }

        };
        // const serverName=(text)=>{
        //     if(serverDetails.active==true){
        //         return <Badge status="success" text={text}/>;

        //     }
        //     else{
        //         <Badge status="error" text={text} />;


        //     }
          
        // }
 
        const serverColumns = [
            { title: 'Server Name', dataIndex: 'serverName', key: 'serverName',
            render: (text, record) => (
                <>
                   <Badge color={record.active?"#87d068":"#8c8c8c"} text=""/><Text style={{ color:'black', fontSize: "14px", transform: "translateX(-20px)" }}> {text}</Text>
               
                </>)},
            { title: 'Mirth Version', dataIndex: 'serverVersion', key: 'serverVersion',
            render: (text, record) => (
                <>
                {text}
                  {(this.checkIfVulnerable(text) && record.active) && <Tooltip title="Vulnerable version">
                                    <BugFilled onClick={() => { this.listVulnerability(text) }} style={{ color: 'red', fontSize: '18px', marginLeft: '10px' }} className="animate__animated animate__heartBeat" />                               
                                </Tooltip>}
                </>)},
        
            { title: 'CC Channel Version', dataIndex: 'ccVersion', key: 'ccVersion' },
            {
                title: 'Actions', dataIndex: '', key: '',
                render: (text, record) => (
                    <>{record.active && 
                        <Space >
                            <Tooltip title="Download Channel">
                            <Button  type="text" disabled={actionStatus()}  onClick={() => { this.handleActionClick(record, 'download') }} icon={<DownloadOutlined />}/>
                              
                            </Tooltip>
                            <Tooltip title="Edit">
                            <Button  type="text" disabled={actionStatus()}  onClick={() => { this.handleActionClick(record, 'edit') }} icon={<EditOutlined />}/>
                              
                            </Tooltip>
                            <Tooltip title="Delete">
                            <Popconfirm
    title="Are you sure ? Deletion of server will delete the associated jobs as well."
    
    onConfirm={ ()=>{this.handleActionClick(record, 'delete')} }
  
   
    
    okText="Yes"
    cancelText="No"
  >
    <Button type="text" disabled={actionStatus()}  icon={<DeleteOutlined />} />

  </Popconfirm>
                                
                            </Tooltip>

                        </Space>}
                    </>)
            }

        ];
        return (
            <>
                <Breadcrumb>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Settings</Breadcrumb.Item>
                    <Breadcrumb.Item>Server</Breadcrumb.Item>
                </Breadcrumb>


                <Spin size="large" spinning={spin}>
                <Modal visible={isModalVisible} footer={modalFooter} onCancel={this.handleCancel}  bodyStyle={{ overflowY: "scroll", height: 480 }} >
                                    {modalMessage}         
                        </Modal>
                    <div class="content_panel">
                        <Row>

                            <Col span={12}>
                                {(!isEdit && !isFormVisible) && <div class="content_panel_heading" >Server
                                </div>}
                                {isEdit && <div class="content_panel_heading" >Edit Server
                                </div>}
                                {isFormVisible && <div class="content_panel_heading" >Add Server
                                </div>}
                            </Col>
                            <Col span={12}>
                                {(!isEdit && !isFormVisible) && <Button disabled={actionStatus()} style={{ float: 'right', borderRadius: '7px', backgroundColor: '#efefef', fontFamily: 'AktivGroteskMedium', border: 'none' }} onClick={this.showForm}>
                                    <PlusCircleFilled style={{ color: '#fc9558', fontSize: '16px' }} />  Add Server
                                </Button>}
                            </Col>


                        </Row>
                        <div class="divider-1"> <span></span></div>
                        {/* <Divider /> */}

                        <Form ref={this.formRef} labelCol={{ span: 10 }} hideRequiredMark
                            wrapperCol={{ span: 16 }} name="control-ref" onFinish={this.onFinish}>
                            {(isEdit || isFormVisible) &&
                                <>
                                    <Form.Item
                                        name="serverName"
                                        label="Server Name"
                                        validateTrigger="onBlur"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input Name!',
                                                
                                            },
                                            {
                                                validator: (_, value) =>
                                                  !value.includes(" ")
                                                    ? Promise.resolve()
                                                    : Promise.reject(new Error("No spaces allowed"))
                                              },
                                              ({
                                        
                                                getFieldValue
                                            }) => ({
                                               
                                                validator(rule, value) {
                                                  
                                                        return new Promise((resolve, reject) => {
                                                            if(isEdit)
                                                            {
                                                                resolve();
                                                            //     getServerByToken(currentServer.accessToken).then((res)=>{
                                                             
                                                            //     let result = res.result;
                                                            //     if(result.serverid ==  currentServer.serverid)
                                                            //     {
                                                            //         resolve();
                                                            //     }
                                                            //     else
                                                            //     {
                                                            //         reject("Server name already taken!");
                                                            //     }
                                                           
                                                            // })
                                                        }
                                                        else
                                                        {
                                                            getServerExistByName(value).then((res)=>{
                                                                console.log(res)
                                                                if(res.result[0])
                                                                {

                                                                    reject("Server name already taken!");
                                                                }
                                                                else
                                                                {
                                                                    resolve();
                                                                }
                                                               })
                                                        }
                                                         });
                                               
                                                }
        
                                            })
                                          
                                        ]}
                                    >

                                        <Input disabled={isEdit?true:false}
                                            placeholder="Enter Name"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="serverVersion"
                                        label="Mirth Version"
                                        rules={[
                                            {
                                                required: true,
                                                 message: 'Please input version!',
                                            },
                                        ]}

                                    >
                                   
                                            <Select placeholder="Select version"  onChange={this.handleSelectChange} style={{ width: '300px' }} >
                                            {version.map((int) => (
                                                        <Option key={int.key} value={int.key}>{int.key} </Option>
                                                    ))

                                                    }
                                                      
                                            </Select>
                                          
                                    </Form.Item>
                                   
                                    <Form.Item
                                        name="interval"
                                        label="Poller Interval"
                                        rules={[
                                            {
                                                required: true,
                                                 message: 'Please input interval!',
                                            },
                                        ]}

                                    >
                                     
                                            <Select placeholder="Select Interval" style={{ width: '300px' }} >
                                            {interval.map((int) => (
                                                        <Option key={int.key} value={int.val}>{int.key}</Option>
                                                    ))

                                                    }
                                             {/* <Option value="300000">5m</Option> */}
                                            </Select>

                                    </Form.Item>

                                    <Form.Item {...tailLayout}>
                                        <Button type="primary" style={{ borderRadius: '7px' }} htmlType="submit">
                                            Submit
                                </Button>

                                        <Button htmlType="button" style={{ borderRadius: '7px', backgroundColor: '#efefef', marginLeft: '10px' }} onClick={this.onReset}>
                                            Cancel
                                </Button>
                                {!isEdit &&   <Button htmlType="button" style={{ backgroundColor: '#efefef',margin: "0 8px" }} onClick={this.onResets}>
                                    Reset</Button>}


                                    </Form.Item>
                                </>
                            }
                        </Form>

                        {(!isEdit && !isFormVisible) &&
                            <div >

                                <SearchBar data={cloneServerDetails} handler={this.handleSearch}></SearchBar>

                                <Table
                                    style={{ marginTop: '10px' }}
                                    columns={serverColumns}
                                    dataSource={serverDetails}
                                />
                            </div>
                        }
                    </div>
                </Spin>
            </>
        );
    }
}