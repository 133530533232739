import React, { Component } from "react";
import { Row, Col, Tag, Table, Radio, Typography, Divider, Card, Descriptions, Result, Space, Select, Tooltip, Modal, Form, Input, Button, DatePicker, Breadcrumb, Spin } from 'antd';
import {
    PlayCircleOutlined,
    StopOutlined,
    PauseCircleOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import {
    getServerList,
    getData,
    getDataNew,
    getChannelStats

} from '../../../service/mirth/MirthService'
import { Line, Area } from '@ant-design/plots';
import moment from 'moment-timezone';
import FlowGraph from '../../../components/Graph/FlowGraph'
import '../../../styles/custom-css/antd-custom.css';
import { properties } from '../../../properties';
import { configurationData } from "../../../service/ConfigService/Config";
import authService from "../../../service/Auth/auth-service";


const { Option } = Select;
const { Text } = Typography;



// const radioFilterValues = [
//     {
//         key: 1,
//         value: 'received',
//         data: 'Received'
//     },
//     {
//         key: 2,
//         value: 'sent',
//         data: 'Sent'
//     },
//     {
//         key: 3,
//         value: 'error',
//         data: 'Error'
//     },
//     {
//         key: 4,
//         value: 'queued',
//         data: 'Queued'
//     },
//     {
//         key: 5,
//         value: 'filtered',
//         data: 'Filtered'
//     }
// ]
export default class ChannelViewGraph extends Component {

    constructor(props) {
        super(props)

        this.state = {
            spin: true,
            graphData: [],
            serverOptions: [],
            resultStatus: '',
            resultTitle: '',
            resultSubTitle: '',
            isError: false,
            currentServer: '',
            currentEndPoint: '',
            radioFilter: 'received',
            radioFilterValues:[],
            interval:[],
            page:'channelViewGraph'
        }

        this.handleServerChange = this.handleServerChange.bind(this);
        this.onRadioChange = this.onRadioChange.bind(this);
        this.handleTimeChange = this.handleTimeChange.bind(this);
    }

    componentDidMount() {
        let user = authService.getCurrentUser();
        configurationData(this.state.page).then((res) =>{
          if(res.code==200){
            let jsonData=JSON.parse(res.result[0].config);
            console.log(jsonData)
          this.setState({radioFilterValues:jsonData.radioFilterValues,interval:jsonData.interval,resultStatus:jsonData.status.resultStatus,
            resultTitle:jsonData.status.resultTitle,
            resultSubTitle:jsonData.status.resultSubTitle})
          
      
        getServerList().then(ServerRes => {
            if (ServerRes.code == 200) {
                let server = [];
                server = ServerRes.result;

                if (server.length > 0) {
                    this.setState({ serverOptions: server, spin: false });
                    this.handleServerChange(server[0].serverId);
                }
                else {
                    this.setState({ resultStatus: 'warning', resultTitle: 'Add a server to check statistics', isError: true, spin: false })
                }
            }
        });
    }
})
         
          //  this.setState({graphData:data});
    }

    handleServerChange(val) {
        let endPoint;
        this.state.serverOptions.map((server) => {
            if (server.serverId == val) {
                endPoint = server.channelEndPoint;
            }
        });
        this.setState({ spin: true, currentServer: val, isError: false, currentEndPoint: endPoint })
        getDataNew(endPoint, "channels").then(res => {
            console.log(res.list.channel)
            let channels = res.list.channel;
            let channelData = [];
            for (let i = 0; i < channels.length; i++) {
                let channelJson = {};
                channelJson.name = channels[i].name;
                channelJson.id = channels[i].id;

                let source = {};
                source.transportName = channels[i].sourceConnector.transportName;
                source.name = channels[i].sourceConnector.name;

                let destination = {};
                destination.transportName = channels[i].destinationConnectors.connector.transportName;
                destination.name = channels[i].destinationConnectors.connector.name;
                channelJson.source = source;
                channelJson.destination = destination;
                channelData.push(channelJson);
            }

            let flowgraphData;
             flowgraphData=this.formNodeAndEdges(channelData[0]);
           this.setState({graphData:this.formNodeAndEdges(channelData[0])});
            console.log(channelData);
           
            this.setState({ spin: false })
        });
        // getDataNew(endPoint,"serverId").then(serverId=>{
        //     if(serverId.string){
        //    let request={};
        //    request.serverId = serverId.string;
        //    request.time = '5 DAYS'
        //     getChannelStats(request).then(res=>{
        //     if(res.code ==200)
        //     {
        //         let result = res.result;
        //         for(let i=0;i<result.length;i++)
        //         {
        //             result[i].observationTime = moment(result[i].observationTime).tz("America/New_York").format('YYYY-MM-DD HH:mm');
        //         }
        //         this.setState({graphData:result,spin:false})
        //     }
        //     else
        //     {
        //         this.setState({isError:true,spin: false})
        //     }

        // })
        //     }
        //     else
        //     {
        //         this.setState({isError:true,spin: false})
        //     }
        // });
    }

    formNodeAndEdges(data) {
        let channelData={};
        let nodes = [], edges = [];
        let sourceNode = {
            id: '',
            value: {
                title: '',
                items: [
                    {
                        text: '',
                    },
                ],
            },
        };
        let destinationNode = {
            id: '',
            value: {
                title: '',
                items: [
                    {
                        text: '',
                    },
                ],
            },
        };

        let edge = {
            source: '',
            targer: ''
        }
        sourceNode.id = '0';
        sourceNode.value.title = data.source.name;
        sourceNode.value.items[0].text = data.source.transportName;
        nodes.push(sourceNode);

        destinationNode.id = '1';
        destinationNode.value.title = data.destination.name;
        destinationNode.value.items[0].text = data.destination.transportName;
        nodes.push(destinationNode);

        edge.source = '0';
        edge.target = '1';
        edges.push(edge);

        //channelData.id = data.id;
        channelData.nodes = nodes;
        channelData.edges = edges;
      
        return channelData;
    }
    onRadioChange(e) {
        this.setState.radioFilterValues.map((filter) => {

            if (e.target.value == filter.key) {
                this.setState({ radioFilter: filter.value, graphData: this.state.graphData })
            }
        })

    }
    handleTimeChange(val) {
        this.setState({ spin: true });
        let endPoint = this.state.currentEndPoint;
        getDataNew(endPoint, "serverId").then(serverId => {
            if (serverId.string) {
                let request = {};
                request.serverId = serverId.string;
                request.time = val
                getChannelStats(request).then(res => {
                    if (res.code == 200) {
                        let result = res.result;
                        for (let i = 0; i < result.length; i++) {
                            result[i].observationTime = moment(result[i].observationTime).tz("America/New_York").format('YYYY-MM-DD HH:mm');
                        }
                        this.setState({ graphData: result, spin: false })
                    }
                    else {
                        this.setState({ isError: true, spin: false })
                    }

                })
            }
            else {
                this.setState({ isError: true, spin: false })
            }
        });
    }
    render() {


        const {spin, graphData, serverOptions, isError, resultStatus, resultTitle, resultSubTitle, radioFilter } = this.state;


        return (
            <>
                <Breadcrumb>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Mirth Monitoring</Breadcrumb.Item>
                    <Breadcrumb.Item>Channel Flow Graph</Breadcrumb.Item>
                </Breadcrumb>

                <div className="content_panel">

                    <Spin size="large" spinning={spin}>

                        <Row>

                            <Col span={7}>
                                <div class="content_panel_heading" >Channel Flow Graph
                                </div>
                            </Col>
                            {serverOptions.length !== 0 &&
                                <>
                                    <Col span={2}>
                                        <Text style={{ marginTop: '2px' }}>Server: </Text>
                                    </Col>
                                    <Col span={12} style={{ marginLeft: '-30px', marginTop: '-4px' }}>

                                        <Select placeholder="Select server" defaultValue={serverOptions[0].serverId} onChange={this.handleServerChange} style={{ width: 160 }} bordered={false}>
                                            {serverOptions.map((server) => (
                                                <Option key={server.serverId} value={server.serverId}>{server.serverName}</Option>
                                            ))

                                            }
                                        </Select>
                                    </Col>
                                </>}
                        </Row>
                        <Divider />
                        {
                            isError ? (
                                <>
                                    <Result
                                        status={resultStatus}
                                        title={resultTitle}
                                        subTitle={resultStatus == '500' ? resultSubTitle : ''}
                                        extra={resultStatus == '500' ? <></> : <Link to="/settings/server"><Button type="primary" style={{ borderRadius: '7px' }}>Add Server</Button></Link>}
                                    />
                                </>) : (
                                    <>
                                        {/* <Row>

                                            <Col span={14}>
                                                <Radio.Group onChange={this.onRadioChange} style={{ marginBottom: '20px' }} defaultValue={1}>
                                                    {radioFilterValues.map((filter) => (
                                                        <Radio value={filter.key}>{filter.data}</Radio>
                                                    ))

                                                    }
                                                </Radio.Group>
                                            </Col>
                                            <Col span={1}>
                                                <Text >Last: </Text>
                                            </Col>
                                            <Col span={4}>
                                                <Select style={{ marginTop: '-10px' }} defaultValue="5 DAYS" onChange={this.handleTimeChange}>
                                                    <Option value="12 HOURS">12 HOURS</Option>
                                                    <Option value="1 DAY">1 DAY</Option>
                                                    <Option value="5 DAYS">5 DAYS</Option>
                                                    <Option value="15 DAYS">15 DAYS</Option>
                                                    <Option value="30 DAYS">30 DAYS</Option>
                                                    <Option value="60 DAYS">60 DAYS</Option>
                                                </Select>
                                            </Col>
                                        </Row> */}
                                        {/* <LineGraph graphData={graphData} typeFilter={radioFilter}/> */}
                                        {graphData.length !=0 && <FlowGraph graphData={graphData}/>}

                                    </>)
                        }

                    </Spin>

                </div>
            </>
        );
    }
}

