import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import './fonts/PingFang SC Regular/PingFang SC Regular.ttf';


// import './assets/fonts/PingFang SC Regular.ttf';

// import './assets/styles/bootstrap/bootstrap.min.css';
// import './components/css/bootstrap/bootstrap.min.js';
// import './components/css/bootstrap/popper.min.js';

import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
