import React, { Component } from 'react';

import { Breadcrumb, Spin, Table,Steps, Divider, Row, Col, Form, Button, notification, Input, Typography, Tabs, Space, Tooltip, InputNumber, Select } from 'antd';
// import './Settings.css';

import { PlusOutlined, PlusCircleFilled, DownloadOutlined,EditOutlined, DeleteOutlined, EyeFilled, EyeTwoTone, EyeInvisibleFilled } from '@ant-design/icons';
// import 'antd/dist/antd.css';
import { SearchBar } from '../../../components'
import '../../../styles/custom-css/antd-custom.css';
import TextArea from 'antd/lib/input/TextArea';
import {
    saveVulnerabilities,
    updateVulnerabilities,
    deleteVulnerabilities,
    getAllVulnerabilities
} from '../../../service/mirth/MirthService'
import moment from 'moment-timezone';
import AuthService from '../../../service/Auth/auth-service';



const { Option } = Select;
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: { offset: 10, span: 16 },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};
const openNotification = (message) => {
    notification.success({
        message: message,

    });
};
const uid = function(){
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
}
export default class MCVulnerabilities extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {

            spin: true,
          
            vulDetails: [],
            cloneVulDetails: [],
            isFormVisible: false,
            isEdit: false,
            currentServer: {},
            initialValues: {},
            request: {},
            confirmFormLoading: false,
            tabKey: "1",
            access: '',
            activeStep:0
        }

         this.loadData = this.loadData.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
     this.handleActionClick = this.handleActionClick.bind(this);
    }
    componentDidMount() {

    
        this.loadData();
    }

    loadData(){

        getAllVulnerabilities().then((res)=>{
            if (res.code == 200) {
                let result = res.result;
                this.setState({vulDetails: result, cloneVulDetails: result, spin: false, access: AuthService.getCurrentUserAccess() });
               
            }
           
        })
    }

    handleSearch(data) {
        this.setState({ vulDetails: data });
    }
    showForm = () => {

     
        this.setState({ initialValues: {}, isEdit: false ,isFormVisible: true  })


    }
 
    onFinish = (values) => {
        this.setState({ confirmFormLoading: true, spin: true });

        let request = this.formRef.current.getFieldsValue();
       
        this.formRef.current.resetFields();
        if (this.state.isEdit) {

            let vulRequest ={
                id:request.id,
            }
          
            vulRequest.vulnerability_details = JSON.stringify(request.vul_value_alt);
           // vulRequest.vulnerability_details = JSON.parse(request.vul_value_alt);
          
            updateVulnerabilities(vulRequest).then(res => {
            
                if (res.code == 200) {
                    this.setState({ initialValues: {}, confirmFormLoading: false, isFormVisible: false, isEdit: false, spin: false });
                    openNotification("Vulernabilities Updated successfully!");
                    this.loadData();

                }
            })
        }
        else {

  
            let vulRequest ={
                mc_version:request.mc_version,
            }
            
            vulRequest.vulnerability_details = JSON.stringify(request.vul_value_alt);
           
            saveVulnerabilities(vulRequest).then(res => {
         
                if (res.code == 200) {

                    this.setState({ initialValues: {}, confirmFormLoading: false, isFormVisible: false, spin: false });
                    openNotification("Vulernabilities added successfully!");
                    this.loadData();

                }
            })
         
           
       
        }



    };
    onReset = () => {
        this.formRef.current.resetFields();

        this.setState({ isFormVisible: false, isEdit: false });
    };
    onResets = () => {
        this.formRef.current.resetFields();
      };
    handleActionClick = (record, action) => {

        this.setState({ initialValues: record, spin: true });
        if (action == "delete") {

                deleteVulnerabilities(record.id).then(res => {
                    if (res.code == 200) {
                        this.setState({ initialValues: {}, isEdit: false, spin: false  });
                        openNotification("Vulernabilities deleted successfully!");
                        this.loadData();
                
                    }
                })
     
        }
        else if (action == "edit") {

            let value = JSON.parse(record.vulnerability_details); // Parse the string into a JavaScript array
            const formattedJsonString = JSON.stringify(value, null, 2); // Stringify the array with formatting
            
            record.vul_value_alt = value;
            this.formRef.current.setFieldsValue(record);
            this.setState({ isEdit: true, spin: false });


        }
     
    }

    render() {
        const { spin, vulDetails,activeStep, access, cloneVulDetails, isFormVisible, initialValues, confirmFormLoading, tabKey, isEdit } = this.state;
        const actionStatus = () => {
            if (access == "read&write") {
                return false
            }
            else {
                return true
            }

        };
        const getFullDate = (date) => {
            if (date !== null) {
                const utcDate = new Date(date);
                const localTimeString = utcDate.toLocaleString();
                return localTimeString;
            }
        };
        const vulColumns = [
            { title: 'MC Version', dataIndex: 'mc_version', key: 'mc_version',
             sorter: (a, b) => {
                const versionA = a.mc_version.split('.').map(Number);
                const versionB = b.mc_version.split('.').map(Number);
          
                for (let i = 0; i < Math.max(versionA.length, versionB.length); i++) {
                  if (versionA[i] === undefined) return -1;
                  if (versionB[i] === undefined) return 1;
                  if (versionA[i] < versionB[i]) return -1;
                  if (versionA[i] > versionB[i]) return 1;
                }
                return 0;
              }
            },
            { title: 'Created on UTC', dataIndex: 'created_on_utc', key: 'created_on_utc',
            sorter: (a, b) => moment(a.created_on_utc).unix() - moment(b.created_on_utc).unix(), 
            render: (text, record) => <>{getFullDate(text)}</> },
           
            {
                title: 'Actions', dataIndex: '', key: '',
                render: (text, record) => (
                    <>
                        <Space >
                          
                            <Tooltip title="Edit">
                            <Button  type="text" disabled={actionStatus()}  onClick={() => { this.handleActionClick(record, 'edit') }} icon={<EditOutlined />}/>
                              
                            </Tooltip>
                            <Tooltip title="Delete">
                                <Button type="text" disabled={actionStatus()} onClick={() => { this.handleActionClick(record, 'delete') }} icon={<DeleteOutlined />} />

                            </Tooltip>

                        </Space>
                    </>)
            }

        ];
      
     
        return (
            <>
                <Breadcrumb>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Settings</Breadcrumb.Item>
                    <Breadcrumb.Item>MC Vulnerabilities</Breadcrumb.Item>
                </Breadcrumb>


                <Spin size="large" spinning={spin}>
                    <div class="content_panel">
                        <Row>

                            <Col span={12}>
                                {(!isEdit && !isFormVisible) && <div class="content_panel_heading" >Vulnerabilities
                                </div>}
                                {isEdit && <div class="content_panel_heading" >Edit Vulnerabilities
                                </div>}
                                {isFormVisible && <div class="content_panel_heading" >Add Vulnerabilities
                                </div>}
                            </Col>
                            <Col span={12}>
                                {(!isEdit && !isFormVisible) && <Button disabled={actionStatus()} style={{ float: 'right', borderRadius: '7px', backgroundColor: '#efefef', fontFamily: 'AktivGroteskMedium', border: 'none' }} onClick={this.showForm}>
                                    <PlusCircleFilled style={{ color: '#fc9558', fontSize: '16px' }} />  Add Vulnerabilities
                                </Button>}
                            </Col>


                        </Row>
                        <div class="divider-1"> <span></span></div>
                        {/* <Divider /> */}

                        <Form ref={this.formRef} labelCol={{ span: 10 }} hideRequiredMark
                            wrapperCol={{ span: 16 }} name="control-ref" onFinish={this.onFinish}>
                            { (isEdit || isFormVisible) &&
                                <>
                                 <Form.Item
                                        name="id"
                                        label="id"
                                        style={{ display: isEdit?'': 'none' }}
                                    >

                                        <Input disabled={true}/>
                                    </Form.Item>
                                
                                    <Form.Item
                                        name="mc_version"
                                        label="MC Version"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input version!',
                                            },
                                        ]}
                                    >

                                        <Input/>
                                    </Form.Item>
                                    <Form.Item
                                        name="vul_value_alt"
                                        label="Vulnerability Details"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input Details!',
                                            },
                                        ]}
                                    >

                                        <TextArea style={{ width: "50%", height: '200px' }} />
                                    </Form.Item>
                                <Form.Item {...tailLayout}>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                                <Button htmlType="button" style={{ backgroundColor: '#efefef',margin: "0 8px" }} onClick={this.onReset}>
                                 Cancel
                               </Button>
                               <Button htmlType="button"style={{ backgroundColor: '#efefef',margin: "0 8px" }} onClick={this.onResets}> Reset</Button>
                               </Form.Item>
                                </>
                            }

                        </Form>

                        {(!isEdit && !isFormVisible) &&
                            <div >

                                <SearchBar data={cloneVulDetails} handler={this.handleSearch}></SearchBar>

                                <Table
                                    style={{ marginTop: '10px' }}
                                    columns={vulColumns}
                                    dataSource={vulDetails}
                                    pagination={{ pageSizeOptions: ['10', '20', '30', '40'] }}
                                />
                            </div>
                        }
                    </div>
                </Spin>
            </>
        );
    }
}