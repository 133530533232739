import React, { Component } from 'react';

import { Breadcrumb, Spin, Table,Steps, Divider, Row, Col, Form, Button, notification, Input, Typography, Tabs, Space, Tooltip, InputNumber, Select } from 'antd';
// import './Settings.css';

import { PlusOutlined, PlusCircleFilled, DownloadOutlined,EditOutlined, DeleteOutlined, EyeFilled, EyeTwoTone, EyeInvisibleFilled } from '@ant-design/icons';
// import 'antd/dist/antd.css';
import { SearchBar } from '../../../components'
import '../../../styles/custom-css/antd-custom.css';
import {

    getServerList,
    createServer,
    updateServer,
    deleteServer,
    getServerByOrgId,
    saveOrganization,
    getAllOrg,
    saveSubscription,
    getSubscriptionsByOrg,
    deleteOrganization,
    deleteSubscription,
    updateOrganization,
    updateSubscription
} from '../../../service/mirth/MirthService'
import moment from 'moment-timezone';
import AuthService from '../../../service/Auth/auth-service';



const { Option } = Select;
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: { offset: 10, span: 16 },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};
const openNotification = (message) => {
    notification.success({
        message: message,

    });
};
const uid = function(){
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
}
export default class OrganizationSettings extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {

            spin: true,
            orgDetails: [],
            cloneOrgDetails: [],
            isFormVisible: false,
            isEdit: false,
            currentServer: {},
            initialValues: {},
            orgRequest: {},
            confirmFormLoading: false,
            tabKey: "1",
            access: '',
            activeStep:0
        }

         this.loadData = this.loadData.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
     this.handleActionClick = this.handleActionClick.bind(this);
    }
    componentDidMount() {

        // this.formRef.current.setFieldsValue({
        //     serverCount:"2",channelCount:"10",planDuration:"6m"
        // });
        this.loadData();
    }

    loadData(){

        getAllOrg().then((res)=>{
            if (res.code == 200) {
                let result = res.result;
                this.setState({orgDetails: result, cloneOrgDetails: result, spin: false, access: AuthService.getCurrentUserAccess() });
                // for(let i=0;i<result.length;i++)
                // {
                //     getSubscriptionsByOrg(result[i].orgId).then((subRes)=>{
                //         if (subRes.code == 200 && subRes.result.length >0) {
                //             let subResult = subRes.result[0];
                //             result[i].planDuration = subResult.planDuration;
                //             result[i].planExpiryOn =moment( subResult.planExpiryOn).tz("UTC").format("YYYY-MM-DD HH:mm:ss");
                //             let attrs =JSON.parse( subResult.planAttributes);
                //             result[i].channelCount = attrs.channelCount;
                //             result[i].serverCount = attrs.serverCount;
                //             result[i].planId = subResult.planId;
                //             result[i].subDetails = subResult;
                //         }
                //         if(i == (result.length-1)){
                //             this.setState({orgDetails: result, cloneOrgDetails: result, spin: false, access: AuthService.getCurrentUserAccess() });
                //         }
                   

                //     })

                 
                    
                // }
                
            }
           
        })
    }

    handleSearch(data) {
        this.setState({ orgDetails: data });
    }
    showForm = () => {

     
        this.setState({ initialValues: {}, isEdit: false ,isFormVisible: true  })


    }

    onFinish = (values) => {
        this.setState({ confirmFormLoading: true, spin: true });

        let request = this.formRef.current.getFieldsValue();
        this.formRef.current.resetFields();
        if (this.state.isEdit) {

            let orgRequest ={
                orgName:request.orgName,
                orgDomain:request.orgDomain,
                orgId:request.orgId
            }
            var end_date;
            var start_date = moment.tz("UTC");

            if(request.planDuration === "6m")
            {
                 end_date = moment(start_date).add(6,"months").format('YYYY-MM-DD HH:mm:ss');
            }
            else if(request.planDuration === "1yr")
            {
                 end_date = moment(start_date).add(1,"years").format('YYYY-MM-DD HH:mm:ss');
            }
            else if(request.planDuration === "2yr")
            {
                 end_date = moment(start_date).add( 2,"years").format('YYYY-MM-DD HH:mm:ss');
            }
            request.planExpiryOn =  new Date(end_date);
            let attr = {
                serverCount:request.serverCount,
                channelCount:request.channelCount
            };
            request.planAttributes = JSON.stringify(attr);
            updateOrganization(orgRequest).then(res => {
            
                if (res.code == 200) {
                   
                    request.orgId = orgRequest.orgId;
                             this.setState({  initialValues: {}, confirmFormLoading: false, isFormVisible: false ,isEdit:false,spin:false});
                            openNotification("Organization Updated successfully!");
                           this.loadData();
                    // updateSubscription(request).then(subRes => {
                    //     if (subRes.code == 200) {
                    //         this.setState({  confirmFormLoading: false, isFormVisible: false ,spin:false});
                    //         openNotification("Organization Updated successfully!");
                    //        this.loadData();
                    //     }
                    // })

                }
            })
        }
        else {

  
            let orgRequest = this.state.orgRequest;
            // let attr = {
            //     serverCount:request.serverCount,
            //     channelCount:request.channelCount
            // };
            // request.planAttributes = JSON.stringify(attr);
            // var end_date;
            // var start_date = moment.tz("UTC");

            // if(request.planDuration === "6m")
            // {
            //      end_date = moment(start_date).add(6,"months").format('YYYY-MM-DD HH:mm:ss');
            // }
            // else if(request.planDuration === "1yr")
            // {
            //      end_date = moment(start_date).add(1,"years").format('YYYY-MM-DD HH:mm:ss');
            // }
            // else if(request.planDuration === "2yr")
            // {
            //      end_date = moment(start_date).add( 2,"years").format('YYYY-MM-DD HH:mm:ss');
            // }
            // request.planExpiryOn = new Date(end_date);

          
            saveOrganization(request).then(res => {
              //  let orgId;
                if (res.code == 200) {
                    // orgId = res.result[0];
                    // request.orgId = orgId;
                              this.setState({  initialValues: {}, confirmFormLoading: false, isFormVisible: false ,spin:false});
                            openNotification("Organization created successfully!");
                           this.loadData();
                    // saveSubscription(request).then(subRes => {
                    //     if (subRes.code == 200) {
                    //         this.setState({  confirmFormLoading: false, isFormVisible: false ,spin:false});
                    //         openNotification("Organization created successfully!");
                    //        this.loadData();
                    //     }
                    // })

                }
            })
         
           
       
        }



    };
    onReset = () => {
        this.formRef.current.resetFields();

        this.setState({ isFormVisible: false, isEdit: false });
    };
    onResets = () => {
        this.formRef.current.resetFields();
      };
    handleActionClick = (record, action) => {

        this.setState({ initialValues: record, spin: true });
        if (action == "delete") {

                deleteOrganization(record.orgId).then(res => {
                    if (res.code == 200) {
                        this.setState({ initialValues: {}, isEdit: false, spin: false  });
                        openNotification("Organization deleted successfully!");
                        this.loadData();
                        // deleteSubscription(record.subDetails.planId).then(subres => {
                        //     if (subres.code == 200) {
                               
                        //     }
                        // })
                    }
                })
     
        }
        else if (action == "edit") {
            
            this.formRef.current.setFieldsValue(record);
            this.setState({ isEdit: true, spin: false });


        }
     
    }

    //  next() {
    //     const nextStep = this.state.activeStep + 1;
    //     let request = this.formRef.current.getFieldsValue();
     
    //     this.formRef.current
    //     .validateFields()
    //     .then(() => {
          
    //         this.setState({orgRequest:request, activeStep: nextStep });

    //     })
    //     .catch((err) => console.log(err));
       
    //   }
    
    //    prev() {
    //     const prevStep = this.state.activeStep - 1;
    //     this.setState({ activeStep: prevStep });
    //   }
    
    render() {
        const { spin, orgDetails,activeStep, access, cloneOrgDetails, isFormVisible, initialValues, confirmFormLoading, tabKey, isEdit } = this.state;
        const actionStatus = () => {
            if (access == "read&write") {
                return false
            }
            else {
                return true
            }

        };
        const orgColumns = [
            { title: 'Organization Name', dataIndex: 'orgName', key: 'orgName' },
            { title: 'Organization Domain', dataIndex: 'orgDomain', key: 'orgDomain' },
            // { title: 'Plan Duartion', dataIndex: 'planDuration', key: 'planDuration' },
            // { title: 'Plan Expires on', dataIndex: 'planExpiryOn', key: 'planExpiryOn' },
            // { title: 'Max Channels', dataIndex: 'channelCount', key: 'channelCount' },
            // { title: 'Max Servers', dataIndex: 'serverCount', key: 'serverCount' },
            {
                title: 'Actions', dataIndex: '', key: '',
                render: (text, record) => (
                    <>
                        <Space >
                          
                            <Tooltip title="Edit">
                            <Button  type="text" disabled={actionStatus()}  onClick={() => { this.handleActionClick(record, 'edit') }} icon={<EditOutlined />}/>
                              
                            </Tooltip>
                            <Tooltip title="Delete">
                                <Button type="text" disabled={actionStatus()} onClick={() => { this.handleActionClick(record, 'delete') }} icon={<DeleteOutlined />} />

                            </Tooltip>

                        </Space>
                    </>)
            }

        ];
      
     
        return (
            <>
                <Breadcrumb>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Settings</Breadcrumb.Item>
                    <Breadcrumb.Item>Organization</Breadcrumb.Item>
                </Breadcrumb>


                <Spin size="large" spinning={spin}>
                    <div class="content_panel">
                        <Row>

                            <Col span={12}>
                                {(!isEdit && !isFormVisible) && <div class="content_panel_heading" >Organizations
                                </div>}
                                {isEdit && <div class="content_panel_heading" >Edit Organization
                                </div>}
                                {isFormVisible && <div class="content_panel_heading" >Add Organization
                                </div>}
                            </Col>
                            <Col span={12}>
                                {(!isEdit && !isFormVisible) && <Button disabled={actionStatus()} style={{ float: 'right', borderRadius: '7px', backgroundColor: '#efefef', fontFamily: 'AktivGroteskMedium', border: 'none' }} onClick={this.showForm}>
                                    <PlusCircleFilled style={{ color: '#fc9558', fontSize: '16px' }} />  Add Organization
                                </Button>}
                            </Col>


                        </Row>
                        <div class="divider-1"> <span></span></div>
                        {/* <Divider /> */}

                        <Form ref={this.formRef} labelCol={{ span: 10 }} hideRequiredMark
                            wrapperCol={{ span: 16 }} name="control-ref" onFinish={this.onFinish}>
                            { (isEdit || isFormVisible) &&
                                <>
                                 <Form.Item
                                        name="orgId"
                                        style={{ display: 'none' }}
                                    >

                                        <Input />
                                    </Form.Item>
                                
                                    <Form.Item
                                        name="orgName"
                                        label="Organization Name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input Name!',
                                            },
                                        ]}
                                    >

                                        <Input
                                            placeholder="Enter Name"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="orgDomain"
                                        label="Organization Domain"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input Domain!',
                                            },
                                        ]}
                                    >

                                        <Input
                                            placeholder="Enter Domain"
                                        />
                                    </Form.Item>
                                <Form.Item {...tailLayout}>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                                <Button htmlType="button" style={{ backgroundColor: '#efefef',margin: "0 8px" }} onClick={this.onReset}>
                                 Cancel
                               </Button>
                               <Button htmlType="button"style={{ backgroundColor: '#efefef',margin: "0 8px" }} onClick={this.onResets}> Reset</Button>
                               </Form.Item>
                                </>
                            }

                        </Form>

                        {(!isEdit && !isFormVisible) &&
                            <div >

                                <SearchBar data={cloneOrgDetails} handler={this.handleSearch}></SearchBar>

                                <Table
                                    style={{ marginTop: '10px' }}
                                    columns={orgColumns}
                                    dataSource={orgDetails}
                                />
                            </div>
                        }
                    </div>
                </Spin>
            </>
        );
    }
}