import React, { Component } from "react";
import { Row, Col,Tag, Table, Typography,Divider, Card, Descriptions, Result, Space, Select, Tooltip, Modal, Form, Input, Button, DatePicker, Breadcrumb, Spin } from 'antd';
import { Link} from 'react-router-dom';
import {
    getServerList,
    getData,
    getMirthServerDetails,
    getServerByOrgId,
    getSubscribedChannels
} from '../../../service/mirth/MirthService'
import AuthService from '../../../service/Auth/auth-service'
import {SearchBar} from '../../../components'
import '../../../styles/custom-css/antd-custom.css';
import { configurationData } from "../../../service/ConfigService/Config";


const {Option} = Select;
const {Text} = Typography;

  function filesizeSorter(arr) {

    arr.sort(function(a, b) {
    console.log(a)
    var a_number = retnum(a.toast_size);
    var b_number = retnum(b.toast_size);

    a = a_number;
    b = b_number; 
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
});
return arr.reverse();
}

function retnum(number) {
    console.log(number)
    var num = number.slice(0, -2);
    var filesizename = number.slice(-2);
 
    num = parseInt(num, 10);

    switch (filesizename) {
        case "KB":
            num = num * 1024;
            break;
        case "MB":
            num = num * Math.pow(1024, 2);
            break;
        case "GB":
            num = num * Math.pow(1024, 3);
            break;
        case "TB":
            num = num * Math.pow(1024, 4);
            break;
    }

    return num;
}
export default class PGToastTable extends Component {

    constructor(props) {
        super(props)

        this.state = {
            spin: true,
            serverOptions:[],
            PGToastData:[],
            clonePGToastData: [],
            resultStatus:'',
            resultTitle:'',
            resultSubTitle:'',
            currentServer:'',
            serverChange:[],
            isError:false,
            page:'pgToastTable'
        }

        this.handleServerChange = this.handleServerChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
       
    }

    componentDidMount() {
        let token = this.props.match.params.accessToken;
        let user = AuthService.getCurrentUser();
        configurationData(this.state.page).then((res) =>{
            if(res.code==200){
              let jsonData=JSON.parse(res.result[0].config);
              console.log(jsonData)
            this.setState({resultStatus:jsonData.status.resultStatus,
            resultTitle:jsonData.status.resultTitle,
            resultSubTitle:jsonData.status.resultSubTitle})
            
            
        getServerByOrgId(user.orgId).then(ServerRes=> {
            if (ServerRes.code == 200) {
                let server=[];
                server = ServerRes.result;

                 if(token == null )
                 {
                    let change=server[0].accessToken;
                    this .setState({serverChange: change})
                    this.setState({ resultStatus:'warning',resultTitle:'Add a server to check statistics', isError:true,spin: false})
                }
                else
                {
                   
                    server.map((servers) =>{
                        let  atoken=servers.accessToken;
                        if(atoken==token){
                        
                        this.setState({serverChange:servers.serverName})
                        }})
                        if(server.length > 0)
                        {
                            this.setState({serverOptions:server,spin: false});
                            this.handleServerChange(token);
                        }
                        else{
                    this.setState({ resultStatus:'warning',resultTitle:'Add a server to check info', isError:true,spin: false})
                }
               
            }
        }
    })
}
        })
      
    }
    handleSearch(data)
    {
        this.setState({ PGToastData: data });
    }
    handleServerChange(val){
     
        this.setState({ spin: true ,currentServer:val,isError:false});
        getMirthServerDetails(val).then(serverRes=>{
            if (serverRes.code == 200 && serverRes.result.length !=0) {
         
                let details = JSON.parse(serverRes.result[0].details);
                if(typeof(details.channelStatus) == 'string')
                {
                    let res =JSON.parse( details.channelStatus);
                    let values= res.list.dashboardStatus;
                    let valueArr=[];
                    if (values !== null && !Array.isArray(values)) {
                        valueArr.push(values);
                      }
                    else
                    {
                        valueArr =values;
                    }
                    if(details.pgToastInfo.length !==0)
                {
                    let finalArray =  details.pgToastInfo;
                    for(let i=0;i<finalArray.length;i++)
                    {
                        for(let j=0;j<valueArr.length;j++)
                    {
                        if(finalArray[i].channel_name == valueArr[j].name )
                        {
                            finalArray[i].state =  valueArr[j].state; 
                            let toast = finalArray[i].toast_size;
                            finalArray[i].toast_size = toast.slice(0, -2) + ' '+toast.slice(-2); 
                        }
                    }
                        
                    }
                    let sorted_arr = filesizeSorter(finalArray);
                    this.setState({ spin: false,PGToastData:sorted_arr ,clonePGToastData:sorted_arr})
                }
                else{
                    this.setState({resultSubTitle:'No data available', isError:true,spin: false})
                }
                }
                else
                {
                    getSubscribedChannels(val).then(channelRes =>{
                        if (channelRes.code == 200 && channelRes.result.length != 0) {
                            let result = channelRes.result;
                            var chArr =result.map(function(obj) {
                                return {
                                    name: obj.channel_name,
                                    channelId: obj.channel_id,
                                    state: obj.status
                                }
                            })
                            if(details.pgToastInfo.length !==0)
                            {
                                let PGArr=[];
                                let finalArray =  details.pgToastInfo;
                                for(let i=0;i<finalArray.length;i++)
                                {
                                    for(let j=0;j<chArr.length;j++)
                                {
                                    if(finalArray[i].channel_name == chArr[j].name )
                                    {
                                        finalArray[i].state =  chArr[j].state; 
                                        let toast = finalArray[i].toast_size;
                                        finalArray[i].toast_size = toast.slice(0, -2) + ' '+toast.slice(-2); 
                                        PGArr.push(finalArray[i])
                                    }
                                }
                                    
                                }
                                
                                let sorted_arr = filesizeSorter(PGArr);
                                this.setState({ spin: false,PGToastData:sorted_arr ,clonePGToastData:sorted_arr})
                            }
                            else{
                                this.setState({resultSubTitle:'No data available', isError:true,spin: false})
                            }
                            
                        }
                        else
                        {
                            this.setState({isError:true,spin: false})
                        }
                    })
                }
             
                
             
            }
             else
            {
                this.setState({isError:true,spin: false})
            }
        })
    }
 
   

    render() {


        const { serverChange,spin, resultStatus,resultTitle,resultSubTitle ,serverOptions,PGToastData,clonePGToastData,isError} = this.state;
       
       
        const renderTag = (tag) => {

            var size = tag.slice(-2);
            var value = tag.slice(0, -2)
           
            console.log(value)
            if(size == 'MB' )
            {
                return <Tag color="success">{tag}</Tag>;
            }
            else if(size == 'GB' && value <= '1')
            {
                return <Tag color="success">{tag}</Tag>;
            }
            else if(size == 'GB' && value >= '1')
            {
                return <Tag color="processing">{tag}</Tag>;
            }
            else if(size == 'GB' && value >= '500')
            {
                return <Tag color="error">{tag}</Tag>;
            }
            else
            {
            return <Tag color="default">{tag}</Tag>;
            }
            // if (tag == 'STARTED')
            //     return <Tag color="success">{tag}</Tag>;
            // else if (tag == 'PAUSED')
            //     return <Tag color="processing">{tag}</Tag>;
            //     else if (tag == 'STOPPED')
            //     return <Tag color="error">{tag}</Tag>;
            // else
            //     return <Tag color="default">{tag}</Tag>;

        }
        const renderStateTag = (tag) => {
          
            if (tag == 'STARTED')
                return <Tag color="success">{tag}</Tag>;
            else if (tag == 'PAUSED')
                return <Tag color="processing">{tag}</Tag>;
                else if (tag == 'STOPPED')
                return <Tag color="error">{tag}</Tag>;
            else
                return <Tag color="default">{tag}</Tag>;

        }
        const psToastColumns = [
            { title: 'Channel Name', dataIndex: 'channel_name', key: 'channel_name',
            render: (text, record) => (
             <><Space><p>{ renderStateTag(record.state)}</p><p>{record.channel_name}</p></Space></> )},
            { title: 'Table Name', dataIndex: 'table_name', key: 'table_name' },
            { title: 'Toast Size', dataIndex: 'toast_size', key: 'toast_size',render: (text) => renderTag(text), },
        ];
        return (
            <>
                <Breadcrumb>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Mirth Monitoring</Breadcrumb.Item>
                    <Breadcrumb.Item>PG Toast Data</Breadcrumb.Item>
                </Breadcrumb>

                <div className="content_panel">

                    <Spin size="large" spinning={spin}>
                   
                        <Row>

                            <Col span={5}>
                              <div class="content_panel_heading" >PG Toast Data
                                </div>
                            </Col>
                            {serverOptions.length !==0 &&
                            <>
                            <Col span={2}   style={{marginTop:'4px'}}>
                            <Text>Server: </Text>
                            </Col>
                            <Col span={12} style={{marginLeft:'-30px'}}>
                            
                            <Select placeholder="Select server" defaultValue={serverChange} onChange={this.handleServerChange} style={{ width: 160 }} bordered={false}>
                            {serverOptions.map((server) => (
                                                    <Option key={server.accessToken} value={server.accessToken}>{server.serverName}</Option>
                                                ))
                                }
                                </Select>
                            </Col>
                            </>}
                           
                        </Row>
                
                        <div class="divider-1"> <span></span></div>
                        {
                        isError ? (
                        <>
                        <Result
                            status={resultStatus}
                            title={resultTitle}
                            subTitle={resultStatus== '500'?resultSubTitle:''}
                            extra={resultStatus== '500'?<></>:  <Link to="/settings/server"><Button type="primary" style={{borderRadius:'7px'}}>Add Server</Button></Link>}
                        />
                        </>):(
                        <>
                                <SearchBar data={clonePGToastData} handler={this.handleSearch}></SearchBar>
                                <Table
                                  style={{marginTop:'10px'}}
                                    columns={psToastColumns}
                                    dataSource={PGToastData}
                                
                                />
                           

                    </>)
                    }
                    </Spin>

                </div>
            </>
        );
    }
}

