import React, { Component } from "react";
import { Row, Col, Table, Tag, Typography, Collapse, Result, message, Radio, Transfer, Divider, Tabs, notification, Space, Select, Tooltip, Modal, Form, Input, Button, DatePicker, Breadcrumb, Spin, InputNumber } from 'antd';
import {
    PlayCircleOutlined,
    StopOutlined,
    PauseCircleOutlined,
    MinusCircleOutlined,
    PlusOutlined,
    DeleteOutlined,
    Loading3QuartersOutlined,
    PoweroffOutlined,
    ReloadOutlined,
    ScheduleOutlined,
    LoadingOutlined,
    PauseOutlined,
    CheckOutlined,
    CloseCircleOutlined,
    EyeOutlined,
    HistoryOutlined,
    ExclamationCircleFilled,
    EditOutlined,
    QuestionCircleOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
//import './EmailScheduler.css';
import '../../../styles/custom-css/antd-custom.css';
import { SearchBar } from '../../../components'
//import moment from 'moment';
import moment from 'moment-timezone';
import {
    getAllJobs,
    scheduleEmail,
    startJob,
    pauseJob,
    resumeJob,
    stopJob,
    deleteJob,
    checkJobName,
    getjobs
} from '../../../service/EmailScheduler/Scheduler'
import {
    getCronExpList,
    getServerList,
    getData,
    getMirthServerDetails,
    getServerByOrgId,
    isJobNameExists,
    updateJobActiveStatus,
    getSubscribedChannels
} from '../../../service/mirth/MirthService'
import ChannelTransfer from '../EmailScheduler/ChannelTransfer';
import Cron from 'react-cron-generator'
import { properties } from '../../../properties';
import AuthService from '../../../service/Auth/auth-service';
import { configurationData } from "../../../service/ConfigService/Config";
import { toHaveFocus } from "@testing-library/jest-dom/dist/matchers";
import { getAllData } from "../../../service/Report";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";




const { RangePicker } = DatePicker;
const { Option } = Select;
const { Text, Title } = Typography;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const { confirm } = Modal;
const getFullDate = (date) => {
    if (date !== null) {
        const value = moment(date).format("YYYY-MM-DD HH:mm:ss");
        return value;
    }
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    },
};
const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: { span: 32, offset: 18 },
        sm: { span: 24, offset: 8 },
    },

};
function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
        result.push(i);
    }
    return result;
}
function disabledDate(current) {
    return moment().add(-1, 'days') >= current
}
function disabledTime() {
    var today = new Date();
    var hours = today.getHours();
    console.log(hours)
    var minutes = today.getMinutes();
    var seconds = today.getSeconds();
    return {
        disabledHours: () => range(0, 24).splice(0, hours),
        disabledMinutes: () => range(0, minutes),
        //   disabledSeconds: () => [55, 56],
    };
}


const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};
const reportOptions = [
    { label: 'Channel Statistics', value: 'channelStatistics' },
    { label: 'Error metric', value: 'errorMetric' },
    { label: 'Usage', value: 'usage' },
];

const alertOptions = [
    { label: 'Error metric', value: 'errorMetric' },
    { label: 'Server Down', value: 'serverDown' },
];
const cronExpressionList = [
    {
        "expression": "0 15 10 * * ? *",
        "key": "Fire at 10:15am every day"
    },
    {
        "expression": "0 30 10-13 ? * WED,FRI",
        "key": "Fires at 10:30, 11:30, 12:30, and 13:30, on every Wednesday and Friday"
    }
];
const contextList = [
    {
        "key": "Error Percent Alert",
        "val": "errorPercentAlert"
    }
    ,
    {
        "key": "Server Down Alert",
        "val": "serverDownAlert"
    },
    {
        "key": "0 Received Alert",
        "val": "zeroReceivedAlert"
    },
    {
        "key": "Raw Delta Alert",
        "val": "rawDeltaAlert"
    },
    {
        "key": "Standard Deviation Alert",
        "val": "stdDevAlert"
    },
    {
        "key": "Standard Deviation observation",
        "val": "stdDevObservation"
    },
    // {
    //     "key": "Poller Data Delete Job",
    //     "val": "pollerDataDeleteJob"
    // },
    {
        "key": "Error Report",
        "val": "errorReport"
    },
    // {
    //     "key": "User Audit Report",
    //     "val": "userAuditReport"
    // },
    // {
    //     "key": "Session Monitoring Report",
    //     "val": "sessionMonitoring"
    // },
    {
        "key": "Channel Trends Report",
        "val": "channelTrendsReport"

    },
    {
        "key": "Daily Usage Report",
        "val": "usageReport"

    },
    {
        "key": "Error Count Alert",
        "val": "errorCountAlert"

    },
    {
        "key": "PgToast Vaccum Recomendation",
        "val": "pgToastAlert"
    }
]
const dbSize = [
    {
        "key": "Greater Than 5Gb and Less Than 10Gb",
        "val": "5-10"
    },
    {
        "key": "Greater Than 10Gb",
        "val": "10"
    }
]
const openNotification = (message) => {
    notification.success({
        message: message,

    });
};
export default class EmailScheduler extends Component {

    formRef = React.createRef();
    editFormRef = React.createRef();
    constructor(props) {
        super(props)

        this.state = {

            spin: true,
            scheduledJobs: [],
            cloneScheduledJobs: [],
            serverOptions: [],
            timeZones: ['Asia/Kolkata', 'America/New_York'],
            jobTypeValue: 1,
            jobType: 'oneTimeJob',
            isOneTimeJob: true,
            isServerDownAlert: false,
            isChannelStats: false,
            isZeroRecievedAlert: false,
            isErrorPercentAlert: false,
            isRawDeltaAlert: false,
            isStdDevAlert: false,
            isStdDevObservation: false,
            isPollerDataDeleteJob: false,
            isErrorReport: false,
            isUserAuditReport: false,
            isSessionMonitoring: false,
            isServerChanged: Math.random(),
            channelStatisticsData: [],
            channelListData: [],
            channelThresholdList: [],
            targetKeys: [],
            cronValue: '',
            host: null,
            endPoint: null,
            userName: null,
            password: null,
            access: '',
            accessToken: null,
            modalTitle: '',
            isModalVisible: false,
            modalMessage: '',
            currentServerName: '',
            serverName: [],
            alertType: '',
            currentUser: {},
            activeKey: '2',
            isRefreshData: false,
            refreshValue: '',
            resultStatus: '',
            resultTitle: '',
            isError: false,
            ischannelTrends: false,
            emailId: [],
            isUsageReport: false,
            isSpikeReport: false,
            isWeeklyReport: false,
            open: false,
            jobName: '',
            confirmLoading: false,
            request: '',
            contextList: [],
            interval: [],
            status: [],
            page: 'reportGeneration',
            validateMessages: [],
            initialValue: {},
            isFormVisible: false,
            isPgToastAlert: false,
            isErrorCountAlert: false,
            tableSize: [],
            tableValue: "MB",
            threshold: 3,
            serverOptionsForServerDown: [],
            ischannelTrendsRange: false,
            isUsageReportWithInterval: false,
            reportData: [],
            cloneReportData: [],
            isSubmit: false,
            context: '',
            reportName: '',
            reportFromDate: '',
            reportToDate: '',
            fileName: '',
            currentServer: '',
            diffDate:700
            // jobDetails:{}


        }


        this.handleServerChange = this.handleServerChange.bind(this);
        this.handleContextChange = this.handleContextChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChannelTransfer = this.handleChannelTransfer.bind(this);
        this.handleChannelListChange = this.handleChannelListChange.bind(this);
        // this.refreshData = this.refreshData.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onChange = this.onChange.bind(this);
        // this.scheduledJob=this.scheduledJob.bind(this);
        this.readOnly = true;
        this.disabledDate = this.disabledDate.bind(this);
        this.myRef = React.createRef();
        this.exportPDF = this.exportPDF.bind(this);



    }

    componentDidMount() {
        //   this.formRef.current.setFieldsValue({jobName:'data'})


        let user = AuthService.getCurrentUser();
        configurationData(this.state.page).then((res) => {
            if (res.code == 200) {
                let jsonData = JSON.parse(res.result[0].config);
                console.log(jsonData)
                this.setState({
                    contextList: jsonData.contextList, resultStatus: jsonData.status.resultStatus,
                    resultTitle: jsonData.status.resultTitle, validateMessages: jsonData.validateMessages
                })
                getServerByOrgId(user.orgId).then(ServerRes => {
                    if (ServerRes.code == 200) {
                        let server = [];
                        let newServer = [];
                        console.log(ServerRes.result)
                        server = ServerRes.result;

                        if (server.length > 0) {
                            newServer.push(server)
                            // this.setState({serverOptionsForServerDown:server})
                            this.setState({ currentUser: user, spin: false, serverOptions: ServerRes.result });


                        }


                        // if(server.length > 0)
                        // {
                        //     this.setState({currentUser:user, spin: false, scheduledJobs: orgJobs, serverOptions: newServer, cloneScheduledJobs: orgJobs, access: AuthService.getCurrentUserAccess() });
                        // }
                        else {
                            this.setState({ currentUser: user, isError: true, spin: false, serverOptions: ServerRes.result });
                        }

                    }
                })




            }
        })

    }
    // showForm=()=>{
    //     this.setState({initialValues:{},isEdit:false})
    //         this.setState({isFormVisible: true });  

    // }
    disabledDate(current) {
        let diffDate = this.state.diffDate;
        return current && (current < moment().startOf('day').subtract(diffDate, 'day') || current > moment().endOf('day'));
    }
    onReset = () => {
        this.formRef.current.resetFields();

        this.setState({ channelListData: [], accessToken: null, isSpikeReport: false, isUsageReportWithInterval: false, ischannelTrendsRange: false, isSubmit: false });
    };
    onCancel = () => {
        this.formRef.current.resetFields();

        this.setState({ channelListData: [], accessToken: null, isSpikeReport: false, isUsageReportWithInterval: false, ischannelTrendsRange: false, isSubmit: false });
    };
    handleSearch(data) {

        this.setState({ scheduledJobs: data });
    }
    // handleReset(isRefreshData){
    //     this.setState({RefreshData:isRefreshData})
    // }

    handleServerChange(val) {
            
            this.setState({ spin: true, accessToken: val ,channelListData:[]});
            getMirthServerDetails(val).then(serverRes => {
                let channelArr = [];
                if (serverRes.code == 200 && serverRes.result.length != 0) {
                    let details = JSON.parse(serverRes.result[0].details);
                    if (details.channelStatus && typeof (details.channelStatus) == 'string') {
                        let res = JSON.parse(details.channelStatus);
                        let values = res.list.dashboardStatus;
                        let finalArr = [];
                        if (values !== null && !Array.isArray(values)) {
                            channelArr.push(values);
                        }
                        else {
                            channelArr = values;
                        }
                        for (let l = 0; l < channelArr.length; l++) {
                            let channeljson = { key: channelArr[l].channelId, name: channelArr[l].name, threshold: 50 };
                            finalArr.push(channeljson);
                        }
                        let serverName = this.state.serverOptions
                            .filter(item => item.accessToken === val)
                            .map(johnObject => johnObject.serverName);
                        this.setState({ channelListData: finalArr, targetKeys: [], spin: false, currentServer: serverName })
                    }
                    else {
                        getSubscribedChannels(val).then(channelRes => {
                            if (channelRes.code == 200 && channelRes.result.length != 0) {
                                let result = channelRes.result;
                                var channelArr = result.map(function (obj) {
                                    return {
                                        name: obj.channel_name,
                                        channelId: obj.channel_id,
                                        state: obj.status
                                    }
                                })
                                let finalArr = [];
                                for (let l = 0; l < channelArr.length; l++) {
                                    let channeljson = { key: channelArr[l].channelId, name: channelArr[l].name, threshold: 50 };
                                    finalArr.push(channeljson);
                                }
                                this.setState({ channelListData: finalArr, targetKeys: [], spin: false })
                            }

                        })
                    }
                }
                else {
                    this.setState({ spin: false })
                    return this.errorChannel()
                }

            })

        


    }


    handleChannelListChange = (items) => {
        console.log(items)

        this.setState({ targetKeys: items });
    }


    handleContextChange(val) {

        //  let val = e.target.value;
        console.log(val);
 
        this.formRef.current.resetFields(["server", "servers","fromDate","toDate","channels"]);
        this.setState({ accessToken: null, isServerChanged: Math.random(), channelListData: [], targetKeys: [] , isSubmit: false });
        if (val == "spikeReport") {
            this.setState({ isSpikeReport: true, ischannelTrendsRange: false, isUsageReportWithInterval: false, context: "Spike Report" })

        }
        else if (val == "channelTrendsRangeReport") {
            this.setState({ isSpikeReport: false, ischannelTrendsRange: true, isUsageReportWithInterval: false, context: "Channek Trends Range Report" })

        }
        else if (val == "usageReport") {
            this.setState({ isSpikeReport: false, ischannelTrendsRange: false, isUsageReportWithInterval: true, context: "Usage Report" })

        }
    }

    handleChannelTransfer(val) {
        this.setState({ channelThresholdList: val });
    }
    error = () => {
        message.error('Please select the channels');
    };
    errorChannel = () => {
        message.error('No channels are present in this server!')
    }
    handleSubmit(val) {
        let user = this.state.currentUser;
        this.setState({ spin: true });
        console.log(val)

        let request = {};

        request.context = val.context;

        if (val.context == 'channelTrendsRangeReport') {
            let channelArr = [];
            this.state.serverOptions.map((server) => {
                if (server.accessToken == val.server) {
                    request.servers = [server];
                }
            });
            if (val.channels != undefined) {
                for (let j = 0; j < this.state.channelListData.length; j++) {
                    for (let k = 0; k < val.channels.length; k++) {
                        if (val.channels[k] == this.state.channelListData[j].key) {
                            let channeljson = { 'id': val.channels[k], 'name': this.state.channelListData[j].name };
                            channelArr.push(channeljson)
                        }
                    }

                }
            }
            else {
                this.setState({ spin: false })
                return this.error();
            }
            request.channels = channelArr;
            request.toDate = moment(val.toDate).tz("America/New_York").format("YYYY-MM-DD HH:mm:ss");
            request.fromDate = moment(val.fromDate).tz("America/New_York").format("YYYY-MM-DD HH:mm:ss");
            let fromDates = val.fromDate.format("YYYY-MM-DD HH:mm:ss")
            let toDates = val.toDate.format("YYYY-MM-DD HH:mm:ss")


            this.setState({ reportName: 'Channel Trends Range Report', reportFromDate: fromDates, reportToDate: toDates })



        }

        else if (val.context == 'usageReport') {
            let channelArr = [];
            this.state.serverOptions.map((server) => {
                if (server.accessToken == val.server) {
                    request.servers = [server];
                }
            });
            if (val.channels != undefined) {
                for (let j = 0; j < this.state.channelListData.length; j++) {
                    for (let k = 0; k < val.channels.length; k++) {
                        if (val.channels[k] == this.state.channelListData[j].key) {
                            let channeljson = { 'id': val.channels[k], 'name': this.state.channelListData[j].name };
                            channelArr.push(channeljson)
                        }
                    }

                }

            }
            else {
                this.setState({ spin: false })
                return this.error();
            }
            request.channels = channelArr;
            request.toDate = moment(val.toDate).tz("America/New_York").format("YYYY-MM-DD HH:mm:ss");
            request.fromDate = moment(val.fromDate).tz("America/New_York").format("YYYY-MM-DD HH:mm:ss");
            let fromDates = val.fromDate.format("YYYY-MM-DD HH:mm:ss")
            let toDates = val.toDate.format("YYYY-MM-DD HH:mm:ss")


            this.setState({ reportName: 'Usage Report', reportFromDate: fromDates, reportToDate: toDates })



        }
        else if (val.context == 'spikeReport') {
            let channelArr = [];
            this.state.serverOptions.map((server) => {
                if (server.accessToken == val.server) {
                    request.servers = [server];
                }
            });
            if (val.channels != undefined) {
                for (let j = 0; j < this.state.channelListData.length; j++) {
                    for (let k = 0; k < val.channels.length; k++) {
                        if (val.channels[k] == this.state.channelListData[j].key) {
                            let channeljson = { 'id': val.channels[k], 'name': this.state.channelListData[j].name };
                            channelArr.push(channeljson)
                        }
                    }

                }
            }
            else {
                this.setState({ spin: false })
                return this.error();

            }
            request.channels = channelArr;
            this.setState({ reportName: "Daily Spike Report" })


            // request.timeRange=val.interval;


        }

        //request.body = val.subject;
        console.log(request);
        this.setState({ cloneUserData: [], userData: [] });
        getAllData(request).then((res) => {
          console.log(res);
          let user = [];
          user = res.result;
          let data = [];
          let spikeReport=false;
          if(user!=null){

          for (let i = 0; i < user.length; i++) {
            let rowData = user[i];
            console.log(rowData)
            data.push(rowData);
            console.log(data)
          }
        }
          this.setState({ spin: false, reportData: data, cloneReportData: data,isSubmit:true });
        });
    }

    exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        let headers = []
        let data = []
        let content = {}
        let fileName = ''

        doc.setFontSize(15);
        if (this.state.isSpikeReport) {
            console.log("spikeReport")
            headers = [["ChannelId", "ChannelName", "Received", "StartTime", "EndTime"]];

            data = this.state.reportData.map(elt => [elt.channelId, elt.channelName, elt.received, elt.startTime, elt.endTime]);
            content = {
                startY: 50,
                head: headers,
                body: data
            };
            fileName = `${this.state.reportName}-${this.state.currentServer}.pdf`
            console.log(fileName)
        }
        else {


            // const title = "Mirth Report";
            headers = [["ChannelName", "Sent", "Received", "Filtered", "Error", "Queue"]];

            data = this.state.reportData.map(elt => [elt.channelName, elt.sent, elt.received, elt.filtered, elt.error, elt.queued]);
            fileName = `${this.state.reportName}-${this.state.currentServer}-${this.state.reportFromDate}-${this.state.reportToDate}.pdf`
            content = {
                startY: 50,
                head: headers,
                body: data
            };
        }



        // doc.text(title, marginLeft, 40);
        message.success("The file is downloading")
        doc.autoTable(content);
        doc.save(fileName)

    }





    handleCancel() {
        this.setState({ isModalVisible: false })
    }


    handleChange(val) {
        this.setState({ tableValue: val })

    }
    onChange(val) {
        this.setState({ threshold: val })
    }



    render() {


        const { currentServer, reportName, reportFromDate, reportToDate, isSubmit, reportData, serverOptionsForServerDown, tableSize, isErrorCountAlert, isPgToastAlert, isEdit, currentUser, validateMessages, contextList, interval, isWeeklyReport, isSpikeReport, isUsageReport, ischannelTrends, isUserAuditReport, isSessionMonitoring, emailId, serverName, spin, access, serverOptions, isError, accessToken, resultStatus, resultTitle, alertType, activeKey, modalMessage, scheduledJobs, cloneScheduledJobs, isOneTimeJob, isErrorPercentAlert, isRawDeltaAlert, isServerDownAlert, isZeroRecievedAlert, isErrorReport, isStdDevAlert, isStdDevObservation, isServerChanged, isModalVisible, threshold, toDate, fromDate, ischannelTrendsRange, isUsageReportWithInterval } = this.state;

    
        const ReportColumns = [
            {
                title: "ChannelName",
                dataIndex: "channelName",
                key: "channelName",
                ellipsis: "true",
            },
            {
                title: "Sent",
                dataIndex: "sent",
                key: "sent",

            },
            {
                title: "Recieved",
                dataIndex: "received",
                key: "received"
            },
            { title: "Filtered", dataIndex: "filtered", key: "filtered" },
            {
                title: "Error",
                dataIndex: "error",
                key: "error",

            },
            {
                title: "Queue",
                dataIndex: "queued",
                key: "queued",

            }



        ];
        const SpikeColumns = [
            {
                title: "ChannelId",
                dataIndex: "channelId",
                key: "channelId",
                ellipsis: "true",
            },
            {
                title: "ChannelName",
                dataIndex: "channelName",
                key: "channelName",

            },
            {
                title: "Recieved",
                dataIndex: "received",
                key: "received"
            },
            { title: "StartTime", dataIndex: "startTime", key: "startTime" },
            {
                title: "EndTime",
                dataIndex: "endTime",
                key: "endTime",

            }
        ];
        const spikeHeaders = [
            {
                label: "ChannelId",
                key: "channelId",

            },
            {
                label: "ChannelName",
                key: "channelName",

            },
            {
                label: "Recieved",
                key: "received"
            },
            { label: "StartTime", key: "startTime" },
            {
                label: "EndTime",
                key: "endTime",

            }
        ];
        const headers = [
            {
                label: "ChannelName",
                key: "channelName",

            },
            {
                label: "Sent",
                key: "sent",

            },
            {
                label: "Recieved",
                key: "received"
            },
            { label: "Filtered", key: "filtered" },
            {
                label: "Error",
                key: "error",

            },
            {
                label: "Queue",
                key: "queued",

            }
        ];

      
        const onChange = (pagination, filters, sorter, extra) => {
            console.log("params", pagination, filters, sorter, extra);
        };
        const data = this.state.reportData.map(elt => [elt.channelName, elt.sent, elt.received, elt.filtered, elt.error, elt.queued]);


        return (
            <>
                <Breadcrumb>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Mirth Monitoring</Breadcrumb.Item>
                    <Breadcrumb.Item>Report Generation</Breadcrumb.Item>
                </Breadcrumb>

                <div className="content_panel">
                    <Spin size="large" spinning={spin}>

                        <Row>
                            <div class="content_panel_heading">Report Generation</div>
                        </Row>

                        <div class="divider-1">
                            {" "}
                            <span></span>
                        </div>
                        <div class="content_form_panel">

                            <Form name="scheduler_form" ref={this.formRef} hideRequiredMark
                                labelCol={{ span: 6 }}
                                wrapperCol={{ span: 15 }}
                                onFinish={this.handleSubmit}
                                validateMessages={validateMessages}
                                className="ant-advanced-search-form"
                                style={{ paddingRight: '0px' ,paddingBottom:'5px'}}
                            >
                                <Row>
                                    <Col span={12}>
                                        <Form.Item
                                            name="context"
                                            label="Report Type"
                                            rules={[
                                                {
                                                    required: true,
                                                    // message: 'Please input context!',
                                                },
                                            ]}

                                        >
                                            {contextList.length !== 0 &&
                                                <Select placeholder="Select type" style={{ width: '300px' }} onChange={this.handleContextChange}>
                                                    {contextList.map((context) => (
                                                        <Option key={context.val} value={context.val}>{context.key}</Option>
                                                    ))

                                                    }
                                                </Select>}

                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>

                                        <Form.Item
                                            name="server"
                                            label="Server"
                                            style={{ display: (isErrorPercentAlert || isZeroRecievedAlert || isRawDeltaAlert || isStdDevAlert || isStdDevObservation || isErrorReport || ischannelTrends || isUsageReport || isSpikeReport || isWeeklyReport || isErrorCountAlert || isPgToastAlert || ischannelTrendsRange || isUsageReportWithInterval) ? '' : 'none' }}
                                            rules={[
                                                {
                                                    required: (isErrorPercentAlert || isZeroRecievedAlert || isRawDeltaAlert || isStdDevAlert || isStdDevObservation || isErrorReport || ischannelTrends || isUsageReport || isSpikeReport || isWeeklyReport || isErrorCountAlert || isPgToastAlert || ischannelTrendsRange || isUsageReportWithInterval),
                                                      message: 'Please input server!',
                                                },
                                            ]}


                                        >
                                            {
                                                <Select placeholder="Select server"
                                                    style={{ width: '300px' }}
                                                    onChange={this.handleServerChange}>
                                                    {serverOptions.map((server) => (
                                                        <Option key={server.accessToken} value={server.accessToken}>{server.serverName}</Option>
                                                    ))

                                                    }
                                                </Select>}

                                        </Form.Item>
                                    </Col>
                                    
                                    </Row>
                                <Row>
                                    <Col span={12}>
                                        <Form.Item
                                            name="fromDate"
                                            label="From Date"
                                            style={{ display: ischannelTrendsRange || isUsageReportWithInterval ? '' : 'none' }}
                                            rules={[
                                                {
                                                    required: (ischannelTrendsRange || isUsageReportWithInterval),
                                                      message: 'Please input From Date!',
                                                },
                                            ]}
                                        >
                                            <DatePicker  disabledDate={this.disabledDate}  readOnly={this.readOnly} style={{ width: '300px', height: '35px', borderRadius: '7px' }} showTime />

                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>


                                        <Form.Item
                                            name="toDate"
                                            label="To Date"
                                            style={{ display: ischannelTrendsRange || isUsageReportWithInterval ? '' : 'none' }}
                                            rules={[
                                                {
                                                    required: (ischannelTrendsRange || isUsageReportWithInterval),
                                                      message: 'Please input To Date!',
                                                },
                                            ]}
                                        >
                                            <DatePicker   disabledDate={this.disabledDate}  readOnly={this.readOnly} style={{ width: '300px', height: '35px', borderRadius: '7px' }} showTime />

                                        </Form.Item>
                                    </Col>
                                </Row>

                                {this.state.channelListData.length !== 0 ? (<Form.Item
                                    name="channels"
                                    label="Channel"
                                   // style={{ display: (isSpikeReport || ischannelTrendsRange || isUsageReportWithInterval) ? '' : 'none' }}
                                >

                                    <Transfer
                                        dataSource={this.state.channelListData}
                                        listStyle={{
                                            width: 250,
                                            height: 300,
                                        }}
                                        showSearch
                                        filterOption={(inputValue, item) =>
                                            item.name.indexOf(inputValue) !== -1
                                        }
                                        targetKeys={this.state.targetKeys}
                                        onChange={this.handleChannelListChange}
                                        render={item => item.name}
                                    />
                                </Form.Item>) : (<></>)}
                                <Form.Item {...tailFormItemLayout}>
                                    <Button htmlType="submit" type="primary" style={{ borderRadius: '7px' }} >SUBMIT </Button>
                                    <Button htmlType="button" style={{ borderRadius: '7px', backgroundColor: '#efefef', marginLeft: '10px' }} onClick={this.onCancel}>
                                        Cancel
                                    </Button>
                                    <Button htmlType="button" style={{borderRadius: '7px', backgroundColor: '#efefef', margin: "0 8px" }} onClick={this.onReset}> Reset</Button>
                                </Form.Item>

                            </Form>


                        </div>
                        {isSubmit ? (
                            <>


                                {isSpikeReport ? (
                                    <>
                                        <Space>

                                            <CSVLink
                                                filename={`${reportName}-${currentServer}.csv`}
                                                headers={spikeHeaders}
                                                data={reportData}
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    message.success("The file is downloading")
                                                }}
                                            >
                                                <Button type="primary">Export to CSV</Button>
                                            </CSVLink>
                                            <Button type="primary" onClick={this.exportPDF}>
                                                Export to PDF
                                            </Button>
                                        </Space>
                                        <Table
                                            style={{ marginTop: "10px" }}
                                            columns={SpikeColumns}
                                            dataSource={reportData}
                                            onChange={onChange}

                                            //  pagination={
                                            //     50
                                            //   }
                                            scroll={{
                                                x: true,
                                            }}
                                        />
                                    </>) : (
                                    <>
                                        <Space>

                                            <CSVLink

                                                filename={`${reportName}-${currentServer}-${reportFromDate}-${reportToDate}.csv`}
                                                headers={headers}
                                                data={reportData}
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    message.success("The file is downloading")
                                                }}
                                            >
                                                <Button type="primary"> Export to CSV</Button>

                                            </CSVLink>
                                            <Button type="primary" onClick={this.exportPDF}>
                                                Export to PDF
                                            </Button>
                                        </Space>
                                        <Table
                                            style={{ marginTop: "10px" }}
                                            columns={ReportColumns}
                                            dataSource={reportData}
                                            onChange={onChange}

                                            //  pagination={
                                            //     50
                                            //   }
                                            scroll={{
                                                x: true,
                                            }}
                                        />

                                    </>)}





                            </>

                        ) : (
                            <></>
                        )}

                    </Spin>
                </div>
            </>
        );
    }
}

















