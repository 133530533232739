import axios from 'axios'

import authHeader from '../Auth/auth-header';

let axiosConfig = {
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    "Access-Control-Allow-Origin": "*",
  }
};
//let SCHEDULER= ' http://localhost:8084/';
let SERVICE_URL= process.env.REACT_APP_API_URL +'api/general-settings'
export async function configurationData(data) {
    try {
      const { data: res } = await axios.post(
        `${SERVICE_URL}/config/getConfig?page=${data}`,
        null,
        {
          headers: authHeader()
      }
      )
    return res
    } catch (e) {
      return e
    }
  };