import React, { Component } from "react";
import { Row, Col, Table, Tag, Typography, Collapse,Result ,message,Radio, Transfer, Divider, Tabs, notification, Space, Select, Tooltip, Modal, Form, Input, Button, DatePicker, Breadcrumb, Spin, InputNumber  } from 'antd';
import {
    PlayCircleOutlined,
    StopOutlined,
    PauseCircleOutlined,
    MinusCircleOutlined,
    PlusOutlined,
    DeleteOutlined,
    Loading3QuartersOutlined,
    PoweroffOutlined,
    ReloadOutlined,
    ScheduleOutlined,
    LoadingOutlined,
    PauseOutlined,
    CheckOutlined,
    CloseCircleOutlined,
    EyeOutlined,
    HistoryOutlined,
    ExclamationCircleFilled,
    EditOutlined,
    QuestionCircleOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
//import './EmailScheduler.css';
import '../../../styles/custom-css/antd-custom.css';
import { SearchBar } from '../../../components'
//import moment from 'moment';
import moment from 'moment-timezone';
import {
    getAllJobs,
    scheduleEmail,
    startJob,
    pauseJob,
    resumeJob,
    stopJob,
    deleteJob,
    checkJobName,
    getjobs
} from '../../../service/EmailScheduler/Scheduler'
import {
    getCronExpList,
    getServerList,
    getData,
    getMirthServerDetails,
    getServerByOrgId,
    isJobNameExists,
    updateJobActiveStatus,
    getSubscribedChannels
} from '../../../service/mirth/MirthService'
import ChannelTransfer from './ChannelTransfer';
import Cron from 'react-cron-generator'
import './cronBuilder.css'
import { properties } from '../../../properties';
import AuthService from '../../../service/Auth/auth-service';
import { configurationData } from "../../../service/ConfigService/Config";
import { toHaveFocus } from "@testing-library/jest-dom/dist/matchers";



const { RangePicker } = DatePicker;
const { Option } = Select;
const { Text ,Title} = Typography;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const { confirm } = Modal;
const getFullDate = (date) => {
    if (date !== null) {
      const value = moment(date).format("YYYY-MM-DD HH:mm:ss");
      return value;
    }
  };
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    },
};
const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: { span: 32, offset: 18 },
        sm: { span: 24, offset: 8 },
    },

};
function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }
function disabledDate(current) {
    return moment().add(-1, 'days') >= current
}
function disabledTime() {
    var today=new Date();
    var hours=today.getHours();
    console.log(hours)
    var minutes=today.getMinutes();
    var seconds=today.getSeconds();
    return {
      disabledHours: () => range(0, 24).splice(0, hours),
      disabledMinutes: () => range(0,minutes),
    //   disabledSeconds: () => [55, 56],
    };
  }


const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};
const reportOptions = [
    { label: 'Channel Statistics', value: 'channelStatistics' },
    { label: 'Error metric', value: 'errorMetric' },
    { label: 'Usage', value: 'usage' },
];

const alertOptions = [
    { label: 'Error metric', value: 'errorMetric' },
    { label: 'Server Down', value: 'serverDown' },
];
const cronExpressionList = [
    {
        "expression": "0 15 10 * * ? *",
        "key": "Fire at 10:15am every day"
    },
    {
        "expression": "0 30 10-13 ? * WED,FRI",
        "key": "Fires at 10:30, 11:30, 12:30, and 13:30, on every Wednesday and Friday"
    }
];
const contextList = [
    {
        "key": "Error Percent Alert",
        "val": "errorPercentAlert"
    }
    ,
    {
        "key": "Server Down Alert",
        "val": "serverDownAlert"
    },
    {
        "key": "0 Received Alert",
        "val": "zeroReceivedAlert"
    },
    {
        "key": "Raw Delta Alert",
        "val": "rawDeltaAlert"
    },
    {
        "key": "Standard Deviation Alert",
        "val": "stdDevAlert"
    },
    {
        "key": "Standard Deviation observation",
        "val": "stdDevObservation"
    } ,
    // {
    //     "key": "Poller Data Delete Job",
    //     "val": "pollerDataDeleteJob"
    // },
    {
        "key": "Error Report",
        "val": "errorReport"
    },
    // {
    //     "key": "User Audit Report",
    //     "val": "userAuditReport"
    // },
    // {
    //     "key": "Session Monitoring Report",
    //     "val": "sessionMonitoring"
    // },
    {
        "key": "Channel Trends Report",
        "val": "channelTrendsReport"
        
    },
    {
        "key": "Daily Usage Report",
        "val": "usageReport"
        
    },
    { "key": "Error Count Alert",
    "val": "errorCountAlert"

    },
    {
        "key": "PgToast Vaccum Recomendation",
        "val": "pgToastAlert"
    }
]
const dbSize=[
    {
        "key":"Greater Than 5Gb and Less Than 10Gb",
        "val":"5-10"
    },
    {
        "key":"Greater Than 10Gb",
        "val":"10"
    }
]
const openNotification = (message) => {
    notification.success({
        message: message,

    });
};
export default class EmailScheduler extends Component {

    formRef = React.createRef();
    editFormRef = React.createRef();
    constructor(props) {
        super(props)

        this.state = {

            spin: true,
            scheduledJobs: [],
            cloneScheduledJobs: [],
            serverOptions: [],
            timeZones: ['Asia/Kolkata', 'America/New_York'],
            jobTypeValue: 1,
            jobType: 'oneTimeJob',
            isOneTimeJob: true,
            isServerDownAlert: false,
            isChannelStats: false,
            isZeroRecievedAlert: false,
            isErrorPercentAlert: false,
            isRawDeltaAlert: false,
            isStdDevAlert: false,
            isStdDevObservation: false,
            isPollerDataDeleteJob: false,
            isErrorReport: false,
            isUserAuditReport:false,
            isSessionMonitoring:false,
            isServerChanged: Math.random(),
            channelStatisticsData: [],
            channelListData: [],
            channelThresholdList: [],
            targetKeys: [],
            cronValue: '',
            host: null,
            endPoint: null,
            userName: null,
            password: null,
            access: '',
            accessToken: null,
            modalTitle: '',
            isModalVisible: false,
            modalMessage: '',
            currentServerName:'',
            serverName:[],
            alertType:'',
            currentUser:{},
            activeKey:'2',
            isRefreshData:false,
            refreshValue:'',
            resultStatus:'',
            resultTitle:'',
            isError:false,
            ischannelTrends:false,
            emailId:[],
            isUsageReport:false,
            isSpikeReport:false,
            isWeeklyReport:false,
            open:false,
            jobName:'',
            confirmLoading:false,
            request:'',
            contextList:[],
            interval:[],
            status:[],
            page:'emailScheduler',
            validateMessages:[],
            initialValue:{},
            isFormVisible: false,
            isPgToastAlert:false,
            isErrorCountAlert:false,
            tableSize:[],
            tableValue:"MB",
            threshold:3,
            serverOptionsForServerDown:[],
            fromDate:'',
            toDate:'',
            ischannelTrendsRange:false,
            isUsageReportWithInterval:false
            // jobDetails:{}


        }


        this.handleServerChange = this.handleServerChange.bind(this);
        this.handleActionClick = this.handleActionClick.bind(this);
        this.handleJobTypeChange = this.handleJobTypeChange.bind(this);
        this.handleContextChange = this.handleContextChange.bind(this);
        this.handleCronChange = this.handleCronChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChannelTransfer = this.handleChannelTransfer.bind(this);
        this.handleChannelListChange = this.handleChannelListChange.bind(this);
        this.refreshData = this.refreshData.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleModalView = this.handleModalView.bind(this);
        this.onKeyChange = this.onKeyChange.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.onChange=this.onChange.bind(this);
        // this.scheduledJob=this.scheduledJob.bind(this);
        this.showConfirm=this.showConfirm.bind(this);
         this.readOnly=true;
         this.myRef = React.createRef();


    }

    componentDidMount() {
     //   this.formRef.current.setFieldsValue({jobName:'data'})
        

        let user = AuthService.getCurrentUser();
        configurationData(this.state.page).then((res) =>{
            if(res.code==200){
              let jsonData=JSON.parse(res.result[0].config);
              console.log(jsonData)
            this.setState({contextList:jsonData.contextList,interval:jsonData.interval,resultStatus:jsonData.status.resultStatus,
                resultTitle:jsonData.status.resultTitle,validateMessages:jsonData.validateMessages,tableSize:jsonData.tableSize})
        getAllJobs().then(res => {
            if (res.statusCode == 200) {
                let jobs = res.data;
                console.log(jobs)
                let orgJobs=[];
                for(let i=0;i<jobs.length;i++)
                {
                    
                    
                    jobs[i].scheduleTime = getFullDate(jobs[i].scheduleTime);
                    jobs[i].lastFiredTime = getFullDate(jobs[i].lastFiredTime);
                    jobs[i].nextFireTime = getFullDate(jobs[i].nextFireTime);
                  
                    if (jobs[i].jobDetails.servers !== null) {
                    let server = jobs[i].jobDetails.servers[0];
                    if(server.orgId == user.orgId)
                    {
                        
                        orgJobs.push(jobs[i])
                        console.log(jobs[i].jobDetails)
                    }
                  
                }
                }
                getServerByOrgId(user.orgId).then(ServerRes => {
                    if (ServerRes.code == 200) {
                        let server=[];
                        let newServer=[];
                        console.log(ServerRes.result)
                        server = ServerRes.result;
                        
                        if (server.length>0){
                            this.setState({serverOptionsForServerDown:server})
                        for(let i=0;i<server.length;i++){
                            console.log("inside for")
                            let accessToken=server[i].accessToken;
                            getMirthServerDetails(accessToken).then(serverRes=>{
                                if (serverRes.code == 200 && serverRes.result.length !=0) {
                                    let details = JSON.parse(serverRes.result[0].details);
                                    if (details.channelStatus && typeof details.channelStatus == "string"){

                                    console.log(serverRes.result)
                                    console.log("inside if")
                                    newServer.push(server[i])
                                    console.log(newServer)}
                                    else{
                                        getSubscribedChannels(accessToken).then((channelRes) => {
                                            if (channelRes.code == 200 && channelRes.result.length != 0) {
                                                console.log(serverRes.result)
                                    console.log("inside if")
                                    newServer.push(server[i])
                                    console.log(newServer)

                                            }})

                                    }
                            

                        }})}
                        this.setState({currentUser:user, spin: false, scheduledJobs: orgJobs, serverOptions: newServer, cloneScheduledJobs: orgJobs, access: AuthService.getCurrentUserAccess() });

                        
                    }

                     
                        // if(server.length > 0)
                        // {
                        //     this.setState({currentUser:user, spin: false, scheduledJobs: orgJobs, serverOptions: newServer, cloneScheduledJobs: orgJobs, access: AuthService.getCurrentUserAccess() });
                        // }
                        else
                        {
                            this.setState({currentUser:user,isError:true, spin: false, scheduledJobs: orgJobs, serverOptions: ServerRes.result, cloneScheduledJobs: orgJobs, access: AuthService.getCurrentUserAccess() });
                        }
                        
                    }
                })

            }

        })
    }
})

    }
    // showForm=()=>{
    //     this.setState({initialValues:{},isEdit:false})
    //         this.setState({isFormVisible: true });  
        
    // }
    onReset = () => {
        this.formRef.current.resetFields();

        this.setState({ channelListData: [],accessToken:null ,isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isChannelStats: false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: false,ischannelTrends:false,isWeeklyReport:false,isUsageReport:false,isErrorCountAlert:false,isSpikeReport:false,isPgToastAlert:false,isOneTimeJob:true});
    };
    onCancel = () => {
        this.formRef.current.resetFields();

        this.setState({activeKey:'2', channelListData: [],accessToken:null ,isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isChannelStats: false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: false,isErrorCountAlert:false,ischannelTrends:false,isPgToastAlert:false,isSpikeReport:false,isWeeklyReport:false,isOneTimeJob:true});
    };
    handleSearch(data) {

        this.setState({ scheduledJobs: data });
    }
    // handleReset(isRefreshData){
    //     this.setState({RefreshData:isRefreshData})
    // }
   
    handleServerChange(val) {
       


    
                if (this.state.isZeroRecievedAlert || this.state.isStdDevAlert || this.state.isStdDevObservation || this.state.isErrorReport||this.state.isPgToastAlert||this.state.isWeeklyReport||this.state.isSpikeReport||this.state.isUsageReport||this.state.ischannelTrends||this.state.ischannelTrendsRange ||this.state.isUsageReportWithInterval) {
                    this.setState({ spin: true,accessToken:val });
                    getMirthServerDetails(val).then(serverRes=>{
                        let channelArr = [];
                        if (serverRes.code == 200 && serverRes.result.length !=0) {
                            let details = JSON.parse(serverRes.result[0].details);
                            if(details.channelStatus && typeof(details.channelStatus) == 'string')
                            {
                            let res =JSON.parse( details.channelStatus);
                            let values= res.list.dashboardStatus;
                            let finalArr =[];
                            if (values !== null && !Array.isArray(values)) {
                                channelArr.push(values);
                              }
                            else
                            {
                                channelArr =values;
                            }
                            for (let l = 0; l < channelArr.length; l++) {
                                let channeljson = { key: channelArr[l].channelId, name: channelArr[l].name, threshold: 50 };
                                finalArr.push(channeljson);
                            }
                            this.setState({ channelListData: finalArr, targetKeys: [], spin: false })
                            }
                            else
                            {
                                getSubscribedChannels(val).then(channelRes =>{
                                    if (channelRes.code == 200 && channelRes.result.length != 0) {
                                        let result = channelRes.result;
                                        var channelArr =result.map(function(obj) {
                                            return {
                                                name: obj.channel_name,
                                                channelId: obj.channel_id,
                                                state: obj.status
                                            }
                                        })
                                        let finalArr =[];
                                        for (let l = 0; l < channelArr.length; l++) {
                                            let channeljson = { key: channelArr[l].channelId, name: channelArr[l].name, threshold: 50 };
                                            finalArr.push(channeljson);
                                        }
                                        this.setState({ channelListData: finalArr, targetKeys: [], spin: false })
                                    }
                                    
                                })
                            }
                        }
                      
                    })

                }
                else if (this.state.isErrorPercentAlert || this.state.isRawDeltaAlert ||this.state.isErrorCountAlert) {
                    this.setState({ isServerChanged: Math.random() ,accessToken:val });
                }
            


    }

    handleActionClick(record, operation){
        console.log(operation);

        this.setState({spin:true});
        let user = this.state.currentUser;
        if (operation == "start") {
            startJob(record.jobName).then(response => {
                if (response.statusCode == 200) {
                    getAllJobs().then(res => {
                        if (res.statusCode == 200) {
                            let jobs = res.data;
                            let orgJobs=[];
                            for(let i=0;i<jobs.length;i++)
                            {
                                jobs[i].scheduleTime = getFullDate(jobs[i].scheduleTime);
                                jobs[i].lastFiredTime = getFullDate(jobs[i].lastFiredTime);
                                jobs[i].nextFireTime = getFullDate(jobs[i].nextFireTime);
                  
                                if (jobs[i].jobDetails.servers !== null) {
                                let server = jobs[i].jobDetails.servers[0];
                                if(server.orgId == this.state.currentUser.orgId)
                                {
                                    orgJobs.push(jobs[i])
                                }
                            }
                            }
                            this.setState({ scheduledJobs: orgJobs, cloneScheduledJobs:orgJobs ,spin:false});
                            openNotification('Job Started successfully.');
                        }
                    });
                }
            })
        }
        else if (operation == "pause") {

            pauseJob(record.jobName).then(response => {
                if (response.statusCode == 200) {
                    getAllJobs().then(res => {
                        if (res.statusCode == 200) {
                            let jobs = res.data;
                            let orgJobs=[];

                            for(let i=0;i<jobs.length;i++)
                            {
                                jobs[i].scheduleTime = getFullDate(jobs[i].scheduleTime);
                                jobs[i].lastFiredTime = getFullDate(jobs[i].lastFiredTime);
                                jobs[i].nextFireTime = getFullDate(jobs[i].nextFireTime);
                  
                                if (jobs[i].jobDetails.servers !== null) {
                                let server = jobs[i].jobDetails.servers[0];
                                if(server.orgId == this.state.currentUser.orgId)
                                {
                                    orgJobs.push(jobs[i])
                                }
                            }
                            }
                            this.setState({ scheduledJobs:orgJobs, cloneScheduledJobs: orgJobs ,spin:false});
                            openNotification('Job Paused successfully.');
                        }
                    });
                }
            })

        }
        else if (operation == "resume") {
            resumeJob(record.jobName).then(response => {
                if (response.statusCode == 200) {
                    getAllJobs().then(res => {
                        if (res.statusCode == 200) {
                            let jobs = res.data;
                            let orgJobs=[];
                            for(let i=0;i<jobs.length;i++)
                            {
                                jobs[i].scheduleTime = getFullDate(jobs[i].scheduleTime);
                                jobs[i].lastFiredTime = getFullDate(jobs[i].lastFiredTime);
                                jobs[i].nextFireTime = getFullDate(jobs[i].nextFireTime);
                  
                                if (jobs[i].jobDetails.servers !== null) {
                                let server = jobs[i].jobDetails.servers[0];
                                if(server.orgId == this.state.currentUser.orgId)
                                {
                                    orgJobs.push(jobs[i])
                                }
                            }
                            }
                            this.setState({ scheduledJobs:orgJobs, cloneScheduledJobs: orgJobs ,spin:false});
                            openNotification('Job Resumed successfully.');
                        }
                    });
                }
            })

        }

        else if (operation == "delete") {
            let request = { "jobName": record.jobName }
            console.log(request)
            deleteJob(record.jobName,user.orgId).then(response => {
                if (response.statusCode == 200) {
                    getAllJobs().then(res => {
                        if (res.statusCode == 200) {
                            let jobs = res.data;
                            let orgJobs=[];
                            for(let i=0;i<jobs.length;i++)
                            {
                                jobs[i].scheduleTime = getFullDate(jobs[i].scheduleTime);
                                jobs[i].lastFiredTime = getFullDate(jobs[i].lastFiredTime);
                                jobs[i].nextFireTime = getFullDate(jobs[i].nextFireTime);
                  
                                if (jobs[i].jobDetails.servers !== null) {
                                let server = jobs[i].jobDetails.servers[0];
                                
                                if(server.orgId == this.state.currentUser.orgId)
                                {
                                    orgJobs.push(jobs[i])
                                }
                            }
                            }
                            this.setState({ scheduledJobs: orgJobs, cloneScheduledJobs: orgJobs,spin:false });
                            openNotification('Job Deleted successfully.');
                        }
                    });
                }
            })

        }


        else if (operation == "stop") {
            stopJob(record.jobName).then(response => {
                if (response.statusCode == 200) {
                    getAllJobs().then(res => {
                        if (res.statusCode == 200) {
                            let jobs = res.data;
                            let orgJobs=[];
                            for(let i=0;i<jobs.length;i++)
                            {
                                jobs[i].scheduleTime = getFullDate(jobs[i].scheduleTime);
                                jobs[i].lastFiredTime = getFullDate(jobs[i].lastFiredTime);
                                jobs[i].nextFireTime = getFullDate(jobs[i].nextFireTime);
                  
                                if (jobs[i].jobDetails.servers !== null) {
                                let server = jobs[i].jobDetails.servers[0];
                                if(server.orgId == this.state.currentUser.orgId)
                                {
                                    orgJobs.push(jobs[i])
                                }
                            }
                            }
                            this.setState({ scheduledJobs: orgJobs, cloneScheduledJobs: orgJobs ,spin:false});
                            openNotification('Job Stopped successfully.');
                        }
                    });
                }
            })

        }
        
        
       
    }
   
    handleChannelListChange = (items) => {
        console.log(items)

        this.setState({ targetKeys: items });
    }
    handleJobTypeChange(e) {

        let val = e.target.value;
        let type = "oneTimeJob";
        let booleanJob = this.state.isOneTimeJob;
        if (val == 1) {
            booleanJob = true;
            type = "oneTimeJob";
        }
        else {
            booleanJob = false;
            type = "cronJob";
        }
        this.setState({ jobTypeValue: e.target.value, jobType: type, isOneTimeJob: booleanJob })
    }
    
    handleContextChange(val) {

        //  let val = e.target.value;
        console.log(val);
        this.formRef.current.resetFields(["server","servers"]);
        this.setState({ accessToken: null, isServerChanged: Math.random(), channelListData: [], targetKeys: [] });
        if (val == "errorPercentAlert") {
            this.setState({ isErrorPercentAlert: true, isRawDeltaAlert: false, isServerDownAlert: false, isChannelStats: false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: false,isPollerDataDeleteJob:false,isErrorReport:false,isUserAuditReport:false,isSessionMonitoring:false,ischannelTrends:false,isUsageReport:false,isSpikeReport:false,isWeeklyReport:false,isErrorCountAlert:false,isPgToastAlert:false,ischannelTrendsRange:false,isUsageReportWithInterval:false})
        
        }
        else if (val == "serverDownAlert") {
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: true,isChannelStats: false, isZeroRecievedAlert: false, isUserAuditReport:false,isStdDevAlert: false, isStdDevObservation: false,isPollerDataDeleteJob:false ,isErrorReport:false,isSessionMonitoring:false,ischannelTrends:false,isUsageReport:false,isSpikeReport:false,isWeeklyReport:false,isErrorCountAlert:false,isPgToastAlert:false,ischannelTrendsRange:false,isUsageReportWithInterval:false})
        }
        else if (val == "zeroReceivedAlert") {
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isChannelStats: false, isZeroRecievedAlert: true, isStdDevAlert: false, isStdDevObservation: false,isPollerDataDeleteJob:false ,isErrorReport:false,isUserAuditReport:false,isSessionMonitoring:false,ischannelTrends:false,isUsageReport:false,isSpikeReport:false,isWeeklyReport:false,isErrorCountAlert:false,isPgToastAlert:false,ischannelTrendsRange:false,isUsageReportWithInterval:false})
        }
        else if (val == "rawDeltaAlert") {
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: true, isServerDownAlert: false,isChannelStats: false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: false,isPollerDataDeleteJob:false,isErrorReport:false,isUserAuditReport:false,isSessionMonitoring:false,ischannelTrends:false,isUsageReport:false,isSpikeReport:false,isWeeklyReport:false,isErrorCountAlert:false,isPgToastAlert:false,ischannelTrendsRange:false,isUsageReportWithInterval:false})
        }
        else if (val == "stdDevAlert") {
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isChannelStats: false, isZeroRecievedAlert: false, isStdDevAlert: true, isStdDevObservation: false,isPollerDataDeleteJob:false ,isErrorReport:false,isUserAuditReport:false,isSessionMonitoring:false ,ischannelTrends:false,isUsageReport:false,isSpikeReport:false,isWeeklyReport:false,isErrorCountAlert:false,isPgToastAlert:false,ischannelTrendsRange:false,isUsageReportWithInterval:false})
        }
        else if (val == "stdDevObservation") {
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isChannelStats: false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: true,isPollerDataDeleteJob:false ,isErrorReport:false,isUserAuditReport:false,isSessionMonitoring:false,ischannelTrends:false,isUsageReport:false ,isSpikeReport:false,isWeeklyReport:false,isErrorCountAlert:false,isPgToastAlert:false,ischannelTrendsRange:false,isUsageReportWithInterval:false})
        }
        else if (val == "channelStats") {
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isChannelStats: true, isZeroRecievedAlert: false ,isUserAuditReport:false,isStdDevAlert: false, isStdDevObservation: false,isPollerDataDeleteJob:false ,isErrorReport:false,isSessionMonitoring:false,ischannelTrends:false,isUsageReport:false,isSpikeReport:false,isWeeklyReport:false,isErrorCountAlert:false,isPgToastAlert:false,ischannelTrendsRange:false,isUsageReportWithInterval:false })
        }
         else if (val == "pollerDataDeleteJob") {
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isChannelStats: false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: false,isPollerDataDeleteJob:true,isErrorReport:false,isUserAuditReport:false,isSessionMonitoring:false ,ischannelTrends:false,isUsageReport:false,isSpikeReport:false,isWeeklyReport:false,isErrorCountAlert:false,isPgToastAlert:false,ischannelTrendsRange:false,isUsageReportWithInterval:false})
        }
        else if (val == "errorReport") {
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isChannelStats: false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: false,isPollerDataDeleteJob:false,isErrorReport:true,isUserAuditReport:false,isSessionMonitoring:false,ischannelTrends:false ,isUsageReport:false,isSpikeReport:false,isWeeklyReport:false,isErrorCountAlert:false,isPgToastAlert:false,ischannelTrendsRange:false,isUsageReportWithInterval:false})
        }
        else if(val=="userAuditReport"){
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isChannelStats: false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: false,isPollerDataDeleteJob:false,isErrorReport:false,isUserAuditReport:true,isSessionMonitoring:false, ischannelTrends:false ,isUsageReport:false,isSpikeReport:false,isWeeklyReport:false,isErrorCountAlert:false,isPgToastAlert:false,ischannelTrendsRange:false,isUsageReportWithInterval:false})

        }
        else if(val=="sessionMonitoring"){
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isChannelStats: false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: false,isPollerDataDeleteJob:false,isErrorReport:false,isUserAuditReport:false,isSessionMonitoring:true,ischannelTrends:false,isUsageReport:false,isSpikeReport:false,isWeeklyReport:false,isErrorCountAlert:false,isPgToastAlert:false,ischannelTrendsRange:false,isUsageReportWithInterval:false })

        }
        else if(val=="channelTrendsReport"){
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isChannelStats: false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: false,isPollerDataDeleteJob:false,isErrorReport:false,isUserAuditReport:false,isSessionMonitoring:false,ischannelTrends:true ,isUsageReport:false,isSpikeReport:false,isWeeklyReport:false,isErrorCountAlert:false,isPgToastAlert:false,ischannelTrendsRange:false,isUsageReportWithInterval:false})

        }
        else if(val=="dailyUsageReport"){
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isChannelStats: false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: false,isPollerDataDeleteJob:false,isErrorReport:false,isUserAuditReport:false,isSessionMonitoring:false,ischannelTrends:false,isUsageReport:true,isSpikeReport:false,isWeeklyReport:false,isErrorCountAlert:false,isPgToastAlert:false,ischannelTrendsRange:false,isUsageReportWithInterval:false })

        }
        else if(val=="spikeReport"){
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isChannelStats: false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: false,isPollerDataDeleteJob:false,isErrorReport:false,isUserAuditReport:false,isSessionMonitoring:false,ischannelTrends:false,isUsageReport:false,isSpikeReport:true,isWeeklyReport:false,isErrorCountAlert:false,isPgToastAlert:false,ischannelTrendsRange:false,isUsageReportWithInterval:false})

        }
        else if(val=="weeklyUsageReport"){
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isChannelStats: false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: false,isPollerDataDeleteJob:false,isErrorReport:false,isUserAuditReport:false,isSessionMonitoring:false,ischannelTrends:false,isUsageReport:false,isSpikeReport:false,isWeeklyReport:true,isErrorCountAlert:false,isPgToastAlert:false,ischannelTrendsRange:false,isUsageReportWithInterval:false })

        } 
        else if(val=="errorCountAlert"){
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isChannelStats: false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: false,isPollerDataDeleteJob:false,isErrorReport:false,isUserAuditReport:false,isSessionMonitoring:false,ischannelTrends:false,isUsageReport:false,isSpikeReport:false,isWeeklyReport:false,isErrorCountAlert:true,isPgToastAlert:false,ischannelTrendsRange:false,isUsageReportWithInterval:false })

        }
        else if(val=="pgToastVacuumAlert"){
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isChannelStats: false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: false,isPollerDataDeleteJob:false,isErrorReport:false,isUserAuditReport:false,isSessionMonitoring:false,ischannelTrends:false,isUsageReport:false,isSpikeReport:false,isWeeklyReport:false,isErrorCountAlert:false,isPgToastAlert:true,ischannelTrendsRange:false,isUsageReportWithInterval:false })

        }
        else if(val=="channelTrendsRangeReport"){
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isChannelStats: false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: false,isPollerDataDeleteJob:false,isErrorReport:false,isUserAuditReport:false,isSessionMonitoring:false,ischannelTrends:false,isUsageReport:false,isSpikeReport:false,isWeeklyReport:false,isErrorCountAlert:false,isPgToastAlert:false,ischannelTrendsRange:true,isUsageReportWithInterval:false })

        }
        else if(val=="usageReport"){
            this.setState({ isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isChannelStats: false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: false,isPollerDataDeleteJob:false,isErrorReport:false,isUserAuditReport:false,isSessionMonitoring:false,ischannelTrends:false,isUsageReport:false,isSpikeReport:false,isWeeklyReport:false,isErrorCountAlert:false,isPgToastAlert:false,ischannelTrendsRange:true,isUsageReportWithInterval:true })

        }
    }

    handleChannelTransfer(val) {
        this.setState({ channelThresholdList: val });
    }
     error = () => {
        message.error('Please select the channels');
      };
    handleSubmit(val) {
        let user = this.state.currentUser;
        this.setState({ spin: true });
        console.log(val)
      
        let request = {};
        let stdObvRequest = {};
        let content = "<p>This message was trigged by Aigilx health on</p><p id='date'></p><h3>SERVER DOWN ERROR</h3><table style='width:100%; border:1px solid black'><tr style=' border:1px solid black'><th style=' border:1px solid black'>Server Id</th><th style=' border:1px solid black'>Server Name</th><th style=' border:1px solid black'>Time</th>    <th style=' border:1px solid black'>Metric</th></tr><tr>  <td style=' border:1px solid black'>jihiui</td> <td style=' border:1px solid black'>Local</td> <td style=' border:1px solid black'>29-09-20</td> <td style=' border:1px solid black'>SERVER DOWN</td></tr></table>";
        request.email = val.email;
        //request.subject = val.subject;
        request.jobName = val.jobName;
        request.timeZone = 'America/New_York';
        request.context = val.context;
        
        if (this.state.jobTypeValue == 1) {
            console.log(moment(val.dateTime).tz("America/New_York").format("YYYY-MM-DDTHH:mm:ss"));
            console.log(val.dateTime)
            //   request.dateTime = moment(val.dateTime).format("YYYY-MM-DDTHH:mm:ss");
            request.dateTime = moment(val.dateTime).tz("America/New_York").format("YYYY-MM-DDTHH:mm:ss");
            request.cronExpression = null;
            request.interval = null;
        }
        else {
            request.dateTime = null;
            request.cronExpression = val.cronExpression;
            request.interval = val.cronExpression.substring(4, 6).trim();
        }
       
        if (val.context == "errorPercentAlert") {
            this.state.serverOptions.map((server) => {
                if (server.accessToken == val.server) {
                    request.servers = [server];
                }
            });
            if(this.state.channelThresholdList.length == 0)
            {
                this.setState({ spin: false })
                return this.error();
               
            }
            request.channels = this.state.channelThresholdList;
        }
        else if (val.context == "rawDeltaAlert") {
            this.state.serverOptions.map((server) => {
                if (server.accessToken == val.server) {
                    request.servers = [server];
                }
            });
            if(this.state.channelThresholdList.length == 0)
            {
                this.setState({ spin: false })
                return this.error();
               
            }   
            request.channels = this.state.channelThresholdList;
        }
        else if (val.context == "channelStats" || val.context == "pollerDataDeleteJob") {
            request.servers = this.state.serverOptions;
            request.channels = null;
        }
        else if (val.context == "serverDownAlert" ) {
            let servers = val.servers;
            let serversArr=[];
            for(let i=0;i<servers.length;i++)
            {
                this.state.serverOptionsForServerDown.map((server) => {
                    if (server.accessToken == servers[i]) {
                        serversArr.push(server);
                       
                    }
                });
            }
            request.servers = serversArr;
            request.channels = null;
            request.threshold=this.state.threshold;
        }
        else if (val.context == "zeroReceivedAlert" || val.context == "stdDevAlert" || val.context == "stdDevObservation"|| val.context == "errorReport") {
            let channelArr = [];
            this.state.serverOptions.map((server) => {
                if (server.accessToken == val.server) {
                    request.servers = [server];
                }
            });
           
            console.log(val)
            if(val.channels!=undefined){
                

            for (let j = 0; j < this.state.channelListData.length; j++) {
                for (let k = 0; k < val.channels.length; k++) {
                    if (val.channels[k] == this.state.channelListData[j].key) {
                        let channeljson = { 'id': val.channels[k], 'name': this.state.channelListData[j].name };
                        channelArr.push(channeljson)
                    }
                }

            }}
            else{
                this.setState({spin:false})
                return this.error();
            }
            request.channels = channelArr;
        }
        else if(val.context == 'userAuditReport' || val.context == 'sessionMonitoring'){
            let serverData=[]
            serverData.push(this.state.serverOptions[0])
            request.timeRange=val.interval;
            request.servers=serverData;
           
        }
        else if(val.context == 'channelTrendsReport'){
            let channelArr = [];
            this.state.serverOptions.map((server) => {
                if (server.accessToken == val.server) {
                    request.servers = [server];
                }
            });
            if(val.channels!=undefined){
            for (let j = 0; j < this.state.channelListData.length; j++) {
                for (let k = 0; k < val.channels.length; k++) {
                    if (val.channels[k] == this.state.channelListData[j].key) {
                        let channeljson = { 'id': val.channels[k], 'name': this.state.channelListData[j].name };
                        channelArr.push(channeljson)
                    }
                }

            }
        }
        else{
            this.setState({spin:false})
            return this.error(); 
        }
            request.channels = channelArr;
            request.timeRange=val.interval;
            
           
        }
        else if(val.context == 'channelTrendsRangeReport'){
            let channelArr = [];
            this.state.serverOptions.map((server) => {
                if (server.accessToken == val.server) {
                    request.servers = [server];
                }
            });
            if(val.channels!=undefined){
            for (let j = 0; j < this.state.channelListData.length; j++) {
                for (let k = 0; k < val.channels.length; k++) {
                    if (val.channels[k] == this.state.channelListData[j].key) {
                        let channeljson = { 'id': val.channels[k], 'name': this.state.channelListData[j].name };
                        channelArr.push(channeljson)
                    }
                }

            }
        }
        else{
            this.setState({spin:false})
            return this.error(); 
        }
            request.channels = channelArr;
            request.toDate = moment(val.toDate).tz("America/New_York").format("YYYY-MM-DD HH:mm:ss");
            request.fromDate = moment(val.fromDate).tz("America/New_York").format("YYYY-MM-DD HH:mm:ss");
            
           
        }
        else if(val.context == 'dailyUsageReport'){
            let channelArr = [];
            this.state.serverOptions.map((server) => {
                if (server.accessToken == val.server) {
                    request.servers = [server];
                }
            });
            if(val.channels!=undefined){
            for (let j = 0; j < this.state.channelListData.length; j++) {
                for (let k = 0; k < val.channels.length; k++) {
                    if (val.channels[k] == this.state.channelListData[j].key) {
                        let channeljson = { 'id': val.channels[k], 'name': this.state.channelListData[j].name };
                        channelArr.push(channeljson)
                    }
                }

            }
        }
        
        else{
            this.setState({spin:false})
            return this.error(); 
        }
            request.channels = channelArr;
            // request.timeRange=val.interval;
            
           
        }
        else if(val.context == 'usageReport'){
            let channelArr = [];
            this.state.serverOptions.map((server) => {
                if (server.accessToken == val.server) {
                    request.servers = [server];
                }
            });
            if(val.channels!=undefined){
            for (let j = 0; j < this.state.channelListData.length; j++) {
                for (let k = 0; k < val.channels.length; k++) {
                    if (val.channels[k] == this.state.channelListData[j].key) {
                        let channeljson = { 'id': val.channels[k], 'name': this.state.channelListData[j].name };
                        channelArr.push(channeljson)
                    }
                }

            }
        }
        else{
            this.setState({spin:false})
            return this.error(); 
        }
            request.channels = channelArr;
            request.toDate= moment(val.toDate).tz("America/New_York").format("YYYY-MM-DD HH:mm:ss");
            request.fromDate = moment(val.fromDate).tz("America/New_York").format("YYYY-MM-DD HH:mm:ss");
            
           
    }
        else if(val.context == 'spikeReport'){
            let channelArr = [];
            this.state.serverOptions.map((server) => {
                if (server.accessToken == val.server) {
                    request.servers = [server];
                }
            });
            if(val.channels!=undefined){
            for (let j = 0; j < this.state.channelListData.length; j++) {
                for (let k = 0; k < val.channels.length; k++) {
                    if (val.channels[k] == this.state.channelListData[j].key) {
                        let channeljson = { 'id': val.channels[k], 'name': this.state.channelListData[j].name };
                        channelArr.push(channeljson)
                    }
                }

            }
        }
        else{
            this.setState({spin:false})
            return this.error(); 

        }
            request.channels = channelArr;
            
            // request.timeRange=val.interval;
            
           
        }
        else if(val.context == 'weeklyUsageReport'){
            let channelArr = [];
            this.state.serverOptions.map((server) => {
                if (server.accessToken == val.server) {
                    request.servers = [server];
                }
                
            });
            if(val.channels!=undefined){
            for (let j = 0; j < this.state.channelListData.length; j++) {
                for (let k = 0; k < val.channels.length; k++) {
                    if (val.channels[k] == this.state.channelListData[j].key) {
                        let channeljson = { 'id': val.channels[k], 'name': this.state.channelListData[j].name };
                        channelArr.push(channeljson)
                    }
                }

            }
        }
        else{
            this.setState({spin:false})
            return this.error(); 
        }
            request.channels = channelArr;
            // request.timeRange=val.interval;
            
           
        }
        else if (val.context == "pgToastVacuumAlert") {
            let channelArr = [];
            this.state.serverOptions.map((server) => {
                if (server.accessToken == val.server) {
                    request.servers = [server];
                }
            });
            if(val.channels!=undefined){

            for (let j = 0; j < this.state.channelListData.length; j++) {
                for (let k = 0; k < val.channels.length; k++) {
                    if (val.channels[k] == this.state.channelListData[j].key) {
                        let channeljson = { 'id': val.channels[k], 'name': this.state.channelListData[j].name };
                        channelArr.push(channeljson)
                    }
                }

            }
        }
        else{
            this.setState({spin:false})
            return this.error(); 

        }
            request.channels = channelArr;
            let size=val.tableValue;
            console.log(size)
            if(this.state.tableValue == "MB"){
            request.tableSize=size;
        }
        else{
            let tableSize=size*1000;
            request.tableSize=tableSize;

        }}
        else if (val.context == "errorCountAlert") {
            this.state.serverOptions.map((server) => {
                if (server.accessToken == val.server) {
                    request.servers = [server];
                }
            });
            if(this.state.channelThresholdList.length == 0)
            {
                this.setState({ spin: false })
                return this.error();
               
            }  
            request.channels = this.state.channelThresholdList;
        }
        this.setState({jobName:val.jobName,request:request});
        //request.body = val.subject;
        console.log(request);
        isJobNameExists( user.orgId,val.jobName).then(res => {
            if (res.code == 200) {
                let result = res.result[0];
            
                if(result.exists === true)
                {
                    if(result.existsbyorg === true)
                    {
                        this.showConfirm()
                    }
                    else
                    {

                    }
                }
                else
                {
                   
                    this.scheduledJob();
                }
            }
        })


        // scheduleEmail(request).then(response => {
        //     if (response.statusCode == 200) {
        //             let jobs = response.data;
        //             let orgJobs=[];
        //             for(let i=0;i<jobs.length;i++)
        //             {
        //                 if (jobs[i].jobDetails.servers !== null) {
        //                 let server = jobs[i].jobDetails.servers[0];
        //                 if(server.orgId == this.state.currentUser.orgId)
        //                 {
        //                     orgJobs.push(jobs[i])
        //                 }
        //             }
        //             }
        //             this.setState({ spin: false, scheduledJobs: orgJobs, isOneTimeJob: true, isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isPollerDataDeleteJob:false,isChannelStats:false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: false,isErrorReport:false, cloneScheduledJobs: orgJobs ,activeKey: '2'})
        //             this.formRef.current.resetFields();
        //             openNotification('Job scheduled successfully!');
        //     }
        // })

    }

    scheduledJob()
    {
       
        scheduleEmail(this.state.request).then(response => {
            if (response.statusCode == 200) {
                    let jobs = response.data;
                    let orgJobs=[];
                    for(let i=0;i<jobs.length;i++)
                    {
                        jobs[i].scheduleTime = getFullDate(jobs[i].scheduleTime);
                        jobs[i].lastFiredTime = getFullDate(jobs[i].lastFiredTime);
                        jobs[i].nextFireTime = getFullDate(jobs[i].nextFireTime);
                        if (jobs[i].jobDetails.servers !== null) {
                        let server = jobs[i].jobDetails.servers[0];
                        if(server.orgId == this.state.currentUser.orgId)
                        {
                            orgJobs.push(jobs[i])
                        }
                    }
                    }
                    this.setState({ spin: false, scheduledJobs: orgJobs, isOneTimeJob: true, isErrorPercentAlert: false, isRawDeltaAlert: false, isServerDownAlert: false,isPollerDataDeleteJob:false,isChannelStats:false, isZeroRecievedAlert: false, isStdDevAlert: false, isStdDevObservation: false,isErrorReport:false,isWeeklyReport:false,isUsageReport:false,ischannelTrends:false,isPgToastAlert:false,isErrorCountAlert:false,isSpikeReport:false, cloneScheduledJobs: orgJobs ,activeKey: '2',jobName:'',request:''})
                    this.formRef.current.resetFields();
                    openNotification('Job scheduled successfully!');
            }
        })
    }
    refreshData() {
        this.setState({ spin: true,isRefreshData:true });
        getAllJobs().then(res => {
            if (res.statusCode == 200) {
                let jobs = res.data;
                console.log(jobs)
                let orgJobs=[];
                for(let i=0;i<jobs.length;i++)
                {
                    jobs[i].scheduleTime = getFullDate(jobs[i].scheduleTime);
                    jobs[i].lastFiredTime = getFullDate(jobs[i].lastFiredTime);
                    jobs[i].nextFireTime = getFullDate(jobs[i].nextFireTime);
                  
                    if (jobs[i].jobDetails.servers !== null) {
                    let server = jobs[i].jobDetails.servers[0];
                    if(server.orgId == this.state.currentUser.orgId)
                    {
                        orgJobs.push(jobs[i])
                    }
                }
                }
                // let booleanRefresh=this.state.isRefreshData;
                // console.log(booleanRefresh)
                // let values=document.getElementById("search-box").value;
                // console.log(values);
                // if(booleanRefresh== true){
                //     values='';
                // }
                // console.log(values)
                // document. getElementById("search-box"). value ="";
                this.setState({ spin: false, scheduledJobs: orgJobs, cloneScheduledJobs: orgJobs,isRefreshData:true })
            }
            //this.myRef.current.reset(); 
            // this.onReset();
            // let booleanRefresh=this.state.isRefreshData;
            //     console.log(booleanRefresh)
            //     let values=document.getElementById("search-box").value;
            //     console.log(values);
            //     if(booleanRefresh== true){
            //         values='';
            //     }
            //     console.log(values)
            //     this.setState({refreshValue:values})
                // document. getElementById("search-box"). value ="";
           
            // document. getElementById("search-box"). value ="";

            
        });
       
    }


     showConfirm = () => {
        confirm({
          title: 'Do you want to replace the job?',
          icon: <ExclamationCircleFilled />,
          content: <span>A job with name <strong>{this.state.jobName}</strong> already exists in deleted jobs list. Click yes to replace the job.</span>,
          okText:"Yes",
         cancelText:"No",
         confirmLoading:this.state.confirmLoading,
          onOk:()=>{
            let user = this.state.currentUser;
            this.setState({ confirmLoading:true})
            updateJobActiveStatus(user.orgId,this.state.jobName).then(res=>{
                if(res.code == 200)
                {
                    this.setState({ open: false ,confirmLoading:false});
                    this.scheduledJob();
                }
            })
            this.setState({ open: false ,confirmLoading:false});
           
          },
          onCancel:()=>{
            this.setState({ spin:false});
          },
        });
      };
   
    handleCancel() {
        this.setState({ isModalVisible: false })
    }
    handleModalView(data) {

        console.log(data)
        if(data.context == 'userAuditReport'||data.context == 'sessionMonitoring' ){
        this.setState({isModalVisible: true,emailId:data.jobDetails.email })
        }
        else{
            this.setState({alertType:data.jobDetails.context,modalMessage: data.jobDetails,serverName:data.jobDetails.servers, isModalVisible: true,emailId:data.jobDetails.email })
        }
        console.log(data.jobDetails.servers);

    }
    handleCronChange(val) {
        console.log(val);
        this.setState({ cronValue: val })
    }
    onKeyChange(val)
    {
        this.setState({ activeKey: val })
       }
       handleChange(val){
        this.setState({tableValue:val})

       }
       onChange(val){
        this.setState({threshold:val})
       }

      
    render() {


        const {serverOptionsForServerDown,tableSize,isErrorCountAlert,isPgToastAlert,isEdit,currentUser,validateMessages,contextList,interval,isWeeklyReport,isSpikeReport,isUsageReport,ischannelTrends,isUserAuditReport,isSessionMonitoring,emailId,serverName,spin, access, serverOptions,isError,accessToken,resultStatus,resultTitle,alertType, activeKey,modalMessage, scheduledJobs, cloneScheduledJobs,  isOneTimeJob, isErrorPercentAlert, isRawDeltaAlert, isServerDownAlert, isZeroRecievedAlert,isErrorReport, isStdDevAlert, isStdDevObservation,isServerChanged, isModalVisible,threshold,toDate,fromDate,ischannelTrendsRange,isUsageReportWithInterval } = this.state;
       
        
        const suffixSelector = (
            <Form.Item name="suffix" noStyle>
                <Select style={{ width: '300px' }} placeholder="Select the table size">
                               {tableSize.map((int) => (
                                                <Option key={int.key} value={int.val}>{int.key}</Option>
                                            ))
            
                                            }
                        </Select>
              
            </Form.Item>
          );
        const getFullDate = (date) => {
            if (date !== null) {
                const value = moment(date).tz("America/New_York").format("YYYY-MM-DD HH:mm:ss")
                // const value = new Date(date).toLocaleString('en-US', { hour12: true });
                return value;
            }

        };

        const renderTag = (tag) => {

            if (tag == 'SCHEDULED')
                return <Tag icon={<ScheduleOutlined />} color="orange">
                    {tag}
                </Tag>;
            else if (tag == 'RUNNING')
                return <Tag color="warning" icon={<LoadingOutlined />}>{tag}</Tag>;
            else if (tag == 'PAUSED')
                return <Tag color="processing" icon={<PauseOutlined />}>{tag}</Tag>;
            else if (tag == 'COMPLETE')
                return <Tag color="success" icon={<CheckOutlined />}>{tag}</Tag>;
            else
                return <Tag color="error" icon={<CloseCircleOutlined />}>{tag}</Tag>;


        }
        const actionStatus = (status, action) => {
            if (access == "read&write") {

                if (action == "start") {
                    if (status == "RUNNING") {
                        return true
                    }
                    else {
                        return false
                    }
                }
                else if (action == "pause") {
                    if (status != "SCHEDULED") {
                        return true
                    }
                    else {
                        return false
                    }
                }
                else if (action == "resume") {
                    if (status != "PAUSED") {
                        return true
                    }
                    else {
                        return false
                    }
                }
                else if (action == "delete") {
                    if (status == "RUNNING") {
                        return true
                    }
                    else {
                        return false
                    }
                }
                else if (action == "stop") {
                    if (status != "RUNNING") {
                        return true
                    }
                    else {
                        return false
                    }
                }
                else if (action == "restore") {
                    return false
                }
                else if (action == "edit") {
                    
                    if (status == "RUNNING") {
                        return true
                    }
                    else {
                        return false
                    }
                    
                }
            
            }
            else {
                return true
            }
        };
        const accessStatus = () => {
            if (access == "read&write") {
                return false
            }
            else {
                return true
            }

        };
        const scheduledJobsColumns = [
            { title: 'Error Type', dataIndex: '', key: '', render: (text, record) => (<>{record.jobDetails.context.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}</>)  },
            { title: 'Job Name', dataIndex: 'jobName', key: 'jobName' },
            {
                title: 'Scheduled Time', dataIndex: 'scheduleTime', key: 'scheduleTime',
               
            },
            {
                title: 'Last Fired Time', dataIndex: 'lastFiredTime', key: 'lastFiredTime',
                
            },
            {
                title: 'Next Fire Time', dataIndex: 'nextFireTime', key: 'nextFireTime',
               
            },
            { title: 'Job Status', dataIndex: 'jobStatus', key: 'jobStatus', render: (text) => renderTag(text) },
            {
                title: 'More Info',
                dataIndex: '',
                key: 'x',

                render: (text, record) => (
                    <>
                        <Tooltip title="More Info">
                            <a
                                onClick={() => { this.handleModalView(record) }}><EyeOutlined /></a>
                        </Tooltip>
                    </>)
            },
            {
                title: 'Actions', dataIndex: '', key: '',
                render: (text, record) => (
                    <>
                        <Space>
                            <Tooltip title="Start">
                                <Button type="text" disabled={actionStatus(record.jobStatus, 'start')} onClick={() => { this.handleActionClick(record, 'start') }} icon={<PlayCircleOutlined />} />
                            </Tooltip>
                            <Tooltip title="Pause">
                                <Button type="text" disabled={actionStatus(record.jobStatus, 'pause')} onClick={() => { this.handleActionClick(record, 'pause') }} icon={<PauseCircleOutlined />} />

                            </Tooltip>
                            <Tooltip title="Resume">
                                <Button type="text" disabled={actionStatus(record.jobStatus, 'resume')} onClick={() => { this.handleActionClick(record, 'resume') }} icon={<Loading3QuartersOutlined />} />

                            </Tooltip>

                            {/* <Tooltip title="Stop">
                                <Button type="text" disabled={actionStatus(record.jobStatus, 'stop')} onClick={() => { this.handleActionClick(record, 'stop') }} icon={<StopOutlined />} />

                            </Tooltip> */}
                            <Tooltip title="Delete">
                                <Button type="text" disabled={actionStatus(record.jobStatus, 'delete')} onClick={() => { this.handleActionClick(record, 'delete') }} icon={<DeleteOutlined />} />

                            </Tooltip>
                            <><Tooltip title="Edit Form" >
            <Link to={`/mirth/edit-form/${record.jobName}`}> <Button type="text"  disabled={actionStatus(record.jobStatus, 'edit')} icon={<EditOutlined />} /></Link>
            </Tooltip></>
                        </Space>
                    </>)

            },


        ];

        const channelColumns = [
            { title: 'Id', dataIndex: 'id', key: 'id' },
            {
                title: 'Name', dataIndex: 'name', key: 'name'
            }
            ];
        // const contentBodyOptions = [
        //     { label: 'Channel Statistics', value: 'channelStatistics' },
        //     { label: 'Error metric', value: 'errorMetric' },
        //     { label: 'Usage', value: 'usage' },
        //   ];
        return (
            <>
                <Breadcrumb>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Mirth Monitoring</Breadcrumb.Item>
                    <Breadcrumb.Item>Email Scheduler</Breadcrumb.Item>
                </Breadcrumb>

                <div className="content_panel">
                    <Spin size="large" spinning={spin}>
                        <Modal title="Job Details" visible={isModalVisible} footer={null} onCancel={this.handleCancel} width={900} bodyStyle={{ overflowY: 'scroll', height: 380 }}>
 
                                {/* {alertType == "serverDownAlert"? (<Title level={4}>Servers : {serverName.map((server) =>(<li>{server.serverName}</li>))}</Title>):(<p>No</p>)} */}
                                <Title level={4}>Alert Type:{alertType.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}</Title>  {alertType == "serverDownAlert" || alertType =="sessionMonitoring" ||alertType == "userAuditReport" ?     
                            (<Collapse defaultActiveKey={['1']} style={{marginTop:'10px'}} accordion>
                            <Panel header="Servers" key="1"><p><ul>{serverName.map((server) =>(<li type="bullet">{server.serverName}</li>))}</ul></p></Panel>
                            <Panel header="To Address" key="2">
                                <p><ul>{emailId.map((i)=><li>{i}</li> )}</ul></p></Panel></Collapse>):(
                                    <Collapse defaultActiveKey={['1']} style={{marginTop:'10px'}} accordion>
                                    <Panel header="Servers" key="1"><p>{serverName.map((server) =>(<li type="bullet">{server.serverName}</li>))}</p></Panel>
                                    <Panel header="To Address" key="2">
                                        <p><ul><li>{emailId.map((i)=><li>{i}</li> )}</li></ul></p></Panel>
                                        <Panel header="Channels" key="3">
                                <Table columns={channelColumns} dataSource={modalMessage.channels} />
                                </Panel></Collapse>

                                )}
                          
                              
                                {/* <Panel header="Channels" key="1">
                                <Table columns={channelColumns} dataSource={modalMessage.channels} />
                                </Panel> */}

                        </Modal>
                      
                        <Row>

                            <Col span={12}>
                                <div class="content_panel_heading">Email Scheduler
                                </div>
                            </Col>
                            <Col span={12}>
                            {
                        isError ?(<></>):
                        (
                        <Link to="/mirth/deleted-jobs"><Button style={{ float: 'right' ,borderRadius:'7px',backgroundColor:'#efefef',fontFamily:'AktivGroteskMedium',border:'none'}} disabled={actionStatus('null', 'restore')}>
                                            <HistoryOutlined style={{color:'#fc9558',fontSize:'16px'}} /> Restore Jobs
                                </Button>
                        </Link>)}
                            </Col>

                        </Row>
                        {
                        isError ? (
                        <>
                          <div class="divider-1"> <span></span></div>
                        <Result
                            status={resultStatus}
                            title={resultTitle}
                            subTitle={''}
                            extra={resultStatus== '500'?<></>:  <Link to="/settings/server"><Button type="primary" style={{borderRadius:'7px'}}>Add Server</Button></Link>}
                        />
                        </>):(
                        <>
                        
                        <Tabs style={{ marginTop: '10px' }} activeKey={activeKey} onChange={this.onKeyChange} tabPosition="top">
                            <TabPane tab=" Jobs" key="2">


                                <Tooltip title="Refresh">
                                    <Button type="primary" shape="circle" icon={<ReloadOutlined />} onClick={this.refreshData} />
                                </Tooltip>
                                {/* <Button style={{ color: '#008fcd', float: 'right', marginRight: 10 }} onClick={this.refreshData} >
                                            <ReloadOutlined /> Refresh
                                </Button> */}
                                <Divider type="vertical" />

                                <SearchBar data={cloneScheduledJobs} handler={this.handleSearch} ref={this.myRef}></SearchBar>


                                <Table
                                    style={{ marginTop: '10px' }}
                                    className="email_scheduler"
                                    columns={scheduledJobsColumns}
                                    dataSource={scheduledJobs}

                                />


                            </TabPane>
                          
                                
                                <TabPane disabled={actionStatus()} tab="Create Job" key="1">

                                <div className="email_scheduler_form_panel">
                                    
                                    <Form name="scheduler_form" ref={this.formRef} hideRequiredMark
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                        onFinish={this.handleSubmit}
                                        validateMessages={validateMessages}
                                    >

                                        <Form.Item
                                            name="jobName"
                                            label="Job Name"
                                            validateTrigger="onBlur"
                                            rules={[
                                                {
                                                    required: true,
                                                    //   message: 'Please input Job name!',
                                                },
                                                ({
                                                    
                                                    getFieldValue
                                                }) => ({
                                                   
                                                    validator(rule, value) {
                                                        return new Promise((resolve, reject) => {
                                                            checkJobName(value).then(res => {
                                                                
                                                                if (res.data == true) {
                                                                    reject("Job Name already exists!");
                                                                }
                                                                else {
                                                                    isJobNameExists( currentUser.orgId,value).then(res => {
                                                                        if (res.code == 200) {
                                                                            let result = res.result[0];
                                                                        
                                                                            if(result.exists === true)
                                                                            {
                                                                                if(result.existsbyorg === true)
                                                                                {
                                                                                    resolve();
                                                                                }
                                                                                else
                                                                                {
                                                                                    reject("Job Name already exists!");
                                                                                }  
                                                                            }
                                                                            else
                                                                            {
                                                                                resolve();
                                                                            }
                                                                        }
                                                                    })
                                                                  
                                                                }

                                                            });
                                                        });
                                                    }

                                                })
                                            ]}
                                        >
                                            <Input
                                                placeholder="Enter Job name"
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            name="jobType"
                                            label="Job Type"
                                        >
                                            <Radio.Group onChange={this.handleJobTypeChange} defaultValue={1}>
                                                <Radio value={1}>One time job</Radio>
                                                <Radio value={2}>Cron job</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                        <Form.Item
                                            name="dateTime"
                                            label="Date Time"
                                            style={{ display: isOneTimeJob ? '' : 'none' }}
                                            rules={[
                                                {
                                                    required: isOneTimeJob,
                                                    //  message: 'Please input date time!',
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        var today=new Date();
                                                        if (getFieldValue("dateTime")<today) {
                                                            return Promise.reject(new Error('The seleceted date is passed'));
                                                           
                                                        }
                                                        return Promise.resolve();
                                                        
                                                    },
                                                }),
                                            

                                                
                                            
                                                        
                                        
                                                // {
                                                //     minDate:()=>{var today=new Date()},
                                                //     message:'passed date and time'
                                                // }
                                                // {
                                                //     validator:disabledDate(),
                                                //     message:'Passesd Date'
                                                // },
                                                // {
                                                //     validator:disabledTime(),
                                                //     message:'Passed Time'
                                                // }
                                            ]}

                                        >
                                            <DatePicker readOnly={this.readOnly} style={{ width: '300px', height: '35px', borderRadius: '7px' }} showTime disabledDate={disabledDate}  disabledTime={disabledTime}/>

                                        </Form.Item>

                                        <Form.Item
                                            name="cronExpression"
                                            label="Cron Expression"
                                            style={{ display: isOneTimeJob ? 'none' : '' }}
                                        >
                                            <Cron
                                                 onChange={(e)=> {this.handleCronChange(e)}}
                                                showResultText={true}
                                                showResultCron={true}
                                            />
                                        </Form.Item>
                                     
                                        <Form.List
                                            name="email"
                                            initialValue={['']}

                                        >
                                            {(fields, { add, remove }, { errors }) => (
                                                <>
                                                    {fields.map((field, index) => (
                                                        <Form.Item
                                                            {...(index === 0 ? '' : formItemLayoutWithOutLabel)}
                                                            label={index === 0 ? 'To Email' : ''}
                                                            required={false}
                                                            style={{ display: "flex", marginBottom: 8 }}
                                                            key={field.key}
                                                        >
                                                            <Form.Item
                                                                {...field}
                                                                validateTrigger={['onChange', 'onBlur']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        whitespace: true,
                                                                        message: "Please input email or delete this field.",
                                                                        //type: 'email'
                                                                    },
                                                                    {
                                                                        message: "Email is not valid",
                                                                        type: 'email'
                                                                    }
                                                                ]}
                                                                noStyle
                                                            >
                                                                <Input placeholder="Enter email" />
                                                            </Form.Item>
                                                            {fields.length > 1 ? (
                                                                <MinusCircleOutlined
                                                                    className="dynamic-delete-button"
                                                                    onClick={() => remove(field.name)}
                                                                />
                                                            ) : null}
                                                        </Form.Item>
                                                    ))}
                                                    <Form.Item  {...tailFormItemLayout}>
                                                        <Button
                                                            type="dashed"
                                                            onClick={() => add()}

                                                            icon={<PlusOutlined />}
                                                        >
                                                            Add Email
                                                    </Button>

                                                        <Form.ErrorList errors={errors} />
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List>
                                       


                                        <Form.Item
                                            name="context"
                                            label="Error Type"
                                            rules={[
                                                {
                                                    required: true,
                                                    // message: 'Please input context!',
                                                },
                                            ]}

                                        >
                                            {contextList.length !== 0 &&
                                                <Select placeholder="Select type" style={{ width: '300px' }} onChange={this.handleContextChange}>
                                                    {contextList.map((context) => (
                                                        <Option key={context.val} value={context.val}>{context.key}</Option>
                                                    ))

                                                    }
                                                </Select>}

                                        </Form.Item>
                                        <Form.Item name="interval" label="Select Interval:"
                                         style={{ display: isSessionMonitoring || isUserAuditReport || ischannelTrends? '' : 'none' }}>
                                       <Select style={{ width: '300px' }} placeholder="Select the interval">
                                       {interval.map((int) => (
                                                        <Option key={int.key} value={int.key}>{int.key}</Option>
                                                    ))

                                                    }
                                </Select>
                                </Form.Item >
                                <Form.Item label="Threshold in Minutes:" name="threshold" style={{ display:isServerDownAlert? '' : 'none' }}>
                                <Space><InputNumber min={3} max={60} defaultValue={threshold}onChange={this.onChange} />
                                <Tooltip title="when the data is older than the given minutes, server down alert will be triggered"><Typography.Link href="#"><QuestionCircleOutlined /></Typography.Link></Tooltip>
                                </Space>
                                </Form.Item>
                                <Form.Item label="Table Size:" style={{ display:isPgToastAlert? '' : 'none' }}>
                                
                                <Input.Group compact>
                                    
                                <Form.Item  name="tableValue"  
                                rules={[
                                    {
                                        required: isPgToastAlert,
                                        message: "Please enter the table size",
                                      },
                                    
                                    
                                ]}>
                                <InputNumber   /></Form.Item>
                                
                                <Form.Item name="suffix">
                <Select defaultValue="MB" onChange={this.handleChange}   style={{height:'40px'}}>
                               {tableSize.map((int) => (
                                                <Option key={int.key} value={int.val}>{int.key}</Option>
                                            ))
            
                                            }
                        </Select>
              
           

                        </Form.Item>
        
                        </Input.Group>
                        
                        </Form.Item>
                        <Form.Item
                                            name="fromDate"
                                            label="From Date"
                                            style={{ display: ischannelTrendsRange ||isUsageReportWithInterval? '' : 'none' }}
                                            // rules={[
                                            //     {
                                            //         required: isOneTimeJob,
                                            //         //  message: 'Please input date time!',
                                            //     },
                                            //     ({ getFieldValue }) => ({
                                            //         validator(_, value) {
                                            //             var today=new Date();
                                            //             if (getFieldValue("dateTime")<today) {
                                            //                 return Promise.reject(new Error('The seleceted date is passed'));
                                                           
                                            //             }
                                            //             return Promise.resolve();
                                                        
                                            //         },
                                            //     }),
                                            

                                                
                                            
                                                        
                                        
                                            //     // {
                                            //     //     minDate:()=>{var today=new Date()},
                                            //     //     message:'passed date and time'
                                            //     // }
                                            //     // {
                                            //     //     validator:disabledDate(),
                                            //     //     message:'Passesd Date'
                                            //     // },
                                            //     // {
                                            //     //     validator:disabledTime(),
                                            //     //     message:'Passed Time'
                                            //     // }
                                            // ]}

                                        >
                                            <DatePicker readOnly={this.readOnly} style={{ width: '300px', height: '35px', borderRadius: '7px' }} showTime/>

                                        </Form.Item>
                                        <Form.Item
                                            name="toDate"
                                            label="To Date"
                                            style={{ display: ischannelTrendsRange ||isUsageReportWithInterval ? '' : 'none' }}
                                            // rules={[
                                            //     {
                                            //         required: isOneTimeJob,
                                            //         //  message: 'Please input date time!',
                                            //     },
                                            //     ({ getFieldValue }) => ({
                                            //         validator(_, value) {
                                            //             var today=new Date();
                                            //             if (getFieldValue("dateTime")<today) {
                                            //                 return Promise.reject(new Error('The seleceted date is passed'));
                                                           
                                            //             }
                                            //             return Promise.resolve();
                                                        
                                            //         },
                                            //     }),
                                            

                                                
                                            
                                                        
                                        
                                            //     // {
                                            //     //     minDate:()=>{var today=new Date()},
                                            //     //     message:'passed date and time'
                                            //     // }
                                            //     // {
                                            //     //     validator:disabledDate(),
                                            //     //     message:'Passesd Date'
                                            //     // },
                                            //     // {
                                            //     //     validator:disabledTime(),
                                            //     //     message:'Passed Time'
                                            //     // }
                                            // ]}

                                        >
                                            <DatePicker readOnly={this.readOnly} style={{ width: '300px', height: '35px', borderRadius: '7px' }} showTime/>

                                        </Form.Item>
                               
                        
                                       
                                        <Form.Item
                                            name="server"
                                            label="Server"
                                            style={{ display: (isErrorPercentAlert || isZeroRecievedAlert || isRawDeltaAlert || isStdDevAlert || isStdDevObservation|| isErrorReport||ischannelTrends || isUsageReport||isSpikeReport||isWeeklyReport ||isErrorCountAlert||isPgToastAlert||ischannelTrendsRange||isUsageReportWithInterval) ? '' : 'none' }}
                                            rules={[
                                                {
                                                    required: (isErrorPercentAlert || isZeroRecievedAlert || isRawDeltaAlert || isStdDevAlert || isStdDevObservation|| isErrorReport||ischannelTrends||isUsageReport||isSpikeReport||isWeeklyReport||isErrorCountAlert||isPgToastAlert||ischannelTrendsRange||isUsageReportWithInterval),
                                                    //  message: 'Please input server!',
                                                },
                                            ]}


                                        >
                                            {
                                                <Select placeholder="Select server"
                                                    style={{ width: '300px' }}
                                                    onChange={this.handleServerChange}>
                                                    {serverOptions.map((server) => (
                                                        <Option key={server.accessToken} value={server.accessToken}>{server.serverName}</Option>
                                                    ))

                                                    }
                                                </Select>}

                                            </Form.Item>
                                        <Form.Item
                                            name="servers"
                                            label="Servers"
                                            style={{ display: (isServerDownAlert) ? '' : 'none' }}
                                            rules={[
                                                {
                                                    required: (isServerDownAlert),
                                                    //  message: 'Please input server!',
                                                },
                                            ]}


                                        >
                                            {
                                                <Select placeholder="Select server"
                                                    style={{ width: '300px' }}
                                                    mode="multiple"
                                                    allowClear
                                                    maxTagCount='responsive'>
                                                    {serverOptionsForServerDown.map((server) => (
                                                        <Option key={server.accessToken} value={server.accessToken}>{server.serverName}</Option>
                                                    ))

                                                    }
                                                </Select>}

                                        </Form.Item>
                                        {this.state.channelListData.length !== 0 ? (<Form.Item
                                            name="channels"
                                            label="Channel"
                                            style={{ display: (isZeroRecievedAlert || isStdDevAlert || isStdDevObservation || isErrorReport||isPgToastAlert||isWeeklyReport||isSpikeReport||ischannelTrends||isUsageReport||ischannelTrendsRange||isUsageReportWithInterval) ? '' : 'none' }}
                                        >

                                            <Transfer
                                                dataSource={this.state.channelListData}
                                                listStyle={{
                                                    width: 250,
                                                    height: 300,
                                                }}
                                                showSearch
                                                filterOption={(inputValue, item) =>
                                                    item.name.indexOf(inputValue) !== -1
                                                }
                                                targetKeys={this.state.targetKeys}
                                                onChange={this.handleChannelListChange}
                                                render={item => item.name}
                                            />
                                        </Form.Item>) : (<></>)}


                                        {accessToken !== null ?
                                            (<Form.Item
                                                name="channels"
                                                label="Channel"
                                                style={{ display: (isErrorPercentAlert || isRawDeltaAlert||isErrorCountAlert) ? '' : 'none' }}
                                            >

                                                <ChannelTransfer key={isServerChanged} accessToken={accessToken}  onSubmitMessage={this.handleChannelTransfer} errorType={this.state.isErrorCountAlert}/>

                                            </Form.Item>) : (<></>)
                                        }

                                       
                                        <Form.Item {...tailFormItemLayout}>
                                            <Button htmlType="submit" type="primary" style={{ borderRadius: '7px' }} >SUBMIT </Button>
                                            <Button htmlType="button" style={{ borderRadius: '7px', backgroundColor: '#efefef', marginLeft: '10px' }} onClick={this.onCancel}>
                                                Cancel
                                </Button>
                                <Button htmlType="button" style={{ backgroundColor: '#efefef',margin: "0 8px" }}  onClick={this.onReset}> Reset</Button>
                                        </Form.Item>
                                        
                                    </Form>
                                   

                                </div>
                            </TabPane>


                        </Tabs>
                    
                    </>
                        )
                                    }
    
                        
                    
                   
            
               
                
    
     </Spin>

</div>
    </>

        );
    
}
}
