import React, { Component } from "react";
import { Row, Col,Tag, Table, Typography,Divider, Card, Descriptions, Result, Space, Select, Tooltip, Modal, Form, Input, Button, DatePicker, Breadcrumb, Spin } from 'antd';
import {
    PlayCircleOutlined,
    StopOutlined,
    PauseCircleOutlined
} from '@ant-design/icons';
import { Link} from 'react-router-dom';
import {
    getServerList,
    getData,
    getMirthServerDetails,
    getOrgById
} from '../../../service/mirth/MirthService'
import {SearchBar} from '../../../components'
// import './ChannelList.css';
import '../../../styles/custom-css/antd-custom.css';

import moment from 'moment-timezone';
import { properties } from '../../../properties';


const {Option} = Select;
const {Text} = Typography;

export default class MirthServers extends Component {

    constructor(props) {
        super(props)

        this.state = {
            spin: true,
            modalTitle: '',
            isModalVisible: false,
            modalMessage: '',
            serverOptions:[],
            channelData:[],
            cloneserverOptions: [],
            resultStatus:'500',
            resultTitle:'500',
            resultSubTitle:'Server not reachable',
            currentServer:'',
            isError:false
        }

      ;
        this.handleSearch = this.handleSearch.bind(this);
       
    }

   
    componentDidMount() {
        getServerList().then(ServerRes=> {
            if (ServerRes.code == 200) {
                let server=[];
                server = ServerRes.result;
             
                if(server.length > 0)
                {
                    for(let i=0;i<server.length;i++)
                    {
                        getOrgById(server[i].orgId).then((orgRes)=>{
                            if(orgRes.result.length >0)
                            {
                            server[i].orgName = orgRes.result[0].orgName;

                            getMirthServerDetails(server[i].accessToken).then(serverRes=>{
                                if (serverRes.code == 200 && serverRes.result.length!=0 ) {
                                    let startTime = moment( serverRes.result[0].observationTime).tz("UTC");
                                    server[i].lastActiveTime =startTime.fromNow();
                                    var endTime = moment.tz("UTC");
                                     let timeDiff = moment.duration(endTime.diff(startTime));
                                    
                                     if(Math.floor(timeDiff.asHours()) == 0 && Math.floor(timeDiff.asDays()) == 0 && Math.floor(timeDiff.asMonths())== 0 && Math.floor(timeDiff.asYears())== 0 )
                                     {
                                         if(Math.floor(timeDiff.asMinutes()) <= 2)
                                         {
                                            server[i].state ="ACTIVE";
                                         }
                                         else{
                                            server[i].state ="IN ACTIVE";
                                         }
                                     }
                                     else{
                                        server[i].state ="IN ACTIVE";
                                     }
                                    
                                    this.setState({serverOptions:server,cloneserverOptions:server,spin: false});
                                     }
                                
                            })
                        }
                        })
                    }
                 
                }
                else
                {
                    this.setState({ resultStatus:'warning',resultTitle:'Add a server to check statistics', isError:true,spin: false})
                }
               
            }
        })
      
    }
    handleSearch(data)
    {
        console.log("In mirth search")
        console.log(data)
        this.setState({ serverOptions: data });
    }
   
 
    
    render() {


        const { spin, modalTitle, modalMessage, resultStatus,resultTitle,resultSubTitle, isModalVisible ,serverOptions,channelData,cloneserverOptions,isError} = this.state;
        const renderTag = (tag) => {

            if (tag == 'ACTIVE')
                return <Tag color="success">{tag}</Tag>;
            else
                return <Tag color="error">{tag}</Tag>;

        }
        const serverColumns = [
            { title: 'Server Id', dataIndex: 'serverid', key: 'serverid' },
            { title: 'Server Name', dataIndex: 'serverName', key: 'serverName' },
            { title: 'Server Version', dataIndex: 'serverVersion', key: 'serverVersion' },
            { title: 'Org Name', dataIndex: 'orgName', key: 'orgName' },
            { title: 'Last Active ', dataIndex: 'lastActiveTime', key: 'lastActiveTime' },
            { title: 'State', dataIndex: 'state', key: 'state',render: (text) => renderTag(text), },
        ];
        return (
            <>
                <Breadcrumb>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Admin</Breadcrumb.Item>
                    <Breadcrumb.Item>Mirth Servers</Breadcrumb.Item>
                </Breadcrumb>

                <div className="content_panel">

                    <Spin size="large" spinning={spin}>
                   
                     
                        <Row>

                            <Col span={5}>
                              <div class="content_panel_heading" >Mirth Servers
                                </div>
                            </Col>
                           
                           
                        </Row>
                        <Row>

                            <Col span={5}>
                              <div class="content_panel_heading" >
                                </div>
                            </Col>
                           
                           
                        </Row>
                        <div class="divider-1"> <span></span></div>
                        {/* <Divider /> */}
                        {
                        isError ? (
                        <>
                        <Result
                            status={resultStatus}
                            title={resultTitle}
                            subTitle={resultStatus== '500'?resultSubTitle:''}
                            extra={resultStatus== '500'?<></>:  <Link to="/settings/server"><Button type="primary" style={{borderRadius:'7px'}}>Add Server</Button></Link>}
                        />
                        </>):(
                        <>
                                <SearchBar data={cloneserverOptions} handler={this.handleSearch}></SearchBar>
                                <Table
                                  style={{marginTop:'10px'}}
                                    columns={serverColumns}
                                    dataSource={serverOptions}
                                
                                />
                           

                    </>)
                    }
                    </Spin>

                </div>
            </>
        );
    }
}

