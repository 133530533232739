import React, { Component } from 'react';

import {Layout, Breadcrumb, Spin, Table, Row, Col, Form, Button, notification, Input, Typography, Tabs, Space, Tooltip, InputNumber, Select } from 'antd';
import { PlusOutlined,LockOutlined,UserOutlined, EditOutlined, DeleteOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import Control_Center_logo from '../../assets/images/Control_Center_logo.png';
import AuthService from "../../service/Auth/auth-service";
import './login.css'


const { Header, Content, Footer, Sider } = Layout;
const openNotification = (message) => {
    notification.error({
        message: message,

    });
};
export default class Login extends Component {

    loginformRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {

            spin: false
        }

        // this.handleTabChange = this.handleTabChange.bind(this);
        this.onFinish = this.onFinish.bind(this);
        this.onReset = this.onReset.bind(this);
    }
    componentDidMount() {
       
    }
    onReset(){
        this.loginformRef.current.resetFields();
    }
    onFinish(val)
    {
        this.setState({spin:true});
            AuthService.login(val.userName, val.password).then(
              () => {
                this.props.history.push("/mirth/server-dashboard");
              //  window.location.reload();
              },
              error => {
                const resMessage =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
                  openNotification(resMessage)
                  this.setState({spin:false});
              }
            );
    }
   
    render() {
        const { spin} = this.state;
        return (
            <Layout style={{ minHeight: '100vh' }}>
             <Spin size="large" spinning={spin}>
             <Content >
             <img class="loginLogo" src={Control_Center_logo} alt="Control Connect logo" />
                    <div class="login_panel">
                      
                    <div class="login_heading" style={{ textAlign:'center' }}>Login
                                </div>
                                <div class="divider-1"> <span></span></div>
                        <Form  ref={this.loginformRef} labelCol={{ span: 8 }}
                        style={{padding:'20px 0 0 0'}}
      wrapperCol={{ span: 16 }} name="control-ref" onFinish={this.onFinish}>
                
                            <Form.Item
                                name="userName"
                                label="User Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter username!',
                                    },
                                ]}
                            >

                                <Input style={{ width: 230 }}
                                 prefix={<UserOutlined className="site-form-item-icon"/>}
                                    placeholder="Enter User Name"
                                   
                                />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                label="Password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter password!',
                                    },
                                ]}
                            >
                               <Input.Password
                                    style={{ width: 230 }}
                                    prefix={<LockOutlined className="site-form-item-icon"/>}
                                    placeholder="Enter password"
                                   
                                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                />
                            </Form.Item>
                          
                            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                
                                <Button type="primary"  style={{borderRadius:'7px'}}   htmlType="submit">
                                    Login
                                </Button>
                                <Button htmlType="button" style={{borderRadius:'7px', marginLeft:'10px',backgroundColor: "#e3e3e3"}}  onClick={this.onReset}>
                                Reset
                                </Button>
                            </Form.Item>
                        
                        </Form>
                      
                       
                    </div>
                    </Content>
                    <Footer style={{ textAlign:'center',color:'#505050'}}>2024 © Aigilx Health. All Rights Reserved.</Footer>
                </Spin>
             </Layout >
        )
    }
}